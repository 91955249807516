import { Helmet } from 'react-helmet';
import {
  Box, Container, Grid, Button, Stack
} from '@material-ui/core';
import AccountSetings from 'src/components/account/AccountSetings';
import {
  useMutation,
  QueryClient, QueryClientProvider, useQuery, isError
} from 'react-query';
import React from 'react';

import ProgressBar from 'src/components/common/ProgressBar';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { getMirConfiguration, updateMiRConfiguration } from 'src/api/api';
import { useTranslation } from 'react-i18next';
import StatusMessage from 'src/components/common/StatusMessage';
import { makeStyles } from '@material-ui/styles';

const queryClient = new QueryClient();

const SettingsView = () => {
  const defaultConfiguration = {
    mirRawFilePath: 'Please set', isBackupRequired: true, mirBackupFilePath: 'C:\\MirBackup', keepRawMirForDays: 2
  };
  const [configuration, setConfiguration] = React.useState(defaultConfiguration);
  const { t } = useTranslation();
  const [openDialog, setOpenDialog] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [responseMessage, setResponseMessage] = React.useState('');
  const useGetSettings = () => useQuery('info', async () => {
    getMirConfiguration().then((data) => {
      setConfiguration((prevConfiguration) => ({ ...prevConfiguration, ...data }));
      return data;
    });
  });
  const {
    isLoading, error, data, isFetching
  } = useGetSettings();
  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const ChangeValueMirFilePath = (e) => {
    setConfiguration({ ...configuration, mirRawFilePath: e.target.value });
  };
  const ChangeValueBackUp = (e) => {
    setConfiguration({ ...configuration, mirBackupFilePath: e.target.value });
  };

  const ChangeValueDay = (e) => {
    setConfiguration({ ...configuration, keepRawMirForDays: e.target.value });
  };

  const ChangeValueBackUpRequired = (e) => {
    setConfiguration({ ...configuration, isBackupRequired: e.target.checked });
  };
  const useUpdateMutation = useMutation(
    () => updateMiRConfiguration(configuration),
    {
      onSuccess: (r) => {
        setResponseMessage(r.data.message);
      },
      onError: () => {
        setResponseMessage('Error While Updating');
      },
    }
  );

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const handleSubmit = () => {
    useUpdateMutation.mutate();
    setOpenDialog(false);

    setOpen(true);
  };

  const showMessage = () => (

    <div>
      <StatusMessage
        open={open}
        title={t('Response Message')}
        message={responseMessage}
      >
        <Stack direction="row" spacing={2}>
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={handleClose}
          >
            OK
          </Button>
        </Stack>
      </StatusMessage>
    </div>

  );
  const useStyles = makeStyles({
    commonBtn: {
      width: '120px'
    }
  });
  const classes = useStyles();

  const loading = <span><ProgressBar /></span>;
  if ((useUpdateMutation.isLoading || useUpdateMutation.isFetching || isLoading) ? loading : null);
  const settings = (
    <div>
      <Container maxWidth style={{ padding: 0 }}>
        <AccountSetings
          onMirFilePathChange={ChangeValueMirFilePath}
          onBackupMirFilePathChange={ChangeValueBackUp}
          onExpiryDateChange={ChangeValueDay}
          onBackUpRequiredChange={ChangeValueBackUpRequired}
          {...configuration}
        />
        <Box sx={{ pt: 1, float: 'right' }}>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={3}
              sm={12}
            >
              <Button
                className={classes.commonBtn}
                variant="contained"
                color="primary"
                onClick={handleClickOpenDialog}
              >
                {t('Update')}
              </Button>
              <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                aria-labelledby="draggable-dialog-title"
              >
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                  {t('MIR SETTINGS')}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    {t('Are you sure to update the  setting(s)?')}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={handleSubmit}
                    color="primary"
                  >
                    {t('OK')}
                  </Button>
                  <Button autoFocus onClick={handleCloseDialog} color="primary">
                    {t('Cancel')}
                  </Button>

                </DialogActions>
              </Dialog>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </div>
  );
  return (
    <>
      <Helmet>
        <title>{t('Travelport GBS | Settings')}</title>
      </Helmet>
      <QueryClientProvider client={queryClient}>
        {(isLoading || isFetching) && !data ? (
          <ProgressBar />
        ) : (
          settings)}
        {((error || isError) ? showMessage() : null)}
      </QueryClientProvider>
    </>
  );
};

export default SettingsView;
