import React from 'react';
// import { useState } from 'react';
import {
  TextField,
  Grid,
  CardContent
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ConnectForm from '../common/ConnectForm';

const Credit = (prop) => {
  const {
    cardcode, cardnumber, holdername, expirationdt, approvalnum, billingamount, division, functionalclassification1, functionalclassification2, cardremarks1, cardremarks2, cardremarks3,
    readOnlyFlag
  } = prop;
  const { t } = useTranslation();
  const TextStyleReadOndy = {
    '& .MuiInputBase-input': {
      color: '#0A121A',
      padding: '10px',
      backgroundColor: '#ececec'
    },
  };
  const TextStyleChangeable = {
    '& .MuiInputBase-input': {
      color: '#0A121A',
      padding: '10px',
      // textTransform: 'uppercase'
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'rgba(0, 0, 0, 0.23)',
      },
      '&:hover fieldset': {
        borderColor: '#FF5E6F',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#FF5E6F',
      },
    },
  };

  return (
    <ConnectForm>
      {({ register }) => (
        <CardContent>
          <Grid
            container
            spacing={3}
            sx={{ marginBottom: '10px' }}
          >
            <Grid
              item
              md={2}
              sm={12}
            >
              <TextField
                sx={readOnlyFlag ? TextStyleReadOndy : TextStyleChangeable}
                fullWidth
                label={t('Card Code')}
                InputLabelProps={{ shrink: true }}
                name="cardcode"
                {...register('cardcode')}
                defaultValue={cardcode}
                variant="outlined"
                InputProps={{ readOnly: readOnlyFlag }}
              />
            </Grid>
            <Grid
              item
              md={3}
              sm={12}
            >
              <TextField
                sx={readOnlyFlag ? TextStyleReadOndy : TextStyleChangeable}
                fullWidth
                label={t('Card Number')}
                InputLabelProps={{ shrink: true }}
                name="cardnumber"
                {...register('cardnumber')}
                defaultValue={cardnumber}
                variant="outlined"
                InputProps={{ readOnly: readOnlyFlag }}
              />
            </Grid>
            <Grid
              item
              md={3}
              sm={12}
            >
              <TextField
                sx={readOnlyFlag ? TextStyleReadOndy : TextStyleChangeable}
                fullWidth
                label={t('Name of the holder')}
                InputLabelProps={{ shrink: true }}
                name="holdername"
                {...register('holdername')}
                defaultValue={holdername}
                variant="outlined"
                InputProps={{ readOnly: readOnlyFlag }}
              />
            </Grid>
            <Grid
              item
              md={2}
              sm={12}
            >
              <TextField
                sx={readOnlyFlag ? TextStyleReadOndy : TextStyleChangeable}
                fullWidth
                label={t('Expiration Date')}
                InputLabelProps={{ shrink: true }}
                name="expirationdt"
                {...register('expirationdt')}
                defaultValue={expirationdt}
                variant="outlined"
                InputProps={{ readOnly: readOnlyFlag }}
              />
            </Grid>
            <Grid
              item
              md={2}
              sm={12}
            >
              <TextField
                sx={readOnlyFlag ? TextStyleReadOndy : TextStyleChangeable}
                fullWidth
                label={t('Approval Number')}
                InputLabelProps={{ shrink: true }}
                name="approvalnum"
                {...register('approvalnum')}
                defaultValue={approvalnum}
                variant="outlined"
                InputProps={{ readOnly: readOnlyFlag }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={3}
            sx={{ marginBottom: '10px' }}
          >
            <Grid
              item
              md={2}
              sm={12}
            >
              <TextField
                sx={readOnlyFlag ? TextStyleReadOndy : TextStyleChangeable}
                fullWidth
                label={t('Billing amount')}
                InputLabelProps={{ shrink: true }}
                name="billingamount"
                {...register('billingamount')}
                defaultValue={billingamount}
                variant="outlined"
                InputProps={{ readOnly: readOnlyFlag }}
              />
            </Grid>
            <Grid
              item
              md={2}
              sm={12}
            >
              <TextField
                sx={readOnlyFlag ? TextStyleReadOndy : TextStyleChangeable}
                fullWidth
                label={t('Division')}
                InputLabelProps={{ shrink: true }}
                name="division"
                {...register('division')}
                defaultValue={division}
                variant="outlined"
                InputProps={{ readOnly: readOnlyFlag }}
              />
            </Grid>
            <Grid
              item
              md={4}
              sm={12}
            >
              <TextField
                sx={readOnlyFlag ? TextStyleReadOndy : TextStyleChangeable}
                fullWidth
                label={t('Classification Code(MK 2 digits)')}
                InputLabelProps={{ shrink: true }}
                name="functionalclassification1"
                {...register('functionalclassification1')}
                defaultValue={functionalclassification1}
                variant="outlined"
                InputProps={{ readOnly: readOnlyFlag }}
              />
            </Grid>
            <Grid
              item
              md={4}
              sm={12}
            >
              <TextField
                sx={readOnlyFlag ? TextStyleReadOndy : TextStyleChangeable}
                fullWidth
                label={t('Classification Code(MK 14 digits)')}
                InputLabelProps={{ shrink: true }}
                name="functionalclassification2"
                {...register('functionalclassification2')}
                defaultValue={functionalclassification2}
                variant="outlined"
                InputProps={{ readOnly: readOnlyFlag }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={3}
            sx={{ marginBottom: '10px' }}
          >
            <Grid
              item
              md={4}
              sm={12}
            >
              <TextField
                sx={readOnlyFlag ? TextStyleReadOndy : TextStyleChangeable}
                fullWidth
                label="Card Remarks(R1)"
                InputLabelProps={{ shrink: true }}
                name="cardremarks1"
                {...register('cardremarks1')}
                defaultValue={cardremarks1}
                variant="outlined"
                InputProps={{ readOnly: readOnlyFlag }}
              />
            </Grid>
            <Grid
              item
              md={4}
              sm={12}
            >
              <TextField
                sx={readOnlyFlag ? TextStyleReadOndy : TextStyleChangeable}
                fullWidth
                label="Card Remarks(R2)"
                InputLabelProps={{ shrink: true }}
                name="cardremarks2"
                {...register('cardremarks2')}
                defaultValue={cardremarks2}
                variant="outlined"
                InputProps={{ readOnly: readOnlyFlag }}
              />
            </Grid>
            <Grid
              item
              md={4}
              sm={12}
            >
              <TextField
                sx={readOnlyFlag ? TextStyleReadOndy : TextStyleChangeable}
                fullWidth
                label="Card Remarks(R3)"
                InputLabelProps={{ shrink: true }}
                name="cardremarks3"
                {...register('cardremarks3')}
                defaultValue={cardremarks3}
                variant="outlined"
                InputProps={{ readOnly: readOnlyFlag }}
              />
            </Grid>
          </Grid>
        </CardContent>
      )}
    </ConnectForm>

  );
};

export default Credit;
