import React from 'react';
import { Helmet } from 'react-helmet';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Container,
  // Stack
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link

} from '@material-ui/core';

import MirToDiskListResults from 'src/components/importmirtodisk/MirToDiskListResults';
import DateFilterResult from 'src/components/importmirtodisk/DateFilterResult';
import InformationResult from 'src/components/importmirtodisk/InformationResult';
import ImportResult from 'src/components/importmirtodisk/ImportResult';
import Button from '@material-ui/core/Button';
import {
  useMutation, useQuery, QueryClientProvider, QueryClient
} from 'react-query';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useTranslation } from 'react-i18next';
import NetworkErrorPage from 'src/components/common/NetworkErrorPage';
import {
  healthCheck, getAllMirFiles, getDateRangeMirFiles, processingMiRFiles, getMirInformation
} from 'src/api/api';
import Modal from '@material-ui/core/Modal';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import Paper from '@material-ui/core/Paper';
import 'react-perfect-scrollbar/dist/css/styles.css';
// import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import Logout from './Logout';

const UNAUTHORIZE_STATUS_CODE = 401;
const queryClient = new QueryClient();

const MIRToDiskPage = () => {
  const [connectionStatus, setConnectionStatus] = React.useState(<></>);
  const [filesFromApi, setFilesFromApi] = React.useState([]);
  const [selectedFiles, setSelectedFiles] = React.useState([]);
  const [isSelectAllResult, setSelectAllResult] = React.useState(false);
  const [isSelectAll, setSelectAll] = React.useState(true);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false); // confirmation Dialog
  const [openLoadingModal, setOpenLoadingModal] = React.useState(false);
  const [openResponseDialog, setOpenResponseDialog] = React.useState(false);
  const isLogged = localStorage.getItem('token');
  const { t } = useTranslation();
  if (!isLogged) {
    Logout();
  }

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const {
    error
  } = useQuery('info', getAllMirFiles,
    {
      onSuccess: (r) => {
        setFilesFromApi(r.data);
      },
      onError: (r) => {
        console.log(r);
      },
      refetchOnWindowFocus: false
    });

  if (error && error.status === UNAUTHORIZE_STATUS_CODE) {
    Logout();
  }

  const useGetInformation = () => useQuery('MirInfo', async () => getMirInformation(), { refetchOnWindowFocus: false });
  const {
    data: dataMirInfo, isLoading: isLoadingMirInfo, isFetching: isFetchingMirInfo, error: isErrorMirInfo
  } = useGetInformation();
  // eslint-disable-next-line no-empty
  if (isErrorMirInfo) {

  }
  const LoadingMirInfo = (
    <div style={{
      marginTop: 5, marginBottom: 5, display: 'flex', justifyContent: 'center'
    }}
    >
      <CircularProgress />
    </div>
  );
  const errorMessageMirInfo = isErrorMirInfo ? (
    <span>
      {t('Error unable to retrieve mir file information. Please check the folder exist.')}
      <Link href="/app/settings" color="inherit">
        {'Click Here '}
      </Link>
    </span>
  ) : null;
  const mirInfos = dataMirInfo
    ? (

      <InformationResult mirInfos={dataMirInfo} />
    )
    : errorMessageMirInfo;

  const mutation = useMutation((filter) => {
    if (filter.isSearchAll) {
      return getAllMirFiles().then((r) => r.data);
    }
    return getDateRangeMirFiles(filter.startDateTime, filter.endDateTime);
  }, {
    onSuccess: (r) => {
      setFilesFromApi(r);
    }
  });

  const getAllMutation = useMutation(
    () => getAllMirFiles(),
    {
      onSuccess: (r) => {
        setFilesFromApi(r.data);
      }
    }
  );

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };
  const handleSearchAllFiles = () => {
    setSelectAllResult(false);
    setSelectedFiles([]);
    mutation.mutate({ isSearchAll: true });
  };

  const handleDateTimeFilterChanged = (filter) => {
    setSelectAllResult(false);
    setSelectedFiles([]);
    mutation.mutate(filter);
  };

  const handleSelectedFileChange = (file, isSelected) => {
    const selected = [...selectedFiles, file];
    // console.log(selected);
    if (isSelected) setSelectedFiles(selected);
    else setSelectedFiles(selectedFiles.filter((item) => item.id !== file.id));

    // const allFiles = mutation.data ? mutation.data.mirFiles : data.mirFiles;
    if (filesFromApi && filesFromApi.mirFiles) {
      const allFilesSorted = filesFromApi.mirFiles.slice().sort((a, b) => ((a.id > b.id) ? 1 : -1));
      const selectedSorted = filesFromApi.mirFiles.slice().sort((a, b) => ((a.id > b.id) ? 1 : -1));

      const isAllSelected = selected.length === allFilesSorted.length
        && selectedSorted.every((value, index) => value.id === allFilesSorted[index].id);

      setSelectAllResult(isAllSelected);
    }
  };
  const useSubmitSelectedFilesMutation = useMutation(
    () => processingMiRFiles(selectedFiles.map((s) => s.id)),
    {
      onSuccess: () => {
        setOpenResponseDialog(true);
        setOpenLoadingModal(false);
        getAllMutation.mutate();
      },
      onError: () => {
        setOpenResponseDialog(true);
        setOpenLoadingModal(false);
        getAllMutation.mutate();
      }
    }
  );

  // const handleSubmit = () => {
  //   useSubmitSelectedFilesMutation.mutate();
  //   setOpenDialog(false);
  //   setFilesFromApi([]);
  //   setOpenResponseDialog(true);
  //   setSelectAll(true);
  //   getAllMutation.mutate();
  // };

  const handleSubmit = () => {
    setOpenLoadingModal(true);
    useSubmitSelectedFilesMutation.mutate();
    setOpenDialog(false);
    setFilesFromApi([]);
    setSelectAll(true);
  };
  const handleSelectAllChange = (isSelected) => {
    setSelectAllResult(isSelected);
    setSelectedFiles(isSelected ? filesFromApi.mirFiles : []);
  };

  // const loading = (
  //   <div style={{
  //     marginTop: 5, marginBottom: 5, display: 'flex', justifyContent: 'center'
  //   }}
  //   >
  //     <CircularProgress />
  //   </div>
  // );

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      getAllMutation.mutate();
      return;
    }
    getAllMutation.mutate();
    setOpenLoadingModal(false);
    setOpenResponseDialog(false);
  };

  const DialogStyle = {
    '& .MuiDialog-paper': {
      color: '#0A121A',
      padding: '10px',
      backgroundColor: '#ececec',
      maxWidth: '900px'
    },
  };

  const DialogLoadingStyle = {
    '& .MuiDialog-paper': {
      color: '#0A121A',
      padding: '10px',
      backgroundColor: '#ececec',
      maxWidth: '300px'
    },
  };

  const TableStyle = {
    '& .MuiTableCell-root': {
      padding: '10px'
    },
  };

  const useStyles = makeStyles(() => ({
    boxLeft: {
      float: 'left'
    },
    boxRight: {
      float: 'left',
      marginBottom: '5px',
      marginLeft: '5px',
    },
    boxBottom: {
      clear: 'both'
    }
  }));
  const classes = useStyles();

  const errorMessage = useSubmitSelectedFilesMutation.error ? useSubmitSelectedFilesMutation.error.toString() : null;

  const duplicateWhenImportNewTickets = (useSubmitSelectedFilesMutation.data != null) ? useSubmitSelectedFilesMutation.data.data.duplicateWhenImportNewTickets
    : null;
  const nonExistentToVoidTickets = (useSubmitSelectedFilesMutation.data != null) ? useSubmitSelectedFilesMutation.data.data.NonExistentToVoidTickets
    : null;
  const nonExistentToRefundTickets = (useSubmitSelectedFilesMutation.data != null) ? useSubmitSelectedFilesMutation.data.data.nonExistentToRefundTickets
    : null;
  const nonExistentToCancelRefundTickets = (useSubmitSelectedFilesMutation.data != null) ? useSubmitSelectedFilesMutation.data.data.nonExistentToCancelRefundTickets
    : null;
  const duplicateWhenImportExchangeTickets = (useSubmitSelectedFilesMutation.data != null) ? useSubmitSelectedFilesMutation.data.data.duplicateWhenImportExchangeTickets
    : null;
  const duplicateWhenImportEMDTickets = (useSubmitSelectedFilesMutation.data != null) ? useSubmitSelectedFilesMutation.data.data.duplicateWhenImportEMDTickets
    : null;
  const errorneousFiles = (useSubmitSelectedFilesMutation.data != null) ? useSubmitSelectedFilesMutation.data.data.errorneousFiles
    : null;
  const getErrorMessage = (useSubmitSelectedFilesMutation.data != null && useSubmitSelectedFilesMutation.data.data.message != null && useSubmitSelectedFilesMutation.data.data.message.indexOf('The Failed File') === -1) ? null
    : t('Registration failure file exists');
  // console.log(getErrorMessage);

  const registeredTicketCount = (useSubmitSelectedFilesMutation.data != null) ? (useSubmitSelectedFilesMutation.data.data.succesfullImportedNewTickets.length
    + useSubmitSelectedFilesMutation.data.data.successfulVoidedTickets.length
    + useSubmitSelectedFilesMutation.data.data.successfulRefundedTickets.length
    + useSubmitSelectedFilesMutation.data.data.successfulCancelRefundedTickets.length
    + useSubmitSelectedFilesMutation.data.data.succesfullImportedExchangeTickets.length
    + useSubmitSelectedFilesMutation.data.data.succesfullImportedEMDTickets.length) : 0;

  const handleCloseModel = () => {
    setOpenModal(false);
  };

  const handleCloseLoadingModel = () => {
  };

  const importResultModal = (
    <Modal
      open={openModal}
      onClose={handleCloseModel}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <ImportResult
        // succesfullNewTickets={succesfullImportedNewTickets}
        duplicateTickets={duplicateWhenImportNewTickets}
        voidTickets={nonExistentToVoidTickets}
        refundTickets={nonExistentToRefundTickets}
        cancelRefundTickets={nonExistentToCancelRefundTickets}
        exchangeTickets={duplicateWhenImportExchangeTickets}
        eMDTickets={duplicateWhenImportEMDTickets}
        errorTickets={errorneousFiles}
      />
    </Modal>
  );

  const displayLoading = (
    <Modal
      open={openLoadingModal}
      onClose={handleCloseLoadingModel}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <Dialog
        sx={DialogLoadingStyle}
        open={openLoadingModal}
      >
        <DialogContent>
          <Card style={{ justifyContent: 'center' }}>
            <CircularProgress style={{
              marginTop: 10, marginBottom: 5, marginLeft: 60
            }}
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Now Processing....
              </Typography>
            </CardContent>
          </Card>
        </DialogContent>
      </Dialog>
    </Modal>
  );

  const canViewDetail = (((duplicateWhenImportNewTickets != null && duplicateWhenImportNewTickets.length > 0)
    || (nonExistentToVoidTickets != null && nonExistentToVoidTickets.length > 0)
    || (nonExistentToRefundTickets != null && nonExistentToRefundTickets.length > 0)
    || (nonExistentToCancelRefundTickets != null && nonExistentToCancelRefundTickets.length > 0))
    || (duplicateWhenImportExchangeTickets != null && duplicateWhenImportExchangeTickets.length > 0)
    || (duplicateWhenImportEMDTickets != null && duplicateWhenImportEMDTickets.length > 0)
    || (errorneousFiles != null && errorneousFiles.length > 0));

  const viewDetailButton = canViewDetail ? (
    <Button
      size="small"
      variant="contained"
      onClick={() => setOpenModal(true)}
    >
      {t('View Details')}
    </Button>
  ) : null;

  const message = (useSubmitSelectedFilesMutation.data != null) ? (
    <Box>
      <div className={classes.boxLeft}>
        <TableContainer component={Paper} sx={TableStyle}>
          <Table aria-label="simple table">
            <TableBody>
              <TableRow>
                <TableCell align="left">
                  {t('Number of MIR files processed')}
                  :
                </TableCell>
                <TableCell align="right">{useSubmitSelectedFilesMutation.data.data.completedProcessedFiles.length}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">
                  {t('Number of PNRs processed')}
                  :
                </TableCell>
                <TableCell align="right">{useSubmitSelectedFilesMutation.data.data.totalPNRProcessed}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">
                  {t('Number of registered tickets')}
                  :
                </TableCell>
                <TableCell align="right">{registeredTicketCount}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div className={classes.boxRight}>
        <TableContainer component={Paper} sx={TableStyle}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell colSpan={2}>
                  {t('breakdown')}
                  :
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell align="left">
                  {t('succesfullImportedNewTickets')}
                  :
                </TableCell>
                <TableCell align="right">{useSubmitSelectedFilesMutation.data.data.succesfullImportedNewTickets.length}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">
                  {t('successfulVoidedTickets')}
                  :
                </TableCell>
                <TableCell align="right">{useSubmitSelectedFilesMutation.data.data.successfulVoidedTickets.length}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">
                  {t('successfulRefundedTickets')}
                  :
                </TableCell>
                <TableCell align="right">{useSubmitSelectedFilesMutation.data.data.successfulRefundedTickets.length}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">
                  {t('successfulCancelRefundedTickets')}
                  :
                </TableCell>
                <TableCell align="right">{useSubmitSelectedFilesMutation.data.data.successfulCancelRefundedTickets.length}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">
                  {t('succesfullImportedExchangeTickets')}
                  :
                </TableCell>
                <TableCell align="right">{useSubmitSelectedFilesMutation.data.data.succesfullImportedExchangeTickets.length}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">
                  {t('succesfullImportedEMDTickets')}
                  :
                </TableCell>
                <TableCell align="right">{useSubmitSelectedFilesMutation.data.data.succesfullImportedEMDTickets.length}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div className={classes.boxBottom}>
        <TableContainer component={Paper} sx={TableStyle}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell colSpan={3}>
                  {t('Error files')}
                  :

                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell align="left">
                  {t('Error Message')}
                  :
                </TableCell>
                {/* <TableCell align="right">{useSubmitSelectedFilesMutation.data.data.exceptionMessage}</TableCell> */}
                <TableCell align="right">{getErrorMessage}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">
                  {t('duplicateWhenImportNewTickets')}
                  :
                </TableCell>
                <TableCell align="right">{useSubmitSelectedFilesMutation.data.data.duplicateWhenImportNewTickets.length}</TableCell>
                <TableCell align="center" rowSpan={7}>{viewDetailButton}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">
                  {t('duplicateWhenImportExchangeTickets')}
                  :
                </TableCell>
                <TableCell align="right">{useSubmitSelectedFilesMutation.data.data.duplicateWhenImportExchangeTickets.length}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">
                  {t('duplicateWhenImportEMDTickets')}
                  :
                </TableCell>
                <TableCell align="right">{useSubmitSelectedFilesMutation.data.data.duplicateWhenImportEMDTickets.length}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">
                  {t('nonExistentToVoidTickets')}
                  :
                </TableCell>
                <TableCell align="right">{useSubmitSelectedFilesMutation.data.data.nonExistentToVoidTickets.length}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">
                  {t('nonExistentToRefundTickets')}
                  :
                </TableCell>
                <TableCell align="right">{useSubmitSelectedFilesMutation.data.data.nonExistentToRefundTickets.length}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">
                  {t('nonExistentToCancelRefundTickets')}
                  :
                </TableCell>
                <TableCell align="right">{useSubmitSelectedFilesMutation.data.data.nonExistentToCancelRefundTickets.length}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">
                  {t('errorneousFiles')}
                  :
                </TableCell>
                <TableCell align="right">{useSubmitSelectedFilesMutation.data.data.errorneousFiles.length}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </Box>
  )
    : errorMessage;

  const updateStatusMessage = (message != null) ? (

    <div>
      <Dialog
        sx={DialogStyle}
        open={openResponseDialog}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle
          style={{ cursor: 'move', fontWeight: 'bold' }}
          id="draggable-dialog-title"
        >
          {t('Response Message')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleClose}>
            {t('CLOSE')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  ) : null;

  const dateFilter = <DateFilterResult isSelectAll={isSelectAll} dateTimeFilterChanged={handleDateTimeFilterChanged} onSelectAll={handleSearchAllFiles} IsImportBackup="false" />;

  const mutationFileResult = !filesFromApi.mirFiles ? displayLoading : (

    <MirToDiskListResults
      files={filesFromApi.mirFiles}
      selectedFiles={selectedFiles}
      onSelectedFilesChange={handleSelectedFileChange}
      isSelectAll={isSelectAllResult}
      onSelectAllChange={handleSelectAllChange}
      useSubmitSelectedFilesMutation={useSubmitSelectedFilesMutation}
      updateStatusMessage={updateStatusMessage}
      importResultModal={importResultModal}
      openDialog={openDialog}
      onCloseDialog={handleCloseDialog}
      onhandleSubmit={handleSubmit}
      onClickOpenDialog={handleClickOpenDialog}
      displayLoading={displayLoading}
      IsImportBackup="false"
    />
  );

  React.useEffect(() => {
    healthCheck().then((r) => {
      if (!r) {
        setConnectionStatus(NetworkErrorPage(t('Connection to server is lost. Please check API server'),
          () => Logout()));
      }
    });
  });
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <Helmet>
          <title>{t('Travelport GBS | Import MIR')}</title>
        </Helmet>
        {connectionStatus}
        <Box
          sx={{
            backgroundColor: 'background.default',
            minHeight: '100%',
            maxWidth: '1100px',
            minWidth: '1100px',
            py: 3
          }}
        >
          <Container maxWidth>
            <Box sx={{ pt: 0 }} color="primary.main" borderBottom={0} marginBottom={0}>
              {isLoadingMirInfo || isFetchingMirInfo
                ? LoadingMirInfo
                : mirInfos}
            </Box>
            <Box sx={{ pt: 1 }} color="primary.main" borderBottom={0} marginBottom={0}>
              {dateFilter}
            </Box>
            <Box sx={{ pt: 1 }}>
              {mutationFileResult}
            </Box>
          </Container>
        </Box>
      </QueryClientProvider>
    </>
  );
};

export default MIRToDiskPage;
