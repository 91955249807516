import React from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {
  TextField,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TablePagination from '@material-ui/core/TablePagination';
import { makeStyles } from '@material-ui/styles';
import {
  useMutation
} from 'react-query';

import { updateAirlineCodeConfigurations, deleteAirlineCodeConfigurations, addAirlineCodeConfigurations } from 'src/api/api';
import { useTranslation } from 'react-i18next';

const AirlineCodeRow = (props) => {
  const { row, refetch } = props;
  const [codeState, setCodeState] = React.useState(row);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);

  const handleChange = (e) => {
    row.decode = e.target.value;
    setCodeState((prevConfiguration) => ({ ...prevConfiguration, ...row }));
  };

  // const handleCheckChange = (e) => {
  //   row.isIATA = e.target.checked;
  //   setCodeState((prevConfiguration) => ({ ...prevConfiguration, ...row }));
  // };
  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleClosDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };
  const handleClickDeleteOpenDialog = () => {
    setOpenDeleteDialog(true);
  };
  const dataToUpdate = { airlineConfiguration: [row] };
  const useUpdateMutation = useMutation(
    () => updateAirlineCodeConfigurations(dataToUpdate).then(refetch)
  );

  const dataToDelete = { airlineConfiguration: [row] };
  const useDeleteMutation = useMutation(
    () => deleteAirlineCodeConfigurations(dataToDelete).then(refetch)
  );
  const handleSubmit = () => {
    useUpdateMutation.mutate();
    setOpenDialog(false);
  };

  const handleDelete = () => {
    useDeleteMutation.mutate();
    setOpenDeleteDialog(false);
  };

  const { t } = useTranslation();

  const useStyles = makeStyles({
    commonBtnSmall: {
      width: '90px'
    }
  });
  const classes = useStyles();

  const TextStyleChangeable = {
    '& .MuiInputBase-input': {
      color: '#0A121A',
      padding: '10px',
      // textTransform: 'uppercase'
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'rgba(0, 0, 0, 0.23)',
      },
      '&:hover fieldset': {
        borderColor: '#FF5E6F',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#FF5E6F',
      },
    },
  };
  return (
    <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
      <TableCell align="center" width="60" sx={{ padding: 1 }}>{codeState.airlineNumber}</TableCell>
      <TableCell align="center" width="80" sx={{ padding: 1 }}>{codeState.airlineCode}</TableCell>
      <TableCell align="left" width="260" sx={{ padding: 1 }}>{codeState.airlineName}</TableCell>
      <TableCell align="left" width="380" sx={{ padding: 1 }}><TextField sx={TextStyleChangeable} value={codeState.decode} onChange={(e) => handleChange(e)} fullWidth="true" /></TableCell>
      {/* <TableCell align="left"><Checkbox checked={codeState.isIATA} onChange={(e) => handleCheckChange(e)} /></TableCell> */}
      <TableCell colSpan={2} sx={{ padding: 1 }}>
        <Button
          className={classes.commonBtnSmall}
          variant="contained"
          color="primary"
          onClick={handleClickOpenDialog}
        >
          {t('Update')}
        </Button>
        <Button
          className={classes.commonBtnSmall}
          sx={{ marginLeft: 1 }}
          variant="contained"
          color="primary"
          onClick={handleClickDeleteOpenDialog}
        >
          {t('Delete')}
        </Button>
      </TableCell>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle
          style={{ cursor: 'move' }}
          id="draggable-dialog-title"
        >
          {t('Update Airline Code')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('Are you sure to update the settings')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit} color="primary">
            OK
          </Button>
          <Button autoFocus onClick={handleCloseDialog} color="primary">
            {t('Cancel')}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openDeleteDialog}
        onClose={handleClosDeleteDialog}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle
          style={{ cursor: 'move' }}
          id="draggable-dialog-title"
        >
          {t('Delete Airline Code')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('Are you sure to delete the settings')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDelete} color="primary">
            OK
          </Button>
          <Button autoFocus onClick={handleClosDeleteDialog} color="primary">
            {t('Cancel')}
          </Button>
        </DialogActions>
      </Dialog>

    </TableRow>

  );
};
AirlineCodeRow.propTypes = {
  row: PropTypes.object.isRequired,
  refetch: PropTypes.func.isRequired
};

const AirlineCodeTable = (props) => {
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [open, setOpen] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 5));
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const { airlineCodes, refetch } = props;
  const [configuration, setConfiguration] = React.useState('');

  const handleClose = () => {
    setOpen(false);
  };
  const addToUpdate = { airlineConfiguration: [configuration] };
  const addUpdateMutation = useMutation(
    () => addAirlineCodeConfigurations(addToUpdate).then(refetch)
  );

  const handleAddSubmit = () => {
    addUpdateMutation.mutate();
    setOpen(false);
  };

  const ChangeValueAirlineNumber = (e) => {
    console.log(configuration);
    setConfiguration({ ...configuration, airlineNumber: e.target.value });
  };
  const ChangeValueAirlineCode = (e) => {
    setConfiguration({ ...configuration, airlineCode: e.target.value });
  };
  const ChangeValueAirlineName = (e) => {
    setConfiguration({ ...configuration, airlineName: e.target.value });
  };
  const ChangeValueDecode = (e) => {
    setConfiguration({ ...configuration, decode: e.target.value });
  };

  // const ChangeValueIATA = (e) => {
  //   setConfiguration({ ...configuration, isiata: e.target.checked });
  // };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const useStyles = makeStyles({
    commonBtn: {
      width: '120px'
    }
  });

  const classes = useStyles();
  const TextStyleChangeable = {
    '& .MuiInputBase-input': {
      color: '#0A121A',
      padding: '10px',
      // textTransform: 'uppercase'
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'rgba(0, 0, 0, 0.23)',
      },
      '&:hover fieldset': {
        borderColor: '#FF5E6F',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#FF5E6F',
      },
    },
  };
  const { t } = useTranslation();

  const airlineCodeSettings = (
    <div>

      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{t('Add Airline Code')}</DialogTitle>
        <DialogContent>
          <TextField
            sx={TextStyleChangeable}
            autoFocus
            margin="dense"
            id="AirlineNumber"
            label={t('Airline Number')}
            type="number"
            onChange={ChangeValueAirlineNumber}
          />
          <br />
          <TextField
            sx={TextStyleChangeable}
            margin="dense"
            id="AirlineCode"
            label={t('Airline Code')}
            onChange={ChangeValueAirlineCode}
          />
          <br />
          <TextField
            sx={TextStyleChangeable}
            margin="dense"
            id="AirlineName"
            label={t('Airline Name')}
            onChange={ChangeValueAirlineName}
          />
          <br />
          <TextField
            sx={TextStyleChangeable}
            margin="dense"
            id="Decode"
            label={t('Decode')}
            onChange={ChangeValueDecode}
          />
          <br />
          {/* <FormControlLabel control={<Checkbox onChange={ChangeValueIATA} />} label="IATA" /> */}

        </DialogContent>
        <DialogActions>
          <Button onClick={handleAddSubmit} color="primary">
            {t('ADD')}
          </Button>
          <Button onClick={handleClose} color="primary">
            {t('Cancel')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>

  );

  return (
    <>
      <Card style={{ marginBottom: 10 }}>
        <CardHeader
          style={{ padding: '10px' }}
          title={t('AirlineCode Settings')}
          titleTypographyProps={{
            fontWeight: 'bold', fontSize: '16px', fontFamily: 'Roboto, Helvetica, Arial, sans-serif', color: '#0A121A',
          }}
        />
        <Divider />
        <CardContent style={{ paddingBottom: '15px' }}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell align="center" width="60" sx={{ padding: 1 }}>{t('Code')}</TableCell>
                  <TableCell align="center" width="100" sx={{ padding: 1 }}>{t('Carrier Code')}</TableCell>
                  <TableCell align="left" width="270" sx={{ padding: 1 }}>{t('Airline Name')}</TableCell>
                  <TableCell align="center" width="250" sx={{ padding: 1 }}>{t('Decode')}</TableCell>
                  {/* <TableCell align="left">isIATA</TableCell> */}
                  <TableCell colSpan={2}> </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {airlineCodes
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((s) => (
                    <AirlineCodeRow key={s.id} row={s} refetch={refetch} />
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5]}
            component="div"
            count={airlineCodes.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </CardContent>
      </Card>
      <div>
        <Button
          className={classes.commonBtn}
          sx={{ float: 'right' }}
          variant="contained"
          color="primary"
          onClick={() => handleClickOpen()}
        >
          {t('Add Data')}
        </Button>
        {airlineCodeSettings}
      </div>
    </>

  );
};

AirlineCodeTable.propTypes = {
  airlineCodes: PropTypes.array.isRequired,
  refetch: PropTypes.func.isRequired
};
export default AirlineCodeTable;
