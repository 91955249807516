import React from 'react';
import {
  TextField,
  Grid
} from '@material-ui/core';
import ConnectForm from '../common/ConnectForm';

const Refundcoupon = (prop) => {
  const {
    cpn1, cpn2, cpn3, cpn4, flt, readOnlyFlag
  } = prop;
  const TextStyleReadOndy = {
    '& .MuiInputBase-input': {
      color: '#0A121A',
      padding: '10px',
      backgroundColor: '#ececec'
    },
  };
  const TextStyleChangeable = {
    '& .MuiInputBase-input': {
      color: '#0A121A',
      padding: '10px',
      // textTransform: 'uppercase'
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'rgba(0, 0, 0, 0.23)',
      },
      '&:hover fieldset': {
        borderColor: '#FF5E6F',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#FF5E6F',
      },
    },
  };
  return (
    <ConnectForm>
      {({ register }) => (
        <Grid
          container
          spacing={3}
          style={{ paddingLeft: 16 }}
        >
          <Grid
            item
            md={2}
            sm={12}
          >
            <TextField
              sx={readOnlyFlag ? TextStyleReadOndy : TextStyleChangeable}
              label="CPN No."
              InputLabelProps={{ shrink: true }}
              name="cpn1"
              {...register('cpn1')}
              value={cpn1}
              variant="outlined"
              InputProps={{ readOnly: readOnlyFlag }}
            />
          </Grid>
          <Grid
            item
            md={2}
            sm={12}
          >
            <TextField
              sx={readOnlyFlag ? TextStyleReadOndy : TextStyleChangeable}
              label="CPN No."
              InputLabelProps={{ shrink: true }}
              name="cpn2"
              {...register('cpn2')}
              value={cpn2}
              variant="outlined"
              InputProps={{ readOnly: readOnlyFlag }}
            />
          </Grid>
          <Grid
            item
            md={2}
            sm={12}
          >
            <TextField
              sx={readOnlyFlag ? TextStyleReadOndy : TextStyleChangeable}
              label="CPN No."
              InputLabelProps={{ shrink: true }}
              name="cpn3"
              {...register('cpn3')}
              value={cpn3}
              variant="outlined"
              InputProps={{ readOnly: readOnlyFlag }}
            />
          </Grid>
          <Grid
            item
            md={2}
            sm={12}
          >
            <TextField
              sx={readOnlyFlag ? TextStyleReadOndy : TextStyleChangeable}
              label="CPN No."
              InputLabelProps={{ shrink: true }}
              name="cpn4"
              {...register('cpn4')}
              value={cpn4}
              variant="outlined"
              InputProps={{ readOnly: readOnlyFlag }}
            />
          </Grid>
          <Grid
            item
            md={1}
            sm={12}
          >
            <TextField
              sx={readOnlyFlag ? TextStyleReadOndy : TextStyleChangeable}
              label="FLT#"
              InputLabelProps={{ shrink: true }}
              name="flt"
              {...register('flt')}
              value={flt}
              variant="outlined"
              InputProps={{ readOnly: readOnlyFlag }}
            />
          </Grid>
        </Grid>
      )}
    </ConnectForm>
  );
};

export default Refundcoupon;
