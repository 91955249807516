import React from 'react';
// import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { makeStyles } from '@material-ui/styles';
import { FormProvider, useFieldArray } from 'react-hook-form';
import {
  Box,
  Container,
  TextField,
  Card,
  CardHeader,
  Divider,
  CardContent,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button,
  MenuItem,
  Stack
} from '@material-ui/core';
import PropTypes from 'prop-types';
import moment from 'moment';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { deleteTicketList, saveTicket } from 'src/api/api';
import StatusMessage from 'src/components/common/StatusMessage';
import Tax from './Tax';
import Others from './Others';
import Credit from './Credit';
import Exchange from './Exchange';
import Additionalinfo from './Additionalinfo';
import Emd from './Emd';
import Refund from './Refund';
import FaresPayments from './FaresPayments';
import SegmentTable from './SegmentTable';
import Refundcoupon from './Refundcoupon';

const TicketDetail = (props) => {
  const {
    seq,
    reloc,
    tcn,
    vrtype,
    vrdate,
    ticketnumber,
    checkdigit,
    airlineCode,
    airlineNumber,
    issueDate,
    iataNumber,
    originalyticketnumber,
    passengerName,
    passengertype,
    farecurrency,
    commConsumptiontax,
    commissionamount,
    commrate,
    fareamount,
    payment,
    equivalentcurrency,
    equivalentamount,
    cashgross,
    creditgross,
    totalcurrency,
    totalamount,
    taxcash,
    taxcredit,
    amountTotalCash,
    amountTotalCredit,
    bsppaymentamount,
    settlementmethod,
    faretype,
    netamount,
    adjustamount,
    vccode,
    carcode,
    nrid,
    decodeid,
    tourcode,
    itno,
    segments,
    taxes,
    conjunctionticketnumber,
    emdtype,
    arrange,
    emdcommissionamount,
    emdcommConsumptiontax,
    emdcommrate,
    emdtourcode,
    endorsedata,
    bsr,
    emddtVal1,
    emddtVal2,
    emddtVal3,
    emddtVal4,
    emdcityVal1,
    emdcityVal2,
    emdcityVal3,
    emdcityVal4,
    emdrficVal1,
    emdrficVal2,
    emdrficVal3,
    emdrficVal4,
    emdrfiscVal1,
    emdrfiscVal2,
    emdrfiscVal3,
    emdrfiscVal4,
    emdrcommercialVal1,
    emdrcommercialVal2,
    emdrcommercialVal3,
    emdrcommercialVal4,
    emdculVal1,
    emdculVal2,
    emdculVal3,
    emdculVal4,
    emdamtVal1,
    emdamtVal2,
    emdamtVal3,
    emdamtVal4,
    emdquantityVal1,
    emdquantityVal2,
    emdquantityVal3,
    emdquantityVal4,
    emdncVal1,
    emdncVal2,
    emdncVal3,
    emdncVal4,
    emdniVal1,
    emdniVal2,
    emdniVal3,
    emdniVal4,
    emdnrVal1,
    emdnrVal2,
    emdnrVal3,
    emdnrVal4,
    emdBaseAmountCurreny1,
    emdBaseAmountCurreny2,
    emdBaseAmountCurreny3,
    emdBaseAmountCurreny4,
    EMDDataValidatingAirlineNumber,
    EMDDataApprovalCodeIndicator,
    EMDDataApprovalCode,
    acceptingorders,
    section,
    incharge,
    client,
    direction,
    free,
    tour,
    kbe1,
    kbe2,
    eo,
    deposit,
    orderid,
    customerid,
    phonecity1,
    phonecity2,
    phonecity3,
    cardcode,
    cardnumber,
    holdername,
    expirationdt,
    approvalnum,
    billingamount,
    division,
    functionalclassification1,
    functionalclassification2,
    cardremarks1,
    cardremarks2,
    cardremarks3,
    exchcurrentgross,
    exchdifferencegross,
    exchcurrenttax,
    exchdifferencetax,
    exchcurrenttaxdetail,
    exchdifferencetaxdetail,
    originaltktnum,
    originalcrr,
    originalplace,
    originaldate,
    originaliata,
    originalgross,
    originaltax,
    originaltaxdetails,
    currenttktnum,
    currentcrr,
    currentplace,
    currentdate,
    currentiata,
    typeofexchticket,
    originalairlinenumber,
    exchangedairlinenumber,
    fcmi,
    eb0,
    eb1,
    eb2,
    eb3,
    eb4,
    eb5,
    tktdisp,
    authnumdisp,
    ifddt,
    ifdtm,
    csvdt,
    csvtm,
    uridt,
    uritm,
    foprmks,
    farecul1,
    farecul2,
    farecul3,
    farecul4,
    farecul5,
    farepaid,
    fareused,
    farerefund,
    cancelpanalty,
    ttlrfndamt,
    waivercode,
    rfnrid,
    twoanet,
    rfcommrate,
    esac,
    rfndpfc,
    refundticketnumber,
    refundCommissionAmount,
    refundCommissionPercentOnPenaltyFee,
    refundCommissionAmountOnPenaltyFee,
    refundCashAmountUsed,
    refundCashRefund,
    refundCreditAmountUsed,
    refundCreditRefund,
    refundRefundCurrency,
    refundRefundTax,
    refundRefundGrossFare,
    refundCurrency,
    cpn1,
    cpn2,
    cpn3,
    cpn4,
    flt,
    rftaxes,
    form,
    TicketingPCC,
    BookingPCC,
    HasBeenExported,
    TextHasBeenExported,
    ExportedBy,
    ExportedDateTime,
    IsRefund,
    IsEmd,
    FareItemNumber,
    PassengerNumber,
    ValidatingAirlineName,
    ValidatingAirlineNumber,
    ConsumptionPercent,
    OBFeesFareItemNumber,
    OBFeesTaxCode,
    OBFeesOBFeeSubCode,
    OBFeesAmount,
    onUpdate,
    onDelete,
    readOnlyFlag,
    otherTickets,
    IsMixedPTC,
    DepartureDate,
    InternationalDepartureDate,
    OtherTicketsInfo,
    ParentEMDNumber,
    SourceName,
    IsNDC,
    CPN,
    FLT
  } = props;

  const { t } = useTranslation();
  const { handleSubmit, register, control } = form;
  const [toBeDeleted, setToBeDeleted] = React.useState(false);
  const [toBeSaved, setToBeSaved] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [statusMessage, setStatusMessage] = React.useState('');
  const [openStatus, setOpenStatus] = React.useState(false);
  const [openDeleteStatusDialog, setOpenConfirmationDialog] = React.useState(false);

  const otherTicketNumbers = otherTickets !== undefined
    ? otherTickets.map((o) => o.TicketNumber.value)
    : null;
  const toTicketDetail = (data) => {
    console.log(data);
    const ticketDetail = {}; // Common Data
    ticketDetail.OtherTickets = [];
    if (data.OtherTicketsInfo.length > 0) {
      ticketDetail.OtherTickets = data.OtherTicketsInfo;
    }
    ticketDetail.SequenceNumber = data.seq;
    ticketDetail.MirCreationDateTime = `${data.issueDate}T00:00:00`;
    ticketDetail.ValidatingAirlineCode = data.airlineCode;
    ticketDetail.ValidatingAirlineNumber = data.airlineNumber;
    ticketDetail.BookingPCC = data.BookingPCC;
    ticketDetail.TicketNumber = { value: data.ticketnumber };
    ticketDetail.TicketingPCC = data.TicketingPCC;
    ticketDetail.IATANumber = data.iataNumber;
    ticketDetail.RecordLocator = data.reloc;
    ticketDetail.HasBeenExported = data.HasBeenExported;
    if (data.HasBeenExported === 'True') {
      ticketDetail.ExportedBy = data.ExportedBy;
      ticketDetail.ExportedDateTime = data.ExportedDateTime;
    }
    ticketDetail.IsNDC = data.IsNDC;
    ticketDetail.CPN = data.CPN;
    ticketDetail.FLT = data.FLT;
    ticketDetail.InternationalDepartureDate = data.InternationalDepartureDate;
    ticketDetail.DepartureDate = data.DepartureDate;
    ticketDetail.IsEmd = data.IsEmd ? data.IsEmd : '';
    // ticketDetail.TicketingStatus = (data.payment !== 'EX:Exchange') ? data.vrtype : 'EXCHANGED';
    if (data.payment === 'EX:Exchange') {
      ticketDetail.TicketingStatus = 'EXCHANGED';
    } else {
      ticketDetail.TicketingStatus = data.vrtype;
    }

    ticketDetail.FlightSegment = [];
    ticketDetail.ListOfTicketNumber = [data.ticketnumber];
    ticketDetail.CheckDigit = data.checkdigit;
    if (data.originalyticketnumber !== null) {
      ticketDetail.ParentTicketNumber = data.originalyticketnumber;
    }
    ticketDetail['@type'] = 'Ticket';
    ticketDetail.IsMixedPTC = data.IsMixedPTC;
    ticketDetail.FormOfPaymentID = [];
    ticketDetail.Endorsement = [];
    ticketDetail.CreditCardRemarks = [];
    ticketDetail.FreeTextRemarks = [];
    // FreeTextRemarks Information
    if (data.acceptingorders !== null) {
      ticketDetail.FreeTextRemarks.push({
        Value: data.acceptingorders,
        Heading: 'R'
      });
    }
    if (data.section !== null) {
      ticketDetail.FreeTextRemarks.push({ Value: data.section, Heading: 'S' });
    }
    if (data.incharge !== null) {
      ticketDetail.FreeTextRemarks.push({ Value: data.incharge, Heading: 'A' });
    }
    if (data.client !== null) {
      ticketDetail.FreeTextRemarks.push({ Value: data.client, Heading: 'C' });
    }
    if (data.direction !== null) {
      ticketDetail.FreeTextRemarks.push({
        Value: data.direction,
        Heading: 'D'
      });
    }
    if (data.free !== null) {
      ticketDetail.FreeTextRemarks.push({ Value: data.free, Heading: 'F' });
    }
    if (data.tour !== null) {
      ticketDetail.FreeTextRemarks.push({ Value: data.tour, Heading: 'T' });
    }
    if (data.kbe1 !== null) {
      ticketDetail.FreeTextRemarks.push({ Value: data.kbe1, Heading: 'K' });
    }
    if (data.kbe2 !== null) {
      ticketDetail.FreeTextRemarks.push({ Value: data.kbe2, Heading: 'B' });
    }
    if (data.eo !== null) {
      ticketDetail.FreeTextRemarks.push({ Value: data.eo, Heading: 'E' });
    }
    if (data.deposit !== null) {
      ticketDetail.FreeTextRemarks.push({ Value: data.deposit, Heading: 'M' });
    }
    if (data.orderid !== null) {
      ticketDetail.FreeTextRemarks.push({ Value: data.orderid, Heading: 'G' });
    }
    if (data.functionalclassification1 !== null) {
      ticketDetail.FreeTextRemarks.push({
        Value: data.functionalclassification1,
        Heading: 'MK2'
      });
    }
    if (data.functionalclassification2 !== null) {
      ticketDetail.FreeTextRemarks.push({
        Value: data.functionalclassification2,
        Heading: 'MK14'
      });
    }
    ticketDetail.FareCalculation = [];
    ticketDetail.PhoneCity = data.phonecity1;
    ticketDetail.IssueDate = `${data.issueDate}T00:00:00`;
    ticketDetail.SourceName = data.SourceName;
    ticketDetail.CPN = data.CPN;
    ticketDetail.FLT = data.FLT;
    ticketDetail.IsRefund = data.IsRefund ? data.IsRefund : '';

    if (vrtype === 'REFUNDED') {
      const chgdateformatRefund = moment(new Date(data.vrdate));
      const rfnddate = chgdateformatRefund.format('DDMMMYY');
      // ticketDetail.IsRefund = data.IsRefund ? data.IsRefund : '';
      ticketDetail.RefundData = {
        RefundTicketNumber: data.refundticketnumber,
        RefundDate: rfnddate.toLocaleUpperCase(),
        PassengerTaxesBreakdown: [],
        ESAC: data.esac,
        CommissionRate: data.rfcommrate,
        CommissionAmount: data.refundCommissionAmount,
        PenaltyFee: data.cancelpanalty,
        CommissionPercentOnPenaltyFee: data.refundCommissionPercentOnPenaltyFee,
        CommissionAmountOnPenaltyFee: data.refundCommissionAmountOnPenaltyFee,
        CashAmountUsed: data.refundCashAmountUsed,
        CashRefund: data.refundCashRefund,
        CreditAmountUsed: data.refundCreditAmountUsed,
        CreditRefund: data.refundCreditRefund,
        RefundCurrency: data.refundRefundCurrency,
        TotalRefundAmount: data.ttlrfndamt,
        RefundTax: data.refundRefundTax,
        RefundGrossFare: data.refundRefundGrossFare,
        WaiverCode: data.waivercode,
        Currency: data.refundCurrency,
        FarePaid: data.farepaid,
        CPN:
          data.cpn1 !== null
            ? [data.cpn1 + data.cpn2 + data.cpn3 + data.cpn4]
            : [],
        FLT: data.flt !== null ? [data.flt] : [],
        RFNDPFC: data.rfndpfc
      };
    }

    if (data.IsEmd !== 'False') {
      // When EMD => True
      ticketDetail.EMDData = {
        PassengerNumber: null,
        SegmentNumber: null,
        TypeOfService: 'ASVC',
        BookingIndicator: data.arrange,
        EMDIdentifier: data.emdtype,
        PassengerName: data.passengerName,
        EMDNumber: data.EMDDataEMDNumber,
        ValidatingCarrier: data.airlineCode,
        // ValidatingAirlineNumber: data.EMDDataValidatingAirlineNumber,
        ValidatingAirlineNumber: data.airlineNumber,
        CurrencyCodeForBaseAmount: data.farecurrency,
        BaseAmount: data.fareamount,
        CurrencyCodeForEquivalentAmount:
          data.equivalentcurrency !== null ? data.equivalentcurrency : '',
        EquivalentAmount: data.equivalentamount,
        CurrencyCodeForTaxAmount: '',
        TaxAmount: data.taxcash !== 0 ? data.taxcash : data.taxcredit,
        CurrencyCodeForTotalAmount: data.totalcurrency,
        TotalAmount: data.totalamount,
        FOP: data.payment === 'S:CASH' ? 'CA' : 'CC',
        // FOPAmount:
        //   data.payment === 'S:CASH' ? data.cashgross : data.creditgross,
        FOPAmount: data.totalamount,
        EndorsementData: data.endorsedata,
        RelatedTicketNumber: data.conjunctionticketnumber,
        RFIC: data.emdrficVal1,
        EMDCouponData: [],
        PassengerTaxesBreakdown: [],
        CCCode: data.cardcode,
        CCNumber: data.cardnumber,
        CCExpDate: data.expirationdt,
        ApprovalCodeIndicator: data.EMDDataApprovalCodeIndicator,
        ApprovalCode: data.EMDDataApprovalCode,
        ParentEMDNumber: data.ParentEMDNumber
      };

      /// EMDCouponData Information
      for (let c = 1; c < 5; c++) {
        const makenameDt = `emddtVal${c}`;
        const makenameCity = `emdcityVal${c}`;
        const makenameRFISC = `emdrfiscVal${c}`;
        const makenameCommercial = `emdrcommercialVal${c}`;
        const makenameQuantity = `emdquantityVal${c}`;
        const makenameNC = `emdncVal${c}`;
        const makenameNI = `emdniVal${c}`;
        const makenameNR = `emdnrVal${c}`;
        const makeBaseAmountCurreny = `emdBaseAmountCurreny${c}`;
        const makeBaseCurreny = `emdculVal${c}`;
        const makeBaseAmount = `emdamtVal${c}`;
        if (data[makenameDt] !== '') {
          ticketDetail.EMDData.EMDCouponData.push({
            Date: data[makenameDt],
            City: data[makenameCity],
            RFISC: data[makenameRFISC],
            CommercialName: data[makenameCommercial],
            NumberOfServiceRequested: data[makenameQuantity],
            NC: data[makenameNC],
            NI: data[makenameNI],
            NR: data[makenameNR],
            BaseAmountCurrenyCode: data[makeBaseAmountCurreny],
            BaseAmount: data[makeBaseAmount],
            BaseCurrenyCode: data[makeBaseCurreny]
          });
        }
      }
      /// EMDData PassengerTaxesBreakdown
      for (let r = 0; r < data.taxes.length; r++) {
        ticketDetail.EMDData.PassengerTaxesBreakdown.push({
          value:
            data.taxes[r].taxValue !== 'EXEMPT' ? data.taxes[r].taxValue : -1.0,
          code: data.taxes[r].taxCode
        });
      }
    } else {
      // When EMD = Flase
      // console.log('test');
      // console.log(data);
      // ticketDetail.PhoneCity = data.phonecity1;
      ticketDetail.VCCode = data.vccode ? data.vccode : '';
      ticketDetail.CarCode = data.carcode ? data.carcode : '';
      ticketDetail.SettlementMethod = data.settlementmethod;
      ticketDetail.FareType = data.faretype ? data.faretype : '';
      if (data.netamount !== 0) {
        ticketDetail.TwoANetAmount = data.netamount;
      }
      if (data.adjustamount !== 0) {
        ticketDetail.AdjustmentAmount = data.adjustamount;
      }
      ticketDetail.NRID = data.nrid !== '--' ? data.nrid : '';
      ticketDetail.DecodeID = data.decodeid !== null ? data.decodeid : '';
      ticketDetail.TransmittingSystem = '1G';
      ticketDetail.SequenceNumber = data.seq;
      ticketDetail.ValidatingAirlineName = data.ValidatingAirlineName;
      // ticketDetail.ValidatingAirlineNumber = data.ValidatingAirlineNumber;
      ticketDetail.ValidatingAirlineNumber = data.airlineNumber;
      // ticketDetail.IsRefund = data.IsRefund ? data.IsRefund : '';
      ticketDetail.FareItemNumber = data.FareItemNumber;
      ticketDetail.PassengerNumber = data.PassengerNumber;
      ticketDetail.TCN = data.tcn;
      ticketDetail.FareCalculationModeIndicator = data.fcmi !== '--' ? data.fcmi : '';
      if (data.passengerName.indexOf('/') !== -1) {
        const paxname = data.passengerName.split('/').map((i) => i.trim());
        ticketDetail.Traveler = {
          '@type': 'Traveler',
          gender: 0,
          PersonName: {
            '@type': 'System.String',
            Given: paxname[0],
            Middle: '',
            Prefix: '',
            Surname: paxname[1]
          },
          passengerTypeCode: data.passengertype
        };
      } else {
        ticketDetail.Traveler = {
          '@type': 'Traveler',
          gender: 0,
          PersonName: {
            '@type': 'System.String',
            Given: data.passengerName,
            Middle: '',
            Prefix: '',
            Surname: ''
          },
          passengerTypeCode: data.passengertype
        };
      }

      ticketDetail.FareInfo = {
        Tax: '',
        '@type': 'System.String',
        BaseFare: data.fareamount,
        TotalTax: data.taxcash + data.taxcredit,
        tourCode: null,
        FareBasis: null,
        TotalAmount: data.totalamount,
        fareBreakdown: null,
        ExtensionPoint: null,
        fareCalculation: null,
        EquivalentAmount: data.equivalentamount,
        TicketDesignator: null,
        ManualFareSegment: null,
        PassengerTypeCode: null,
        totalAmountCurrency: data.totalcurrency,
        FareSectionIndicator: '01',
        PassengerTaxBreakdown: [],
        BaseFareAmountCurrency: data.farecurrency ? data.farecurrency : '',
        EquivalentAmountCurrency: data.equivalentcurrency
          ? data.equivalentcurrency
          : '',
        FareBreakdownConstruction: null
      };
      const chgdateformatExchange = moment(new Date(data.originaldate));
      const exchdate = chgdateformatExchange.format('DDMMMYY');
      if (data.payment === 'EX:Exchange') {
        ticketDetail.ExchangeData = {
          Tax: data.originaltaxdetails,
          Date: exchdate.toLocaleUpperCase(),
          BaseFare: data.originalgross.substr(3),
          CityCode: data.originalplace,
          Currency: data.originalgross.substr(0, 3),
          TotalTax: data.originaltax.substr(3),
          IATANumber: data.currentiata,
          TypeOfExchTicket: data.typeofexchticket,
          OriginalIATANumber: data.originaliata,
          // OriginalTicketNumber: data.originaltktnum,
          OriginalTicketNumber: '',
          ExchangedTicketNumber: data.originaltktnum,
          OriginalAirlineNumber: data.originalairlinenumber,
          AdditionalCollectionTax: data.exchdifferencetax.substr(3),
          PassengerTaxesBreakdown: [],
          AdditionalCollectionBaseFare: data.exchdifferencegross.substr(3),
          // ExchangedAirlineNumber: '' // we dont show this hence we just leave it blank
          ExchangedAirlineNumber: data.exchangedairlinenumber // Add 11MAR24
        };
      }
      // if (vrtype === 'REFUNDED') {
      //   const chgdateformatRefund = moment(new Date(data.vrdate));
      //   const rfnddate = chgdateformatRefund.format('DDMMMYY');
      //   ticketDetail.RefundData = {
      //     RefundTicketNumber: data.refundticketnumber,
      //     RefundDate: rfnddate.toLocaleUpperCase(),
      //     PassengerTaxesBreakdown: [],
      //     ESAC: data.esac,
      //     CommissionRate: data.rfcommrate,
      //     CommissionAmount: data.refundCommissionAmount,
      //     PenaltyFee: data.cancelpanalty,
      //     CommissionPercentOnPenaltyFee:
      //       data.refundCommissionPercentOnPenaltyFee,
      //     CommissionAmountOnPenaltyFee: data.refundCommissionAmountOnPenaltyFee,
      //     CashAmountUsed: data.refundCashAmountUsed,
      //     CashRefund: data.refundCashRefund,
      //     CreditAmountUsed: data.refundCreditAmountUsed,
      //     CreditRefund: data.refundCreditRefund,
      //     RefundCurrency: data.refundRefundCurrency,
      //     TotalRefundAmount: data.ttlrfndamt,
      //     RefundTax: data.refundRefundTax,
      //     RefundGrossFare: data.refundRefundGrossFare,
      //     WaiverCode: data.waivercode,
      //     Currency: data.refundCurrency,
      //     FarePaid: data.farepaid,
      //     CPN:
      //       data.cpn1 !== null
      //         ? [data.cpn1 + data.cpn2 + data.cpn3 + data.cpn4]
      //         : [],
      //     FLT: data.flt !== null ? [data.flt] : [],
      //     RFNDPFC: data.rfndpfc
      //   };
      // }
      if (data.ConsumptionPercent !== null) {
        ticketDetail.ConsumptionPercent = data.ConsumptionPercent;
      }
      ticketDetail.CommissionPercent = {
        Percent: data.commrate,
        '@type': 'CommissionPercent'
      };
      if (data.bsppaymentamount !== 0) {
        ticketDetail.BspAmount = data.bsppaymentamount;
      }
      if (data.tourcode !== null) {
        ticketDetail.TourCode = { value: data.tourcode };
      } else {
        ticketDetail.TourCode = {};
      }
      if (vrtype === 'REFUNDED') {
        // ticketDetail.CPN = [data.cpn1, data.cpn2, data.cpn3, data.cpn4];
        ticketDetail.CPN = `${data.cpn1}${data.cpn2}${data.cpn3}${data.cpn4}`;
        ticketDetail.FLT = data.flt;
      } else {
        ticketDetail.CPN = '';
        ticketDetail.FLT = '';
      }
      // ticketDetail.IssueDate = `${data.issueDate}T00:00:00`;
      if (OBFeesFareItemNumber !== undefined) {
        ticketDetail.OBFeesInfo = {
          FareItemNumber: data.OBFeesFareItemNumber,
          TaxCode: data.OBFeesTaxCode,
          OBFeeSubCode: data.OBFeesOBFeeSubCode,
          Amount: data.OBFeesAmount
        };
      }
      /// FareInfo.Tax Information
      for (let r = 0; r < data.taxes.length; r++) {
        // console.log(data.taxes[r].taxValue);
        // if (data.taxes[r].taxValue === 'EXEMPT' ){ setTaxValue = -1.0; } else { setTaxValue = data.taxes[r].taxValue; }
        ticketDetail.FareInfo.PassengerTaxBreakdown.push({
          value:
            data.taxes[r].taxValue !== 'EXEMPT' ? data.taxes[r].taxValue : -1.0,
          code: data.taxes[r].taxCode
        });
        // ticketDetail.FareInfo.PassengerTaxBreakdown.push({ value: data.taxes[r].taxValue, code: data.taxes[r].taxCode });
        // console.log(ticketDetail.FareInfo.PassengerTaxBreakdown);
        // console.log(ticketDetail.FareInfo.Tax);
        if (ticketDetail.FareInfo.Tax) {
          ticketDetail.FareInfo.Tax += `/${
            data.taxes[r].taxValue + data.taxes[r].taxCode
          }`;
        } else {
          ticketDetail.FareInfo.Tax += `${
            data.taxes[r].taxValue + data.taxes[r].taxCode
          }`;
        }
      }
      /// ExchangeData.Tax Information
      if (data.originaltaxdetails != null) {
        const ExchTax = data.originaltaxdetails.split('/').map((i) => i.trim());
        for (let rr = 0; rr < ExchTax.length; rr++) {
          ticketDetail.ExchangeData.PassengerTaxesBreakdown.push({
            value: ExchTax[rr].replace(/[^0-9]/g, ''),
            code: ExchTax[rr].substr(-2, 2)
          });
        }
      }
      /// Refund.Tax Information
      if (data.rftaxes != null) {
        for (let rt = 0; rt < data.rftaxes.length; rt++) {
          ticketDetail.RefundData.PassengerTaxesBreakdown.push({
            value: data.rftaxes[rt].taxValue,
            code: data.rftaxes[rt].taxCode
          });
        }
      }
      /// EndorsementBox Information
      for (let e = 0; e < 6; e++) {
        const makename = `eb${e}`;
        if (data[makename] !== null) {
          ticketDetail.Endorsement.push(data[makename]);
        }
      }
      /// FareCalculation
      for (let f = 1; f < 6; f++) {
        const makename = `farecul${f}`;
        if (data[makename] !== null) {
          ticketDetail.FareCalculation.push(data[makename]);
        }
      }
      /// FightSegment
      for (let s = 0; s < data.segments.length; s++) {
        const makeFlightInfo = {
          '@type': 'System.String',
          sequence: 0,
          connectionDuration: '',
          Flight: {
            '@type': 'System.String',
            id: '0'
          },
          Carrier: data.segments[s].CarrierCode,
          FareBasisCode: data.segments[s].FareBasis,
          FlightNumber: data.segments[s].FlightNum,
          DepartureDate: data.segments[s].departuredate,
          DepartureTime: data.segments[s].departuretime,
          BookingClass: data.segments[s].BookigClass,
          TicketingStatus: '',
          Origin: data.segments[s].OriginCode,
          Destination: data.segments[s].DestinationCode,
          SegmentNumber: data.segments[s].segmentno,
          AirlineNumber: data.segments[s].airlinenumber,
          AirlineName: data.segments[s].airlinename,
          Status: data.segments[s].status,
          ArrivalTime: data.segments[s].arrivaltime,
          ArrivalDate: data.segments[s].arrivaldate,
          ArrivalIndicator: data.segments[s].arrivalday,
          OriginCityName: data.segments[s].origincityname,
          DestinationCityName: data.segments[s].destinationcityname,
          DomesticInternationalIndicator:
            data.segments[s].domesticinternationalindicator,
          SeatIndicator: data.segments[s].Seat,
          MealCodes: data.segments[s].mealcode,
          StopoverIndiactors: data.segments[s].stopover,
          NumberOfStops: data.segments[s].numberofstops,
          BuggageAllowance: data.segments[s].baggage,
          TypeOfAirCraft: data.segments[s].typeofaircraft,
          DepartureTerminal: data.segments[s].terminal,
          FlightSectionType: data.segments[s].flightsectiontype,
          NauticalMiles: data.segments[s].Mile,
          FlightCouponIndicator: data.segments[s].couponno,
          InternationalDepartureDate:
            data.segments[s].internationaldeparturedate,
          SegmentIdentifier: data.segments[s].segmentidentifier,
          TicketIndicator: data.segments[s].ticketIndicator,
          DepartureDateLong: '',
          NotValidBeforeDate: data.segments[s].NVBC,
          NotValidAfterDate: data.segments[s].NVAC
        };
        ticketDetail.FlightSegment.push(makeFlightInfo);
      }
      /// Card Remarks Information
      if (data.cardremarks1 !== null) {
        ticketDetail.CreditCardRemarks.push({
          Value: data.cardremarks1,
          Heading: 'CARD RMKS(R1)'
        });
      }
      if (data.cardremarks2 !== null) {
        ticketDetail.CreditCardRemarks.push({
          Value: data.cardremarks2,
          Heading: 'CARD RMKS(R2)'
        });
      }
      if (data.cardremarks3 !== null) {
        ticketDetail.CreditCardRemarks.push({
          Value: data.cardremarks3,
          Heading: 'CARD RMKS(R3)'
        });
      }

      /// Form of Payment Information
      if (data.cardcode !== null) {
        ticketDetail.FormOfPaymentID.push({
          '@type': 'FormOfPaymentPaymentCard',
          amount: {
            Base: data.creditgross,
            '@type': 'System.String',
            Total: data.amountTotalCredit,
            TotalTax: data.taxcredit
          },
          PaymentCard: {
            '@type': 'System.String',
            CardCode: data.cardcode,
            CardNumber: {
              '@type': 'System.String',
              PlainText: data.cardnumber
            },
            expireDate: data.expirationdt,
            approvalCode: data.approvalnum
          }
        });
      } else {
        ticketDetail.FormOfPaymentID.push({
          '@type': 'FormOfPaymentCash',
          amount: {
            Base: data.cashgross,
            '@type': 'System.String',
            Total: data.amountTotalCash,
            TotalTax: data.taxcash
          }
        });
      }
      // if (data.cashgross > 0) {
      //   ticketDetail.FormOfPaymentID.push({
      //     '@type': 'FormOfPaymentCash',
      //     amount: {
      //       Base: data.cashgross,
      //       '@type': 'System.String',
      //       Total: data.amountTotalCash,
      //       TotalTax: data.taxcash
      //     }
      //   });
      // }
    }
    console.log(ticketDetail);
    return ticketDetail;
  };

  const deleteTicketsFromList = useMutation(
    (ticketsToBeDeleted) => deleteTicketList(ticketsToBeDeleted),
    {
      onSuccess: (d, ticketsToBeDeleted) => {
        if (d.status === 200) {
          setOpenConfirmationDialog(true);
          setStatusMessage(
            ticketsToBeDeleted.length > 1
              ? t('Conjuction Tickets Deleted Successfully')
              : t('Ticket Deleted Successfully')
          );
        } else {
          setOpenConfirmationDialog(true);
          setStatusMessage(d.data.message);
        }
      },
      onError: (e) => {
        setStatusMessage(e.toString());
      }
    }
  );

  const saveTicketMutation = useMutation((ticket) => saveTicket(ticket), {
    onSuccess: (d) => {
      if (d.status === 200) {
        setOpenStatus(true);
        setStatusMessage(t('Updated Successfully'));
      } else {
        setOpenStatus(true);
        setStatusMessage(d.data.message);
      }
    },
    onError: (e) => {
      setOpenStatus(true);
      setStatusMessage(e.toString());
    }
  });

  // const errorMessage = saveTicketMutation.error ? saveTicketMutation.error.toString() : null;
  // const errorMsgForDelete = deleteTicketsFromList.error ? deleteTicketsFromList.error.toString() : null;
  //  const message = (saveTicketMutation != null || errorMsgForDelete != null) ? t('Updated Successfully')
  //  : errorMessage || errorMsgForDelete;

  const handleConfirmDelete = () => {
    deleteTicketsFromList.mutate([...otherTicketNumbers, ticketnumber]);
  };
  const handleDelete = () => {
    if (otherTicketNumbers != null && otherTicketNumbers.length > 0) {
      setOpen(true);
    } else {
      deleteTicketsFromList.mutate([ticketnumber]);
    }

    setToBeDeleted(false);
  };

  const openSaveDialog = () => {
    setToBeSaved(true);
  };

  const handleCloseSaveDialog = () => {
    setToBeSaved(false);
  };

  const handleClosDeleteDialog = () => {
    setToBeDeleted(false);
  };

  const handleClickDelete = () => {
    setToBeDeleted(true);
  };

  const handleClose = (isConjunction, reason) => {
    onDelete(isConjunction);
    if (reason === 'clickaway') {
      return;
    }
    setOpenConfirmationDialog(false);
  };

  const handleCloseStatus = (event, reason) => {
    onUpdate();
    if (reason === 'clickaway') {
      return;
    }
    setOpenStatus(false);
  };

  const setMessage = t('Are you sure want to delete the conjuction tickets');
  const deleteConjuctionTicketMessage = (
    <div>
      <StatusMessage
        open={open}
        title={t('Response Message')}
        // message={`Are you sure want to delete the conjuction tickets${otherTicketNumbers} - ${ticketnumber}`}
        message={`${setMessage} ${otherTicketNumbers} - ${ticketnumber}`}
      >
        <Stack direction="row" spacing={2}>
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={handleConfirmDelete}
          >
            OK
          </Button>
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={() => setOpen(false)}
          >
            CANCEL
          </Button>
        </Stack>
      </StatusMessage>
    </div>
  );

  const deleteConfirmationMessage = (
    <div>
      <StatusMessage
        open={openDeleteStatusDialog}
        title={t('Response Message')}
        message={statusMessage}
      >
        <Stack direction="row" spacing={2}>
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={() => handleClose(statusMessage.includes(t('Conjuction')))}
          >
            OK
          </Button>
        </Stack>
      </StatusMessage>
    </div>
  );

  const deleteTicketListSettings = (
    <div>
      <Dialog
        open={toBeDeleted}
        onClose={handleClosDeleteDialog}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {t('Delete Ticket from the Ticket details')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('Are you sure to Delete the ticket')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDelete} color="primary">
            OK
          </Button>
          <Button autoFocus onClick={handleClosDeleteDialog} color="primary">
            {t('Cancel')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );

  const updateStatusMessage = (
    <div>
      <StatusMessage
        open={openStatus}
        title={t('Response Message')}
        message={statusMessage}
      >
        <Stack direction="row" spacing={2}>
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={handleCloseStatus}
          >
            OK
          </Button>
        </Stack>
      </StatusMessage>
    </div>
  );

  const handleSaveDialog = () => {
    const ticketDetail = toTicketDetail(form.getValues());
    saveTicketMutation.mutate({ TicketDetail: ticketDetail });
    setToBeSaved(false);
  };

  const onSubmit = (d) => {
    const ticketDetail = toTicketDetail(d);
    // open the dialog..

    saveTicketMutation.mutate({ TicketDetail: ticketDetail });
    return false;
  };
  const saveTicketListSettings = (
    <div>
      <Dialog
        open={toBeSaved}
        onClose={handleCloseSaveDialog}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {t('Save the Ticket details')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('Are you sure to Save the ticket')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSaveDialog} color="primary">
            OK
          </Button>
          <Button autoFocus onClick={handleCloseSaveDialog} color="primary">
            {t('Cancel')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );

  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%'
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular
    },
    mainTitle: {
      fontSize: '16px',
      fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
      fontWeight: 'bold',
      color: '#0A121A'
    }
  }));
  const classes = useStyles();

  const TextStyleReadOndy = {
    '& .MuiInputBase-input': {
      color: '#0A121A',
      padding: '10px',
      backgroundColor: '#ececec'
    }
  };
  const TextStyleChangeable = {
    '& .MuiInputBase-input': {
      color: '#0A121A',
      padding: '10px'
      // textTransform: 'uppercase'
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'rgba(0, 0, 0, 0.23)'
      },
      '&:hover fieldset': {
        borderColor: '#FF5E6F'
      },
      '&.Mui-focused fieldset': {
        borderColor: '#FF5E6F'
      }
    }
  };

  const [styleSwich, setstyleSwich] = React.useState(
    vrtype !== 'OPEN' ? TextStyleChangeable : TextStyleReadOndy
  );
  const [readOnlySwich, setreadOnlySwich] = React.useState(vrtype === 'OPEN');
  const handleChange = (e) => {
    if (e.target.value === 'VOID' || e.target.value === 'REFUNDED') {
      setstyleSwich(TextStyleChangeable);
      setreadOnlySwich(false);
    } else {
      setstyleSwich(TextStyleReadOndy);
      setreadOnlySwich(true);
    }
  };

  const galileoInformation = (
    <Card style={{ clear: 'right' }}>
      <CardHeader
        title="Galileo Information"
        titleTypographyProps={{
          fontWeight: 'bold',
          fontSize: '16px',
          fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
          color: '#0A121A'
        }}
      />
      <Divider />
      <CardContent>
        <Grid container spacing={3} sx={{ marginBottom: '0px' }}>
          <Grid item md={2} sm={12}>
            <TextField
              sx={TextStyleReadOndy}
              fullWidth
              label="RELOC"
              InputLabelProps={{ shrink: true }}
              name="reloc"
              {...register('reloc')}
              defaultValue={reloc}
              variant="outlined"
              InputProps={{
                readOnly: true
              }}
            />
          </Grid>
          <Grid item md={2} sm={12}>
            <TextField
              sx={TextStyleReadOndy}
              fullWidth
              label="SEQ"
              InputLabelProps={{ shrink: true }}
              name="seq"
              {...register('seq')}
              defaultValue={seq}
              variant="outlined"
              InputProps={{
                readOnly: true
              }}
            />
          </Grid>
          <Grid item md={1} sm={12}>
            <TextField
              sx={TextStyleReadOndy}
              fullWidth
              label="TCN"
              InputLabelProps={{ shrink: true }}
              name="tcn"
              {...register('tcn')}
              defaultValue={tcn}
              variant="outlined"
              InputProps={{
                readOnly: true
              }}
            />
          </Grid>
          <Grid item md={3} sm={12}>
            <TextField
              sx={readOnlyFlag ? TextStyleReadOndy : TextStyleChangeable}
              select
              label="VOID/REFUND Type"
              {...register('vrtype')}
              defaultValue={vrtype}
              htmlFor="vrStatus"
              variant="outlined"
              fullWidth="true"
              InputProps={{
                readOnly: readOnlyFlag,
                name: 'vrtype',
                id: 'vrStatus'
              }}
              onChange={handleChange}
            >
              <MenuItem value="OPEN">NONE</MenuItem>
              <MenuItem value="VOID">VOID</MenuItem>
              <MenuItem value="REFUNDED" disabled={vrtype !== 'REFUNDED'}>
                REFUND
              </MenuItem>
            </TextField>
          </Grid>
          <Grid item md={3} sm={12}>
            <TextField
              sx={styleSwich}
              fullWidth
              label={t('VOID/REFUND Designated date')}
              InputLabelProps={{ shrink: true }}
              name="vrdate"
              type="date"
              {...register('vrdate')}
              defaultValue={vrdate ? vrdate !== null : ''}
              inputProps={{ readOnly: readOnlySwich }}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );

  const {
    fields // , append // , prepend, remove, swap, move, insert
  } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'taxes', // unique name for your Field Array
    keyName: 'taxCode'
  });

  const taxList = fields.map((tx, index) => (
    <Tax
      key={tx.taxCode}
      index={index}
      taxValueLabel={tx.taxValueLabel}
      taxCodeLabel={tx.taxCodeLabel}
      taxValue={tx.taxValue}
      taxCode={tx.taxCode}
      taxValueId={tx.taxValueId}
      taxCodeId={tx.taxCodeId}
      readOnlyFlag={tx.readOnlyFlag}
    />
  ));
  const taxDetails = () => {
    if (!taxes) return null;
    // console.log('Test');
    // console.log(taxes);
    return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>
            <span className={classes.mainTitle}>{t('Taxes Details')}</span>
          </Typography>
        </AccordionSummary>
        <Card>
          <CardContent>{taxList}</CardContent>
        </Card>
      </Accordion>
    );
  };

  const otherInformations = phonecity1 !== undefined ? (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography className={classes.heading}>
          <span className={classes.mainTitle}>{t('Others')}</span>
        </Typography>
      </AccordionSummary>
      <Card>
        <Others
          acceptingorders={acceptingorders}
          section={section}
          incharge={incharge}
          client={client}
          direction={direction}
          free={free}
          tour={tour}
          kbe1={kbe1}
          kbe2={kbe2}
          eo={eo}
          deposit={deposit}
          orderid={orderid}
          customerid={customerid}
          phonecity1={phonecity1}
          phonecity2={phonecity2}
          phonecity3={phonecity3}
          readOnlyFlag={readOnlyFlag}
        />
      </Card>
    </Accordion>
  ) : null;

  const creditInformations = cardcode !== null ? (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography className={classes.heading}>
          <span className={classes.mainTitle}>{t('Credit')}</span>
        </Typography>
      </AccordionSummary>
      <Card>
        <Credit
          cardcode={cardcode}
          cardnumber={cardnumber}
          holdername={holdername}
          expirationdt={expirationdt}
          approvalnum={approvalnum}
          billingamount={billingamount}
          division={division}
          functionalclassification1={functionalclassification1}
          functionalclassification2={functionalclassification2}
          cardremarks1={cardremarks1}
          cardremarks2={cardremarks2}
          cardremarks3={cardremarks3}
          readOnlyFlag={readOnlyFlag}
        />
      </Card>
    </Accordion>
  ) : null;

  const exchangeInformations = exchcurrentgross !== undefined ? (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography className={classes.heading}>
          <span className={classes.mainTitle}>Exchange</span>
        </Typography>
      </AccordionSummary>
      <Card>
        <Exchange
          exchcurrentgross={exchcurrentgross}
          exchdifferencegross={exchdifferencegross}
          exchcurrenttax={exchcurrenttax}
          exchdifferencetax={exchdifferencetax}
          exchcurrenttaxdetail={exchcurrenttaxdetail}
          exchdifferencetaxdetail={exchdifferencetaxdetail}
          originaltktnum={originaltktnum}
          originalcrr={originalcrr}
          originalplace={originalplace}
          originaldate={originaldate}
          originaliata={originaliata}
          originalgross={originalgross}
          originaltax={originaltax}
          originaltaxdetails={originaltaxdetails}
          currenttktnum={currenttktnum}
          currentcrr={currentcrr}
          currentplace={currentplace}
          currentdate={currentdate}
          currentiata={currentiata}
        />
      </Card>
    </Accordion>
  ) : null;

  const AdditionalInformations = farecul1 !== undefined ? (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography className={classes.heading}>
          <span className={classes.mainTitle}>
            {t('Additional Informations')}
          </span>
        </Typography>
      </AccordionSummary>
      <Card>
        <Additionalinfo
          eb0={eb0}
          fcmi={fcmi}
          eb1={eb1}
          eb2={eb2}
          eb3={eb3}
          eb4={eb4}
          eb5={eb5}
          tktdisp={tktdisp}
          authnumdisp={authnumdisp}
          ifddt={ifddt}
          ifdtm={ifdtm}
          csvdt={csvdt}
          csvtm={csvtm}
          uridt={uridt}
          uritm={uritm}
          foprmks={foprmks}
          farecul1={farecul1}
          farecul2={farecul2}
          farecul3={farecul3}
          farecul4={farecul4}
          farecul5={farecul5}
          readOnlyFlag={readOnlyFlag}
        />
      </Card>
    </Accordion>
  ) : null;

  const EmdInformations = conjunctionticketnumber !== undefined ? (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography className={classes.heading}>
          <span className={classes.mainTitle}>EMD</span>
        </Typography>
      </AccordionSummary>
      <Card>
        <Emd
          conjunctionticketnumber={conjunctionticketnumber}
          emdtype={emdtype}
          arrange={arrange}
          emdcommissionamount={emdcommissionamount}
          emdcommConsumptiontax={emdcommConsumptiontax}
          emdcommrate={emdcommrate}
          emdtourcode={emdtourcode}
          endorsedata={endorsedata}
          bsr={bsr}
          emddtVal1={emddtVal1}
          emddtVal2={emddtVal2}
          emddtVal3={emddtVal3}
          emddtVal4={emddtVal4}
          emdcityVal1={emdcityVal1}
          emdcityVal2={emdcityVal2}
          emdcityVal3={emdcityVal3}
          emdcityVal4={emdcityVal4}
          emdrficVal1={emdrficVal1}
          emdrficVal2={emdrficVal2}
          emdrficVal3={emdrficVal3}
          emdrficVal4={emdrficVal4}
          emdrfiscVal1={emdrfiscVal1}
          emdrfiscVal2={emdrfiscVal2}
          emdrfiscVal3={emdrfiscVal3}
          emdrfiscVal4={emdrfiscVal4}
          emdrcommercialVal1={emdrcommercialVal1}
          emdrcommercialVal2={emdrcommercialVal2}
          emdrcommercialVal3={emdrcommercialVal3}
          emdrcommercialVal4={emdrcommercialVal4}
          emdculVal1={emdculVal1}
          emdculVal2={emdculVal2}
          emdculVal3={emdculVal3}
          emdculVal4={emdculVal4}
          emdamtVal1={emdamtVal1}
          emdamtVal2={emdamtVal2}
          emdamtVal3={emdamtVal3}
          emdamtVal4={emdamtVal4}
          emdquantityVal1={emdquantityVal1}
          emdquantityVal2={emdquantityVal2}
          emdquantityVal3={emdquantityVal3}
          emdquantityVal4={emdquantityVal4}
          emdncVal1={emdncVal1}
          emdncVal2={emdncVal2}
          emdncVal3={emdncVal3}
          emdncVal4={emdncVal4}
          emdniVal1={emdniVal1}
          emdniVal2={emdniVal2}
          emdniVal3={emdniVal3}
          emdniVal4={emdniVal4}
          emdnrVal1={emdnrVal1}
          emdnrVal2={emdnrVal2}
          emdnrVal3={emdnrVal3}
          emdnrVal4={emdnrVal4}
          readOnlyFlag={readOnlyFlag}
        />
      </Card>
    </Accordion>
  ) : null;
  console.log(IsEmd);

  // const RefundInformations = farepaid !== undefined ? (
  const RefundInformations = IsEmd === 'False' && IsRefund === 'True' ? (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography className={classes.heading}>
          <span className={classes.mainTitle}>Refund</span>
        </Typography>
      </AccordionSummary>
      <Card>
        <Refund
          farepaid={farepaid}
          fareused={fareused}
          farerefund={farerefund}
          cancelpanalty={cancelpanalty}
          ttlrfndamt={ttlrfndamt}
          waivercode={waivercode}
          nrid={rfnrid}
          twoanet={twoanet}
          rfcommrate={rfcommrate}
          esac={esac}
          rfndpfc={rfndpfc}
          readOnlyFlag={readOnlyFlag}
        />
        <Refundcoupon
          cpn1={cpn1}
          cpn2={cpn2}
          cpn3={cpn3}
          cpn4={cpn4}
          flt={flt}
          readOnlyFlag={readOnlyFlag}
        />
        <div style={{ paddingLeft: 16, paddingTop: 16 }}>
          {rftaxes
            ? rftaxes.map((tx, index) => (
              <Tax
                isRefund
                key={tx.taxCode}
                index={index}
                taxValueLabel={tx.taxValueLabel}
                taxCodeLabel={tx.taxCodeLabel}
                taxValue={tx.taxValue}
                taxCode={tx.taxCode}
                taxValueId={tx.taxValueId}
                taxCodeId={tx.taxCodeId}
              />
            ))
            : null}
        </div>
      </Card>
    </Accordion>
  ) : null;

  const segmentInformation = () => {
    if (!segments) return null;
    const segmentList = (
      <AccordionDetails>
        <SegmentTable segments={segments} />
      </AccordionDetails>
    );
    const standardInformation = (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>
            <span className={classes.mainTitle}>
              {t('Standard Information')}
            </span>
          </Typography>
        </AccordionSummary>

        <Card>
          <CardContent>
            <FaresPayments
              farecurrency={farecurrency}
              fareamount={fareamount}
              payment={payment}
              equivalentcurrency={equivalentcurrency}
              equivalentamount={equivalentamount}
              cashgross={cashgross}
              creditgross={creditgross}
              totalcurrency={totalcurrency}
              totalamount={totalamount}
              taxcash={taxcash}
              taxcredit={taxcredit}
              readOnlyFlag={readOnlyFlag}
            />
            <div
              style={{
                marginBottom: 20,
                paddingBottom: 10,
                borderTop: 0,
                borderLeft: 0,
                borderRight: 0,
                borderBottom: 1,
                borderStyle: 'dotted'
              }}
            >
              <Grid container spacing={3} sx={{ marginBottom: '10px' }}>
                <Grid item md={2} sm={12}>
                  <TextField
                    sx={readOnlyFlag ? TextStyleReadOndy : TextStyleChangeable}
                    fullWidth
                    label={t('Fare Type')}
                    InputLabelProps={{ shrink: true }}
                    name="faretype"
                    {...register('faretype')}
                    defaultValue={faretype}
                    variant="outlined"
                    InputProps={{ readOnly: readOnlyFlag }}
                  />
                </Grid>
                <Grid item md={2} sm={12}>
                  <TextField
                    sx={readOnlyFlag ? TextStyleReadOndy : TextStyleChangeable}
                    fullWidth
                    label={t('Settlement method')}
                    InputLabelProps={{ shrink: true }}
                    name="settlementmethod"
                    {...register('settlementmethod')}
                    defaultValue={settlementmethod}
                    variant="outlined"
                    InputProps={{ readOnly: readOnlyFlag }}
                  />
                </Grid>
                <Grid item md={2} sm={12}>
                  <TextField
                    sx={readOnlyFlag ? TextStyleReadOndy : TextStyleChangeable}
                    select
                    label="NR-ID"
                    InputLabelProps={{ shrink: true }}
                    {...register('nrid')}
                    defaultValue={nrid}
                    htmlFor="nridStatus"
                    variant="outlined"
                    fullWidth="true"
                    InputProps={{
                      readOnly: readOnlyFlag,
                      name: 'nrid',
                      id: 'nridStatus'
                    }}
                  >
                    <MenuItem value="--">{t('NONE')}</MenuItem>
                    <MenuItem value="N">N</MenuItem>
                  </TextField>
                </Grid>
              </Grid>
            </div>
            <Grid container spacing={3} sx={{ marginBottom: '10px' }}>
              <Grid item md={2} sm={12}>
                <TextField
                  sx={readOnlyFlag ? TextStyleReadOndy : TextStyleChangeable}
                  fullWidth
                  label={t('2A&#45;NET Amount')}
                  InputLabelProps={{ shrink: true }}
                  name="netamount"
                  required
                  {...register('netamount', { required: true })}
                  // defaultValue={netamount ? netamount === undefined : 0}
                  defaultValue={netamount}
                  variant="outlined"
                  InputProps={{ readOnly: readOnlyFlag }}
                />
                {/* {errors.netamount && <span>This field is required</span>} */}
              </Grid>
              <Grid item md={2} sm={12}>
                <TextField
                  sx={readOnlyFlag ? TextStyleReadOndy : TextStyleChangeable}
                  fullWidth
                  label="VC CODE"
                  InputLabelProps={{ shrink: true }}
                  name="vccode"
                  {...register('vccode')}
                  defaultValue={vccode}
                  variant="outlined"
                  InputProps={{ readOnly: readOnlyFlag }}
                />
              </Grid>
              <Grid item md={2} sm={12}>
                {/* <FormControl fullWidth>
                  <InputLabel variant="outlined" htmlFor="decodeidStatus">
                    DECODE-ID
                  </InputLabel>
                  <NativeSelect
                    {...register('decodeid')}
                    defaultValue={decodeid}
                    inputProps={{ name: 'decodeid', id: 'decodeidStatus', readOnly: readOnlyFlag }}
                  >
                    <option value="True">Valid</option>
                    <option value="False">Invalid</option>
                  </NativeSelect>
                </FormControl> */}
                <TextField
                  sx={readOnlyFlag ? TextStyleReadOndy : TextStyleChangeable}
                  select
                  label="DECODE-ID"
                  InputLabelProps={{ shrink: true }}
                  {...register('decodeid')}
                  defaultValue={decodeid}
                  htmlFor="decodeidStatus"
                  variant="outlined"
                  fullWidth="true"
                  InputProps={{
                    readOnly: readOnlyFlag,
                    name: 'decodeid',
                    id: 'decodeidStatus'
                  }}
                >
                  <MenuItem value="True">{t('Valid')}</MenuItem>
                  <MenuItem value="False">{t('Invalid')}</MenuItem>
                </TextField>
              </Grid>
              <Grid item md={2} sm={12}>
                <TextField
                  sx={readOnlyFlag ? TextStyleReadOndy : TextStyleChangeable}
                  fullWidth
                  label="CAR CODE"
                  InputLabelProps={{ shrink: true }}
                  name="carcode"
                  {...register('carcode')}
                  defaultValue={carcode}
                  variant="outlined"
                  InputProps={{ readOnly: readOnlyFlag }}
                />
              </Grid>
              <Grid item md={2} sm={12}>
                <TextField
                  sx={readOnlyFlag ? TextStyleReadOndy : TextStyleChangeable}
                  fullWidth
                  label="TOUR CODE"
                  InputLabelProps={{ shrink: true }}
                  name="tourcode"
                  {...register('tourcode')}
                  defaultValue={tourcode}
                  variant="outlined"
                  InputProps={{ readOnly: readOnlyFlag }}
                />
              </Grid>
              <Grid item md={2} sm={12}>
                <TextField
                  sx={readOnlyFlag ? TextStyleReadOndy : TextStyleChangeable}
                  fullWidth
                  label="IT-NO"
                  InputLabelProps={{ shrink: true }}
                  name="itno"
                  {...register('itno')}
                  defaultValue={itno}
                  variant="outlined"
                  InputProps={{ readOnly: readOnlyFlag }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3} sx={{ marginBottom: '10px' }}>
              <Grid item md={2} sm={12}>
                <TextField
                  sx={readOnlyFlag ? TextStyleReadOndy : TextStyleChangeable}
                  fullWidth
                  label={t('Comm Amt')}
                  InputLabelProps={{ shrink: true }}
                  name="commissionamount"
                  required
                  {...register('commissionamount', { required: true })}
                  defaultValue={commissionamount}
                  variant="outlined"
                  InputProps={{ readOnly: readOnlyFlag }}
                />
              </Grid>
              <Grid item md={2} sm={12}>
                <TextField
                  sx={readOnlyFlag ? TextStyleReadOndy : TextStyleChangeable}
                  fullWidth
                  label={t('Comm ConsumptionTax')}
                  InputLabelProps={{ shrink: true }}
                  name="commConsumptiontax"
                  required
                  {...register('commConsumptiontax', { required: true })}
                  defaultValue={commConsumptiontax}
                  variant="outlined"
                  InputProps={{ readOnly: readOnlyFlag }}
                />
              </Grid>
              <Grid item md={2} sm={12}>
                <TextField
                  sx={readOnlyFlag ? TextStyleReadOndy : TextStyleChangeable}
                  fullWidth
                  label={t('Comm Rate')}
                  InputLabelProps={{ shrink: true }}
                  name="commrate"
                  required
                  {...register('commrate', { required: true })}
                  defaultValue={commrate}
                  variant="outlined"
                  InputProps={{ readOnly: readOnlyFlag }}
                />
              </Grid>
              {/* <Grid
                item
                md={2}
                sm={12}
              >
                <Button
                  variant="contained"
                  color="primary"
                  style={{ marginTop: 3 }}
                  className={classes.button}
                >
                  {t('Recalculation')}
                </Button>
              </Grid> */}
            </Grid>
            <Grid container spacing={3} sx={{ marginBottom: '10px' }}>
              <Grid item md={2} sm={12}>
                <TextField
                  sx={readOnlyFlag ? TextStyleReadOndy : TextStyleChangeable}
                  fullWidth
                  label={t('Discount Adjustment Amount')}
                  InputLabelProps={{ shrink: true }}
                  name="adjustamount"
                  required
                  {...register('adjustamount', { required: true })}
                  defaultValue={adjustamount}
                  variant="outlined"
                  InputProps={{ readOnly: readOnlyFlag }}
                />
              </Grid>
              <Grid item md={2} sm={12}>
                <TextField
                  sx={readOnlyFlag ? TextStyleReadOndy : TextStyleChangeable}
                  fullWidth
                  label={t('BSP payment amount')}
                  InputLabelProps={{ shrink: true }}
                  name="bsppaymentamount"
                  required
                  {...register('bsppaymentamount', { required: true })}
                  defaultValue={bsppaymentamount}
                  variant="outlined"
                  InputProps={{ readOnly: readOnlyFlag }}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        {segmentList}
      </Accordion>
    );
    return standardInformation;
  };

  const ticketInformation = (
    <Card>
      <CardHeader
        title={TextHasBeenExported}
        titleTypographyProps={{
          fontWeight: 'bold',
          fontSize: '16px',
          fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
          color: '#0A121A'
        }}
      />
      <Divider />
      <CardContent>
        <Grid container spacing={3} sx={{ marginBottom: '10px' }}>
          <Grid item md={3} sm={12}>
            <TextField
              sx={TextStyleReadOndy}
              fullWidth
              label={t('Ticket Number')}
              InputLabelProps={{ shrink: true }}
              name="ticketnumber"
              {...register('ticketnumber', { required: true })}
              defaultValue={ticketnumber}
              variant="outlined"
              InputProps={{
                readOnly: true
              }}
            />
          </Grid>
          <Grid item md={1} sm={12}>
            <TextField
              sx={TextStyleReadOndy}
              fullWidth
              label={t('Check Digit')}
              InputLabelProps={{ shrink: true }}
              name="checkdigit"
              {...register('checkdigit', { required: true })}
              defaultValue={checkdigit}
              variant="outlined"
              InputProps={{
                readOnly: true
              }}
            />
          </Grid>
          <Grid item md={2} sm={12}>
            <TextField
              sx={readOnlyFlag ? TextStyleReadOndy : TextStyleChangeable}
              fullWidth
              label={t('Airline Code')}
              InputLabelProps={{ shrink: true }}
              name="airlineCode"
              {...register('airlineCode', { required: true })}
              defaultValue={airlineCode}
              variant="outlined"
            />
          </Grid>
          <Grid item md={2} sm={12}>
            <TextField
              sx={readOnlyFlag ? TextStyleReadOndy : TextStyleChangeable}
              fullWidth
              label={t('Airline Number')}
              InputLabelProps={{ shrink: true }}
              name="airlineNumber"
              {...register('airlineNumber', { required: true })}
              defaultValue={airlineNumber}
              variant="outlined"
            />
          </Grid>
          <Grid item md={2} sm={12}>
            <TextField
              sx={readOnlyFlag ? TextStyleReadOndy : TextStyleChangeable}
              fullWidth
              label={t('Issue Date')}
              InputLabelProps={{ shrink: true }}
              name="issueDate"
              type="date"
              required
              {...register('issueDate', { required: true })}
              defaultValue={issueDate}
              variant="outlined"
            />
          </Grid>
          <Grid item md={2} sm={12}>
            <TextField
              sx={TextStyleReadOndy}
              fullWidth
              label={t('IATA Number')}
              InputLabelProps={{ shrink: true }}
              name="iataNumber"
              {...register('iataNumber', { required: true })}
              defaultValue={iataNumber}
              variant="outlined"
              InputProps={{
                readOnly: true
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} sx={{ marginBottom: '10px' }}>
          <Grid item md={3} sm={12}>
            <TextField
              sx={TextStyleReadOndy}
              fullWidth
              label={t('Original Ticket Number')}
              InputLabelProps={{ shrink: true }}
              name="originalyticketnumber"
              {...register('originalyticketnumber')}
              defaultValue={originalyticketnumber}
              variant="outlined"
              InputProps={{
                readOnly: true
              }}
            />
          </Grid>
          <Grid item md={6} sm={12}>
            <TextField
              sx={readOnlyFlag ? TextStyleReadOndy : TextStyleChangeable}
              fullWidth
              label={t('Passenger Name')}
              InputLabelProps={{ shrink: true }}
              name="passengerName"
              required
              {...register('passengerName', { required: true })}
              defaultValue={passengerName}
              variant="outlined"
            />
          </Grid>
          <Grid item md={2} sm={12}>
            <TextField
              sx={readOnlyFlag ? TextStyleReadOndy : TextStyleChangeable}
              fullWidth
              label={t('Passenger Type')}
              InputLabelProps={{ shrink: true }}
              name="passengertype"
              {...register('passengertype')}
              defaultValue={passengertype}
              variant="outlined"
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
  if (!reloc) {
    return (
      <div
        style={{
          marginTop: 20,
          marginBottom: 5,
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        No registered tickets
      </div>
    );
  }
  return (
    <>
      <Helmet>
        <title>Ticket Details</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth="lg" style={{ padding: 0 }}>
          <FormProvider {...form}>
            <form onSubmit={handleSubmit(onSubmit)}>
              {galileoInformation}
              {ticketInformation}
              <Divider />
              <Grid sx={{ marginBottom: '10px' }} item lg={12} spacing={3}>
                {segmentInformation()}
                {otherInformations}
                {taxDetails()}
                {creditInformations}
                {exchangeInformations}
                {AdditionalInformations}
                {EmdInformations}
                {RefundInformations}
              </Grid>
              <input
                type="hidden"
                {...register('TicketingPCC')}
                value={TicketingPCC}
                name="TicketingPCC"
              />
              <input
                type="hidden"
                {...register('BookingPCC')}
                value={BookingPCC}
                name="BookingPCC"
              />
              <input
                type="hidden"
                {...register('IsRefund')}
                value={IsRefund}
                name="IsRefund"
              />
              <input
                type="hidden"
                {...register('HasBeenExported')}
                value={HasBeenExported}
                name="HasBeenExported"
              />
              <input
                type="hidden"
                {...register('ExportedBy')}
                value={ExportedBy}
                name="ExportedBy"
              />
              <input
                type="hidden"
                {...register('ExportedDateTime')}
                value={ExportedDateTime}
                name="ExportedDateTime"
              />
              <input
                type="hidden"
                {...register('IsEmd')}
                value={IsEmd}
                name="IsEmd"
              />
              <input
                type="hidden"
                {...register('FareItemNumber')}
                value={FareItemNumber}
                name="FareItemNumber"
              />
              <input
                type="hidden"
                {...register('PassengerNumber')}
                value={PassengerNumber}
                name="PassengerNumber"
              />
              <input
                type="hidden"
                {...register('amountTotalCash')}
                value={amountTotalCash}
                name="amountTotalCash"
              />
              <input
                type="hidden"
                {...register('amountTotalCredit')}
                value={amountTotalCredit}
                name="amountTotalCredit"
              />
              <input
                type="hidden"
                {...register('ValidatingAirlineName')}
                value={ValidatingAirlineName}
                name="ValidatingAirlineName"
              />
              <input
                type="hidden"
                {...register('ValidatingAirlineNumber')}
                value={ValidatingAirlineNumber}
                name="ValidatingAirlineNumber"
              />
              <input
                type="hidden"
                {...register('ConsumptionPercent')}
                value={ConsumptionPercent}
                name="ConsumptionPercent"
              />
              <input
                type="hidden"
                {...register('typeofexchticket')}
                value={typeofexchticket}
                name="typeofexchticket"
              />
              <input
                type="hidden"
                {...register('originalairlinenumber')}
                value={originalairlinenumber}
                name="originalairlinenumber"
              />
              <input
                type="hidden"
                {...register('exchangedairlinenumber')}
                value={exchangedairlinenumber}
                name="exchangedairlinenumber"
              />
              <input
                type="hidden"
                {...register('refundticketnumber')}
                value={refundticketnumber}
                name="refundticketnumber"
              />
              <input
                type="hidden"
                {...register('refundCommissionAmount')}
                value={refundCommissionAmount}
                name="refundCommissionAmount"
              />
              <input
                type="hidden"
                {...register('refundCommissionPercentOnPenaltyFee')}
                value={refundCommissionPercentOnPenaltyFee}
                name="refundCommissionPercentOnPenaltyFee"
              />
              <input
                type="hidden"
                {...register('refundCommissionAmountOnPenaltyFee')}
                value={refundCommissionAmountOnPenaltyFee}
                name="refundCommissionAmountOnPenaltyFee"
              />
              <input
                type="hidden"
                {...register('refundCashAmountUsed')}
                value={refundCashAmountUsed}
                name="refundCashAmountUsed"
              />
              <input
                type="hidden"
                {...register('refundCashRefund')}
                value={refundCashRefund}
                name="refundCashRefund"
              />
              <input
                type="hidden"
                {...register('refundCreditAmountUsed')}
                value={refundCreditAmountUsed}
                name="refundCreditAmountUsed"
              />
              <input
                type="hidden"
                {...register('refundCreditRefund')}
                value={refundCreditRefund}
                name="refundCreditRefund"
              />
              <input
                type="hidden"
                {...register('refundRefundCurrency')}
                value={refundRefundCurrency}
                name="refundRefundCurrency"
              />
              <input
                type="hidden"
                {...register('refundRefundTax')}
                value={refundRefundTax}
                name="refundRefundTax"
              />
              <input
                type="hidden"
                {...register('refundRefundGrossFare')}
                value={refundRefundGrossFare}
                name="refundRefundGrossFare"
              />
              <input
                type="hidden"
                {...register('refundCurrency')}
                value={refundCurrency}
                name="refundCurrency"
              />
              <input
                type="hidden"
                {...register('OBFeesFareItemNumber')}
                value={OBFeesFareItemNumber}
                name="OBFeesFareItemNumber"
              />
              <input
                type="hidden"
                {...register('OBFeesTaxCode')}
                value={OBFeesTaxCode}
                name="OBFeesTaxCode"
              />
              <input
                type="hidden"
                {...register('OBFeesOBFeeSubCode')}
                value={OBFeesOBFeeSubCode}
                name="OBFeesOBFeeSubCode"
              />
              <input
                type="hidden"
                {...register('OBFeesAmount')}
                value={OBFeesAmount}
                name="OBFeesAmount"
              />
              <input
                type="hidden"
                {...register('EMDDataValidatingAirlineNumber')}
                value={EMDDataValidatingAirlineNumber}
                name="EMDDataValidatingAirlineNumber"
              />
              <input
                type="hidden"
                {...register('EMDDataApprovalCodeIndicator')}
                value={EMDDataApprovalCodeIndicator}
                name="EMDDataApprovalCodeIndicator"
              />
              <input
                type="hidden"
                {...register('EMDDataApprovalCode')}
                value={EMDDataApprovalCode}
                name="EMDDataApprovalCode"
              />
              <input
                type="hidden"
                {...register('IsMixedPTC')}
                value={IsMixedPTC}
                name="IsMixedPTC"
              />
              <input
                type="hidden"
                {...register('DepartureDate')}
                value={DepartureDate}
                name="DepartureDate"
              />
              <input
                type="hidden"
                {...register('OtherTickets')}
                value={OtherTicketsInfo}
                name="OtherTickets"
              />
              <input
                type="hidden"
                {...register('InternationalDepartureDate')}
                value={InternationalDepartureDate}
                name="InternationalDepartureDate"
              />
              <input
                type="hidden"
                {...register('ParentEMDNumber')}
                value={ParentEMDNumber}
                name="ParentEMDNumber"
              />
              <input
                type="hidden"
                {...register('SourceName')}
                value={SourceName}
                name="SourceName"
              />
              <input
                type="hidden"
                {...register('emdBaseAmountCurreny1')}
                value={emdBaseAmountCurreny1}
                name="emdBaseAmountCurreny1"
              />
              <input
                type="hidden"
                {...register('emdBaseAmountCurreny2')}
                value={emdBaseAmountCurreny2}
                name="emdBaseAmountCurreny2"
              />
              <input
                type="hidden"
                {...register('emdBaseAmountCurreny3')}
                value={emdBaseAmountCurreny3}
                name="emdBaseAmountCurreny3"
              />
              <input
                type="hidden"
                {...register('emdBaseAmountCurreny4')}
                value={emdBaseAmountCurreny4}
                name="emdBaseAmountCurreny4"
              />
              <input
                type="hidden"
                {...register('IsNDC')}
                value={IsNDC}
                name="IsNDC"
              />
              <input
                type="hidden"
                {...register('CPN')}
                value={CPN}
                name="CPN"
              />
              <input
                type="hidden"
                {...register('FLT')}
                value={FLT}
                name="FLT"
              />
              <Button
                variant="contained"
                color="primary"
                style={{ marginTop: 10 }}
                onClick={openSaveDialog}
                className={classes.button}
              >
                {t('Save')}
              </Button>
              <Button
                variant="contained"
                color="primary"
                style={{ marginTop: 10, marginLeft: 5 }}
                onClick={handleClickDelete}
                className={classes.button}
              >
                {t('Delete')}
              </Button>
            </form>
          </FormProvider>
        </Container>
      </Box>
      {saveTicketListSettings}
      {deleteTicketListSettings}
      {deleteConjuctionTicketMessage}
      {deleteConfirmationMessage}
      {updateStatusMessage}
    </>
  );
};

TicketDetail.propTypes = {
  reloc: PropTypes.string,
  seq: PropTypes.string,
  tcn: PropTypes.string,
  // analysis: PropTypes.string,
  vrtype: PropTypes.string,
  vrdate: PropTypes.string,
  ticketnumber: PropTypes.string,
  checkdigit: PropTypes.string,
  airlineCode: PropTypes.string,
  airlineNumber: PropTypes.string,
  issueDate: PropTypes.string,
  iataNumber: PropTypes.string,
  originalyticketnumber: PropTypes.string,
  passengerName: PropTypes.string,
  passengertype: PropTypes.string,
  farecurrency: PropTypes.string,
  commissionamount: PropTypes.string,
  commrate: PropTypes.string,
  commConsumptiontax: PropTypes.string,
  bsppaymentamount: PropTypes.string,
  fareamount: PropTypes.number,
  payment: PropTypes.string,
  equivalentcurrency: PropTypes.string,
  equivalentamount: PropTypes.number,
  cashgross: PropTypes.number,
  creditgross: PropTypes.number,
  totalcurrency: PropTypes.string,
  totalamount: PropTypes.number,
  amountTotalCash: PropTypes.number,
  amountTotalCredit: PropTypes.number,
  taxcash: PropTypes.number,
  taxcredit: PropTypes.number,
  settlementmethod: PropTypes.string,
  faretype: PropTypes.string,
  netamount: PropTypes.string,
  adjustamount: PropTypes.string,
  vccode: PropTypes.string,
  carcode: PropTypes.string,
  nrid: PropTypes.string,
  decodeid: PropTypes.string,
  tourcode: PropTypes.string,
  itno: PropTypes.string,
  segments: PropTypes.array,
  taxes: PropTypes.array,
  rftaxes: PropTypes.array,
  // others: PropTypes.object,
  acceptingorders: PropTypes.string,
  section: PropTypes.string,
  incharge: PropTypes.string,
  client: PropTypes.string,
  direction: PropTypes.string,
  free: PropTypes.string,
  tour: PropTypes.string,
  kbe1: PropTypes.string,
  kbe2: PropTypes.string,
  eo: PropTypes.string,
  deposit: PropTypes.string,
  orderid: PropTypes.string,
  customerid: PropTypes.string,
  phonecity1: PropTypes.string,
  phonecity2: PropTypes.string,
  phonecity3: PropTypes.string,
  cardcode: PropTypes.string,
  cardnumber: PropTypes.string,
  holdername: PropTypes.string,
  expirationdt: PropTypes.string,
  approvalnum: PropTypes.string,
  billingamount: PropTypes.string,
  division: PropTypes.string,
  functionalclassification1: PropTypes.string,
  functionalclassification2: PropTypes.string,
  cardremarks1: PropTypes.string,
  cardremarks2: PropTypes.string,
  cardremarks3: PropTypes.string,
  exchcurrentgross: PropTypes.string,
  exchdifferencegross: PropTypes.string,
  exchcurrenttax: PropTypes.string,
  exchdifferencetax: PropTypes.string,
  exchcurrenttaxdetail: PropTypes.string,
  exchdifferencetaxdetail: PropTypes.string,
  originaltktnum: PropTypes.string,
  originalcrr: PropTypes.string,
  originalplace: PropTypes.string,
  originaldate: PropTypes.string,
  originaliata: PropTypes.string,
  originalgross: PropTypes.string,
  originaltax: PropTypes.string,
  originaltaxdetails: PropTypes.string,
  currenttktnum: PropTypes.string,
  currentcrr: PropTypes.string,
  currentplace: PropTypes.string,
  currentdate: PropTypes.string,
  currentiata: PropTypes.string,
  typeofexchticket: PropTypes.string,
  originalairlinenumber: PropTypes.string,
  exchangedairlinenumber: PropTypes.string,
  fcmi: PropTypes.string,
  eb0: PropTypes.string,
  eb1: PropTypes.string,
  eb2: PropTypes.string,
  eb3: PropTypes.string,
  eb4: PropTypes.string,
  eb5: PropTypes.string,
  tktdisp: PropTypes.string,
  authnumdisp: PropTypes.string,
  ifddt: PropTypes.string,
  ifdtm: PropTypes.string,
  csvdt: PropTypes.string,
  csvtm: PropTypes.string,
  uridt: PropTypes.string,
  uritm: PropTypes.string,
  foprmks: PropTypes.string,
  farecul1: PropTypes.string,
  farecul2: PropTypes.string,
  farecul3: PropTypes.string,
  farecul4: PropTypes.string,
  farecul5: PropTypes.string,
  farepaid: PropTypes.string,
  fareused: PropTypes.string,
  farerefund: PropTypes.string,
  cancelpanalty: PropTypes.string,
  ttlrfndamt: PropTypes.string,
  waivercode: PropTypes.string,
  rfnrid: PropTypes.string,
  twoanet: PropTypes.string,
  rfcommrate: PropTypes.string,
  esac: PropTypes.string,
  rfndpfc: PropTypes.string,
  refundticketnumber: PropTypes.string,
  refundCommissionAmount: PropTypes.number,
  refundCommissionPercentOnPenaltyFee: PropTypes.number,
  refundCommissionAmountOnPenaltyFee: PropTypes.number,
  refundCashAmountUsed: PropTypes.number,
  refundCashRefund: PropTypes.number,
  refundCreditAmountUsed: PropTypes.number,
  refundCreditRefund: PropTypes.number,
  refundRefundCurrency: PropTypes.string,
  refundRefundTax: PropTypes.number,
  refundRefundGrossFare: PropTypes.number,
  refundCurrency: PropTypes.string,
  cpn1: PropTypes.string,
  cpn2: PropTypes.string,
  cpn3: PropTypes.string,
  cpn4: PropTypes.string,
  flt: PropTypes.string,
  conjunctionticketnumber: PropTypes.string,
  emdtype: PropTypes.string,
  arrange: PropTypes.string,
  emdcommissionamount: PropTypes.string,
  emdcommConsumptiontax: PropTypes.string,
  emdcommrate: PropTypes.string,
  emdtourcode: PropTypes.string,
  endorsedata: PropTypes.string,
  bsr: PropTypes.string,
  emddtVal1: PropTypes.string,
  emddtVal2: PropTypes.string,
  emddtVal3: PropTypes.string,
  emddtVal4: PropTypes.string,
  emdcityVal1: PropTypes.string,
  emdcityVal2: PropTypes.string,
  emdcityVal3: PropTypes.string,
  emdcityVal4: PropTypes.string,
  emdrficVal1: PropTypes.string,
  emdrficVal2: PropTypes.string,
  emdrficVal3: PropTypes.string,
  emdrficVal4: PropTypes.string,
  emdrfiscVal1: PropTypes.string,
  emdrfiscVal2: PropTypes.string,
  emdrfiscVal3: PropTypes.string,
  emdrfiscVal4: PropTypes.string,
  emdrcommercialVal1: PropTypes.string,
  emdrcommercialVal2: PropTypes.string,
  emdrcommercialVal3: PropTypes.string,
  emdrcommercialVal4: PropTypes.string,
  emdculVal1: PropTypes.string,
  emdculVal2: PropTypes.string,
  emdculVal3: PropTypes.string,
  emdculVal4: PropTypes.string,
  emdamtVal1: PropTypes.string,
  emdamtVal2: PropTypes.string,
  emdamtVal3: PropTypes.string,
  emdamtVal4: PropTypes.string,
  emdquantityVal1: PropTypes.string,
  emdquantityVal2: PropTypes.string,
  emdquantityVal3: PropTypes.string,
  emdquantityVal4: PropTypes.string,
  emdncVal1: PropTypes.string,
  emdncVal2: PropTypes.string,
  emdncVal3: PropTypes.string,
  emdncVal4: PropTypes.string,
  emdniVal1: PropTypes.string,
  emdniVal2: PropTypes.string,
  emdniVal3: PropTypes.string,
  emdniVal4: PropTypes.string,
  emdnrVal1: PropTypes.string,
  emdnrVal2: PropTypes.string,
  emdnrVal3: PropTypes.string,
  emdnrVal4: PropTypes.string,
  emdBaseAmountCurreny1: PropTypes.string,
  emdBaseAmountCurreny2: PropTypes.string,
  emdBaseAmountCurreny3: PropTypes.string,
  emdBaseAmountCurreny4: PropTypes.string,
  EMDDataValidatingAirlineNumber: PropTypes.string,
  EMDDataApprovalCodeIndicator: PropTypes.string,
  EMDDataApprovalCode: PropTypes.string,
  rfdcpn: PropTypes.array,
  form: PropTypes.object,
  exportdate: PropTypes.string,
  exportedby: PropTypes.string,
  TicketingPCC: PropTypes.string,
  BookingPCC: PropTypes.string,
  HasBeenExported: PropTypes.string,
  TextHasBeenExported: PropTypes.string,
  ExportedBy: PropTypes.string,
  ExportedDateTime: PropTypes.string,
  IsRefund: PropTypes.string,
  IsEmd: PropTypes.string,
  FareItemNumber: PropTypes.string,
  PassengerNumber: PropTypes.string,
  ValidatingAirlineName: PropTypes.string,
  ValidatingAirlineNumber: PropTypes.string,
  ConsumptionPercent: PropTypes.number,
  OBFeesFareItemNumber: PropTypes.string,
  OBFeesTaxCode: PropTypes.string,
  OBFeesOBFeeSubCode: PropTypes.string,
  OBFeesAmount: PropTypes.string,
  onUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  readOnlyFlag: PropTypes.bool,
  setBG: PropTypes.string,
  otherTickets: PropTypes.array,
  IsMixedPTC: PropTypes.string,
  DepartureDate: PropTypes.string,
  OtherTicketsInfo: PropTypes.array,
  InternationalDepartureDate: PropTypes.string,
  ParentEMDNumber: PropTypes.string,
  SourceName: PropTypes.string,
  IsNDC: PropTypes.string,
  CPN: PropTypes.string,
  FLT: PropTypes.string
};

export default TicketDetail;
