/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-lone-blocks */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  Grid,
  Button,
  Checkbox,
  Stack
} from '@material-ui/core';

import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { updateSchedulerConfigurations } from 'src/api/api';
import {
  useMutation
} from 'react-query';
import { makeStyles } from '@material-ui/styles';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import TimePicker from '@material-ui/lab/TimePicker';
import moment from 'moment';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import StatusMessage from 'src/components/common/StatusMessage';
import { useTranslation } from 'react-i18next';

const SchedulerSettings = (props) => {
  const { row, refetch } = props;

  const [configuration, setConfiguration] = React.useState(row.data[0].schedulerType);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [value, setValue] = React.useState(row.data[0].dailyRunTime);
  const [enabledValue, setEnabledValue] = React.useState(row.data[0].isEnabled);
  const [show, setShow] = React.useState(row.data[0].schedulerType !== 'Daily');
  const [updateStatus, setUpdateStatus] = React.useState({ open: false, message: '' });
  const [checkingInterval, setCheckingInterval] = React.useState(row.data[0].checkingInterval);
  const [importTimeOffset, setImportTimeOffset] = React.useState(row.data[0].importTimeOffset);
  const [responseMessage, setResponseMessage] = React.useState('');

  const { t } = useTranslation();
  const handleChange = (e) => {
    setConfiguration(e.target.value);

    if (e.target.value === 'Daily') {
      setShow(false);
    }
    if (e.target.value === 'Hourly') {
      setShow(true);
    }
  };
  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const dataToUpdate = {
    SchedulerType: configuration,
    DailyRunTime: value,
    IsEnabled: enabledValue,
    CheckingInterval: checkingInterval,
    ImportTimeOffset: importTimeOffset
  };
  const useUpdateMutation = useMutation(
    () => updateSchedulerConfigurations(dataToUpdate).then(refetch),
    {
      onSuccess: (r) => {
        setUpdateStatus({ open: true, message: r.data.message });
        setResponseMessage(r.data.message);
      },
      refetchOnWindowFocus: true
    }
  );
  const handleSubmit = () => {
    useUpdateMutation.mutate();
    setOpenDialog(false);
  };

  const onEnableChange = (e) => {
    if (e.target.checked === true) {
      //   setEnable(true);
      setEnabledValue(true);
    } else {
    //  setEnable(false);
      setEnabledValue(false);
    }
  };

  const handleChangeInterval = (e) => {
    setCheckingInterval(e.target.value);
  };

  const handleChangeTimeOffSet = (e) => {
    setImportTimeOffset(e.target.value);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setUpdateStatus({ open: false, message: '' });
  };

  const useStyles = makeStyles({
    commonBtn: {
      width: '120px'
    }
  });
  const classes = useStyles();

  const TextStyleChangeable = {
    '& .MuiInputBase-input': {
      color: '#0A121A',
      padding: '10px',
      // textTransform: 'uppercase'
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'rgba(0, 0, 0, 0.23)',
      },
      '&:hover fieldset': {
        borderColor: '#FF5E6F',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#FF5E6F',
      },
    },
  };
  const schedulerSetting = (
    <>
     <Grid
       item
       md={9}
       sm={12}
     >
       <Card style={{ marginBottom: 10 }}>
        <CardHeader
          style={{ padding: '10px' }}
          title={t('for Export')}
          titleTypographyProps={{
            fontWeight: 'bold', fontSize: '16px', fontFamily: 'Roboto, Helvetica, Arial, sans-serif', color: '#0A121A',
          }}
        />
        <Divider />
        <CardContent style={{ paddingBottom: '15px' }}>
        <Grid
          container
          spacing={1}
        >
            <Grid
              item
              md={4}
              sm={12}
            >
               <TextField
                 sx={TextStyleChangeable}
                 select
                 label={t('Type')}
                 value={configuration}
                 variant="outlined"
                 fullWidth="true"
                 onChange={handleChange}
               >
                <MenuItem value="Please Select">{t('Please Select')}</MenuItem>
                <MenuItem value="Daily">{t('Daily')}</MenuItem>
                <MenuItem value="Hourly">{t('Hourly')}</MenuItem>
               </TextField>
            </Grid>
            <Grid
              item
              md={4}
              sm={12}
            >
             <LocalizationProvider dateAdapter={AdapterDateFns}>
          <TimePicker
            disabled={show}
            label={t('Select Time')}
            InputLabelProps={{ shrink: true }}
            value={`${moment(value).format('l h:mm:ss a')}`}
            onChange={(newValue) => {
              setValue(newValue);
            }}
            renderInput={(params) => <TextField variant="outlined" sx={TextStyleChangeable} {...params} />}
          />
             </LocalizationProvider>
            </Grid>
        </Grid>
        </CardContent>
       </Card>
       <Card>
        <CardHeader
          style={{ padding: '10px' }}
          title={t('for Import')}
          titleTypographyProps={{
            fontWeight: 'bold', fontSize: '16px', fontFamily: 'Roboto, Helvetica, Arial, sans-serif', color: '#0A121A',
          }}
        />
        <Divider />
        <CardContent style={{ paddingBottom: '15px' }}>
        <Grid
          container
          spacing={1}
        >
            <Grid
              item
              md={4}
              sm={12}
            >
                <TextField
                  sx={TextStyleChangeable}
                  id="checkingInterval"
                  label={t('CheckingInterval (minutes)')}
                  type="text"
                  value={checkingInterval}
                  margin="normal"
                  InputLabelProps={{
                    shrink: true
                  }}
                  fullWidth="true"
                  onChange={handleChangeInterval}
                />
            </Grid>
            <Grid
              item
              md={4}
              sm={12}
            >
             <TextField
               sx={TextStyleChangeable}
               id="importTimeOffset"
               label={t('ImportTimeOffset (minutes)')}
               type="text"
               value={importTimeOffset}
               margin="normal"
               helperText={t('*Only files that have passed the set time will be imported')}
               InputLabelProps={{
                 shrink: true
               }}
               fullWidth="true"
               onChange={handleChangeTimeOffSet}
             />
            </Grid>
        </Grid>
        </CardContent>
       </Card>
     </Grid>
    </>
  );
  const showMessage = (
<div>
      <StatusMessage
        open={updateStatus.message}
        title={t('Response Message')}
        message={t(responseMessage)}
      >
        <Stack direction="row" spacing={2}>
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={handleClose}
          >
            OK
          </Button>
        </Stack>
      </StatusMessage>
</div>
  );
  const schedulerUpdateMessage = (
  <div>
  <Dialog
    open={openDialog}
    onClose={handleCloseDialog}
    aria-labelledby="draggable-dialog-title"
  >
  <DialogTitle
    style={{ cursor: 'move' }}
    id="draggable-dialog-title"
  >
    {t('Update Scheduler Setting')}
  </DialogTitle>
  <DialogContent>
    <DialogContentText>
      {t('Are you sure to update the Scheduler')}
    </DialogContentText>
  </DialogContent>
  <DialogActions>
    <Button onClick={handleSubmit} color="primary">
      OK
    </Button>
    <Button autoFocus onClick={handleCloseDialog} color="primary">
      {t('Cancel')}
    </Button>
  </DialogActions>
  </Dialog>
  </div>
  );

  return (
    <>
      <Card style={{ marginBottom: 10 }}>
        <CardHeader
          style={{ padding: '10px' }}
          title={t('Scheduler')}
          titleTypographyProps={{
            fontWeight: 'bold', fontSize: '16px', fontFamily: 'Roboto, Helvetica, Arial, sans-serif', color: '#0A121A',
          }}
        />
        <Divider />
        <CardContent style={{ paddingBottom: '15px' }}>
        <Grid
          container
          spacing={1}
        >
          <Grid
            item
            md={3}
            sm={12}
          >
            <FormControlLabel
              control={(
                <Checkbox
                  name="Enable"
                  color="primary"
                  checked={enabledValue}
                  onChange={onEnableChange}
                />
          )}
              label={t('Enable Schedule')}
            />
          </Grid>
          { enabledValue ? schedulerSetting : null}
        </Grid>
        </CardContent>
      </Card>
      <div>
      <Button
        sx={{ float: 'right' }}
        className={classes.commonBtn}
        variant="contained"
        color="primary"
        onClick={handleClickOpenDialog}
      >
              {t('Update')}
      </Button>
        {showMessage}
        {schedulerUpdateMessage}
      </div>
    </>
  );
};
SchedulerSettings.propTypes = {
  row: PropTypes.array.isRequired,
  refetch: PropTypes.func.isRequired
};

export default SchedulerSettings;
