import {
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Card,
  CardContent,
  Divider,
  CardHeader,
} from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import Button from '@material-ui/core/Button';
// import SendIcon from '@material-ui/icons/Send';
import { makeStyles } from '@material-ui/styles';

export default function DateFilterResult(props) {
  const {
    startDateTime, endDateTime, dateTimeFilterChanged, onSelectAll, isSelectAll, IsImportBackup
  } = props;
  // console.log(IsImportBackup);
  const [selectedStartDateValue, setSelectedStartDateValue] = React.useState(startDateTime);
  const [selectedEndDateValue, setSelectedEndDateValue] = React.useState(endDateTime);
  const [isSelectSingleDate, setIsSelectSingleDate] = React.useState(false);
  const [isSelectDisplayAll, setIsSelectDisplayAll] = React.useState(isSelectAll);
  const [isSelectDateRange, setIsSelectDateRange] = React.useState(false);

  const ChangeValueEndDate = (e) => {
    setSelectedEndDateValue(e.target.value);
  };
  const ChangeValueStartDate = (e) => {
    setSelectedStartDateValue(e.target.value);
    if (isSelectSingleDate) {
      setSelectedEndDateValue(moment(e.target.value).format('yyyy-MM-DD'));
    }
  };
  const HandleChangeValue = (e) => {
    if (e.target.value === 'radioDisplay') {
      setIsSelectSingleDate(true);
      setIsSelectDisplayAll(false);
      setIsSelectDateRange(false);
      setSelectedStartDateValue(moment().format('yyyy-MM-DD'));
      setSelectedEndDateValue(moment().format('yyyy-MM-DD'));
    } else if (e.target.value === 'radioDisplayAllFile') {
      setIsSelectSingleDate(false);
      setIsSelectDisplayAll(true);
      setIsSelectDateRange(false);
    } else if (e.target.value === 'radioDisplaySpecify') {
      setIsSelectSingleDate(false);
      setIsSelectDisplayAll(false);
      setIsSelectDateRange(true);
    }
  };
  const handleSearch = () => {
    if (isSelectDisplayAll) {
      onSelectAll();
    } else {
      dateTimeFilterChanged({ startDateTime: selectedStartDateValue, endDateTime: selectedEndDateValue });
    }
  };
  const { t } = useTranslation();
  const TextStyleChangeable = {
    '& .MuiInputBase-input': {
      color: '#0A121A',
      padding: '10px',
      // textTransform: 'uppercase'
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'rgba(0, 0, 0, 0.23)',
      },
      '&:hover fieldset': {
        borderColor: '#FF5E6F',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#FF5E6F',
      },
    },
  };

  const useStyles = makeStyles({
    labelRoot: {
      fontSize: '14px'
    },
    commonBtn: {
      width: '120px'
    }
  });
  const classes = useStyles();
  const setLabelDate = (IsImportBackup === 'true') ? t('Display by specifying the issue date') : t('Display by specifying the creation date');
  const setLabelDateRange = (IsImportBackup === 'true') ? t('Display specifying the issue date range') : t('Display specifying the creation date range');

  return (
    <Card style={{ clear: 'right' }}>
      <CardHeader
        style={{ padding: '10px' }}
        title={t('Files display option of MIR to DISK')}
        titleTypographyProps={{
          fontWeight: 'bold', fontSize: '16px', fontFamily: 'Roboto, Helvetica, Arial, sans-serif', color: '#0A121A'
        }}
      />
      <Divider />
      <CardContent style={{ padding: '5px' }}>

        <RadioGroup style={{ marginLeft: 15, marginBottom: 0 }}>
          <div style={{ marginTop: 2, marginBottom: 7 }}>
            <FormControlLabel
              value="Display all files"
              control={<Radio value="radioDisplayAllFile" onChange={HandleChangeValue} checked={isSelectDisplayAll} />}
              label={<span className={classes.labelRoot}>{t('Display all files')}</span>}
            />

          </div>
          <div style={{ marginBottom: 7 }}>
            <FormControlLabel
              style={{ width: 360 }}
              value="Display processing date file"
              control={<Radio onChange={HandleChangeValue} value="radioDisplay" checked={isSelectSingleDate} />}
              // label={<span className={classes.labelRoot}>{t('Display processing date file')}</span>}
              label={<span className={classes.labelRoot}>{setLabelDate}</span>}
            />
            <TextField
              sx={TextStyleChangeable}
              id="date"
              type="date"
              value={selectedStartDateValue}
              defaultValue={moment().format('yyyy-MM-DD')}
              onChange={ChangeValueStartDate}
              InputLabelProps={{
                shrink: true
              }}
            />
          </div>
          <div style={{ marginBottom: 7 }}>
            <FormControlLabel
              style={{ width: 360 }}
              value="DisplayOnDate"
              control={<Radio value="radioDisplaySpecify" onChange={HandleChangeValue} checked={isSelectDateRange} />}
              // label={<span className={classes.labelRoot}>{t('Display by specifying the creation date')}</span>}
              label={<span className={classes.labelRoot}>{setLabelDateRange}</span>}
            />
            <TextField
              sx={TextStyleChangeable}
              id="date"
              label="From"
              type="date"
              value={selectedStartDateValue}
              defaultValue={startDateTime}
              onChange={ChangeValueStartDate}
              InputLabelProps={{
                shrink: true
              }}
            />
            <TextField
              sx={TextStyleChangeable}
              style={{ marginLeft: 10 }}
              id="date"
              label="To"
              type="date"
              value={selectedEndDateValue}
              defaultValue={endDateTime}
              onChange={ChangeValueEndDate}
              InputLabelProps={{
                shrink: true
              }}
            />
          </div>
          <div>
            <Button
              className={classes.commonBtn}
              style={{ marginLeft: 120, marginBottom: 10 }}
              variant="contained"
              color="primary"
              // endIcon={<SendIcon />}
              onClick={handleSearch}
            >
              {t('Search')}
            </Button>
          </div>
        </RadioGroup>
      </CardContent>
    </Card>
  );
}

DateFilterResult.propTypes = {
  dateTimeFilterChanged: PropTypes.func,
  onSelectAll: PropTypes.func,
  startDateTime: PropTypes.string,
  endDateTime: PropTypes.string,
  isSelectAll: PropTypes.bool,
  IsImportBackup: PropTypes.string
};
