import { makeStyles } from '@material-ui/styles';

const LangEN = (props) => {
  const useStyles = makeStyles(() => ({
    imageButton: {
      width: '34px',
      height: '22px',
      cursor: 'pointer'
    },
  }));
  const classes = useStyles();

  return (
    <img
      alt="English"
      src="/static/images/lang_EN.png"
      className={classes.imageButton}
      {...props}
    />
  );
};
export default LangEN;
