import React from 'react';
import {
  TextField,
  Grid,
  CardContent,
  // Card
} from '@material-ui/core';
// import { useTranslation } from 'react-i18next';
// import Refundcoupon from './Refundcoupon';
import ConnectForm from '../common/ConnectForm';

const Refund = (prop) => {
  const {
    farepaid, fareused, farerefund, cancelpanalty, ttlrfndamt, waivercode, rfnrid, twoanet, rfcommrate, esac, rfndpfc, refundticketnumber, refundCommissionAmount, refundCommissionPercentOnPenaltyFee, refundCommissionAmountOnPenaltyFee, refundCashAmountUsed, refundCashRefund, refundCreditAmountUsed, refundCreditRefund, refundRefundCurrency, refundRefundTax, refundRefundGrossFare, refundCurrency,
    readOnlyFlag
  } = prop;
  // const { t } = useTranslation();
  const TextStyleReadOndy = {
    '& .MuiInputBase-input': {
      color: '#0A121A',
      padding: '10px',
      backgroundColor: '#ececec'
    },
  };
  const TextStyleChangeable = {
    '& .MuiInputBase-input': {
      color: '#0A121A',
      padding: '10px',
      // textTransform: 'uppercase'
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'rgba(0, 0, 0, 0.23)',
      },
      '&:hover fieldset': {
        borderColor: '#FF5E6F',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#FF5E6F',
      },
    },
  };
  return (
    <ConnectForm>
      {({ register }) => (
        <CardContent>
          <Grid
            container
            spacing={3}
            sx={{ marginBottom: '10px' }}
          >
            <Grid
              item
              md={2}
              sm={12}
            >
              <TextField
                sx={readOnlyFlag ? TextStyleReadOndy : TextStyleChangeable}
                fullWidth
                label="Fare Paid"
                InputLabelProps={{ shrink: true }}
                name="farepaid"
                {...register('farepaid')}
                defaultValue={farepaid}
                variant="outlined"
                InputProps={{ readOnly: readOnlyFlag }}
              />
            </Grid>
            <Grid
              item
              md={2}
              sm={12}
            >
              <TextField
                sx={readOnlyFlag ? TextStyleReadOndy : TextStyleChangeable}
                fullWidth
                label="Fare Used"
                InputLabelProps={{ shrink: true }}
                name="fareused"
                {...register('fareused')}
                defaultValue={fareused}
                variant="outlined"
                InputProps={{ readOnly: readOnlyFlag }}
              />
            </Grid>
            <Grid
              item
              md={2}
              sm={12}
            >
              <TextField
                sx={readOnlyFlag ? TextStyleReadOndy : TextStyleChangeable}
                fullWidth
                label="Fare Refund"
                InputLabelProps={{ shrink: true }}
                name="farerefund"
                {...register('farerefund')}
                defaultValue={farerefund}
                variant="outlined"
                InputProps={{ readOnly: readOnlyFlag }}
              />
            </Grid>
            <Grid
              item
              md={2}
              sm={12}
            >
              <TextField
                sx={readOnlyFlag ? TextStyleReadOndy : TextStyleChangeable}
                fullWidth
                label="Cancel Panalty"
                InputLabelProps={{ shrink: true }}
                name="cancelpanalty"
                {...register('cancelpanalty')}
                defaultValue={cancelpanalty}
                variant="outlined"
                InputProps={{ readOnly: readOnlyFlag }}
              />
            </Grid>
            <Grid
              item
              md={2}
              sm={12}
            >
              <TextField
                sx={readOnlyFlag ? TextStyleReadOndy : TextStyleChangeable}
                fullWidth
                label="Total Refund Amount"
                InputLabelProps={{ shrink: true }}
                name="ttlrfndamt"
                {...register('ttlrfndamt')}
                defaultValue={ttlrfndamt}
                variant="outlined"
                InputProps={{ readOnly: readOnlyFlag }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={3}
            sx={{ marginBottom: '10px' }}
          >
            <Grid
              item
              md={2}
              sm={12}
            >
              <TextField
                sx={readOnlyFlag ? TextStyleReadOndy : TextStyleChangeable}
                fullWidth
                label="Waiver Code"
                InputLabelProps={{ shrink: true }}
                name="waivercode"
                {...register('waivercode')}
                defaultValue={waivercode}
                variant="outlined"
                InputProps={{ readOnly: readOnlyFlag }}
              />
            </Grid>
            <Grid
              item
              md={2}
              sm={12}
            >
              <TextField
                sx={readOnlyFlag ? TextStyleReadOndy : TextStyleChangeable}
                fullWidth
                label="NR ID"
                InputLabelProps={{ shrink: true }}
                name="rfnrid"
                {...register('rfnrid')}
                defaultValue={rfnrid}
                variant="outlined"
                InputProps={{ readOnly: readOnlyFlag }}
              />
            </Grid>
            <Grid
              item
              md={2}
              sm={12}
            >
              <TextField
                sx={readOnlyFlag ? TextStyleReadOndy : TextStyleChangeable}
                fullWidth
                label="2A NET"
                InputLabelProps={{ shrink: true }}
                name="twoanet"
                {...register('twoanet')}
                defaultValue={twoanet}
                variant="outlined"
                InputProps={{ readOnly: readOnlyFlag }}
              />
            </Grid>
            <Grid
              item
              md={2}
              sm={12}
            >
              <TextField
                sx={readOnlyFlag ? TextStyleReadOndy : TextStyleChangeable}
                fullWidth
                label="Comm Rate"
                InputLabelProps={{ shrink: true }}
                name="rfcommrate"
                {...register('rfcommrate')}
                defaultValue={rfcommrate}
                variant="outlined"
                InputProps={{ readOnly: readOnlyFlag }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={3}
              sm={12}
            >
              <TextField
                sx={readOnlyFlag ? TextStyleReadOndy : TextStyleChangeable}
                fullWidth
                label="ESAC"
                InputLabelProps={{ shrink: true }}
                name="esac"
                {...register('esac')}
                defaultValue={esac}
                variant="outlined"
                InputProps={{ readOnly: readOnlyFlag }}
              />
            </Grid>
            <Grid
              item
              md={3}
              sm={12}
            >
              <TextField
                sx={readOnlyFlag ? TextStyleReadOndy : TextStyleChangeable}
                fullWidth
                label="Refund PFC(XF)"
                InputLabelProps={{ shrink: true }}
                name="rfndpfc"
                {...register('rfndpfc')}
                defaultValue={rfndpfc}
                variant="outlined"
                InputProps={{ readOnly: readOnlyFlag }}
              />
            </Grid>
          </Grid>
          <input type="hidden" value={refundticketnumber} name="refundticketnumber" />
          <input type="hidden" value={refundCommissionAmount} name="refundCommissionAmount" />
          <input type="hidden" value={refundCommissionPercentOnPenaltyFee} name="refundCommissionPercentOnPenaltyFee" />
          <input type="hidden" value={refundCommissionAmountOnPenaltyFee} name="refundCommissionAmountOnPenaltyFee" />
          <input type="hidden" value={refundCashAmountUsed} name="refundCashAmountUsed" />
          <input type="hidden" value={refundCashRefund} name="refundCashRefund" />
          <input type="hidden" value={refundCreditAmountUsed} name="refundCreditAmountUsed" />
          <input type="hidden" value={refundCreditRefund} name="refundCreditRefund" />
          <input type="hidden" value={refundRefundCurrency} name="refundRefundCurrency" />
          <input type="hidden" value={refundRefundTax} name="refundRefundTax" />
          <input type="hidden" value={refundRefundGrossFare} name="refundRefundGrossFare" />
          <input type="hidden" value={refundCurrency} name="refundCurrency" />
        </CardContent>
      )}
    </ConnectForm>
  );
};

export default Refund;
