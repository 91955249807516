import React from 'react';

import PropTypes from 'prop-types';

import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  Grid,
  Button,
  Stack
} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import { savingFareTypeConfig } from 'src/api/api';
import {
  useMutation
} from 'react-query';

import ProgressBar from 'src/components/common/ProgressBar';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import StatusMessage from 'src/components/common/StatusMessage';

const useStyles = makeStyles({
  mainTitle: {
    paddingLeft: '10px',
    fontSize: '20px',
    fontFamily: 'DrukText-Bold ���m�p�S�V�b�N sans-serif',
    fontWeight: 'bold',
    color: '#0A121A'
  },
  commonBtn: {
    width: '120px'
  }
});

export default function FareTypeSettings(props) {
  const classes = useStyles();
  const { fareTypes } = props;
  const [selectedFareTypes, setSelectedFareTypes] = React.useState(fareTypes);
  const [open, setOpen] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const handleChangeStartPosition = (event) => {
    const selected = selectedFareTypes.find((x) => x.id === event.target.name);
    selected.startPosition = event.target.value;
    setSelectedFareTypes([...selectedFareTypes]);
  };

  const handleChangeMatches = (event) => {
    const selected = selectedFareTypes.find((x) => x.id === event.target.name);
    selected.matches = event.target.value.split(',').map((i) => i.trim());
    setSelectedFareTypes([...selectedFareTypes]);
  };
  const dataToUpdate = { fareTypeConfigurations: selectedFareTypes };
  const useSubmitSaveFareType = useMutation(
    () => savingFareTypeConfig(dataToUpdate)
  );

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleSubmit = () => {
    useSubmitSaveFareType.mutate();
    setOpenDialog(false);
    setOpen(true);
  };
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const { t } = useTranslation();
  const errorMessage = useSubmitSaveFareType.error ? useSubmitSaveFareType.error.toString() : null;
  const message = (useSubmitSaveFareType.data != null) ? t('Updated Successfully')
    : errorMessage;
  const loading = <span><ProgressBar /></span>;
  const TextStyleChangeable = {
    '& .MuiInputBase-input': {
      color: '#0A121A',
      padding: '10px',
      // textTransform: 'uppercase'
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'rgba(0, 0, 0, 0.23)',
      },
      '&:hover fieldset': {
        borderColor: '#FF5E6F',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#FF5E6F',
      },
    },
  };

  const updateStatusMessage = (

    <div>
      <StatusMessage
        open={open}
        title={t('Response Message')}
        message={message}
      >
        <Stack direction="row" spacing={2}>
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={handleClose}
          >
            OK
          </Button>
        </Stack>
      </StatusMessage>
    </div>
  );

  const fareTypeSelect = (f) => (
    <div style={{ paddingBottom: 10 }}>
      <Grid
        container
        spacing={1}
        sx={{ marginBottom: '0px' }}
      >
        <Grid
          item
          md={3}
          sm={12}
        >
          <TextField
            sx={TextStyleChangeable}
            key={f.id}
            select
            name={f.id}
            value={f.startPosition}
            label={f.fareTypeName}
            onChange={handleChangeStartPosition}
            variant="outlined"
            fullWidth="true"
          >
            <MenuItem value="Two">{t('Fare Basis - After 2 digits')}</MenuItem>
            <MenuItem value="Three">{t('Fare Basis - After 3 digits')}</MenuItem>
          </TextField>
        </Grid>
        <Grid
          item
          md={9}
          sm={12}
        >
          <TextField
            sx={TextStyleChangeable}
            key={f.id}
            name={f.id}
            label={f.fareTypeName}
            onChange={handleChangeMatches}
            value={f.matches.join(', ')}
            fullWidth="true"
          />
        </Grid>
      </Grid>
    </div>
  );
  const configs = selectedFareTypes ? (
    <CardContent style={{ paddingBottom: '15px' }}>
      {selectedFareTypes.map((f) => fareTypeSelect(f))}
    </CardContent>
  ) : null;
  // const dataToUpdate = { fareTypeConfigurations: selectedFareTypes };

  const messageDialog = (
    <Dialog
      open={openDialog}
      onClose={handleCloseDialog}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        {t('SAVE FARE TYPE')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t('Are you sure to save the Fare Type Setting(s)')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSubmit} color="primary">
          {t('OK')}
        </Button>
        <Button autoFocus onClick={handleCloseDialog} color="primary">
          {t('Cancel')}
        </Button>

      </DialogActions>
    </Dialog>
  );

  return (
    <div>
      <Card style={{ marginBottom: 10 }}>
        <CardHeader
          style={{ padding: '10px' }}
          title={t('Fare type code settings')}
          titleTypographyProps={{
            fontWeight: 'bold', fontSize: '16px', fontFamily: 'Roboto, Helvetica, Arial, sans-serif', color: '#0A121A',
          }}
        />
        <Divider />
        {/* <CardContent style={{ paddingBottom: '15px' }}> */}
        {configs}
        {messageDialog}
        { !useSubmitSaveFareType.isLoading ? updateStatusMessage : null }
        {
    (useSubmitSaveFareType.isLoading
       || useSubmitSaveFareType.isFetching)
      ? loading : (
        null
      )
  }
        {/* </CardContent> */}
      </Card>
      <Button
        sx={{ float: 'right' }}
        className={classes.commonBtn}
        variant="contained"
        color="primary"
        onClick={handleClickOpenDialog}
      >
        {t('Update')}
      </Button>
      {/* {configs}
      {
    (useSubmitSaveFareType.isLoading
       || useSubmitSaveFareType.isFetching)
      ? loading : (
        null
      )
  }
      { !useSubmitSaveFareType.isLoading ? updateStatusMessage : null }
      <br />
      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        endIcon={<SendIcon />}
        onClick={handleClickOpenDialog}
      >
        {t('Update')}
      </Button>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {t('SAVE FARE TYPE')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('Are you sure to save the Fare Type Setting(s)')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit} color="primary">
            {t('OK')}
          </Button>
          <Button autoFocus onClick={handleCloseDialog} color="primary">
            {t('Cancel')}
          </Button>

        </DialogActions>
      </Dialog> */}
    </div>
  );
}

FareTypeSettings.propTypes = {
  fareTypes: PropTypes.array
};
