import React from 'react';
// import { useState } from 'react';
import {
  TextField,
  Grid,
  CardContent,
  MenuItem
  // FormControl,
  // InputLabel,
  // NativeSelect
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ConnectForm from '../common/ConnectForm';

const AdditionalInfo = (prop) => {
  const {
    fcmi, eb0, eb1, eb2, eb3, eb4, eb5, tktdisp, authnumdisp, ifddt, ifdtm, csvdt, csvtm, uridt, uritm, foprmks, farecul1, farecul2, farecul3, farecul4, farecul5,
    readOnlyFlag
  } = prop;
  const { t } = useTranslation();
  const TextStyleReadOndy = {
    '& .MuiInputBase-input': {
      color: '#0A121A',
      padding: '10px',
      backgroundColor: '#ececec'
    },
  };
  const TextStyleChangeable = {
    '& .MuiInputBase-input': {
      color: '#0A121A',
      padding: '10px',
      // textTransform: 'uppercase'
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'rgba(0, 0, 0, 0.23)',
      },
      '&:hover fieldset': {
        borderColor: '#FF5E6F',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#FF5E6F',
      },
    },
  };
  return (
    <ConnectForm>
      {({ register }) => (
        <CardContent>
          <Grid
            container
            spacing={3}
            sx={{ marginBottom: '10px' }}
          >
            <Grid
              item
              md={6}
              sm={12}
            >
              <TextField
                sx={readOnlyFlag ? TextStyleReadOndy : TextStyleChangeable}
                fullWidth
                label="EB"
                InputLabelProps={{ shrink: true }}
                name="eb0"
                {...register('eb0')}
                defaultValue={eb0}
                variant="outlined"
                InputProps={{ readOnly: readOnlyFlag }}
              />
            </Grid>
            <Grid
              item
              md={6}
              sm={12}
            >
              {/* <FormControl>
                <InputLabel variant="outlined" htmlFor="fcmiStatus" InputLabelProps={{ shrink: true }}>
                  FCMI
                </InputLabel>
                <NativeSelect
                  {...register('fcmi')}
                  defaultValue={fcmi}
                  inputProps={{ name: 'fcmi', id: 'fcmiStatus', readOnly: readOnlyFlag }}
                  style={{ backgroundColor: setBG }}
                >
                  <option value="0">0:AUTO</option>
                  <option value="1">1:MANUAL</option>
                  <option value="2">2:AUTO(BGG/TFC CHGD)</option>
                  <option value="--">UNKNOWN</option>
                </NativeSelect>
              </FormControl> */}
              <TextField
                sx={readOnlyFlag ? TextStyleReadOndy : TextStyleChangeable}
                select
                label="FCMI"
                InputLabelProps={{ shrink: true }}
                {...register('fcmi')}
                defaultValue={fcmi}
                htmlFor="fcmiStatus"
                variant="outlined"
                fullWidth="true"
                InputProps={{ readOnly: readOnlyFlag, name: 'fcmi', id: 'fcmiStatus' }}
              >
                <MenuItem value="0">0:AUTO</MenuItem>
                <MenuItem value="1">1:MANUAL</MenuItem>
                <MenuItem value="2">2:AUTO(BGG/TFC CHGD)</MenuItem>
                <MenuItem value="--">UNKNOWN</MenuItem>
              </TextField>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={3}
            sx={{ marginBottom: '10px' }}
          >
            <Grid
              item
              md={6}
              sm={12}
            >
              <TextField
                sx={readOnlyFlag ? TextStyleReadOndy : TextStyleChangeable}
                fullWidth
                label="EB2(1)"
                InputLabelProps={{ shrink: true }}
                name="eb1"
                {...register('eb1')}
                defaultValue={eb1}
                variant="outlined"
                InputProps={{ readOnly: readOnlyFlag }}
              />
            </Grid>
            <Grid
              item
              md={3}
              sm={12}
            >
              <TextField
                sx={readOnlyFlag ? TextStyleReadOndy : TextStyleChangeable}
                fullWidth
                label={t('Ticket face display')}
                InputLabelProps={{ shrink: true }}
                name="tktdisp"
                {...register('tktdisp')}
                defaultValue={tktdisp}
                variant="outlined"
                InputProps={{ readOnly: readOnlyFlag }}
              />
            </Grid>
            <Grid
              item
              md={3}
              sm={12}
            >
              <TextField
                sx={readOnlyFlag ? TextStyleReadOndy : TextStyleChangeable}
                fullWidth
                label={t('Authentication number display')}
                InputLabelProps={{ shrink: true }}
                name="authnumdisp"
                {...register('authnumdisp')}
                defaultValue={authnumdisp}
                variant="outlined"
                InputProps={{ readOnly: readOnlyFlag }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={3}
            sx={{ marginBottom: '10px' }}
          >
            <Grid
              item
              md={6}
              sm={12}
            >
              <TextField
                sx={readOnlyFlag ? TextStyleReadOndy : TextStyleChangeable}
                fullWidth
                label="EB2(2)"
                InputLabelProps={{ shrink: true }}
                name="eb2"
                {...register('eb2')}
                defaultValue={eb2}
                variant="outlined"
                InputProps={{ readOnly: readOnlyFlag }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={3}
            sx={{ marginBottom: '10px' }}
          >
            <Grid
              item
              md={6}
              sm={12}
            >
              <TextField
                sx={readOnlyFlag ? TextStyleReadOndy : TextStyleChangeable}
                fullWidth
                label="EB2(3)"
                InputLabelProps={{ shrink: true }}
                name="eb3"
                {...register('eb3')}
                defaultValue={eb3}
                variant="outlined"
                InputProps={{ readOnly: readOnlyFlag }}
              />
            </Grid>
            <Grid
              item
              md={3}
              sm={12}
            >
              <TextField
                sx={readOnlyFlag ? TextStyleReadOndy : TextStyleChangeable}
                fullWidth
                label={t('IFD Issue Date')}
                InputLabelProps={{ shrink: true }}
                name="ifddt"
                {...register('ifddt')}
                defaultValue={ifddt}
                variant="outlined"
                InputProps={{ readOnly: readOnlyFlag }}
              />
            </Grid>
            <Grid
              item
              md={3}
              sm={12}
            >
              <TextField
                sx={readOnlyFlag ? TextStyleReadOndy : TextStyleChangeable}
                fullWidth
                label={t('IFD Issue Time')}
                InputLabelProps={{ shrink: true }}
                name="ifdtm"
                {...register('ifdtm')}
                defaultValue={ifdtm}
                variant="outlined"
                InputProps={{ readOnly: readOnlyFlag }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={3}
            sx={{ marginBottom: '10px' }}
          >
            <Grid
              item
              md={6}
              sm={12}
            >
              <TextField
                sx={readOnlyFlag ? TextStyleReadOndy : TextStyleChangeable}
                fullWidth
                label="EB2(4)"
                InputLabelProps={{ shrink: true }}
                name="eb4"
                {...register('eb4')}
                defaultValue={eb4}
                variant="outlined"
                InputProps={{ readOnly: readOnlyFlag }}
              />
            </Grid>
            <Grid
              item
              md={3}
              sm={12}
            >
              <TextField
                sx={readOnlyFlag ? TextStyleReadOndy : TextStyleChangeable}
                fullWidth
                label={t('CSV Issue Date')}
                InputLabelProps={{ shrink: true }}
                name="csvdt"
                {...register('csvdt')}
                defaultValue={csvdt}
                variant="outlined"
                InputProps={{ readOnly: readOnlyFlag }}
              />
            </Grid>
            <Grid
              item
              md={3}
              sm={12}
            >
              <TextField
                sx={readOnlyFlag ? TextStyleReadOndy : TextStyleChangeable}
                fullWidth
                label={t('CSV Issue Time')}
                InputLabelProps={{ shrink: true }}
                name="csvtm"
                {...register('csvtm')}
                defaultValue={csvtm}
                variant="outlined"
                InputProps={{ readOnly: readOnlyFlag }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={3}
            sx={{ marginBottom: '10px' }}
          >
            <Grid
              item
              md={6}
              sm={12}
            >
              <TextField
                sx={readOnlyFlag ? TextStyleReadOndy : TextStyleChangeable}
                fullWidth
                label="EB2(5)"
                InputLabelProps={{ shrink: true }}
                name="eb5"
                {...register('eb5')}
                defaultValue={eb5}
                variant="outlined"
                InputProps={{ readOnly: readOnlyFlag }}
              />
            </Grid>
            <Grid
              item
              md={3}
              sm={12}
            >
              <TextField
                sx={readOnlyFlag ? TextStyleReadOndy : TextStyleChangeable}
                fullWidth
                label={t('URI Issue Date')}
                InputLabelProps={{ shrink: true }}
                name="uridt"
                {...register('uridt')}
                defaultValue={uridt}
                variant="outlined"
                InputProps={{ readOnly: readOnlyFlag }}
              />
            </Grid>
            <Grid
              item
              md={3}
              sm={12}
            >
              <TextField
                sx={readOnlyFlag ? TextStyleReadOndy : TextStyleChangeable}
                fullWidth
                label={t('URI Issue Time')}
                InputLabelProps={{ shrink: true }}
                name="uritm"
                {...register('uritm')}
                defaultValue={uritm}
                variant="outlined"
                InputProps={{ readOnly: readOnlyFlag }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={3}
            sx={{ marginBottom: '10px' }}
          >
            <Grid
              item
              md={12}
              sm={12}
            >
              <TextField
                sx={readOnlyFlag ? TextStyleReadOndy : TextStyleChangeable}
                fullWidth
                label="FOP Remarks"
                InputLabelProps={{ shrink: true }}
                name="foprmks"
                {...register('foprmks')}
                defaultValue={foprmks}
                variant="outlined"
                InputProps={{ readOnly: readOnlyFlag }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={3}
            sx={{ marginBottom: '10px' }}
          >
            <Grid
              item
              md={12}
              sm={12}
            >
              <TextField
                sx={readOnlyFlag ? TextStyleReadOndy : TextStyleChangeable}
                fullWidth
                label="Fare Calculation(1)"
                InputLabelProps={{ shrink: true }}
                name="farecul1"
                {...register('farecul1')}
                defaultValue={farecul1}
                variant="outlined"
                InputProps={{ readOnly: readOnlyFlag }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={3}
            sx={{ marginBottom: '10px' }}
          >
            <Grid
              item
              md={12}
              sm={12}
            >
              <TextField
                sx={readOnlyFlag ? TextStyleReadOndy : TextStyleChangeable}
                fullWidth
                label="Fare Calculation(2)"
                InputLabelProps={{ shrink: true }}
                name="farecul2"
                {...register('farecul2')}
                defaultValue={farecul2}
                variant="outlined"
                InputProps={{ readOnly: readOnlyFlag }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={3}
            sx={{ marginBottom: '10px' }}
          >
            <Grid
              item
              md={12}
              sm={12}
            >
              <TextField
                sx={readOnlyFlag ? TextStyleReadOndy : TextStyleChangeable}
                fullWidth
                label="Fare Calculation(3)"
                InputLabelProps={{ shrink: true }}
                name="farecul3"
                {...register('farecul3')}
                defaultValue={farecul3}
                variant="outlined"
                InputProps={{ readOnly: readOnlyFlag }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={3}
            sx={{ marginBottom: '10px' }}
          >
            <Grid
              item
              md={12}
              sm={12}
            >
              <TextField
                sx={readOnlyFlag ? TextStyleReadOndy : TextStyleChangeable}
                fullWidth
                label="Fare Calculation(4)"
                InputLabelProps={{ shrink: true }}
                name="farecul4"
                {...register('farecul4')}
                defaultValue={farecul4}
                variant="outlined"
                InputProps={{ readOnly: readOnlyFlag }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={3}
            sx={{ marginBottom: '10px' }}
          >
            <Grid
              item
              md={12}
              sm={12}
            >
              <TextField
                sx={readOnlyFlag ? TextStyleReadOndy : TextStyleChangeable}
                fullWidth
                label="Fare Calculation(5)"
                InputLabelProps={{ shrink: true }}
                name="farecul5"
                {...register('farecul5')}
                defaultValue={farecul5}
                variant="outlined"
                InputProps={{ readOnly: readOnlyFlag }}
              />
            </Grid>
          </Grid>
        </CardContent>
      )}
    </ConnectForm>
  );
};

export default AdditionalInfo;
