/* eslint-disable no-lone-blocks */
import React from 'react';
import PropTypes from 'prop-types';

import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  Grid,
  Button,
  Stack
} from '@material-ui/core';
import { useMutation } from 'react-query';
import MenuItem from '@material-ui/core/MenuItem';
import { updateExportConfiguration } from 'src/api/api';
import { makeStyles } from '@material-ui/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTranslation } from 'react-i18next';
import StatusMessage from 'src/components/common/StatusMessage';

const ExportSettings = (props) => {
  const { row, refetch } = props;
  // const { row } = props;
  const [configuration, setConfiguration] = React.useState(row);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [statusMessage, setStatusMessage] = React.useState('');

  const handleChangeExportFolder = (e) => {
    setConfiguration({ ...configuration, exportFolder: e.target.value });
  };

  const handleChangeExportType = (e) => {
    setConfiguration({ ...configuration, exportType: e.target.value });
  };

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  // const useUpdateMutation = useMutation(
  //   () => updateExportConfiguration(configuration).then(refetch)
  // );
  const { t } = useTranslation();

  const useUpdateMutation = useMutation(
    () => updateExportConfiguration(configuration),
    {
      onSuccess: (r) => {
        if (r.data.status === 'Ok') {
          setOpenDialog(false);
          setOpen(true);
          setStatusMessage(t('Updated Successfully'));
        }
      },
      onError: () => {
        setOpenDialog(false);
        setOpen(true);
        setStatusMessage(t('Export folder path is not valid or or not exist'));
      }
    }
  );

  const handleSubmit = () => {
    useUpdateMutation.mutate();
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
    refetch();
  };

  const showMessage = (
    <div>
      <StatusMessage
        open={open}
        title={t('Response Message')}
        message={statusMessage}
      >
        <Stack direction="row" spacing={2}>
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={handleClose}
          >
            OK
          </Button>
        </Stack>
      </StatusMessage>
    </div>
  );

  const exportSetting = (
    <div>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {t('Update Export Configuration')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('Are you sure to update Export Configuration')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit} color="primary">
            OK
          </Button>
          <Button autoFocus onClick={handleCloseDialog} color="primary">
            {t('Cancel')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );

  const useStyles = makeStyles({
    commonBtn: {
      width: '120px'
    },
    information: {
      fontSize: '12px',
      color: 'red',
      textAlign: 'center'
    }
  });
  const classes = useStyles();

  const TextStyleChangeable = {
    '& .MuiInputBase-input': {
      color: '#0A121A',
      padding: '10px'
      // textTransform: 'uppercase'
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'rgba(0, 0, 0, 0.23)'
      },
      '&:hover fieldset': {
        borderColor: '#FF5E6F'
      },
      '&.Mui-focused fieldset': {
        borderColor: '#FF5E6F'
      }
    }
  };
  return (
    <>
      <Card style={{ marginBottom: 10 }}>
        <CardHeader
          style={{ padding: '10px' }}
          title={t('Export Configuration')}
          titleTypographyProps={{
            fontWeight: 'bold',
            fontSize: '16px',
            fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
            color: '#0A121A'
          }}
        />
        <Divider />
        <CardContent style={{ paddingBottom: '15px' }}>
          <Grid container spacing={3}>
            <Grid item md={6} sm={12}>
              <TextField
                sx={TextStyleChangeable}
                fullWidth
                label={t('Export Folder')}
                name="exportFolder"
                variant="outlined"
                value={configuration.exportFolder}
                // value={exportFolderValue}
                onChange={handleChangeExportFolder}
              />
            </Grid>
            <Grid item md={3} sm={12}>
              <TextField
                sx={TextStyleChangeable}
                select
                label={t('Type')}
                value={configuration.exportType}
                // value={exportTypeValue}
                variant="outlined"
                fullWidth="true"
                onChange={handleChangeExportType}
              >
                <MenuItem value="Please Select">{t('Please Select')}</MenuItem>
                <MenuItem value="UriProFormat">{t('URIPro')}</MenuItem>
                <MenuItem value="UriProFormat4DigitFlightNumber">
                  {t('NewURIPro')}
                </MenuItem>
                {/* <MenuItem value="NewUriProFormat">{t('NewURIPro')}</MenuItem>
                <MenuItem value="NewUriProFormat4DigitFlightNumber">
                  {t('NewURIPRo3Digit')}
                </MenuItem>
                <MenuItem value="UriProNew">{t('UriProNewTest')}</MenuItem> */}
              </TextField>
            </Grid>
          </Grid>
        </CardContent>
        <div className={classes.information}>
          {t(
            '* "URIPRo3Digit" has been changed to "NewURIPro" since UI Version 3.0.3'
          )}
        </div>
        <div className={classes.information}>
          {t(
            '* "NewURIPro" is an output format compatible with NDC, applicable for API version 3.0.0 or higher'
          )}
        </div>
      </Card>
      <div>
        <Button
          sx={{ float: 'right' }}
          className={classes.commonBtn}
          variant="contained"
          color="primary"
          onClick={handleClickOpenDialog}
        >
          {t('Update')}
        </Button>
        {showMessage}
        {exportSetting}
      </div>
    </>
  );
};

ExportSettings.propTypes = {
  row: PropTypes.array.isRequired,
  refetch: PropTypes.func.isRequired
};
export default ExportSettings;
