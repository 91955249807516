import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  Grid
} from '@material-ui/core';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';

export default function AccountSetings(props) {
  const {
    onMirFilePathChange, onBackupMirFilePathChange, onExpiryDateChange, onBackUpRequiredChange, mirRawFilePath,
    mirBackupFilePath, keepRawMirForDays, isBackupRequired
  } = props;

  const { t } = useTranslation();

  const useStyles = makeStyles({
    labelRoot: {
      fontSize: '14px'
    }
  });
  const classes = useStyles();

  const TextStyleChangeable = {
    '& .MuiInputBase-input': {
      color: '#0A121A',
      padding: '10px',
      // textTransform: 'uppercase'
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'rgba(0, 0, 0, 0.23)',
      },
      '&:hover fieldset': {
        borderColor: '#FF5E6F',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#FF5E6F',
      },
    },
  };

  return (
    <div>
      <Card>
        <CardHeader
          style={{ padding: '10px' }}
          title={t('MIR to Disk Settings')}
          titleTypographyProps={{
            fontWeight: 'bold', fontSize: '16px', fontFamily: 'Roboto, Helvetica, Arial, sans-serif', color: '#0A121A',
          }}
        />
        <Divider />
        <CardContent style={{ paddingBottom: '15px' }}>
          <Grid
            container
            spacing={1}
            sx={{ marginBottom: '0px' }}
          >
            <Grid
              item
              md={3}
              sm={12}
            >
              <TextField
                sx={TextStyleChangeable}
                fullWidth
                label={t('Current MIR File Location')}
                name="currentMirFileLocation"
                variant="outlined"
                value={mirRawFilePath}
                onChange={onMirFilePathChange}
              />
            </Grid>
            <Grid
              item
              md={3}
              sm={12}
            >
              <TextField
                sx={TextStyleChangeable}
                fullWidth
                label={t('MIR File Location')}
                name="newMirFileLocation"
                variant="outlined"
                value={mirBackupFilePath}
                onChange={onBackupMirFilePathChange}
              />
            </Grid>
            <Grid
              item
              md={3}
              sm={12}
            >
              <TextField
                sx={TextStyleChangeable}
                fullWidth
                label={t('File Expiry day')}
                name="File Expiry day"
                variant="outlined"
                value={keepRawMirForDays}
                onChange={onExpiryDateChange}
              />
            </Grid>
            <Grid
              item
              md={3}
              sm={12}
            >
              <FormControlLabel
                control={(
                  <Checkbox
                    style={{ paddingLeft: 20, fontSize: '14px' }}
                    checked={isBackupRequired}
                    onChange={onBackUpRequiredChange}
                    name="backuprequired"
                    color="primary"
                  />
          )}
                label={<span className={classes.labelRoot}>{t('BackUp Required')}</span>}
              />

            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
}
AccountSetings.propTypes = {

  onMirFilePathChange: PropTypes.func,
  onBackupMirFilePathChange: PropTypes.func,
  onExpiryDateChange: PropTypes.func,
  mirRawFilePath: PropTypes.string,
  mirBackupFilePath: PropTypes.string,
  keepRawMirForDays: PropTypes.number,
  isBackupRequired: PropTypes.bool,
  onBackUpRequiredChange: PropTypes.func
};
