import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import * as serviceWorker from './serviceWorker';

import './i18n';
import App from './App';

const queryClient = new QueryClient();
ReactDOM.render((
  <BrowserRouter>
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  </BrowserRouter>
), document.getElementById('root'));

serviceWorker.unregister();
