import { Navigate } from 'react-router-dom';
import DashboardLayout from 'src/components/DashboardLayout';
import MainLayout from 'src/components/MainLayout';
import MIRToDiskPage from 'src/pages/MIRToDiskPage';
import Dashboard from 'src/pages/Dashboard';
import Login from 'src/pages/Login';
import NotFound from 'src/pages/NotFound';
import ImportBackUp from 'src/pages/ImportBackUp';
import Register from 'src/pages/Register';
import Settings from 'src/pages/Settings';
import TicketDetails from 'src/pages/TicketDetails';
import TicketList from 'src/pages/TicketList';

const routes = [
  {
    path: 'app',
    element: <DashboardLayout />,
    children: [
      { path: 'ImportMIRToDisk', element: <MIRToDiskPage /> },
      { path: 'tickets', element: <TicketDetails /> },
      { path: 'tickets/:ticketId', element: <TicketDetails /> },
      { path: 'list', element: <TicketList /> },
      { path: 'dashboard', element: <Dashboard /> },
      { path: 'ImportBackUp', element: <ImportBackUp /> },
      { path: 'Settings', element: <Settings /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'login', element: <Login /> },
      { path: 'register', element: <Register /> },
      { path: '404', element: <NotFound /> },
      { path: '/', element: <Navigate to="/app/ImportMIRToDisk" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
];

export default routes;
