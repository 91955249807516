import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box,
  Drawer,
  Hidden,
  List,
} from '@material-ui/core';
import FileImport from '@material-ui/icons/FileDownload';
import ModifyTicket from '@material-ui/icons/ChangeCircleOutlined';
import TicketList from '@material-ui/icons/ListAltOutlined';
import Settings from '@material-ui/icons/SettingsOutlined';
import Logout from '@material-ui/icons/ExitToAppOutlined';
import { useTranslation } from 'react-i18next';
import NavItem from './NavItem';

// {t('URI Issue Date')}
const items = [
  {
    href: '/app/ImportMIRToDisk',
    icon: FileImport,
    title: 'Import MIR From Disk'
  },
  {
    href: '/app/ImportBackUp',
    icon: FileImport,
    title: 'Import MIR From Backup'
  },
  {
    href: '/app/tickets',
    icon: ModifyTicket,
    title: 'Ticket Details'
  },
  {
    href: '/app/list',
    icon: TicketList,
    title: 'Ticket List'
  },
  {
    href: '/app/settings',
    icon: Settings,
    title: 'Settings'
  },
  {
    href: '/login',
    icon: Logout,
    title: 'Sign Out'
  }
];

const DashboardSidebar = ({ onMobileClose, openMobile }) => {
  const location = useLocation();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);
  const { t } = useTranslation();

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Box sx={{ p: 2 }}>
        <List>
          {items.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              // title={item.title}
              title={t(item.title)}
              icon={item.icon}
            />
          ))}
        </List>
      </Box>
      <Box sx={{ flexGrow: 1 }} />
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
          PaperProps={{
            sx: {
              width: 256
            }
          }}
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="left"
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: 256,
              top: 64,
              height: 'calc(100% - 64px)'
            }
          }}
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

DashboardSidebar.defaultProps = {
  onMobileClose: () => { },
  openMobile: false
};

export default DashboardSidebar;
