import React from 'react';
import {
  TextField,
  Grid
  // MenuItem,
  // CardContent
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ConnectForm from '../common/ConnectForm';

export default function FaresPayments(props) {
  const {
    farecurrency,
    fareamount,
    payment,
    equivalentcurrency,
    equivalentamount,
    cashgross,
    creditgross,
    totalcurrency,
    totalamount,
    taxcash,
    taxcredit,
    amountTotalCash,
    amountTotalCredit,
    readOnlyFlag
  } = props;
  const { t } = useTranslation();
  const TextStyleReadOndy = {
    '& .MuiInputBase-input': {
      color: '#0A121A',
      padding: '10px',
      backgroundColor: '#ececec'
    }
  };
  const TextStyleChangeable = {
    '& .MuiInputBase-input': {
      color: '#0A121A',
      padding: '10px'
      // textTransform: 'uppercase'
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'rgba(0, 0, 0, 0.23)'
      },
      '&:hover fieldset': {
        borderColor: '#FF5E6F'
      },
      '&.Mui-focused fieldset': {
        borderColor: '#FF5E6F'
      }
    }
  };

  return (
    <ConnectForm>
      {({ register }) => (
        <div
          style={{
            marginBottom: 20,
            paddingBottom: 10,
            borderTop: 0,
            borderLeft: 0,
            borderRight: 0,
            borderBottom: 1,
            borderStyle: 'dotted'
          }}
        >
          <Grid container spacing={3} sx={{ marginBottom: '10px' }}>
            <Grid item md={2} sm={12}>
              <TextField
                sx={TextStyleReadOndy}
                fullWidth
                label="Fare - Cur."
                InputLabelProps={{ shrink: true }}
                name="farecurrency"
                {...register('farecurrency')}
                defaultValue={farecurrency}
                variant="outlined"
                InputProps={{
                  readOnly: true
                }}
              />
            </Grid>
            <Grid item md={2} sm={12}>
              <TextField
                sx={readOnlyFlag ? TextStyleReadOndy : TextStyleChangeable}
                fullWidth
                label="Fare - Amt."
                InputLabelProps={{ shrink: true }}
                name="fareamount"
                required
                {...register('fareamount')}
                defaultValue={fareamount}
                variant="outlined"
                InputProps={{ readOnly: readOnlyFlag }}
              />
            </Grid>
            <Grid item md={2} sm={12} style={{ marginLeft: 190 }}>
              <TextField
                sx={readOnlyFlag ? TextStyleReadOndy : TextStyleChangeable}
                fullWidth
                label={t('Payment')}
                name="payment"
                value={payment}
                variant="outlined"
                InputProps={{ readOnly: readOnlyFlag }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} sx={{ marginBottom: '10px' }}>
            <Grid item md={2} sm={12}>
              <TextField
                sx={TextStyleReadOndy}
                fullWidth
                label="Equivalent - Cur."
                InputLabelProps={{ shrink: true }}
                name="equivalentcurrency"
                {...register('equivalentcurrency')}
                defaultValue={equivalentcurrency}
                variant="outlined"
                InputProps={{
                  readOnly: true
                }}
              />
            </Grid>
            <Grid item md={2} sm={12}>
              <TextField
                sx={readOnlyFlag ? TextStyleReadOndy : TextStyleChangeable}
                fullWidth
                label="Equivalent - Amt."
                InputLabelProps={{ shrink: true }}
                name="equivalentamount"
                required
                {...register('equivalentamount')}
                defaultValue={equivalentamount}
                variant="outlined"
                InputProps={{ readOnly: readOnlyFlag }}
              />
            </Grid>
            <Grid item md={2} sm={12} style={{ marginLeft: 190 }}>
              <TextField
                sx={readOnlyFlag ? TextStyleReadOndy : TextStyleChangeable}
                fullWidth
                label="CASH - Gross"
                InputLabelProps={{ shrink: true }}
                name="cashgross"
                required
                {...register('cashgross')}
                defaultValue={cashgross}
                variant="outlined"
                InputProps={{ readOnly: readOnlyFlag }}
              />
            </Grid>
            <Grid item md={2} sm={12}>
              <TextField
                sx={readOnlyFlag ? TextStyleReadOndy : TextStyleChangeable}
                fullWidth
                label="CREDIT - Gross"
                InputLabelProps={{ shrink: true }}
                name="creditgross"
                required
                {...register('creditgross')}
                // defaultValue={creditgross}
                defaultValue={creditgross}
                variant="outlined"
                InputProps={{ readOnly: readOnlyFlag }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} sx={{ marginBottom: '10px' }}>
            <Grid item md={2} sm={12}>
              <TextField
                sx={TextStyleReadOndy}
                fullWidth
                label="Total - Cur."
                InputLabelProps={{ shrink: true }}
                name="totalcurrency"
                {...register('totalcurrency')}
                defaultValue={totalcurrency}
                variant="outlined"
                InputProps={{
                  readOnly: true
                }}
              />
            </Grid>
            <Grid item md={2} sm={12}>
              <TextField
                sx={readOnlyFlag ? TextStyleReadOndy : TextStyleChangeable}
                fullWidth
                label="Total - Amt."
                InputLabelProps={{ shrink: true }}
                name="totalamount"
                required
                {...register('totalamount')}
                defaultValue={totalamount}
                variant="outlined"
                InputProps={{ readOnly: readOnlyFlag }}
              />
            </Grid>
            <Grid item md={2} sm={12} style={{ marginLeft: 190 }}>
              <TextField
                sx={readOnlyFlag ? TextStyleReadOndy : TextStyleChangeable}
                fullWidth
                label="CASH - Tax"
                InputLabelProps={{ shrink: true }}
                name="taxcash"
                required
                {...register('taxcash')}
                defaultValue={taxcash}
                variant="outlined"
                InputProps={{ readOnly: readOnlyFlag }}
              />
            </Grid>
            <Grid item md={2} sm={12}>
              <TextField
                sx={readOnlyFlag ? TextStyleReadOndy : TextStyleChangeable}
                fullWidth
                label="CREDIT - Tax"
                InputLabelProps={{ shrink: true }}
                name="taxcredit"
                required
                {...register('taxcredit')}
                defaultValue={taxcredit}
                variant="outlined"
                InputProps={{ readOnly: readOnlyFlag }}
              />
            </Grid>
          </Grid>
          <input type="hidden" value={amountTotalCash} name="amountTotalCash" />
          <input
            type="hidden"
            value={amountTotalCredit}
            name="amountTotalCredit"
          />
        </div>
      )}
    </ConnectForm>
  );
}

FaresPayments.propTypes = {
  farecurrency: PropTypes.string,
  fareamount: PropTypes.number,
  payment: PropTypes.string,
  equivalentcurrency: PropTypes.string,
  equivalentamount: PropTypes.number,
  cashgross: PropTypes.number,
  creditgross: PropTypes.number,
  totalcurrency: PropTypes.string,
  totalamount: PropTypes.number,
  taxcash: PropTypes.number,
  taxcredit: PropTypes.number,
  amountTotalCash: PropTypes.number,
  amountTotalCredit: PropTypes.number,
  readOnlyFlag: PropTypes.bool
};
