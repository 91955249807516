/* eslint-disable padded-blocks */

import React from 'react';
import {
  Button,
  Stack
} from '@material-ui/core';
import StatusMessage from 'src/components/common/StatusMessage';

const NetworkErrorPage = (message, handleClose) => (

  <div>
    <StatusMessage
      open
      title="Response Message"
      message={message}
    >
      <Stack direction="row" spacing={2}>
        <Button
          size="small"
          variant="contained"
          color="primary"
          onClick={handleClose}
        >
          OK
        </Button>
      </Stack>
    </StatusMessage>
  </div>

);
export default NetworkErrorPage;
