import React from 'react';
// import { useState } from 'react';
import {
  TextField,
  Grid
} from '@material-ui/core';
import ConnectForm from '../common/ConnectForm';

const Tax = (prop) => {
  const {
    taxValueLabel, taxCodeLabel, taxValue, taxCode, taxValueId, taxCodeId, index, isRefund, readOnlyFlag
  } = prop;
  const taxValueName = isRefund ? `rftaxes.${index}.taxValue` : `taxes.${index}.taxValue`;
  const taxCodeName = isRefund ? `rftaxes.${index}.taxCode` : `taxes.${index}.taxCode`;
  const TextStyleReadOndy = {
    '& .MuiInputBase-input': {
      color: '#0A121A',
      padding: '10px',
      backgroundColor: '#ececec'
    },
  };
  const TextStyleChangeable = {
    '& .MuiInputBase-input': {
      color: '#0A121A',
      padding: '10px',
      // textTransform: 'uppercase'
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'rgba(0, 0, 0, 0.23)',
      },
      '&:hover fieldset': {
        borderColor: '#FF5E6F',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#FF5E6F',
      },
    },
  };
  return (
    <ConnectForm>
      {({ register }) => (
        <Grid container spacing={3} sx={{ marginBottom: '10px' }}>
          <Grid item md={2} xs={12}>
            <TextField
              sx={readOnlyFlag ? TextStyleReadOndy : TextStyleChangeable}
              label={taxValueLabel}
              InputLabelProps={{ shrink: true }}
              name={taxValueId}
              {...register(taxValueName)}
              defaultValue={taxValue}
              variant="outlined"
              InputProps={{ readOnly: readOnlyFlag }}
            />
          </Grid>
          <Grid item md={2} xs={12}>
            <TextField
              sx={readOnlyFlag ? TextStyleReadOndy : TextStyleChangeable}
              label={taxCodeLabel}
              InputLabelProps={{ shrink: true }}
              name={taxCodeId}
              // {...register(`taxes.${index}.taxCode`)}
              {...register(taxCodeName)}
              defaultValue={taxCode}
              variant="outlined"
              InputProps={{ readOnly: readOnlyFlag }}
            />
          </Grid>
        </Grid>
      )}
    </ConnectForm>
  );
};

export default Tax;
