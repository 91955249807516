import React from 'react';
import { Helmet } from 'react-helmet';
import {
  geTicketDetails,
  getTicketByIssueDate,
  getTicketByNumber,
  getTicketByRangeAndExport,
  getTicketByNumberAndExport,
  getExportByAll,
  healthCheck
} from 'src/api/api';
import { QueryClient, QueryClientProvider, useMutation } from 'react-query';
import {
  Box,
  Container,
  Button,
  Stack,
  snackbarClasses
} from '@material-ui/core';
import TicketFilter from 'src/components/ticket/TicketFilter';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import CircularProgress from '@material-ui/core/CircularProgress';
import NetworkErrorPage from 'src/components/common/NetworkErrorPage';
import StatusMessage from 'src/components/common/StatusMessage';
import TicketListResult from 'src/components/ticket/TicketListResult';

import Logout from './Logout';

const DEFAULT_PAGE_SIZE = 20; // Kenji want to show all
const TicketList = () => {
  const isLogged = localStorage.getItem('token');
  if (!isLogged) {
    Logout();
  }
  const [currentPage, setCurrentPage] = React.useState(1);
  const [selectedTickets, setSelectedTickets] = React.useState([]);
  const [resultTickets, setResultTickets] = React.useState([]);
  const [snackBarMessage, setSnackBarMessage] = React.useState('');
  const [msg, setMsg] = React.useState(null);
  const [connectionStatus, setConnectionStatus] = React.useState(<></>);
  const [open, setOpen] = React.useState(false);
  const [searchParameter, setSearchParameter] = React.useState();
  const queryClient = new QueryClient();

  React.useEffect(() => {
    if (resultTickets && resultTickets.ticketDetails) {
      setSelectedTickets(
        resultTickets.ticketDetails.map((t) => t.TicketNumber.value)
      );
    }
  }, [resultTickets]);

  const searchByDateTimeMutation = useMutation(
    (filterByDate) => getTicketByIssueDate(
      filterByDate.startDateTime,
      filterByDate.endDateTime,
      DEFAULT_PAGE_SIZE,
      currentPage - 1,
      filterByDate.carrier,
      filterByDate.iataNumber,
      filterByDate.isEmd,
      filterByDate.isRefund,
      filterByDate.isExported,
      filterByDate.isNdc
    ),
    {
      onSuccess: (r) => {
        setResultTickets(r);
      }
    }
  );
  const searchByDateTime = (filterByDate) => {
    setCurrentPage(1);
    setSearchParameter({ type: 'date', filter: filterByDate });
    searchByDateTimeMutation.mutate(filterByDate);
  };
  // export by date range
  const exportByDateMutation = useMutation(
    (dateFilters) => getTicketByRangeAndExport(
      dateFilters.startDateTime,
      // dateFilters.endDateTime, dateFilters.carrier, dateFilters.iataNumber,
      dateFilters.endDateTime,
      dateFilters.iataNumber,
      dateFilters.carrier,
      dateFilters.isEmd,
      dateFilters.isRefund,
      dateFilters.isExported,
      dateFilters.isNdc
    ),
    {
      onSuccess: (r) => {
        setSnackBarMessage(r.data.message);
      },
      onError: (e) => {
        setSnackBarMessage(e.message);
      }
    }
  );

  const handleDateTimeFilterExportChanged = (dateFilters) => {
    exportByDateMutation.mutate(dateFilters);
    setOpen(true);
  };

  // export by ticket range

  const exportByTicketNumberMutation = useMutation(
    (ticketFilters) => getTicketByNumberAndExport(
      ticketFilters.StartTicketNumber,
      ticketFilters.EndTicketNumber,
      ticketFilters.iataNumber,
      ticketFilters.carrier,
      ticketFilters.isEmd,
      ticketFilters.isExported,
      ticketFilters.isRefund,
      ticketFilters.isNdc
    ),
    {
      onSuccess: (r) => {
        setSnackBarMessage(r.data.message);
      },
      onError: (e) => {
        setSnackBarMessage(e.message);
      }
    }
  );
  const handleFromToTicketExportChanged = (ticketFilters) => {
    exportByTicketNumberMutation.mutate(ticketFilters);
    setOpen(true);
  };

  const searchByTicketNumberMutation = useMutation(
    (filterByNumber) => getTicketByNumber(
      filterByNumber.StartTicketNumber,
      filterByNumber.EndTicketNumber,
      DEFAULT_PAGE_SIZE,
      currentPage - 1,
      filterByNumber.iataNumber,
      filterByNumber.carrier,
      filterByNumber.isEmd,
      filterByNumber.isRefund,
      filterByNumber.isExported,
      filterByNumber.isNdc
    ),
    {
      onSuccess: (r) => {
        setResultTickets(r);
      }
    }
  );
  const searchByTicketNumber = (filterByNumber) => {
    setCurrentPage(1);
    setSearchParameter({ type: 'ticketnumber', filter: filterByNumber });
    searchByTicketNumberMutation.mutate(filterByNumber);
    setOpen(true);
  };

  const exportAllMutation = useMutation((filter) => getExportByAll(filter), {
    onSuccess: (r) => {
      setSnackBarMessage(r.data.message);
    },
    onError: (e) => {
      setSnackBarMessage(e.message);
    }
  });

  const handleAllFilterExportChanged = (filterByAll) => {
    exportAllMutation.mutate(filterByAll);
    setOpen(true);
  };

  const searchAllMutation = useMutation(
    (filterByAll) => geTicketDetails(
      filterByAll.iataNumber,
      filterByAll.carrier,
      currentPage - 1,
      DEFAULT_PAGE_SIZE,
      filterByAll.isEmd,
      filterByAll.isRefund,
      filterByAll.isExported,
      filterByAll.isNdc
    ),
    {
      onSuccess: (r) => {
        setResultTickets(r);
      },
      onError: (e) => {
        console.log(e);
      }
    }
  );

  const searchAll = (filterByAll) => {
    setCurrentPage(1);
    setSearchParameter({ type: 'all', filter: filterByAll });
    searchAllMutation.mutate(filterByAll);
  };

  const handlePageChange = (value) => {
    if (searchParameter.type === 'all') {
      setCurrentPage(value);
    }
    if (searchParameter.type === 'ticketnumber') {
      setCurrentPage(value);
    }
    if (searchParameter.type === 'date') {
      setCurrentPage(value);
    }
  };

  const { t } = useTranslation();
  React.useEffect(() => {
    healthCheck().then((r) => {
      if (!r) {
        setConnectionStatus(
          NetworkErrorPage(
            t('Connection to server is lost. Please check API server'),
            () => Logout()
          )
        );
      }
    });
  });

  React.useEffect(() => {
    if (searchParameter && searchParameter.type === 'all') {
      searchAllMutation.mutate(searchParameter.filter);
    }
    if (searchParameter && searchParameter.type === 'ticketnumber') {
      searchByTicketNumberMutation.mutate(searchParameter.filter);
    }
    if (searchParameter && searchParameter.type === 'date') {
      searchByDateTimeMutation.mutate(searchParameter.filter);
    }
  }, [currentPage]);

  // console.log(resultTickets);
  const ticketListAry = resultTickets.ticketDetails
    ? resultTickets.ticketDetails.map((p) => {
      const makePassengerName = p.IsEmd && p.EMDData != null
        ? p.EMDData.PassengerName
        : `${p.Traveler.PersonName.Prefix}${p.Traveler.PersonName.Given}/${p.Traveler.PersonName.Surname}`;
      const isConjuctionTicket = p.OtherTickets != null && p.OtherTickets.length > 0;
      const isChildrenTicket = p.ParentTicketNumber && p.ParentTicketNumber.length > 0;
      return {
        isConjuctionTicket,
        isChildrenTicket,
        tktnumber: p.TicketNumber.value,
        tktrecloc: p.RecordLocator,
        tktstatus: p.TicketingStatus,
        tktaircode:
            p.IsEmd && p.EMDData != null
              ? p.EMDData.ValidatingCarrier
              : p.ValidatingAirlineCode,
        tktiatanum: p.IATANumber,
        tktissuedate:
            p.IssueDate != null ? moment(p.IssueDate).format('yyyy-MM-DD') : '',
        tktpassenger: makePassengerName.replace(/(.+)\/$/, '$1'),
        // tktpassenger: p.IsEmd && p.EMDData != null ? p.EMDData.PassengerName : `${p.Traveler.PersonName.Prefix} ${p.Traveler.PersonName.Given}/${p.Traveler.PersonName.Surname}`,
        // tktpassengertype: t.IsEmd ? null : t.Traveler.passengerTypeCode
        // tktpassengertype:
        //     p.IsEmd && p.EMDData != null ? null : p.Traveler.passengerTypeCode
        tktpassengertype:
            p.IsEmd && p.EMDData != null
              ? null
              : p.Traveler.passengerTypeCode.substr(0, 6)
      };
    })
    : null;

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackBarMessage('');
    setOpen(false);
  };

  const updateStatusMessage = (
    <div>
      <StatusMessage
        open={open}
        title={t('Response Message')}
        message={snackBarMessage}
      >
        <Stack direction="row" spacing={2}>
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={handleClose}
          >
            CANCEL
          </Button>
        </Stack>
      </StatusMessage>
    </div>
  );

  const handleTicketSelected = (ticketData, ticketStatus) => {
    if (ticketStatus === true) {
      const selected = [...selectedTickets, ticketData];
      setSelectedTickets(selected); // this is async
    }
    if (ticketStatus === false) {
      setSelectedTickets(selectedTickets.filter((item) => item !== ticketData));
    }
  };

  React.useEffect(() => {
    if (snackBarMessage) {
      setMsg(StatusMessage(true, snackbarClasses));
    } else {
      setMsg(null);
    }
  }, [snackBarMessage]);

  const isMutationLoading = searchAllMutation.isLoading
    || searchByDateTimeMutation.isLoading
    || searchByTicketNumberMutation.isLoading
    || exportAllMutation.isLoading
    || exportByDateMutation.isLoading
    || exportByTicketNumberMutation.isLoading;

  const LoadingMirInfo = (
    <div
      style={{
        marginTop: 5,
        marginBottom: 5,
        display: 'flex',
        justifyContent: 'center'
      }}
    >
      <CircularProgress />
    </div>
  );

  React.useEffect(() => {
    if (resultTickets.ticketDetails) {
      resultTickets.ticketDetails.forEach((p) => {
        if (p.OtherTickets != null && p.OtherTickets.length > 0) {
          p.OtherTickets.forEach((o) => {
            if (
              !ticketListAry
                .map((n) => n.tktnumber)
                .includes(o.TicketNumber.value)
            ) {
              const makePassengerName = p.IsEmd && p.EMDData != null
                ? p.EMDData.PassengerName
                : `${p.Traveler.PersonName.Prefix}${p.Traveler.PersonName.Given}/${p.Traveler.PersonName.Surname}`;
              const isConjuctionTicket = true;
              const isChildrenTicket = o.ParentTicketNumber && o.ParentTicketNumber.length > 0;
              ticketListAry.push({
                isConjuctionTicket,
                isChildrenTicket,
                tktnumber: `${o.TicketNumber.value}`,
                tktrecloc: o.RecordLocator,
                tktstatus: p.TicketingStatus,
                tktaircode:
                  p.IsEmd && p.EMDData != null
                    ? p.EMDData.ValidatingCarrier
                    : o.ValidatingAirlineCode,
                tktiatanum: o.IATANumber,
                tktissuedate:
                  o.IssueDate != null
                    ? moment(o.IssueDate).format('yyyy-MM-DD')
                    : '',
                tktpassenger: makePassengerName.replace(/(.+)\/$/, '$1'),
                // tktpassenger: p.IsEmd && p.EMDData != null ? p.EMDData.PassengerName : `${p.Traveler.PersonName.Prefix} ${p.Traveler.PersonName.Given} ${p.Traveler.PersonName.Surname}`,
                tktpassengertype:
                  p.IsEmd && p.EMDData != null
                    ? null
                    : p.Traveler.passengerTypeCode.substr(0, 6)
              });
            }
          });
        }
      });
    }
  }, [resultTickets.ticketDetails]);

  const ticketList = ticketListAry ? (
    <TicketListResult
      tickets={ticketListAry.sort((t1, t2) => {
        if (t1.tktissuedate < t2.tktissuedate) return -1;
        if (t1.tktissuedate > t2.tktissuedate) return 1;
        if (t1.tktnumber < t2.tktnumber) return -1;
        if (t1.tktnumber > t2.tktnumber) return 1;
        return 0;
      })}
      selectedTickets={selectedTickets}
      onTicketSelected={handleTicketSelected}
      currentPage={currentPage}
      totalNumberOfTickets={resultTickets.totalNumberOfTicketDetail}
      onPageChange={handlePageChange}
      searchParameter={searchParameter}
      onDeleteComplete={() => searchAllMutation.mutate(searchParameter.filter)}
    />
  ) : null;
  const loadingOrResult = isMutationLoading ? LoadingMirInfo : ticketList;

  return (
    <div>
      <QueryClientProvider client={queryClient}>
        <Helmet>
          <title>{t('Travelport GBS | Ticket List/Export')}</title>
        </Helmet>
        {connectionStatus}
        <Box
          sx={{
            backgroundColor: 'background.default',
            minHeight: '100%',
            maxWidth: '1100px',
            minWidth: '1100px',
            py: 3
          }}
        >
          <Container maxWidth={false}>
            <Box
              sx={{ pt: 0 }}
              color="primary.main"
              borderBottom={0}
              marginBottom={0}
            >
              <TicketFilter
                dateTimeFilterChanged={searchByDateTime}
                fromToTicketChanged={searchByTicketNumber}
                dateTimeFilterChangedExport={handleDateTimeFilterExportChanged}
                fromToTicketChangedExport={handleFromToTicketExportChanged}
                allFilterChangedExport={handleAllFilterExportChanged}
                allOptionSelected={searchAll}
              />
            </Box>
            <Box style={{ float: 'left' }}>
              {snackBarMessage ? updateStatusMessage : null}
            </Box>
            <Box sx={{ pt: 1 }}>
              {msg}
              {loadingOrResult}
            </Box>
          </Container>
        </Box>
      </QueryClientProvider>
    </div>
  );
};

export default TicketList;
