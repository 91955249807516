import React from 'react';
// import { useState } from 'react';
import { TextField, Grid, CardContent } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ConnectForm from '../common/ConnectForm';

const Others = (prop) => {
  const {
    acceptingorders,
    section,
    incharge,
    client,
    direction,
    free,
    tour,
    kbe1,
    kbe2,
    eo,
    deposit,
    orderid,
    customerid,
    phonecity1,
    phonecity2,
    phonecity3,
    readOnlyFlag
  } = prop;

  const { t } = useTranslation();
  const TextStyleReadOndy = {
    '& .MuiInputBase-input': {
      color: '#0A121A',
      padding: '10px',
      backgroundColor: '#ececec'
    }
  };
  const TextStyleChangeable = {
    '& .MuiInputBase-input': {
      color: '#0A121A',
      padding: '10px'
      // textTransform: 'uppercase'
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'rgba(0, 0, 0, 0.23)'
      },
      '&:hover fieldset': {
        borderColor: '#FF5E6F'
      },
      '&.Mui-focused fieldset': {
        borderColor: '#FF5E6F'
      }
    }
  };
  return (
    <ConnectForm>
      {({ register }) => (
        <CardContent>
          <Grid container spacing={3} sx={{ marginBottom: '10px' }}>
            <Grid item md={2} sm={12}>
              <TextField
                sx={readOnlyFlag ? TextStyleReadOndy : TextStyleChangeable}
                fullWidth
                label={t('(R) Accepting orders')}
                InputLabelProps={{ shrink: true }}
                name="acceptingorders"
                {...register('acceptingorders')}
                defaultValue={acceptingorders}
                variant="outlined"
                InputProps={{ readOnly: readOnlyFlag }}
              />
            </Grid>
            <Grid item md={2} sm={12}>
              <TextField
                sx={readOnlyFlag ? TextStyleReadOndy : TextStyleChangeable}
                fullWidth
                label={t('(S) Section')}
                InputLabelProps={{ shrink: true }}
                name="section"
                {...register('section')}
                defaultValue={section}
                variant="outlined"
                InputProps={{ readOnly: readOnlyFlag }}
              />
            </Grid>
            <Grid item md={2} sm={12}>
              <TextField
                sx={readOnlyFlag ? TextStyleReadOndy : TextStyleChangeable}
                fullWidth
                label={t('(A) In Charge')}
                InputLabelProps={{ shrink: true }}
                name="incharge"
                {...register('incharge')}
                defaultValue={incharge}
                variant="outlined"
                InputProps={{ readOnly: readOnlyFlag }}
              />
            </Grid>
            <Grid item md={2} sm={12}>
              <TextField
                sx={readOnlyFlag ? TextStyleReadOndy : TextStyleChangeable}
                fullWidth
                label={t('(C) Client')}
                InputLabelProps={{ shrink: true }}
                name="client"
                {...register('client')}
                defaultValue={client}
                variant="outlined"
                InputProps={{ readOnly: readOnlyFlag }}
              />
            </Grid>
            <Grid item md={2} sm={12}>
              <TextField
                sx={readOnlyFlag ? TextStyleReadOndy : TextStyleChangeable}
                fullWidth
                label={t('(D) Direction')}
                InputLabelProps={{ shrink: true }}
                name="direction"
                {...register('direction')}
                defaultValue={direction}
                variant="outlined"
                InputProps={{ readOnly: readOnlyFlag }}
              />
            </Grid>
            <Grid item md={2} sm={12}>
              <TextField
                sx={readOnlyFlag ? TextStyleReadOndy : TextStyleChangeable}
                fullWidth
                label="(F) Free"
                InputLabelProps={{ shrink: true }}
                name="free"
                {...register('free')}
                defaultValue={free}
                variant="outlined"
                InputProps={{ readOnly: readOnlyFlag }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} sx={{ marginBottom: '10px' }}>
            <Grid item md={2} sm={12}>
              <TextField
                sx={readOnlyFlag ? TextStyleReadOndy : TextStyleChangeable}
                fullWidth
                label={t('(T) Tour')}
                InputLabelProps={{ shrink: true }}
                name="tour"
                {...register('tour')}
                defaultValue={tour}
                variant="outlined"
                InputProps={{ readOnly: readOnlyFlag }}
              />
            </Grid>
            <Grid item md={2} sm={12}>
              <TextField
                sx={readOnlyFlag ? TextStyleReadOndy : TextStyleChangeable}
                fullWidth
                label="(K) KBE1"
                InputLabelProps={{ shrink: true }}
                name="kbe1"
                {...register('kbe1')}
                defaultValue={kbe1}
                variant="outlined"
                InputProps={{ readOnly: readOnlyFlag }}
              />
            </Grid>
            <Grid item md={2} sm={12}>
              <TextField
                sx={readOnlyFlag ? TextStyleReadOndy : TextStyleChangeable}
                fullWidth
                label="(B) KBE2"
                InputLabelProps={{ shrink: true }}
                name="kbe2"
                {...register('kbe2')}
                defaultValue={kbe2}
                variant="outlined"
                InputProps={{ readOnly: readOnlyFlag }}
              />
            </Grid>
            <Grid item md={2} sm={12}>
              <TextField
                sx={readOnlyFlag ? TextStyleReadOndy : TextStyleChangeable}
                fullWidth
                label="(E) E/O"
                InputLabelProps={{ shrink: true }}
                name="eo"
                {...register('eo')}
                defaultValue={eo}
                variant="outlined"
                InputProps={{ readOnly: readOnlyFlag }}
              />
            </Grid>
            <Grid item md={2} sm={12}>
              <TextField
                sx={readOnlyFlag ? TextStyleReadOndy : TextStyleChangeable}
                fullWidth
                label={t('(M) Deposit')}
                InputLabelProps={{ shrink: true }}
                name="deposit"
                {...register('deposit')}
                defaultValue={deposit}
                variant="outlined"
                InputProps={{ readOnly: readOnlyFlag }}
              />
            </Grid>
            <Grid item md={2} sm={12}>
              <TextField
                sx={readOnlyFlag ? TextStyleReadOndy : TextStyleChangeable}
                fullWidth
                label={t('(G) Order ID')}
                InputLabelProps={{ shrink: true }}
                name="orderid"
                {...register('orderid')}
                defaultValue={orderid}
                variant="outlined"
                InputProps={{ readOnly: readOnlyFlag }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} sx={{ marginBottom: '10px' }}>
            <Grid item md={4} sm={12}>
              <TextField
                sx={readOnlyFlag ? TextStyleReadOndy : TextStyleChangeable}
                label="Customer ID"
                InputLabelProps={{ shrink: true }}
                name="customerid"
                {...register('customerid')}
                defaultValue={customerid}
                variant="outlined"
                InputProps={{ readOnly: readOnlyFlag }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ marginBottom: '10px' }}>
            <Grid item md={3} sm={12}>
              <TextField
                sx={readOnlyFlag ? TextStyleReadOndy : TextStyleChangeable}
                label="Phone City(1)"
                InputLabelProps={{ shrink: true }}
                name="phonecity1"
                {...register('phonecity1')}
                defaultValue={phonecity1}
                variant="outlined"
                InputProps={{ readOnly: readOnlyFlag }}
              />
            </Grid>
            <Grid item md={3} sm={12}>
              <TextField
                sx={readOnlyFlag ? TextStyleReadOndy : TextStyleChangeable}
                label="Phone City(2)"
                InputLabelProps={{ shrink: true }}
                name="phonecity2"
                {...register('phonecity2')}
                defaultValue={phonecity2}
                variant="outlined"
                InputProps={{ readOnly: readOnlyFlag }}
              />
            </Grid>
            <Grid item md={3} sm={12}>
              <TextField
                sx={readOnlyFlag ? TextStyleReadOndy : TextStyleChangeable}
                label="Phone City(3)"
                InputLabelProps={{ shrink: true }}
                name="phonecity3"
                {...register('phonecity3')}
                defaultValue={phonecity3}
                variant="outlined"
                InputProps={{ readOnly: readOnlyFlag }}
              />
            </Grid>
          </Grid>
        </CardContent>
      )}
    </ConnectForm>
  );
};

export default Others;
