import React from 'react';
import { Helmet } from 'react-helmet';
import TicketDetail from 'src/components/ticket/TicketDetail';
import {
  geTicketDetails,
  getTicketByIssueDate,
  getTicketByNumber,
  healthCheck
} from 'src/api/api';
import {
  QueryClient,
  QueryClientProvider,
  useQuery,
  useMutation
} from 'react-query';
import {
  Box,
  Container,
  Card,
  CardContent,
  Grid,
  Accordion,
  AccordionSummary,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import moment from 'moment';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Pagination from '@material-ui/lab/Pagination';
import { useForm } from 'react-hook-form';
import TicketFilter from 'src/components/ticket/TicketFilter';
import NetworkErrorPage from 'src/components/common/NetworkErrorPage';
import { useTranslation } from 'react-i18next';
import SearchIcon from '@material-ui/icons/Search';
import LinearProgress from '@material-ui/core/LinearProgress';
import Logout from './Logout';

const TicketDetails = () => {
  const { t } = useTranslation();
  const isLogged = localStorage.getItem('token');
  const [connectionStatus, setConnectionStatus] = React.useState(<></>);
  if (!isLogged) {
    Logout();
  }
  const [ticket, setTicket] = React.useState({ data: null, length: 0 }); // tickets should only contain ticket numbers
  const [searchOptions, setSearchOptions] = React.useState({
    filter: 'all',
    iataNumber: '',
    carrier: '',
    isEmd: false,
    isExported: false,
    isRefund: false,
    index: 0,
    isNdc: false
  });

  const DEFAULT_PAGE_SIZE = 1;
  const queryClient = new QueryClient();
  const {
    isLoading, isError, error, isFetching
  } = useQuery(
    'tickets',
    async () => geTicketDetails(
      searchOptions.iataNumber,
      searchOptions.carrier,
      searchOptions.index,
      DEFAULT_PAGE_SIZE,
      searchOptions.isEmd,
      searchOptions.isRefund,
      searchOptions.isExported,
      searchOptions.isNdc
    ),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onSuccess: (d) => {
        setTicket({
          data: d.totalNumberOfTicketDetail ? d.ticketDetails[0] : null,
          length: d.totalNumberOfTicketDetail
        });
      }
    }
  );
  const errorDiv = isError ? (
    <div>
      Error:
      {error.message}
    </div>
  ) : null;
  const loadingOrError = isLoading || isFetching ? (
    <div>Loading...</div>
  ) : (
  // <LinearProgress />
    errorDiv
  );

  const getTicketNumbersByIssueDateMutation = useMutation(
    (filterByDate) => getTicketByIssueDate(
      filterByDate.startDateTime,
      filterByDate.endDateTime,
      DEFAULT_PAGE_SIZE,
      filterByDate.index,
      filterByDate.carrier,
      filterByDate.iataNumber,
      filterByDate.isEmd,
      filterByDate.isRefund,
      filterByDate.isExported
    ),
    {
      onSuccess: (d) => {
        setTicket({
          data: d.totalNumberOfTicketDetail ? d.ticketDetails[0] : null,
          length: d.totalNumberOfTicketDetail
        });
      }
    }
  );

  const getTicketDetailsByTicketNumberMutation = useMutation(
    (filterByNumber) => getTicketByNumber(
      filterByNumber.StartTicketNumber,
      filterByNumber.EndTicketNumber,
      DEFAULT_PAGE_SIZE,
      filterByNumber.index,
      filterByNumber.iataNumber,
      filterByNumber.carrier,
      filterByNumber.isEmd,
      filterByNumber.isRefund,
      filterByNumber.isExported
    ),
    {
      onSuccess: (d) => {
        setTicket({
          data: d.totalNumberOfTicketDetail ? d.ticketDetails[0] : null,
          length: d.totalNumberOfTicketDetail
        });
      }
    }
  );

  const searchByIssueDate = (filter) => {
    setSearchOptions({ ...filter, index: 0, filter: 'issuedate' });
  };
  const searchByTicketNumbers = (filter) => {
    setSearchOptions({ ...filter, index: 0, filter: 'ticketnumber' });
  };

  const searchAllMutation = useMutation(
    (filterByAll) => geTicketDetails(
      filterByAll.iataNumber,
      filterByAll.carrier,
      searchOptions.index,
      DEFAULT_PAGE_SIZE,
      filterByAll.isEmd,
      filterByAll.isRefund,
      filterByAll.isExported,
      filterByAll.isNdc
    ),
    {
      onSuccess: (d) => {
        setTicket({
          data: d.totalNumberOfTicketDetail ? d.ticketDetails[0] : null,
          length: d.totalNumberOfTicketDetail
        });
      }
    }
  );
  const searchAll = (filterByAll) => {
    setSearchOptions({ ...filterByAll, index: 0, filter: 'all' });
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%'
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular
    },
    mainTitle: {
      fontSize: '16px',
      fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
      fontWeight: 'bold',
      // color: '#0A121A'
      color: '#ffffff'
    },
    noDataText: {
      fontSize: '14px',
      fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
      fontWeight: 'bold',
      color: '#0A121A'
    },
    changeableText: {
      borderColor: '#FB5E70'
    }
  }));
  const classes = useStyles();
  React.useEffect(() => {
    healthCheck().then((r) => {
      if (!r) {
        setConnectionStatus(
          NetworkErrorPage(
            t('Connection to server is lost. Please check API server'),
            () => Logout()
          )
        );
      }
    });
  });

  const createTicketDetail = () => {
    const result = {};
    if (!ticket.data || ticket.length === 0) return result;
    const ticketInfo = ticket.data; // always 1 element

    console.log(ticketInfo);
    /// ----- TAB : Galileo Information
    result.reloc = ticketInfo.RecordLocator;
    result.seq = ticketInfo.SequenceNumber;
    result.tcn = ticketInfo.TCN;
    if (ticketInfo.TicketingStatus === 'VOID') {
      result.vrtype = 'VOID';
    } else if (ticketInfo.TicketingStatus === 'REFUNDED') {
      result.vrtype = 'REFUNDED';
    } else {
      result.vrtype = 'OPEN';
    }
    if (ticketInfo.TicketingStatus === 'REFUNDED') {
      const chgdateformatRefund = ticketInfo.RefundData
        ? moment(new Date(ticketInfo.RefundData.RefundDate))
        : moment(new Date('01-01-1900'));
      const rfnddate = chgdateformatRefund.format();
      result.vrdate = rfnddate.substr(0, 10);
    } else if (ticketInfo.TicketingStatus === 'VOID') {
      result.vrdate = ticketInfo.MirCreationDateTime.substr(0, 10);
    } else {
      result.vrdate = null;
    }

    /// ----- TAB : Ticket Information
    result.ticketnumber = ticketInfo.TicketNumber.value;
    result.otherTickets = ticketInfo.OtherTickets;
    result.checkdigit = ticketInfo.CheckDigit;
    result.iataNumber = ticketInfo.IATANumber;
    result.issueDate = ticketInfo.IssueDate != null
      ? ticketInfo.IssueDate.substr(0, 10)
      : ticketInfo.MirCreationDateTime.substr(0, 10);
    /// result.issueDate = ticketInfo.MirCreationDateTime.substr(0, 10);
    result.originalyticketnumber = ticketInfo.ParentTicketNumber !== undefined
      ? ticketInfo.ParentTicketNumber
      : null;
    result.readOnlyFlag = !!(
      ticketInfo.ParentTicketNumber !== undefined
      && ticketInfo.ParentTicketNumber
    );
    result.setBG = ticketInfo.ParentTicketNumber !== undefined
      && ticketInfo.ParentTicketNumber
      ? '#ececec'
      : null;
    if (ticketInfo.IsEmd !== undefined && ticketInfo.IsEmd === 'True') {
      result.airlineCode = ticketInfo.EMDData.ValidatingCarrier;
      result.airlineNumber = ticketInfo.EMDData.ValidatingAirlineNumber;
      result.passengerName = ticketInfo.EMDData.PassengerName;
      result.passengertype = null;
    } else {
      result.airlineCode = ticketInfo.ValidatingAirlineCode;
      result.airlineNumber = ticketInfo.ValidatingAirlineNumber;
      if (ticketInfo.Traveler.PersonName.Surname !== '') {
        result.passengerName = `${ticketInfo.Traveler.PersonName.Prefix}${ticketInfo.Traveler.PersonName.Given}/${ticketInfo.Traveler.PersonName.Surname}`;
      } else {
        result.passengerName = `${ticketInfo.Traveler.PersonName.Prefix}${ticketInfo.Traveler.PersonName.Given}${ticketInfo.Traveler.PersonName.Surname}`;
      }
      result.passengertype = ticketInfo.Traveler.passengerTypeCode.substr(0, 6);
    }
    /// ----- TAB : Standard Information
    if (ticketInfo.IsEmd !== undefined && ticketInfo.IsEmd === 'True') {
      result.farecurrency = ticketInfo.EMDData.CurrencyCodeForBaseAmount
        ? ticketInfo.EMDData.CurrencyCodeForBaseAmount
        : null;
      result.fareamount = ticketInfo.EMDData.BaseAmount
        ? ticketInfo.EMDData.BaseAmount
        : 0;
      result.equivalentcurrency = ticketInfo.EMDData
        .CurrencyCodeForEquivalentAmount
        ? ticketInfo.EMDData.CurrencyCodeForEquivalentAmount
        : null;
      result.equivalentamount = ticketInfo.EMDData.EquivalentAmount
        ? ticketInfo.EMDData.EquivalentAmount
        : 0;
      result.totalcurrency = ticketInfo.EMDData.CurrencyCodeForTotalAmount
        ? ticketInfo.EMDData.CurrencyCodeForTotalAmount
        : null;
      result.totalamount = ticketInfo.EMDData.TotalAmount
        ? ticketInfo.EMDData.TotalAmount
        : 0;
      if (ticketInfo.EMDData.FOP === 'CA') {
        result.payment = 'S:CASH';
        // result.cashgross = ticketInfo.EMDData.FOPAmount
        //   ? ticketInfo.EMDData.FOPAmount
        //   : 0;
        result.cashgross = ticketInfo.EMDData.BaseAmount
          ? ticketInfo.EMDData.BaseAmount
          : 0;
        result.taxcash = ticketInfo.EMDData.TaxAmount
          ? ticketInfo.EMDData.TaxAmount
          : 0;
        result.creditgross = 0;
        result.taxcredit = 0;
      } else {
        result.payment = 'CC:CREDIT';

        result.creditgross = ticketInfo.EMDData.FOPAmount
          ? ticketInfo.EMDData.FOPAmount
          : 0;
        result.taxcredit = ticketInfo.EMDData.TaxAmount
          ? ticketInfo.EMDData.TaxAmount
          : 0;
        result.cashgross = 0;
        result.taxcash = 0;
      }
    } else {
      result.farecurrency = ticketInfo.FareInfo.BaseFareAmountCurrency
        ? ticketInfo.FareInfo.BaseFareAmountCurrency
        : null;
      result.fareamount = ticketInfo.FareInfo.BaseFare
        ? ticketInfo.FareInfo.BaseFare
        : 0;
      result.equivalentcurrency = ticketInfo.FareInfo.EquivalentAmountCurrency
        ? ticketInfo.FareInfo.EquivalentAmountCurrency
        : null;
      result.equivalentamount = ticketInfo.FareInfo.EquivalentAmount
        ? ticketInfo.FareInfo.EquivalentAmount
        : 0;
      result.totalcurrency = ticketInfo.FareInfo.totalAmountCurrency
        ? ticketInfo.FareInfo.totalAmountCurrency
        : null;
      result.totalamount = ticketInfo.FareInfo.TotalAmount
        ? ticketInfo.FareInfo.TotalAmount
        : 0;
      result.cashgross = 0;
      result.taxcash = 0;
      result.creditgross = 0;
      result.taxcredit = 0;
      result.amountTotalCash = 0;
      result.amountTotalCredit = 0;
      if (ticketInfo.FormOfPaymentID) {
        for (let f = 0; f < ticketInfo.FormOfPaymentID.length; f++) {
          if (ticketInfo.FormOfPaymentID[f]['@type'] === 'FormOfPaymentCash') {
            result.payment = 'S:CASH';
            result.cashgross = ticketInfo.FormOfPaymentID[f].amount.Base
              ? ticketInfo.FormOfPaymentID[f].amount.Base
              : 0;
            result.taxcash = ticketInfo.FormOfPaymentID[f].amount.TotalTax
              ? ticketInfo.FormOfPaymentID[f].amount.TotalTax
              : 0;
            result.amountTotalCash = ticketInfo.FormOfPaymentID[f].amount.Total;
          }
          if (
            ticketInfo.FormOfPaymentID[f]['@type']
            === 'FormOfPaymentPaymentCard'
          ) {
            result.payment = 'CC:CREDIT';
            result.creditgross = ticketInfo.FormOfPaymentID[f].amount.Base
              ? ticketInfo.FormOfPaymentID[f].amount.Base
              : 0;
            result.taxcredit = ticketInfo.FormOfPaymentID[f].amount.TotalTax
              ? ticketInfo.FormOfPaymentID[f].amount.TotalTax
              : 0;
            result.amountTotalCredit = ticketInfo.FormOfPaymentID[f].amount.Total;
          }
        }
        if (ticketInfo.FormOfPaymentID.length === 2) {
          result.payment = '99:Multiple';
        }
      } else {
        result.payment = '--';
        result.cashgross = 0;
        result.taxcash = 0;
        result.creditgross = 0;
        result.taxcredit = 0;
      }
    }
    // Child Ticket for conjunction
    if (
      ticketInfo.ParentTicketNumber !== undefined
      && ticketInfo.ParentTicketNumber
    ) {
      result.farecurrency = null;
      result.fareamount = 0;
      result.equivalentcurrency = null;
      result.equivalentamount = 0;
      result.totalamount = 0;
      result.totalcurrency = null;
      result.cashgross = 0;
      result.taxcash = 0;
      result.amountTotalCash = 0;
      result.creditgross = 0;
      result.taxcredit = 0;
      result.amountTotalCredit = 0;
    }
    result.faretype = ticketInfo.FareType ? ticketInfo.FareType : null;
    // ticketInfo.SettlementMethod ? ticketInfo.SettlementMethod : '';
    result.settlementmethod = '';
    if (ticketInfo.TwoANetAmount) {
      result.settlementmethod = '2A';
    }
    if (ticketInfo.VCCode) {
      result.settlementmethod = 'VC';
    }
    if (ticketInfo.TwoANetAmount && ticketInfo.VCCode) {
      result.settlementmethod = 'VC';
    }
    if (ticketInfo.NRID === 'N') {
      result.nrid = 'N';
    } else {
      result.nrid = '--';
    }
    if (ticketInfo.TwoANetAmount !== undefined) {
      result.netamount = ticketInfo.TwoANetAmount;
    } else {
      result.netamount = 0;
    }
    result.vccode = ticketInfo.VCCode ? ticketInfo.VCCode : null;
    if (ticketInfo.DecodeID !== undefined) {
      result.decodeid = ticketInfo.DecodeID;
    } else {
      result.decodeid = null;
    }
    result.carcode = ticketInfo.CarCode ? ticketInfo.CarCode : null;
    if (ticketInfo.TourCode) {
      if (ticketInfo.TourCode.value) {
        result.tourcode = ticketInfo.TourCode.value;
      } else {
        result.tourcode = null;
      }
    } else {
      result.tourcode = null;
    }
    if (result.tourcode !== null) {
      result.itno = result.tourcode;
    } else if (result.vccode !== null && result.carcode !== null) {
      result.itno = `${result.vccode}/${result.carcode}`;
    } else if (result.vccode !== null && result.carcode === null) {
      result.itno = result.vccode;
    } else if (result.vccode === null && result.carcode !== null) {
      result.itno = result.carcode;
    } else {
      result.itno = null;
    }
    if (ticketInfo.CommissionAmount !== undefined) {
      result.commissionamount = ticketInfo.CommissionAmount;
    } else {
      result.commissionamount = 0;
    }
    if (ticketInfo.ConsumptionAmount !== undefined) {
      result.commConsumptiontax = ticketInfo.ConsumptionAmount;
    } else {
      result.commConsumptiontax = 0;
    }
    if (ticketInfo.CommissionPercent !== undefined) {
      result.commrate = ticketInfo.CommissionPercent.Percent;
    } else {
      result.commrate = 0;
    }
    if (ticketInfo.AdjustmentAmount !== undefined) {
      result.adjustamount = ticketInfo.AdjustmentAmount;
    } else {
      result.adjustamount = 0;
    }
    if (ticketInfo.BspAmount !== undefined) {
      result.bsppaymentamount = ticketInfo.BspAmount;
    } else {
      result.bsppaymentamount = 0;
    }

    /// ----- TAB : Others
    if (ticketInfo.FreeTextRemarks) {
      const ftrmkscount = 12 - ticketInfo.FreeTextRemarks.length;
      for (let iii = 0; iii < ftrmkscount; iii++) {
        ticketInfo.FreeTextRemarks.push('');
      }
      const feermkstxt = [{}];
      feermkstxt.R = '';
      feermkstxt.S = '';
      feermkstxt.A = '';
      feermkstxt.C = '';
      feermkstxt.D = '';
      feermkstxt.F = '';
      feermkstxt.T = '';
      feermkstxt.K = '';
      feermkstxt.B = '';
      feermkstxt.E = '';
      feermkstxt.M = '';
      feermkstxt.G = '';
      for (let r = 0; r < ticketInfo.FreeTextRemarks.length; r++) {
        if (ticketInfo.FreeTextRemarks[r].Heading === 'R') {
          feermkstxt.R = ticketInfo.FreeTextRemarks[r].Value;
        }
        if (ticketInfo.FreeTextRemarks[r].Heading === 'S') {
          feermkstxt.S = ticketInfo.FreeTextRemarks[r].Value;
        }
        if (ticketInfo.FreeTextRemarks[r].Heading === 'A') {
          feermkstxt.A = ticketInfo.FreeTextRemarks[r].Value;
        }
        if (ticketInfo.FreeTextRemarks[r].Heading === 'C') {
          feermkstxt.C = ticketInfo.FreeTextRemarks[r].Value;
        }
        if (ticketInfo.FreeTextRemarks[r].Heading === 'D') {
          feermkstxt.D = ticketInfo.FreeTextRemarks[r].Value;
        }
        if (ticketInfo.FreeTextRemarks[r].Heading === 'F') {
          feermkstxt.F = ticketInfo.FreeTextRemarks[r].Value;
        }
        if (ticketInfo.FreeTextRemarks[r].Heading === 'T') {
          feermkstxt.T = ticketInfo.FreeTextRemarks[r].Value;
        }
        if (ticketInfo.FreeTextRemarks[r].Heading === 'K') {
          feermkstxt.K = ticketInfo.FreeTextRemarks[r].Value;
        }
        if (ticketInfo.FreeTextRemarks[r].Heading === 'B') {
          feermkstxt.B = ticketInfo.FreeTextRemarks[r].Value;
        }
        if (ticketInfo.FreeTextRemarks[r].Heading === 'E') {
          feermkstxt.E = ticketInfo.FreeTextRemarks[r].Value;
        }
        if (ticketInfo.FreeTextRemarks[r].Heading === 'M') {
          feermkstxt.M = ticketInfo.FreeTextRemarks[r].Value;
        }
        if (ticketInfo.FreeTextRemarks[r].Heading === 'G') {
          feermkstxt.G = ticketInfo.FreeTextRemarks[r].Value;
        }
      }
      feermkstxt.shift();
      result.acceptingorders = feermkstxt.R ? feermkstxt.R.substr(0, 12) : null;
      result.section = feermkstxt.S ? feermkstxt.S.substr(0, 6) : null;
      result.incharge = feermkstxt.A ? feermkstxt.A.substr(0, 6) : null;
      result.client = feermkstxt.C ? feermkstxt.C.substr(0, 10) : null;
      result.direction = feermkstxt.D ? feermkstxt.D.substr(0, 3) : null;
      result.free = feermkstxt.F ? feermkstxt.F.substr(0, 10) : null;
      result.tour = feermkstxt.T ? feermkstxt.T.substr(0, 15) : null;
      result.kbe1 = feermkstxt.K ? feermkstxt.K.substr(0, 7) : null;
      result.kbe2 = feermkstxt.B ? feermkstxt.B.substr(0, 7) : null;
      result.eo = feermkstxt.E ? feermkstxt.E.substr(0, 7) : null;
      result.deposit = feermkstxt.M ? feermkstxt.M.substr(0, 1) : null;
      result.orderid = feermkstxt.G ? feermkstxt.G : null;
      result.customerid = null;
      result.phonecity1 = ticketInfo.PhoneCity;
      result.phonecity2 = null;
      result.phonecity3 = null;
    }
    /// ----- TAB : Tax Details
    if (ticketInfo.IsEmd !== 'True') {
      // Child Ticket for conjunction
      if (
        ticketInfo.ParentTicketNumber === undefined
        || !ticketInfo.ParentTicketNumber
      ) {
        const makeTaxArry = [];
        if (ticketInfo.FareInfo.PassengerTaxBreakdown) {
          for (
            let tt = 0;
            tt < ticketInfo.FareInfo.PassengerTaxBreakdown.length;
            tt++
          ) {
            if (ticketInfo.FareInfo.PassengerTaxBreakdown[tt].code !== 'XT') {
              makeTaxArry.push(ticketInfo.FareInfo.PassengerTaxBreakdown[tt]);
            }
          }
        }
        result.taxes = makeTaxArry.length > 0
          ? makeTaxArry.map((tt, index) => ({
            taxValueLabel: `Tax${index + 1}-Amount`,
            taxCodeLabel: `Tax${index + 1}-Code`,
            taxValue: tt.value === -1 ? 'EXEMPT' : tt.value,
            taxCode: tt.code,
            taxValueId: `tax${index + 1}`,
            taxCodeId: `code${index + 1}`
          }))
          : null;
      }
    } else if (ticketInfo.EMDData.PassengerTaxesBreakdown !== null) {
      if (
        ticketInfo.ParentTicketNumber === undefined
        || !ticketInfo.ParentTicketNumber
      ) {
        const makeTaxArry = [];
        if (ticketInfo.EMDData.PassengerTaxesBreakdown) {
          for (
            let tt = 0;
            tt < ticketInfo.EMDData.PassengerTaxesBreakdown.length;
            tt++
          ) {
            if (ticketInfo.EMDData.PassengerTaxesBreakdown[tt].code !== 'XT') {
              makeTaxArry.push(ticketInfo.EMDData.PassengerTaxesBreakdown[tt]);
            }
          }
        }
        result.taxes = makeTaxArry.length > 0
          ? makeTaxArry.map((tt, index) => ({
            taxValueLabel: `Tax${index + 1}-Amount`,
            taxCodeLabel: `Tax${index + 1}-Code`,
            taxValue: tt.value === -1 ? 'EXEMPT' : tt.value,
            taxCode: tt.code,
            taxValueId: `tax${index + 1}`,
            taxCodeId: `code${index + 1}`
          }))
          : null;
      }
    }

    /// ----- TAB : Credit
    const creditdata = [{}];
    creditdata.cardcode = null;
    creditdata.cardnumber = null;
    creditdata.holdername = null;
    creditdata.expirationdt = null;
    creditdata.approvalnum = null;
    creditdata.billingamount = 0;
    creditdata.functionalclassification1 = null;
    creditdata.functionalclassification2 = null;
    creditdata.cardremarks1 = null;
    creditdata.cardremarks2 = null;
    creditdata.cardremarks3 = null;
    if (ticketInfo.IsEmd !== undefined && ticketInfo.IsEmd === 'True') {
      creditdata.cardcode = ticketInfo.EMDData.CCCode;
      creditdata.cardnumber = ticketInfo.EMDData.CCNumber;
      creditdata.holdername = null;
      creditdata.expirationdt = ticketInfo.EMDData.CCExpDate;
      creditdata.approvalnum = ticketInfo.EMDData.ApprovalCode;
      creditdata.billingamount = ticketInfo.EMDData.FOPAmount;
    } else if (ticketInfo.FormOfPaymentID) {
      for (let ff = 0; ff < ticketInfo.FormOfPaymentID.length; ff++) {
        if (
          ticketInfo.FormOfPaymentID[ff]['@type'] === 'FormOfPaymentPaymentCard'
        ) {
          creditdata.cardcode = ticketInfo.FormOfPaymentID[ff].PaymentCard.CardCode;
          creditdata.cardnumber = ticketInfo.FormOfPaymentID[ff].PaymentCard.CardNumber.PlainText;
          // creditdata.holdername = ticketInfo.FreeTextRemarks.Heading.Value === "M";
          creditdata.expirationdt = ticketInfo.FormOfPaymentID[ff].PaymentCard.expireDate;
          creditdata.approvalnum = ticketInfo.FormOfPaymentID[ff].PaymentCard.approvalCode;
          creditdata.billingamount = ticketInfo.FormOfPaymentID[ff].amount.Total;
        }
      }
    }
    if (
      ticketInfo.CreditCardRemarks
      && ticketInfo.CreditCardRemarks.length > 0
    ) {
      for (let cc = 0; cc < ticketInfo.CreditCardRemarks.length; cc++) {
        if (ticketInfo.CreditCardRemarks[cc].Heading === 'CARD RMKS(R1)') {
          creditdata.cardremarks1 = ticketInfo.CreditCardRemarks[cc].Value;
        }
        if (ticketInfo.CreditCardRemarks[cc].Heading === 'CARD RMKS(R2)') {
          creditdata.cardremarks2 = ticketInfo.CreditCardRemarks[cc].Value;
        }
        if (ticketInfo.CreditCardRemarks[cc].Heading === 'CARD RMKS(R3)') {
          creditdata.cardremarks3 = ticketInfo.CreditCardRemarks[cc].Value;
        }
      }
    }
    creditdata.functionalclassification1 = null;
    creditdata.functionalclassification2 = null;
    creditdata.holdername = null;
    if (ticketInfo.FreeTextRemarks && ticketInfo.FreeTextRemarks.length > 0) {
      for (let rr = 0; rr < ticketInfo.FreeTextRemarks.length; rr++) {
        if (ticketInfo.FreeTextRemarks[rr].Heading === 'MK2') {
          creditdata.functionalclassification1 = ticketInfo.FreeTextRemarks[rr].Value;
        }
        if (ticketInfo.FreeTextRemarks[rr].Heading === 'MK14') {
          creditdata.functionalclassification2 = ticketInfo.FreeTextRemarks[rr].Value;
        }
        if (ticketInfo.FreeTextRemarks[rr].Heading === 'NM') {
          creditdata.holdername = ticketInfo.FreeTextRemarks[rr].Value;
        }
      }
    }
    result.cardcode = creditdata.cardcode ? creditdata.cardcode : null;
    result.cardnumber = creditdata.cardnumber ? creditdata.cardnumber : null;
    result.holdername = creditdata.holdername ? creditdata.holdername : null;
    result.expirationdt = creditdata.expirationdt
      ? creditdata.expirationdt
      : null;
    result.approvalnum = creditdata.approvalnum ? creditdata.approvalnum : null;
    // Child Ticket for conjunction
    if (
      ticketInfo.ParentTicketNumber !== undefined
      && ticketInfo.ParentTicketNumber
    ) {
      result.billingamount = null;
    } else {
      result.billingamount = creditdata.billingamount
        ? creditdata.billingamount
        : null;
    }
    result.division = '01';
    result.functionalclassification1 = creditdata.functionalclassification1
      ? creditdata.functionalclassification1
      : null;
    result.functionalclassification2 = creditdata.functionalclassification2
      ? creditdata.functionalclassification2
      : null;
    result.cardremarks1 = creditdata.cardremarks1
      ? creditdata.cardremarks1
      : null;
    result.cardremarks2 = creditdata.cardremarks2
      ? creditdata.cardremarks2
      : null;
    result.cardremarks3 = creditdata.cardremarks3
      ? creditdata.cardremarks3
      : null;

    if (ticketInfo.IsEmd !== undefined && ticketInfo.IsEmd === 'True') {
      result.segments = [
        {
          CarrierCode: '',
          FlightNum: 'VOID',
          BookigClass: '',
          departuredate: '',
          OriginCode: '',
          DestinationCode: '',
          FareBasis: '',
          Mile: '',
          Seat: '',
          NVBC: '',
          NVAC: '',
          baggage: '',
          status: '',
          departuretime: '',
          arrivaltime: '',
          arrivalday: '',
          stopover: '',
          couponno: '',
          segmentno: '',
          airlinenumber: '',
          airlinename: '',
          arrivaldate: '',
          origincityname: '',
          destinationcityname: '',
          domesticinternationalindicator: '',
          mealcode: '',
          numberofstops: '',
          typeofaircraft: '',
          terminal: '',
          segmentidentifier: '',
          ticketIndicator: '',
          flightsectiontype: '',
          internationaldeparturedate: ''
        }
      ];
    } else {
      result.segments = ticketInfo.FlightSegment
        ? ticketInfo.FlightSegment.map((s) => ({
          // CarrierCode: s.Carrier, FlightNum: s.FlightNumber ? (`000${s.FlightNumber}`).slice(-4) : '', BookigClass: s.BookingClass, departuredate: s.DepartureDate, OriginCode: s.Origin, DestinationCode: s.Destination, FareBasis: s.FareBasisCode, Mile: s.NauticalMiles, Seat: s.SeatIndicator, NVBC: s.NotValidBeforeDate, NVAC: s.NotValidAfterDate, baggage: s.BuggageAllowance, status: s.Status, departuretime: s.DepartureTime, arrivaltime: s.ArrivalTime, arrivalday: s.ArrivalIndicator, stopover: s.StopoverIndiactors, couponno: s.FlightCouponIndicator,
          CarrierCode: s.Carrier,
          // FlightNum: s.FlightNumber ? `000${s.FlightNumber}`.slice(-4) : '',
          FlightNum: s.FlightNumber ? s.FlightNumber : '',
          BookigClass: s.BookingClass,
          departuredate: s.DepartureDate,
          OriginCode: s.Origin,
          DestinationCode: s.Destination,
          FareBasis: s.FareBasisCode,
          Mile: s.NauticalMiles,
          Seat: s.SeatIndicator,
          NVBC: s.NotValidBeforeDate,
          NVAC: s.NotValidAfterDate,
          baggage: s.BuggageAllowance,
          status: s.Status,
          departuretime: s.DepartureTime,
          arrivaltime: s.ArrivalTime,
          arrivalday: s.ArrivalIndicator,
          stopover: s.StopoverIndiactors,
          couponno: s.FlightCouponIndicator,
          segmentno: s.SegmentNumber,
          airlinenumber: s.AirlineNumber,
          airlinename: s.AirlineName,
          arrivaldate: s.ArrivalDate,
          origincityname: s.OriginCityName,
          destinationcityname: s.DestinationCityName,
          domesticinternationalindicator: s.DomesticInternationalIndicator,
          mealcode: s.MealCodes,
          numberofstops: s.NumberOfStops,
          typeofaircraft: s.TypeOfAirCraft,
          terminal: s.DepartureTerminal,
          segmentidentifier: s.SegmentIdentifier,
          ticketIndicator: s.TicketIndicator,
          flightsectiontype: s.FlightSectionType,
          internationaldeparturedate: s.InternationalDepartureDate
        }))
        : null;
    }

    /// ----- TAB : Addtional Information
    // if (ticketInfo.IsEmd !== true) {
    // if (ticketInfo.IsEmd === undefined) {
    if (ticketInfo.IsEmd !== undefined && ticketInfo.IsEmd !== 'True') {
      if (ticketInfo.Endorsement) {
        const endorscount = 6 - ticketInfo.Endorsement.length;
        for (let i = 0; i < endorscount; i++) {
          ticketInfo.Endorsement.push('');
        }
      }
      if (ticketInfo.FareCalculation) {
        const fareculcount = 5 - ticketInfo.FareCalculation.length;
        for (let ii = 0; ii < fareculcount; ii++) {
          ticketInfo.FareCalculation.push('');
        }
      }
      const Endorsementdata = ticketInfo.Endorsement
        ? ticketInfo.Endorsement.map((e) => ({ ebval: e }))
        : null;
      const Fareculdata = ticketInfo.FareCalculation
        ? ticketInfo.FareCalculation.map((f) => ({ fcval: f }))
        : null;
      if (ticketInfo.FareCalculationModeIndicator !== undefined) {
        if (ticketInfo.FareCalculationModeIndicator !== '') {
          result.fcmi = ticketInfo.FareCalculationModeIndicator;
        } else {
          result.fcmi = '--';
        }
      } else {
        result.fcmi = '--';
      }
      result.eb0 = Endorsementdata[0].ebval ? Endorsementdata[0].ebval : null;
      result.eb1 = Endorsementdata[1].ebval ? Endorsementdata[1].ebval : null;
      result.eb2 = Endorsementdata[2].ebval ? Endorsementdata[2].ebval : null;
      result.eb3 = Endorsementdata[3].ebval ? Endorsementdata[3].ebval : null;
      result.eb4 = Endorsementdata[4].ebval ? Endorsementdata[4].ebval : null;
      result.eb5 = Endorsementdata[5].ebval ? Endorsementdata[5].ebval : null;
      result.tktdisp = '';
      result.authnumdisp = '';
      result.ifddt = '';
      result.ifdtm = '';
      result.csvdt = '';
      result.csvtm = '';
      result.uridt = '';
      result.uritm = '';
      result.foprmks = '';
      result.farecul1 = Fareculdata[0].fcval ? Fareculdata[0].fcval : null;
      result.farecul2 = Fareculdata[1].fcval ? Fareculdata[1].fcval : null;
      result.farecul3 = Fareculdata[2].fcval ? Fareculdata[2].fcval : null;
      result.farecul4 = Fareculdata[3].fcval ? Fareculdata[3].fcval : null;
      result.farecul5 = Fareculdata[4].fcval ? Fareculdata[4].fcval : null;
    }
    /// ----- TAB : Refund
    // if (ticketInfo.IsEmd !== 'True') {
    if (ticketInfo.TicketingStatus === 'REFUNDED') {
      if (ticketInfo.RefundData) {
        result.farepaid = '0';
        result.fareused = '0';
        result.farerefund = '0';
        result.cancelpanalty = '0';
        result.ttlrfndamt = '0';
        result.waivercode = '';
        result.rfnrid = '';
        result.twoanet = '0';
        result.rfcommrate = '0';
        result.esac = '';
        result.rfndpfc = '';
        result.farepaid = ticketInfo.RefundData.FarePaid
          ? ticketInfo.RefundData.FarePaid
          : 0;
        result.fareused = ticketInfo.RefundData.CashAmountUsed
          + ticketInfo.RefundData.CreditAmountUsed
          ? ticketInfo.RefundData.CashAmountUsed
              + ticketInfo.RefundData.CreditAmountUsed
          : 0;
        // result.farerefund = (ticketInfo.RefundData.CashRefund + ticketInfo.RefundData.CreditRefund) ? (ticketInfo.RefundData.CashRefund + ticketInfo.RefundData.CreditRefund) : 0;
        result.farerefund = ticketInfo.RefundData.FarePaid
          - (ticketInfo.RefundData.CashAmountUsed
            + ticketInfo.RefundData.CreditAmountUsed);
        result.cancelpanalty = ticketInfo.RefundData.PenaltyFee
          ? ticketInfo.RefundData.PenaltyFee
          : 0;
        result.ttlrfndamt = ticketInfo.RefundData.TotalRefundAmount
          ? ticketInfo.RefundData.TotalRefundAmount
          : 0;
        result.waivercode = ticketInfo.RefundData.WaiverCode
          ? ticketInfo.RefundData.WaiverCode
          : null;
        // if (ticketInfo.NRID === 'N') { result.nrid = 'N'; } else { result.nrid = null; }
        result.rfnrid = result.nrid ? result.nrid : null;
        result.twoanet = result.netamount;
        result.rfcommrate = ticketInfo.RefundData.CommissionRate
          ? ticketInfo.RefundData.CommissionRate
          : 0;
        result.esac = ticketInfo.RefundData.ESAC
          ? ticketInfo.RefundData.ESAC
          : null;
        result.rfndpfc = ticketInfo.RefundData.RFNDPFC
          ? ticketInfo.RefundData.RFNDPFC
          : null;
        const [cpn1, cpn2, cpn3, cpn4] = [...ticketInfo.CPN];
        result.cpn1 = cpn1;
        result.cpn2 = cpn2;
        result.cpn3 = cpn3;
        result.cpn4 = cpn4;
        result.flt = ticketInfo.FLT ? ticketInfo.FLT : null;

        // Child Ticket for conjunction
        const makeRFTaxArry = [];
        if (ticketInfo.RefundData.PassengerTaxesBreakdown !== null) {
          for (
            let tt = 0;
            tt < ticketInfo.RefundData.PassengerTaxesBreakdown.length;
            tt++
          ) {
            if (ticketInfo.RefundData.PassengerTaxesBreakdown[tt].code !== '') {
              makeRFTaxArry.push(
                ticketInfo.RefundData.PassengerTaxesBreakdown[tt]
              );
            }
          }
        }

        result.rftaxes = makeRFTaxArry
          ? makeRFTaxArry.map((tt, index) => ({
            taxValueLabel: `Tax${index + 1}-Amount`,
            taxCodeLabel: `Tax${index + 1}-Code`,
            taxValue: tt.value,
            taxCode: tt.code,
            taxValueId: `tax${index + 1}`,
            taxCodeId: `code${index + 1}`
          }))
          : null;
      }
      // Child Ticket for conjunction
      if (
        ticketInfo.ParentTicketNumber !== undefined
        && ticketInfo.ParentTicketNumber
      ) {
        result.farepaid = '0';
        result.fareused = '0';
        result.farerefund = '0';
        result.cancelpanalty = '0';
        result.ttlrfndamt = '0';
        result.waivercode = '';
        result.rfnrid = '';
        result.twoanet = '0';
        result.rfcommrate = '0';
        result.esac = '';
        result.rfndpfc = '';
        result.rftaxes = null;
      }
    }
    // }

    /// ----- TAB : Exchange
    if (ticketInfo.ExchangeData) {
      if (result.payment === 'CC:CREDIT') {
        result.bsppaymentamount = 0;
      }
      result.payment = 'EX:Exchange';
      const chgdateformatExchange = moment(
        new Date(ticketInfo.ExchangeData.Date)
      );
      const exchdate = chgdateformatExchange.format();
      let chkexchcurrentgross = '';
      if (ticketInfo.FareInfo.EquivalentAmountCurrency === '') {
        chkexchcurrentgross = ticketInfo.FareInfo.BaseFareAmountCurrency
          + ticketInfo.FareInfo.BaseFare;
      } else {
        chkexchcurrentgross = ticketInfo.FareInfo.EquivalentAmountCurrency
          + ticketInfo.FareInfo.EquivalentAmount;
      }

      result.exchcurrentgross = chkexchcurrentgross;
      result.exchdifferencegross = ticketInfo.ExchangeData.Currency
        + ticketInfo.ExchangeData.AdditionalCollectionBaseFare
        ? ticketInfo.ExchangeData.Currency
            + ticketInfo.ExchangeData.AdditionalCollectionBaseFare
        : null;
      result.exchcurrenttax = ticketInfo.FareInfo.totalAmountCurrency + ticketInfo.FareInfo.TotalTax
        ? ticketInfo.FareInfo.totalAmountCurrency
            + ticketInfo.FareInfo.TotalTax
        : null;
      result.exchdifferencetax = ticketInfo.ExchangeData.Currency
        + ticketInfo.ExchangeData.AdditionalCollectionTax
        ? ticketInfo.ExchangeData.Currency
            + ticketInfo.ExchangeData.AdditionalCollectionTax
        : null;
      result.exchcurrenttaxdetail = ticketInfo.FareInfo.Tax
        ? ticketInfo.FareInfo.Tax
        : '';
      result.exchdifferencetaxdetail = ticketInfo.FareInfo.Tax
        ? ticketInfo.FareInfo.Tax
        : '';
      result.originaltktnum = ticketInfo.ExchangeData.ExchangedTicketNumber
        ? ticketInfo.ExchangeData.ExchangedTicketNumber
        : null;
      result.originalcrr = ticketInfo.ValidatingAirlineCode
        ? ticketInfo.ValidatingAirlineCode
        : null;
      result.originalplace = ticketInfo.ExchangeData.CityCode
        ? ticketInfo.ExchangeData.CityCode
        : null;
      result.originaldate = exchdate.substr(0, 10);
      result.originaliata = ticketInfo.ExchangeData.OriginalIATANumber
        ? ticketInfo.ExchangeData.OriginalIATANumber
        : null;
      result.originalgross = ticketInfo.ExchangeData.Currency + ticketInfo.ExchangeData.BaseFare
        ? ticketInfo.ExchangeData.Currency + ticketInfo.ExchangeData.BaseFare
        : null;
      result.originaltax = ticketInfo.ExchangeData.Currency + ticketInfo.ExchangeData.TotalTax
        ? ticketInfo.ExchangeData.Currency + ticketInfo.ExchangeData.TotalTax
        : null;
      result.originaltaxdetails = ticketInfo.ExchangeData.Tax
        ? ticketInfo.ExchangeData.Tax
        : null;
      // result.currenttktnum = ticketInfo.TicketNumber.value ? ticketInfo.TicketNumber.value : null;
      result.currenttktnum = ticketInfo.ExchangeData.OriginalTicketNumber !== ''
        ? ticketInfo.ExchangeData.OriginalTicketNumber
        : ticketInfo.ExchangeData.ExchangedTicketNumber;
      result.currentcrr = ticketInfo.ValidatingAirlineCode
        ? ticketInfo.ValidatingAirlineCode
        : null;
      // result.currentplace = ticketInfo.PhoneCity ? ticketInfo.PhoneCity : null;
      // result.currentdate = ((ticketInfo.MirCreationDateTime).substr(0, 10));
      result.currentplace = ticketInfo.ExchangeData.OriginalTicketNumber === ''
        ? ticketInfo.ExchangeData.CityCode
        : null;
      result.currentdate = ticketInfo.ExchangeData.OriginalTicketNumber === ''
        ? ticketInfo.MirCreationDateTime.substr(0, 10)
        : null;
      result.currentiata = ticketInfo.IATANumber ? ticketInfo.IATANumber : null;
      result.typeofexchticket = ticketInfo.ExchangeData.TypeOfExchTicket
        ? ticketInfo.ExchangeData.TypeOfExchTicket
        : null;
      result.originalairlinenumber = ticketInfo.ExchangeData
        .OriginalAirlineNumber
        ? ticketInfo.ExchangeData.OriginalAirlineNumber
        : null;
      result.exchangedairlinenumber = ticketInfo.ExchangeData
        .ExchangedAirlineNumber
        ? ticketInfo.ExchangeData.ExchangedAirlineNumber
        : null;
      result.adjustamount = '0';
    }

    /// ----- TAB : EMD
    if (ticketInfo.IsEmd !== undefined && ticketInfo.IsEmd === 'True') {
      if (ticketInfo.EMDData.EMDCouponData) {
        const couponcount = 4 - ticketInfo.EMDData.EMDCouponData.length;
        for (let e = 0; e < couponcount; e++) {
          ticketInfo.EMDData.EMDCouponData.push('');
        }
      }
      const Coupondata = ticketInfo.EMDData.EMDCouponData
        ? ticketInfo.EMDData.EMDCouponData.map((e) => ({ couponbval: e }))
        : null;
      result.conjunctionticketnumber = ticketInfo.EMDData.RelatedTicketNumber;
      result.emdtype = ticketInfo.EMDData.EMDIdentifier;
      result.arrange = ticketInfo.EMDData.BookingIndicator;
      result.emdcommissionamount = '0';
      result.emdcommConsumptiontax = '0';
      result.emdcommrate = '0.00';
      result.emdtourcode = null;
      result.endorsedata = ticketInfo.EMDData.EndorsementData;
      result.bsr = null;
      result.emddtVal1 = Coupondata[0].couponbval
        ? Coupondata[0].couponbval.Date
        : '';
      result.emddtVal2 = Coupondata[1].couponbval
        ? Coupondata[1].couponbval.Date
        : '';
      result.emddtVal3 = Coupondata[2].couponbval
        ? Coupondata[2].couponbval.Date
        : '';
      result.emddtVal4 = Coupondata[3].couponbval
        ? Coupondata[3].couponbval.Date
        : '';
      result.emdcityVal1 = Coupondata[0].couponbval
        ? Coupondata[0].couponbval.City
        : '';
      result.emdcityVal2 = Coupondata[1].couponbval
        ? Coupondata[1].couponbval.City
        : '';
      result.emdcityVal3 = Coupondata[2].couponbval
        ? Coupondata[2].couponbval.City
        : '';
      result.emdcityVal4 = Coupondata[3].couponbval
        ? Coupondata[3].couponbval.City
        : '';
      result.emdrficVal1 = Coupondata[0].couponbval
        ? ticketInfo.EMDData.RFIC
        : '';
      result.emdrficVal2 = Coupondata[1].couponbval
        ? ticketInfo.EMDData.RFIC
        : '';
      result.emdrficVal3 = Coupondata[2].couponbval
        ? ticketInfo.EMDData.RFIC
        : '';
      result.emdrficVal4 = Coupondata[3].couponbval
        ? ticketInfo.EMDData.RFIC
        : '';
      result.emdrfiscVal1 = Coupondata[0].couponbval
        ? Coupondata[0].couponbval.RFISC
        : '';
      result.emdrfiscVal2 = Coupondata[1].couponbval
        ? Coupondata[1].couponbval.RFISC
        : '';
      result.emdrfiscVal3 = Coupondata[2].couponbval
        ? Coupondata[2].couponbval.RFISC
        : '';
      result.emdrfiscVal4 = Coupondata[3].couponbval
        ? Coupondata[3].couponbval.RFISC
        : '';
      result.emdrcommercialVal1 = Coupondata[0].couponbval
        ? Coupondata[0].couponbval.CommercialName
        : '';
      result.emdrcommercialVal2 = Coupondata[1].couponbval
        ? Coupondata[1].couponbval.CommercialName
        : '';
      result.emdrcommercialVal3 = Coupondata[2].couponbval
        ? Coupondata[2].couponbval.CommercialName
        : '';
      result.emdrcommercialVal4 = Coupondata[3].couponbval
        ? Coupondata[3].couponbval.CommercialName
        : '';

      // console.log(Coupondata);
      if (Coupondata[0].couponbval !== '') {
        if (Coupondata[0].couponbval.BaseCurrenyCode !== null) {
          result.emdculVal1 = Coupondata[0].couponbval.BaseCurrenyCode;
          result.emdamtVal1 = Coupondata[0].couponbval.BaseAmount;
        } else {
          result.emdculVal1 = '';
          result.emdamtVal1 = '';
        }
      }
      if (Coupondata[1].couponbval !== '') {
        if (Coupondata[1].couponbval.BaseCurrenyCode !== null) {
          result.emdculVal2 = Coupondata[1].couponbval.BaseCurrenyCode;
          result.emdamtVal2 = Coupondata[1].couponbval.BaseAmount;
        } else {
          result.emdculVal2 = '';
          result.emdamtVal2 = '';
        }
      }

      if (Coupondata[2].couponbval !== '') {
        if (Coupondata[2].couponbval.BaseCurrenyCode !== null) {
          result.emdculVal3 = Coupondata[2].couponbval.BaseCurrenyCode;
          result.emdamtVal3 = Coupondata[2].couponbval.BaseAmount;
        } else {
          result.emdculVal3 = '';
          result.emdamtVal3 = '';
        }
      }

      if (Coupondata[3].couponbval !== '') {
        if (Coupondata[3].couponbval.BaseCurrenyCode !== null) {
          result.emdculVal4 = Coupondata[3].couponbval.BaseCurrenyCode;
          result.emdamtVal4 = Coupondata[3].couponbval.BaseAmount;
        } else {
          result.emdculVal4 = '';
          result.emdamtVal4 = '';
        }
      }

      result.emdBaseAmountCurreny1 = Coupondata[0].couponbval
        ? Coupondata[0].couponbval.BaseAmountCurrenyCode
        : '';
      result.emdBaseAmountCurreny2 = Coupondata[1].couponbval
        ? Coupondata[1].couponbval.BaseAmountCurrenyCode
        : '';
      result.emdBaseAmountCurreny3 = Coupondata[2].couponbval
        ? Coupondata[2].couponbval.BaseAmountCurrenyCode
        : '';
      result.emdBaseAmountCurreny4 = Coupondata[3].couponbval
        ? Coupondata[3].couponbval.BaseAmountCurrenyCode
        : '';
      result.emdquantityVal1 = Coupondata[0].couponbval
        ? Coupondata[0].couponbval.NumberOfServiceRequested
        : '';
      result.emdquantityVal2 = Coupondata[1].couponbval
        ? Coupondata[1].couponbval.NumberOfServiceRequested
        : '';
      result.emdquantityVal3 = Coupondata[2].couponbval
        ? Coupondata[2].couponbval.NumberOfServiceRequested
        : '';
      result.emdquantityVal4 = Coupondata[3].couponbval
        ? Coupondata[3].couponbval.NumberOfServiceRequested
        : '';
      result.emdncVal1 = Coupondata[0].couponbval
        ? Coupondata[0].couponbval.NC
        : '';
      result.emdncVal2 = Coupondata[1].couponbval
        ? Coupondata[1].couponbval.NC
        : '';
      result.emdncVal3 = Coupondata[2].couponbval
        ? Coupondata[2].couponbval.NC
        : '';
      result.emdncVal4 = Coupondata[3].couponbval
        ? Coupondata[3].couponbval.NC
        : '';
      result.emdniVal1 = Coupondata[0].couponbval
        ? Coupondata[0].couponbval.NI
        : '';
      result.emdniVal2 = Coupondata[1].couponbval
        ? Coupondata[1].couponbval.NI
        : '';
      result.emdniVal3 = Coupondata[2].couponbval
        ? Coupondata[2].couponbval.NI
        : '';
      result.emdniVal4 = Coupondata[3].couponbval
        ? Coupondata[3].couponbval.NI
        : '';
      result.emdnrVal1 = Coupondata[0].couponbval
        ? Coupondata[0].couponbval.NR
        : '';
      result.emdnrVal2 = Coupondata[1].couponbval
        ? Coupondata[1].couponbval.NR
        : '';
      result.emdnrVal3 = Coupondata[2].couponbval
        ? Coupondata[2].couponbval.NR
        : '';
      result.emdnrVal4 = Coupondata[3].couponbval
        ? Coupondata[3].couponbval.NR
        : '';
    }
    /// ----- Hidden Vaues
    result.TicketingPCC = ticketInfo.TicketingPCC;
    result.BookingPCC = ticketInfo.BookingPCC;
    result.HasBeenExported = ticketInfo.HasBeenExported;
    const flagNDC = ticketInfo.IsNDC === 'True' ? ' / [  NDC  ]' : '';
    result.IsNDC = ticketInfo.IsNDC;
    if (ticketInfo.HasBeenExported !== 'False') {
      result.TextHasBeenExported = `Ticket Information ${flagNDC} (Exported : ${ticketInfo.ExportedDateTime.substr(
        0,
        10
      )}   by   ${ticketInfo.ExportedBy})`;
      result.ExportedBy = ticketInfo.ExportedBy;
      result.ExportedDateTime = ticketInfo.ExportedDateTime;
    } else {
      result.TextHasBeenExported = `Ticket Information ${flagNDC}`;
      result.ExportedBy = '';
      result.ExportedDateTime = '';
    }
    result.IsRefund = ticketInfo.IsRefund;
    result.IsEmd = ticketInfo.IsEmd;
    result.FareItemNumber = ticketInfo.FareItemNumber;
    result.PassengerNumber = ticketInfo.PassengerNumber;
    result.TransmittingSystem = ticketInfo.TransmittingSystem;
    result.ValidatingAirlineName = ticketInfo.ValidatingAirlineName;
    result.ValidatingAirlineNumber = ticketInfo.ValidatingAirlineNumber;
    result.ConsumptionPercent = ticketInfo.ConsumptionPercent !== undefined
      ? ticketInfo.ConsumptionPercent
      : null;
    if (ticketInfo.RefundData) {
      result.refundticketnumber = ticketInfo.RefundData.RefundTicketNumber;
      result.refundCommissionAmount = ticketInfo.RefundData.CommissionAmount;
      result.refundCommissionPercentOnPenaltyFee = ticketInfo.RefundData.CommissionPercentOnPenaltyFee;
      result.refundCommissionAmountOnPenaltyFee = ticketInfo.RefundData.CommissionAmountOnPenaltyFee;
      result.refundCashAmountUsed = ticketInfo.RefundData.CashAmountUsed;
      result.refundCashRefund = ticketInfo.RefundData.CashRefund;
      result.refundCreditAmountUsed = ticketInfo.RefundData.CreditAmountUsed;
      result.refundCreditRefund = ticketInfo.RefundData.CreditRefund;
      result.refundRefundCurrency = ticketInfo.RefundData.RefundCurrency;
      result.refundRefundTax = ticketInfo.RefundData.RefundTax;
      result.refundRefundGrossFare = ticketInfo.RefundData.RefundGrossFare;
      result.refundCurrency = ticketInfo.RefundData.Currency;
      result.ESAC = ticketInfo.RefundData.ESAC;
    }

    if (ticketInfo.EMDData) {
      result.EMDDataEMDNumber = ticketInfo.EMDData.EMDNumber;
      result.EMDDataValidatingAirlineNumber = ticketInfo.EMDData.ValidatingAirlineNumber;
      result.EMDDataApprovalCodeIndicator = ticketInfo.EMDData.ApprovalCodeIndicator;
      result.EMDDataApprovalCode = ticketInfo.EMDData.ApprovalCode;
    }
    if (ticketInfo.OBFeesInfo) {
      result.OBFeesFareItemNumber = ticketInfo.OBFeesInfo.FareItemNumber;
      result.OBFeesTaxCode = ticketInfo.OBFeesInfo.TaxCode;
      result.OBFeesOBFeeSubCode = ticketInfo.OBFeesInfo.OBFeeSubCode;
      result.OBFeesAmount = ticketInfo.OBFeesInfo.Amount;
    }
    result.IsMixedPTC = ticketInfo.IsMixedPTC;
    result.DepartureDate = ticketInfo.DepartureDate;
    result.OtherTicketsInfo = ticketInfo.OtherTickets.length > 0 ? ticketInfo.OtherTickets : [];
    result.InternationalDepartureDate = ticketInfo.InternationalDepartureDate;
    result.SourceName = ticketInfo.SourceName;
    result.CPN = ticketInfo.CPN;
    result.FLT = ticketInfo.FLT;

    return result;
  };
  const ticketDetail = createTicketDetail();
  const { reset, ...form } = useForm({
    shouldFocusError: false,
    defaultValues: ticketDetail
  });
  const handlePageChange = (event, value) => {
    if (value != null) {
      // when clicking on 3 dots.. value will be null
      setSearchOptions({ ...searchOptions, index: value - 1 });
    }
  };

  React.useEffect(() => {
    if (!ticketDetail.taxes) {
      ticketDetail.taxes = []; // otherwise useFieldArray will be error
    }
    reset(ticketDetail);
  }, [ticket]);

  React.useEffect(() => {
    if (searchOptions.filter === 'all') {
      searchAllMutation.mutate(searchOptions);
    } else if (searchOptions.filter === 'issuedate') {
      getTicketNumbersByIssueDateMutation.mutate(searchOptions);
    } else if (searchOptions.filter === 'ticketnumber') {
      getTicketDetailsByTicketNumberMutation.mutate(searchOptions);
    }
  }, [searchOptions]);

  const pagination = isLoading || isFetching ? null : (
    <div
      style={{
        marginTop: 20,
        marginBottom: 5,
        display: 'flex',
        justifyContent: 'center'
      }}
    >
      {loadingOrError}
      <Pagination
        count={ticket.data ? ticket.length : 0}
        color="primary"
        page={searchOptions.index + 1}
        onChange={handlePageChange}
      />
    </div>
  );

  const handleTicketDetailDelete = (isConjunction) => {
    setSearchOptions({
      ...searchOptions,
      index:
        searchOptions.index - 1 < 0 || isConjunction
          ? 0
          : searchOptions.index - 1
    });
  };
  const handleTicketDetailUpdate = () => {
    setSearchOptions({ ...searchOptions });
  };

  const ticketDetailComponent = (tkt) => (tkt ? (
    <TicketDetail
      {...tkt}
      form={form}
      onDelete={(isConjunction) => handleTicketDetailDelete(isConjunction)}
      onUpdate={(e) => handleTicketDetailUpdate(e)}
    />
  ) : (
    <span>No Tickets</span>
  ));

  const ticketDetailLoading = getTicketDetailsByTicketNumberMutation.isLoading
    || getTicketDetailsByTicketNumberMutation.isFetching
    || getTicketNumbersByIssueDateMutation.isLoading
    || getTicketNumbersByIssueDateMutation.isFetching
    || searchAllMutation.isLoading
    || searchAllMutation.isFetching
    || isFetching
    || isLoading;

  // console.log(ticket.data);
  const ticketDetailsDisplay = ticket.data ? (
    ticketDetailComponent(ticketDetail)
  ) : (
    <>
      {' '}
      <Card style={{ marginBottom: 10 }}>
        <CardContent style={{ paddingBottom: '15px' }}>
          <Grid container spacing={3}>
            <Grid item md={12} sm={12}>
              <Typography style={{ textAlign: 'center' }}>
                <span className={classes.noDataText}>
                  {t('No registered tickets')}
                </span>
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <Helmet>
          <title>{t('Travelport GBS | Ticket Details')}</title>
        </Helmet>
        {connectionStatus}
        <Box
          sx={{
            backgroundColor: 'background.default',
            minHeight: '100%',
            maxWidth: '1100px',
            minWidth: '1100px',
            py: 3
          }}
        >
          <Container maxWidth>
            <Box
              sx={{ pt: 0 }}
              color="primary.main"
              borderBottom={0}
              marginBottom={0}
            >
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  style={{ backgroundColor: 'black' }}
                >
                  <Typography className={classes.heading}>
                    <SearchIcon
                      style={{
                        color: 'white',
                        float: 'left',
                        marginTop: 1,
                        marginLeft: -6
                      }}
                    />
                    <span
                      className={classes.mainTitle}
                      style={{ paddingLeft: -10 }}
                    >
                      {t('Search')}
                    </span>
                  </Typography>
                </AccordionSummary>
                <Card>
                  <TicketFilter
                    isTicketDetails
                    dateTimeFilterChanged={searchByIssueDate}
                    fromToTicketChanged={searchByTicketNumbers}
                    allOptionSelected={searchAll}
                  />
                </Card>
              </Accordion>
            </Box>
            {pagination}
            <Box
              sx={{ pt: 1 }}
              color="primary.main"
              borderBottom={0}
              marginBottom={0}
            >
              {ticketDetailLoading ? (
                <div
                  style={{
                    marginTop: 20,
                    marginBottom: 5,
                    display: 'flex',
                    justifyContent: 'center'
                  }}
                >
                  <LinearProgress style={{ width: 600 }} />
                </div>
              ) : (
                ticketDetailsDisplay
              )}
            </Box>
          </Container>
        </Box>
      </QueryClientProvider>
    </>
  );
};

export default TicketDetails;
