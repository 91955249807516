import React from 'react';
import {
  TextField,
  Grid,
  CardContent,
  // FormControl,
  // InputLabel,
  // NativeSelect,
  MenuItem
} from '@material-ui/core';
// import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// import FaresPayments from './FaresPayments';
import ConnectForm from '../common/ConnectForm';

const Emd = (prop) => {
  const {
    conjunctionticketnumber,
    emdtype,
    arrange,
    emdcommissionamount,
    emdcommConsumptiontax,
    emdcommrate,
    emdtourcode,
    endorsedata,
    bsr,
    emddtVal1,
    emddtVal2,
    emddtVal3,
    emddtVal4,
    emdcityVal1,
    emdcityVal2,
    emdcityVal3,
    emdcityVal4,
    emdrficVal1,
    emdrficVal2,
    emdrficVal3,
    emdrficVal4,
    emdrfiscVal1,
    emdrfiscVal2,
    emdrfiscVal3,
    emdrfiscVal4,
    emdrcommercialVal1,
    emdrcommercialVal2,
    emdrcommercialVal3,
    emdrcommercialVal4,
    emdculVal1,
    emdculVal2,
    emdculVal3,
    emdculVal4,
    emdamtVal1,
    emdamtVal2,
    emdamtVal3,
    emdamtVal4,
    emdquantityVal1,
    emdquantityVal2,
    emdquantityVal3,
    emdquantityVal4,
    emdncVal1,
    emdncVal2,
    emdncVal3,
    emdncVal4,
    emdniVal1,
    emdniVal2,
    emdniVal3,
    emdniVal4,
    emdnrVal1,
    emdnrVal2,
    emdnrVal3,
    emdnrVal4,
    EMDDataValidatingAirlineNumber,
    EMDDataApprovalCodeIndicator,
    EMDDataApprovalCode,
    readOnlyFlag
    // emdBaseAmountCurreny1
  } = prop;

  const { t } = useTranslation();
  const TextStyleReadOndy = {
    '& .MuiInputBase-input': {
      color: '#0A121A',
      padding: '10px',
      backgroundColor: '#ececec'
    }
  };
  const TextStyleChangeable = {
    '& .MuiInputBase-input': {
      color: '#0A121A',
      padding: '10px'
      // textTransform: 'uppercase'
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'rgba(0, 0, 0, 0.23)'
      },
      '&:hover fieldset': {
        borderColor: '#FF5E6F'
      },
      '&.Mui-focused fieldset': {
        borderColor: '#FF5E6F'
      }
    }
  };
  return (
    <ConnectForm>
      {({ register }) => (
        <CardContent>
          <Grid container spacing={3} sx={{ marginBottom: '10px' }}>
            <Grid item md={3} sm={12}>
              <TextField
                sx={readOnlyFlag ? TextStyleReadOndy : TextStyleChangeable}
                fullWidth
                label={t('Conjunction / Ticket Number')}
                InputLabelProps={{ shrink: true }}
                name="conjunctionticketnumber"
                {...register('conjunctionticketnumber')}
                defaultValue={conjunctionticketnumber}
                variant="outlined"
                InputProps={{ readOnly: readOnlyFlag }}
              />
            </Grid>
            <Grid item md={3} sm={12}>
              <TextField
                sx={readOnlyFlag ? TextStyleReadOndy : TextStyleChangeable}
                select
                label={t('EMD Type')}
                InputLabelProps={{ shrink: true }}
                {...register('emdtype')}
                defaultValue={emdtype}
                htmlFor="emdtypeStatus"
                variant="outlined"
                fullWidth="true"
                InputProps={{
                  readOnly: readOnlyFlag,
                  name: 'emdtype',
                  id: 'emdtypeStatus'
                }}
              >
                <MenuItem value="S">S</MenuItem>
                <MenuItem value="A">A</MenuItem>
              </TextField>
            </Grid>
            <Grid item md={3} sm={12}>
              <TextField
                sx={readOnlyFlag ? TextStyleReadOndy : TextStyleChangeable}
                select
                label={t('Arrange')}
                InputLabelProps={{ shrink: true }}
                {...register('arrange')}
                defaultValue={arrange}
                htmlFor="arrangeStatus"
                variant="outlined"
                fullWidth="true"
                InputProps={{
                  readOnly: readOnlyFlag,
                  name: 'arrange',
                  id: 'arrangeStatus'
                }}
              >
                <MenuItem value="A">A: Auto</MenuItem>
                <MenuItem value="M">M: Manual</MenuItem>
              </TextField>
            </Grid>
          </Grid>
          <Grid container spacing={3} sx={{ marginBottom: '10px' }}>
            <Grid item md={3} sm={12}>
              <TextField
                sx={readOnlyFlag ? TextStyleReadOndy : TextStyleChangeable}
                fullWidth
                label={t('Comm Amt')}
                InputLabelProps={{ shrink: true }}
                name="emdcommissionamount"
                {...register('emdcommissionamount')}
                defaultValue={emdcommissionamount}
                variant="outlined"
                InputProps={{ readOnly: readOnlyFlag }}
              />
            </Grid>
            <Grid item md={3} sm={12}>
              <TextField
                sx={readOnlyFlag ? TextStyleReadOndy : TextStyleChangeable}
                fullWidth
                label={t('Comm ConsumptionTax')}
                InputLabelProps={{ shrink: true }}
                name="emdcommConsumptiontax"
                {...register('emdcommConsumptiontax')}
                defaultValue={emdcommConsumptiontax}
                variant="outlined"
                InputProps={{ readOnly: readOnlyFlag }}
              />
            </Grid>
            <Grid item md={3} sm={12}>
              <TextField
                sx={readOnlyFlag ? TextStyleReadOndy : TextStyleChangeable}
                fullWidth
                label={t('Comm Rate')}
                InputLabelProps={{ shrink: true }}
                name="emdcommrate"
                {...register('emdcommrate')}
                defaultValue={emdcommrate}
                variant="outlined"
                InputProps={{ readOnly: readOnlyFlag }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} sx={{ marginBottom: '10px' }}>
            <Grid item md={3} sm={12}>
              <TextField
                sx={readOnlyFlag ? TextStyleReadOndy : TextStyleChangeable}
                fullWidth
                label="Tour Code"
                InputLabelProps={{ shrink: true }}
                name="emdtourcode"
                {...register('emdtourcode')}
                defaultValue={emdtourcode}
                variant="outlined"
                InputProps={{ readOnly: readOnlyFlag }}
              />
            </Grid>
            <Grid item md={6} sm={12}>
              <TextField
                sx={readOnlyFlag ? TextStyleReadOndy : TextStyleChangeable}
                fullWidth
                label="Endorse Data"
                InputLabelProps={{ shrink: true }}
                name="endorsedata"
                {...register('endorsedata')}
                defaultValue={endorsedata}
                variant="outlined"
                InputProps={{ readOnly: readOnlyFlag }}
              />
            </Grid>
            <Grid item md={3} sm={12}>
              <TextField
                sx={readOnlyFlag ? TextStyleReadOndy : TextStyleChangeable}
                fullWidth
                label="BSR"
                InputLabelProps={{ shrink: true }}
                name="bsr"
                {...register('bsr')}
                defaultValue={bsr}
                variant="outlined"
                InputProps={{ readOnly: readOnlyFlag }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} sx={{ marginBottom: '10px' }}>
            <Grid item md={1} sm={12}>
              <TextField
                sx={TextStyleChangeable}
                fullWidth
                label="1-Date"
                InputLabelProps={{ shrink: true }}
                name="emddtVal1"
                {...register('emddtVal1')}
                defaultValue={emddtVal1}
                variant="outlined"
              />
            </Grid>
            <Grid item md={1} sm={12}>
              <TextField
                sx={TextStyleChangeable}
                fullWidth
                label="City"
                InputLabelProps={{ shrink: true }}
                name="emdcityVal1"
                {...register('emdcityVal1')}
                defaultValue={emdcityVal1}
                variant="outlined"
              />
            </Grid>
            <Grid item md={1} sm={12}>
              <TextField
                sx={TextStyleChangeable}
                fullWidth
                label="RFIC"
                InputLabelProps={{ shrink: true }}
                name="emdrficVal1"
                {...register('emdrficVal1')}
                defaultValue={emdrficVal1}
                variant="outlined"
              />
            </Grid>
            <Grid item md={1} sm={12}>
              <TextField
                sx={TextStyleChangeable}
                fullWidth
                label="RFISC"
                InputLabelProps={{ shrink: true }}
                name="emdrfiscVal1"
                {...register('emdrfiscVal1')}
                defaultValue={emdrfiscVal1}
                variant="outlined"
              />
            </Grid>
            <Grid item md={2} sm={12}>
              <TextField
                sx={TextStyleChangeable}
                fullWidth
                label="Commercial Name"
                InputLabelProps={{ shrink: true }}
                name="emdrcommercialVal1"
                {...register('emdrcommercialVal1')}
                defaultValue={emdrcommercialVal1}
                variant="outlined"
              />
            </Grid>
            <Grid item md={1} sm={12}>
              <TextField
                sx={TextStyleChangeable}
                fullWidth
                label="Cur"
                InputLabelProps={{ shrink: true }}
                name="emdculVal1"
                {...register('emdculVal1')}
                defaultValue={emdculVal1}
                variant="outlined"
              />
            </Grid>
            <Grid item md={1} sm={12}>
              <TextField
                sx={TextStyleChangeable}
                fullWidth
                label="AMT"
                InputLabelProps={{ shrink: true }}
                name="emdamtVal1"
                {...register('emdamtVal1')}
                defaultValue={emdamtVal1}
                variant="outlined"
              />
            </Grid>
            <Grid item md={1} sm={12}>
              <TextField
                sx={TextStyleChangeable}
                fullWidth
                label={t('Quantity')}
                InputLabelProps={{ shrink: true }}
                name="emdquantityVal1"
                {...register('emdquantityVal1')}
                defaultValue={emdquantityVal1}
                variant="outlined"
              />
            </Grid>
            <Grid item md={1} sm={12}>
              <TextField
                sx={TextStyleChangeable}
                fullWidth
                label="NC"
                InputLabelProps={{ shrink: true }}
                name="emdncVal1"
                {...register('emdncVal1')}
                defaultValue={emdncVal1}
                variant="outlined"
              />
            </Grid>
            <Grid item md={1} sm={12}>
              <TextField
                sx={TextStyleChangeable}
                fullWidth
                label="NI"
                InputLabelProps={{ shrink: true }}
                name="emdniVal1"
                {...register('emdniVal1')}
                defaultValue={emdniVal1}
                variant="outlined"
              />
            </Grid>
            <Grid item md={1} sm={12}>
              <TextField
                sx={TextStyleChangeable}
                fullWidth
                label="NR"
                InputLabelProps={{ shrink: true }}
                name="emdnrVal1"
                {...register('emdnrVal1')}
                defaultValue={emdnrVal1}
                variant="outlined"
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} sx={{ marginBottom: '10px' }}>
            <Grid item md={1} sm={12}>
              <TextField
                sx={TextStyleChangeable}
                fullWidth
                label="2-Date"
                InputLabelProps={{ shrink: true }}
                name="emddtVal2"
                {...register('emddtVal2')}
                defaultValue={emddtVal2}
                variant="outlined"
              />
            </Grid>
            <Grid item md={1} sm={12}>
              <TextField
                sx={TextStyleChangeable}
                fullWidth
                label="City"
                InputLabelProps={{ shrink: true }}
                name="emdcityVal2"
                {...register('emdcityVal2')}
                defaultValue={emdcityVal2}
                variant="outlined"
              />
            </Grid>
            <Grid item md={1} sm={12}>
              <TextField
                sx={TextStyleChangeable}
                fullWidth
                label="RFIC"
                InputLabelProps={{ shrink: true }}
                name="emdrficVal2"
                {...register('emdrficVal2')}
                defaultValue={emdrficVal2}
                variant="outlined"
              />
            </Grid>
            <Grid item md={1} sm={12}>
              <TextField
                sx={TextStyleChangeable}
                fullWidth
                label="RFISC"
                InputLabelProps={{ shrink: true }}
                name="emdrfiscVal2"
                {...register('emdrfiscVal2')}
                defaultValue={emdrfiscVal2}
                variant="outlined"
              />
            </Grid>
            <Grid item md={2} sm={12}>
              <TextField
                sx={TextStyleChangeable}
                fullWidth
                label="Commercial Name"
                InputLabelProps={{ shrink: true }}
                name="emdrcommercialVal2"
                {...register('emdrcommercialVal2')}
                defaultValue={emdrcommercialVal2}
                variant="outlined"
              />
            </Grid>
            <Grid item md={1} sm={12}>
              <TextField
                sx={TextStyleChangeable}
                fullWidth
                label="Cur"
                InputLabelProps={{ shrink: true }}
                name="emdculVal2 "
                {...register('emdculVal2')}
                defaultValue={emdculVal2}
                variant="outlined"
              />
            </Grid>
            <Grid item md={1} sm={12}>
              <TextField
                sx={TextStyleChangeable}
                fullWidth
                label="AMT"
                InputLabelProps={{ shrink: true }}
                name="emdamtVal2"
                {...register('emdamtVal2')}
                defaultValue={emdamtVal2}
                variant="outlined"
              />
            </Grid>
            <Grid item md={1} sm={12}>
              <TextField
                sx={TextStyleChangeable}
                fullWidth
                label={t('Quantity')}
                InputLabelProps={{ shrink: true }}
                name="emdquantityVal2"
                {...register('emdquantityVal2')}
                defaultValue={emdquantityVal2}
                variant="outlined"
              />
            </Grid>
            <Grid item md={1} sm={12}>
              <TextField
                sx={TextStyleChangeable}
                fullWidth
                label="NC"
                InputLabelProps={{ shrink: true }}
                name="emdncVal2"
                {...register('emdncVal2')}
                defaultValue={emdncVal2}
                variant="outlined"
              />
            </Grid>
            <Grid item md={1} sm={12}>
              <TextField
                sx={TextStyleChangeable}
                fullWidth
                label="NI"
                InputLabelProps={{ shrink: true }}
                name="emdniVal2"
                {...register('emdniVal2')}
                defaultValue={emdniVal2}
                variant="outlined"
              />
            </Grid>
            <Grid item md={1} sm={12}>
              <TextField
                sx={TextStyleChangeable}
                fullWidth
                label="NR"
                InputLabelProps={{ shrink: true }}
                name="emdnrVal2"
                {...register('emdnrVal2')}
                defaultValue={emdnrVal2}
                variant="outlined"
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} sx={{ marginBottom: '10px' }}>
            <Grid item md={1} sm={12}>
              <TextField
                sx={TextStyleChangeable}
                fullWidth
                label="3-Date"
                InputLabelProps={{ shrink: true }}
                name="emddtVal3"
                {...register('emddtVal3')}
                defaultValue={emddtVal3}
                variant="outlined"
              />
            </Grid>
            <Grid item md={1} sm={12}>
              <TextField
                sx={TextStyleChangeable}
                fullWidth
                label="City"
                InputLabelProps={{ shrink: true }}
                name="emdcityVal3"
                {...register('emdcityVal3')}
                defaultValue={emdcityVal3}
                variant="outlined"
              />
            </Grid>
            <Grid item md={1} sm={12}>
              <TextField
                sx={TextStyleChangeable}
                fullWidth
                label="RFIC"
                InputLabelProps={{ shrink: true }}
                name="emdrficVal3"
                {...register('emdrficVal3')}
                defaultValue={emdrficVal3}
                variant="outlined"
              />
            </Grid>
            <Grid item md={1} sm={12}>
              <TextField
                sx={TextStyleChangeable}
                fullWidth
                label="RFISC"
                InputLabelProps={{ shrink: true }}
                name="emdrfiscVal3"
                {...register('emdrfiscVal3')}
                defaultValue={emdrfiscVal3}
                variant="outlined"
              />
            </Grid>
            <Grid item md={2} sm={12}>
              <TextField
                sx={TextStyleChangeable}
                fullWidth
                label="Commercial Name"
                InputLabelProps={{ shrink: true }}
                name="emdrcommercialVal3"
                {...register('emdrcommercialVal3')}
                defaultValue={emdrcommercialVal3}
                variant="outlined"
              />
            </Grid>
            <Grid item md={1} sm={12}>
              <TextField
                sx={TextStyleChangeable}
                fullWidth
                label="Cur"
                name="emdculVal3"
                InputLabelProps={{ shrink: true }}
                {...register('emdculVal3')}
                defaultValue={emdculVal3}
                variant="outlined"
              />
            </Grid>
            <Grid item md={1} sm={12}>
              <TextField
                sx={TextStyleChangeable}
                fullWidth
                label="AMT"
                name="emdamtVal3"
                InputLabelProps={{ shrink: true }}
                {...register('emdamtVal3')}
                defaultValue={emdamtVal3}
                variant="outlined"
              />
            </Grid>
            <Grid item md={1} sm={12}>
              <TextField
                sx={TextStyleChangeable}
                fullWidth
                label={t('Quantity')}
                InputLabelProps={{ shrink: true }}
                name="emdquantityVal3"
                {...register('emdquantityVal3')}
                defaultValue={emdquantityVal3}
                variant="outlined"
              />
            </Grid>
            <Grid item md={1} sm={12}>
              <TextField
                sx={TextStyleChangeable}
                fullWidth
                label="NC"
                InputLabelProps={{ shrink: true }}
                name="emdncVal3"
                {...register('emdncVal3')}
                defaultValue={emdncVal3}
                variant="outlined"
              />
            </Grid>
            <Grid item md={1} sm={12}>
              <TextField
                sx={TextStyleChangeable}
                fullWidth
                label="NI"
                InputLabelProps={{ shrink: true }}
                name="emdniVal3"
                {...register('emdniVal3')}
                defaultValue={emdniVal3}
                variant="outlined"
              />
            </Grid>
            <Grid item md={1} sm={12}>
              <TextField
                sx={TextStyleChangeable}
                fullWidth
                label="NR"
                InputLabelProps={{ shrink: true }}
                name="emdnrVal3"
                {...register('emdnrVal3')}
                defaultValue={emdnrVal3}
                variant="outlined"
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} sx={{ marginBottom: '10px' }}>
            <Grid item md={1} sm={12}>
              <TextField
                sx={TextStyleChangeable}
                fullWidth
                label="4-Date"
                InputLabelProps={{ shrink: true }}
                name="emddtVal4"
                {...register('emddtVal4')}
                defaultValue={emddtVal4}
                variant="outlined"
              />
            </Grid>
            <Grid item md={1} sm={12}>
              <TextField
                sx={TextStyleChangeable}
                fullWidth
                label="City"
                InputLabelProps={{ shrink: true }}
                name="emdcityVal4"
                {...register('emdcityVal4')}
                defaultValue={emdcityVal4}
                variant="outlined"
              />
            </Grid>
            <Grid item md={1} sm={12}>
              <TextField
                sx={TextStyleChangeable}
                fullWidth
                label="RFIC"
                InputLabelProps={{ shrink: true }}
                name="emdrficVal4"
                {...register('emdrficVal4')}
                defaultValue={emdrficVal4}
                variant="outlined"
              />
            </Grid>
            <Grid item md={1} sm={12}>
              <TextField
                sx={TextStyleChangeable}
                fullWidth
                label="RFISC"
                InputLabelProps={{ shrink: true }}
                name="emdrfiscVal4"
                {...register('emdrfiscVal4')}
                defaultValue={emdrfiscVal4}
                variant="outlined"
              />
            </Grid>
            <Grid item md={2} sm={12}>
              <TextField
                sx={TextStyleChangeable}
                fullWidth
                label="Commercial Name"
                InputLabelProps={{ shrink: true }}
                name="emdrcommercialVal4"
                {...register('emdrcommercialVal4')}
                defaultValue={emdrcommercialVal4}
                variant="outlined"
              />
            </Grid>
            <Grid item md={1} sm={12}>
              <TextField
                sx={TextStyleChangeable}
                fullWidth
                label="Cur"
                InputLabelProps={{ shrink: true }}
                name="emdculVal4"
                {...register('emdculVal4')}
                defaultValue={emdculVal4}
                variant="outlined"
              />
            </Grid>
            <Grid item md={1} sm={12}>
              <TextField
                sx={TextStyleChangeable}
                fullWidth
                label="AMT"
                InputLabelProps={{ shrink: true }}
                name="emdamtVal4"
                {...register('emdamtVal4')}
                defaultValue={emdamtVal4}
                variant="outlined"
              />
            </Grid>
            <Grid item md={1} sm={12}>
              <TextField
                sx={TextStyleChangeable}
                fullWidth
                label={t('Quantity')}
                InputLabelProps={{ shrink: true }}
                name="emdquantityVal4"
                {...register('emdquantityVal4')}
                defaultValue={emdquantityVal4}
                variant="outlined"
              />
            </Grid>
            <Grid item md={1} sm={12}>
              <TextField
                sx={TextStyleChangeable}
                fullWidth
                label="NC"
                InputLabelProps={{ shrink: true }}
                name="emdncVal4"
                {...register('emdncVal4')}
                defaultValue={emdncVal4}
                value={emdncVal4}
                variant="outlined"
              />
            </Grid>
            <Grid item md={1} sm={12}>
              <TextField
                sx={TextStyleChangeable}
                fullWidth
                label="NI"
                InputLabelProps={{ shrink: true }}
                name="emdniVal4"
                {...register('emdniVal4')}
                defaultValue={emdniVal4}
                variant="outlined"
              />
            </Grid>
            <Grid item md={1} sm={12}>
              <TextField
                sx={TextStyleChangeable}
                fullWidth
                label="NR"
                InputLabelProps={{ shrink: true }}
                name="emdnrVal4"
                {...register('emdnrVal4')}
                defaultValue={emdnrVal4}
                variant="outlined"
              />
            </Grid>
          </Grid>
          <input
            type="hidden"
            value={EMDDataValidatingAirlineNumber}
            name="EMDDataValidatingAirlineNumber"
          />
          <input
            type="hidden"
            value={EMDDataApprovalCodeIndicator}
            name="EMDDataApprovalCodeIndicator"
          />
          <input
            type="hidden"
            value={EMDDataApprovalCode}
            name="EMDDataApprovalCode"
          />
        </CardContent>
      )}
    </ConnectForm>
  );
};

export default Emd;
