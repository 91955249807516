import React from 'react';
import PropTypes from 'prop-types';
// import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
// import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  subTitle: {
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontWeight: 'bold',
  }
});

const Segment = (props) => {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <Tooltip title="Extra Information" placement="top">
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </Tooltip>
        </TableCell>
        <TableCell component="th" scope="row" align="center">{row.CarrierCode + row.FlightNum}</TableCell>
        <TableCell align="center">{row.BookigClass}</TableCell>
        <TableCell align="center">{row.departuredate}</TableCell>
        <TableCell align="center">
          {row.OriginCode}
          &nbsp;&nbsp;
          {row.departuretime}
        </TableCell>
        <TableCell align="center">
          {row.DestinationCode}
          &nbsp;&nbsp;
          {row.arrivaltime}
        </TableCell>
        <TableCell align="center">{row.arrivalday}</TableCell>
        <TableCell align="center">{row.status}</TableCell>
        <TableCell align="center">{row.FareBasis}</TableCell>
        <TableCell align="center">{row.baggage}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0, paddingLeft: 85, }} colSpan={10}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Table size="small" aria-label="purchases">
              {/* <TableHead>
                <TableRow>
                  <TableCell colSpan={7}>Extra Information</TableCell>
                </TableRow>
              </TableHead> */}
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row" align="center">
                    <span className={classes.subTitle}>NVBC&nbsp;:&nbsp;</span>
                    {row.NVBC}
                  </TableCell>
                  <TableCell align="center">
                    <span className={classes.subTitle}>NVBC&nbsp;:&nbsp;</span>
                    {row.NVAC}
                  </TableCell>
                  <TableCell align="center">
                    <span className={classes.subTitle}>StopOver&nbsp;:&nbsp;</span>
                    {row.stopover}
                  </TableCell>
                  <TableCell align="center">
                    <span className={classes.subTitle}>Coupon No&nbsp;:&nbsp;</span>
                    {row.couponno}
                  </TableCell>
                  <TableCell align="center">
                    <span className={classes.subTitle}>Seat&nbsp;:&nbsp;</span>
                    {row.Seat}
                  </TableCell>
                  <TableCell align="center">
                    <span className={classes.subTitle}>Mile&nbsp;:&nbsp;</span>
                    {row.Mile}
                  </TableCell>
                </TableRow>
                <input type="hidden" value={row.segmentno} name="segmentno" />
                <input type="hidden" value={row.airlinenumber} name="airlinenumber" />
                <input type="hidden" value={row.airlinename} name="airlinename" />
                <input type="hidden" value={row.arrivaldate} name="arrivaldate" />
                <input type="hidden" value={row.origincityname} name="origincityname" />
                <input type="hidden" value={row.destinationcityname} name="destinationcityname" />
                <input type="hidden" value={row.domesticinternationalindicator} name="domesticinternationalindicator" />
                <input type="hidden" value={row.mealcode} name="mealcode" />
                <input type="hidden" value={row.numberofstops} name="numberofstops" />
                <input type="hidden" value={row.typeofaircraft} name="typeofaircraft" />
                <input type="hidden" value={row.terminal} name="terminal" />
                <input type="hidden" value={row.segmentidentifier} name="segmentidentifier" />
                <input type="hidden" value={row.ticketIndicator} name="ticketIndicator" />
                <input type="hidden" value={row.flightsectiontype} name="flightsectiontype" />
                <input type="hidden" value={row.internationaldeparturedate} name="internationaldeparturedate" />
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};
Segment.propTypes = {
  row: PropTypes.shape({
    CarrierCode: PropTypes.string.isRequired,
    FlightNum: PropTypes.string.isRequired,
    BookigClass: PropTypes.string.isRequired,
    departuredate: PropTypes.string.isRequired,
    OriginCode: PropTypes.string.isRequired,
    DestinationCode: PropTypes.string.isRequired,
    FareBasis: PropTypes.string.isRequired,
    Mile: PropTypes.string.isRequired,
    Seat: PropTypes.string.isRequired,
    NVBC: PropTypes.string.isRequired,
    NVAC: PropTypes.string.isRequired,
    baggage: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    departuretime: PropTypes.string.isRequired,
    arrivaltime: PropTypes.string.isRequired,
    arrivalday: PropTypes.string.isRequired,
    stopover: PropTypes.string.isRequired,
    couponno: PropTypes.string.isRequired,
    segmentno: PropTypes.string.isRequired,
    airlinenumber: PropTypes.string.isRequired,
    airlinename: PropTypes.string.isRequired,
    arrivaldate: PropTypes.string.isRequired,
    origincityname: PropTypes.string.isRequired,
    destinationcityname: PropTypes.string.isRequired,
    domesticinternationalindicator: PropTypes.string.isRequired,
    mealcode: PropTypes.string.isRequired,
    numberofstops: PropTypes.string.isRequired,
    typeofaircraft: PropTypes.string.isRequired,
    terminal: PropTypes.string.isRequired,
    segmentidentifier: PropTypes.string.isRequired,
    ticketIndicator: PropTypes.string.isRequired,
    flightsectiontype: PropTypes.string.isRequired,
    internationaldeparturedate: PropTypes.string.isRequired,
  }).isRequired,

};

const SegmentTable = (props) => {
  const { segments } = props;
  const classes = useStyles();
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell align="center"><span className={classes.subTitle}>Flight</span></TableCell>
            <TableCell align="center"><span className={classes.subTitle}>Class</span></TableCell>
            <TableCell align="center"><span className={classes.subTitle}>Date</span></TableCell>
            <TableCell align="center"><span className={classes.subTitle}>From</span></TableCell>
            <TableCell align="center"><span className={classes.subTitle}>To</span></TableCell>
            <TableCell align="center">&nbsp;</TableCell>
            <TableCell align="center"><span className={classes.subTitle}>Status</span></TableCell>
            <TableCell align="center"><span className={classes.subTitle}>FareBasis</span></TableCell>
            <TableCell align="center"><span className={classes.subTitle}>Baggage</span></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {segments.map((s) => (
            <Segment key={s.CarrierCode + s.FlightNum} row={s} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

SegmentTable.propTypes = {
  segments: PropTypes.array.isRequired
};

export default SegmentTable;
