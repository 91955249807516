import {
  TextField,
  Grid,
  Card,
  CardContent,
  Divider,
  CardHeader,
} from '@material-ui/core';
import { QueryClient, QueryClientProvider, useQuery } from 'react-query';
// import ProgressBar from 'src/components/common/ProgressBar';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useTranslation } from 'react-i18next';
import { getMirInformation } from 'src/api/api';

export default function InformationResult() {
  const queryClient = new QueryClient();
  const useGetInformation = () => useQuery('info', async () => getMirInformation());
  const {
    isLoading, error, data, isFetching
  } = useGetInformation();
  const { t } = useTranslation();
  const TextStyleReadOndy = {
    '& .MuiInputBase-input': {
      color: '#0A121A',
      padding: '10px',
      backgroundColor: '#ececec'
    },
  };
  return (
    <Card style={{ clear: 'right' }}>
      <CardHeader
        style={{ padding: '10px' }}
        title={t('Information')}
        titleTypographyProps={{
          fontWeight: 'bold', fontSize: '16px', fontFamily: 'Roboto, Helvetica, Arial, sans-serif', color: '#0A121A'
        }}
      />

      <QueryClientProvider client={queryClient}>
        {isLoading || isFetching ? (
          <div style={{
            marginTop: 5, marginBottom: 10, display: 'flex', justifyContent: 'center'
          }}
          >
            <CircularProgress />
          </div>
        ) : (
          <>
            {error ? (
              <div>
                An error occurred:
                {error.message}
              </div>
            ) : null}

            <Divider />
            <CardContent style={{ paddingBottom: '15px' }}>
              <Grid
                container
                spacing={1}
                sx={{ marginBottom: '0px' }}
              >
                <Grid
                  item
                  md={2}
                  sm={12}
                >
                  <TextField
                    sx={TextStyleReadOndy}
                    id="standard-disabled"
                    fullWidth
                    label={t('No of files MIR To DISK')}
                    InputLabelProps={{ shrink: true }}
                    value={data.numberOfMirFiles}
                    variant="outlined"
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid
                  item
                  md={2}
                  sm={12}
                >
                  <TextField
                    sx={TextStyleReadOndy}
                    id="standard-name"
                    label={t('No of Expired files MIR')}
                    InputLabelProps={{ shrink: true }}
                    value={data.numberOfExpiredMirFiles}
                    variant="outlined"
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid
                  item
                  md={2}
                  sm={12}
                >
                  <TextField
                    sx={TextStyleReadOndy}
                    id="standard-name"
                    label={t('Storage folder to disk')}
                    value={data.mirFilePath}
                    variant="outlined"
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid
                  item
                  md={2}
                  sm={12}
                >
                  <TextField
                    sx={TextStyleReadOndy}
                    id="standard-name"
                    label={t('No of expired file')}
                    value={data.fileExpirePeriod}
                    variant="outlined"
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid
                  item
                  md={2}
                  sm={12}
                >
                  <TextField
                    sx={TextStyleReadOndy}
                    id="standard-name"
                    label={t('Creating Backup Files')}
                    value={data.creatingBackupFiles === true ? 'Yes' : 'No'}
                    variant="outlined"
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </>
        )}
      </QueryClientProvider>
    </Card>
  );
}
