import axios from 'axios';
import { Navigate } from 'react-router';
import createError from 'http-errors';

const BASE_URL = process.env.REACT_APP_BASE_URL || 'http://localhost:4001/api/';
const INVALID_TOKEN = 'INVALID_TOKEN';
const UNAUTHORIZE_STATUS_CODE = 401;
const API_EXCEPTION = 'API_EXCEPTION';
const getToken = () => localStorage.getItem('token');

export async function getMirInformation() {
  const token = getToken();
  if (!token) {
    throw INVALID_TOKEN;
  }
  const { data } = await axios
    .get(`${BASE_URL}mirinformation`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .catch((error) => {
      if (!error.response) {
        throw new createError.ServiceUnavailable('Network error');
      } else {
        throw new createError.ServiceUnavailable(error.toString());
      }
    });
  return data;
}

export async function getMirConfiguration() {
  const token = getToken();
  if (!token) {
    throw INVALID_TOKEN;
  }
  const { data } = await axios
    .get(`${BASE_URL}mirconfiguration`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .catch((error) => {
      if (error.response && error.response.status === UNAUTHORIZE_STATUS_CODE) {
        throw new createError.Unauthorized();
      }
    });
  return data;
}

export async function getDateRangeMirFiles(startDateTime, endDateTime) {
  const token = getToken();
  if (!token) {
    throw INVALID_TOKEN;
  }
  const { data } = await axios.get(
    `${BASE_URL}DateRangeMirFiles?startdate=${startDateTime}&enddate=${endDateTime}`,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  );
  return data;
}

export async function getDateRangeBackupMirFiles(startDateTime, endDateTime) {
  const token = getToken();
  if (!token) {
    throw INVALID_TOKEN;
  }
  const { data } = await axios.get(
    `${BASE_URL}daterangebackupmirfiles?startdate=${startDateTime}&enddate=${endDateTime}`,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  );
  return data;
}

export const getAllMirFiles = async () => {
  const token = getToken();
  if (!token) {
    throw INVALID_TOKEN;
  }
  return axios
    .get(`${BASE_URL}AllMirFiles`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then((response) => ({ data: response.data }))
    .catch((error) => {
      if (!error.response) {
        throw new createError.ServiceUnavailable('Network error');
      } else if (
        error.response
        && error.response.status === UNAUTHORIZE_STATUS_CODE
      ) {
        throw new createError.Unauthorized();
      } else {
        throw new createError.ServiceUnavailable(error.toString());
      }
    });
};

export const healthCheck = () => new Promise((myResolve) => {
  myResolve(true);
});

export async function getBackupMirFiles() {
  const token = getToken();
  if (!token) {
    throw INVALID_TOKEN;
  }
  const { data } = await axios
    .get(`${BASE_URL}backupmirfiles`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .catch((error) => {
      if (error.response && error.response.status === UNAUTHORIZE_STATUS_CODE) {
        throw new createError.Unauthorized();
      }
    });
  return data;
}

export async function updateMiRConfiguration(configuration) {
  const token = getToken();
  if (!token) {
    throw INVALID_TOKEN;
  }
  const { data, status, statusText } = await axios
    .post(`${BASE_URL}mirconfiguration`, configuration, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .catch((error) => {
      throw error;
    });
  return { data, status, statusText };
}

export async function updateExportConfiguration(configuration) {
  const token = getToken();
  if (!token) {
    throw INVALID_TOKEN;
  }
  const { data, status, statusText } = await axios
    .post(`${BASE_URL}ExportConfiguration`, configuration, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .catch((error) => {
      throw error;
    });
  return { data, status, statusText };
}

export async function geTicketDetails(
  iataNumber,
  carrier,
  pageIndex,
  pageSize,
  isEmd,
  isRefund,
  isExported,
  isNdc
) {
  const token = getToken();
  if (!token) {
    throw INVALID_TOKEN;
  }
  const { data } = await axios
    .get(
      `${BASE_URL}Ticket?iataNumber=${iataNumber}&carrier=${carrier}
  &pageIndex=${pageIndex}&pageSize=${pageSize}&isEmd=${isEmd}&isRefund=${isRefund}&isExported=${isExported}&isNdc=${isNdc} `,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    )
    .catch((error) => {
      console.log(error);
      if (error.response && error.response.status === UNAUTHORIZE_STATUS_CODE) {
        throw new createError.Unauthorized();
      }
      throw API_EXCEPTION;
    });
  return data;
}

export async function getTicketNumbers(
  iataNumber,
  carrier,
  isEmd,
  isRefund,
  isExported,
  startIssueDate,
  endIssueDate,
  startTicketNumber,
  endTicketNumber
) {
  const token = getToken();
  if (!token) {
    throw INVALID_TOKEN;
  }
  const payload = {
    // StartTicketNumber: startNumber,
    // EndTicketNumber: fromNumber,
    IataNumber: iataNumber || '',
    Carrier: carrier || '',
    IsEmd: isEmd,
    IsRefund: isRefund,
    IsExported: isExported,
    StartIssueDate: startIssueDate,
    EndIssueDate: endIssueDate,
    StartTicketNumber: startTicketNumber,
    EndTicketNumber: endTicketNumber
  };
  const { data } = await axios
    .post(`${BASE_URL}Ticket/all`, payload, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .catch((error) => {
      if (error.response && error.response.status === UNAUTHORIZE_STATUS_CODE) {
        throw new createError.Unauthorized();
      }
      throw API_EXCEPTION;
    });
  return data;
}

export async function processingMiRFiles(files) {
  const token = getToken();
  if (!token) {
    throw INVALID_TOKEN;
  }
  const { data, status, statusText } = await axios
    .post(`${BASE_URL}mirfiles`, files, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .catch((error) => {
      if (error.response && error.response.status === UNAUTHORIZE_STATUS_CODE) {
        throw new createError.Unauthorized();
      }
      throw error;
    });
  return { data, status, statusText };
}

export async function getFareTypeConfigurations() {
  const token = getToken();
  if (!token) {
    throw INVALID_TOKEN;
  }
  const { data } = await axios
    .get(`${BASE_URL}FareTypeConfiguration`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .catch((error) => {
      if (error.response && error.response.status === UNAUTHORIZE_STATUS_CODE) {
        throw new createError.Unauthorized();
      }
    });
  return data;
}

export async function getExportConfiguration() {
  const token = getToken();
  if (!token) {
    throw INVALID_TOKEN;
  }
  const { data } = await axios
    .get(`${BASE_URL}ExportConfiguration`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .catch((error) => {
      if (error.response && error.response.status === UNAUTHORIZE_STATUS_CODE) {
        throw new createError.Unauthorized();
      }
    });
  return data;
}

export async function savingFareTypeConfig(fareTypes) {
  const token = getToken();
  if (!token) {
    throw INVALID_TOKEN;
  }
  const { data, status, statusText } = await axios
    .post(`${BASE_URL}FareTypeConfiguration`, fareTypes, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .catch((error) => {
      console.log(error);
      throw error;
    });
  return { data, status, statusText };
}

export async function getFCMIConfigurations() {
  const token = getToken();
  if (!token) {
    throw INVALID_TOKEN;
  }
  const { data } = await axios
    .get(`${BASE_URL}FCMIConfiguration`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .catch((error) => {
      if (error.response && error.response.status === UNAUTHORIZE_STATUS_CODE) {
        throw new createError.Unauthorized();
      }
      throw API_EXCEPTION;
    });
  return data;
}

export async function savingFCMIConfig(fcmiTypes) {
  const token = getToken();
  if (!token) {
    throw INVALID_TOKEN;
  }
  const { data, status, statusText } = await axios
    .post(`${BASE_URL}FCMIConfiguration`, fcmiTypes, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .catch((error) => {
      throw error;
    });
  return { data, status, statusText };
}

export async function addFCMIConfig(fcmiTypes) {
  const token = getToken();
  if (!token) {
    throw INVALID_TOKEN;
  }
  const { data, status, statusText } = await axios
    .put(`${BASE_URL}FCMIConfiguration`, fcmiTypes, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .catch((error) => {
      console.log(error);
      throw error;
    });
  return { data, status, statusText };
}
export async function deleteFCMIConfig(id) {
  const token = getToken();
  if (!token) {
    throw INVALID_TOKEN;
  }
  const { data, status, statusText } = await axios
    .delete(`${BASE_URL}FCMIConfiguration`, {
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: { id }
    })
    .catch((error) => {
      throw error;
    });
  return { data, status, statusText };
}

export async function processingBackUpFiles(files) {
  const token = getToken();
  if (!token) {
    throw INVALID_TOKEN;
  }
  const { data, status, statusText } = await axios
    .post(`${BASE_URL}BackupMirFiles`, files, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .catch((error) => {
      if (error.response && error.response.status === UNAUTHORIZE_STATUS_CODE) {
        <Navigate to="/login" />;
      }
      throw API_EXCEPTION;
    });
  return { data, status, statusText };
}

export async function getAirlineCodeConfigurations() {
  const token = getToken();
  if (!token) {
    throw INVALID_TOKEN;
  }
  const { data } = await axios
    .get(`${BASE_URL}AirlineCodeConfiguration`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .catch((error) => {
      if (error.response && error.response.status === UNAUTHORIZE_STATUS_CODE) {
        throw new createError.Unauthorized();
      }
      throw API_EXCEPTION;
    });
  return data;
}

export async function updateAirlineCodeConfigurations(airlineConfiguration) {
  const token = getToken();
  if (!token) {
    throw INVALID_TOKEN;
  }
  const { data, status, statusText } = await axios
    .post(`${BASE_URL}AirlineCodeConfiguration`, airlineConfiguration, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .catch((error) => {
      console.log(error);
      throw error;
    });
  return { data, status, statusText };
}

export async function deleteAirlineCodeConfigurations(airlineConfiguration) {
  const token = getToken();
  if (!token) {
    throw INVALID_TOKEN;
  }
  const { data, status, statusText } = await axios
    .delete(`${BASE_URL}AirlineCodeConfiguration`, {
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: airlineConfiguration
    })
    .catch((error) => {
      throw error;
    });
  return { data, status, statusText };
}

export async function addAirlineCodeConfigurations(airlineConfiguration) {
  const token = getToken();
  if (!token) {
    throw INVALID_TOKEN;
  }
  const { data, status, statusText } = await axios
    .put(`${BASE_URL}AirlineCodeConfiguration`, airlineConfiguration, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .catch((error) => {
      console.log(error);
      throw error;
    });
  return { data, status, statusText };
}

export async function getCityCodeConfigurations() {
  const token = getToken();
  if (!token) {
    throw INVALID_TOKEN;
  }
  const { data } = await axios
    .get(`${BASE_URL}CityCodeConfiguration`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .catch((error) => {
      if (error.response && error.response.status === UNAUTHORIZE_STATUS_CODE) {
        throw new createError.Unauthorized();
      }
      throw API_EXCEPTION;
    });
  return data;
}

export async function updateCityCodeConfigurations(cityCodeConfiguration) {
  const token = getToken();
  if (!token) {
    throw INVALID_TOKEN;
  }
  const { data, status, statusText } = await axios
    .post(`${BASE_URL}CityCodeConfiguration`, cityCodeConfiguration, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .catch((error) => {
      throw error;
    });
  return { data, status, statusText };
}

export async function deleteCityCodeConfigurations(cityCodeConfiguration) {
  const token = getToken();
  if (!token) {
    throw INVALID_TOKEN;
  }
  const { data, status, statusText } = await axios
    .delete(`${BASE_URL}CityCodeConfiguration`, {
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: cityCodeConfiguration
    })
    .catch((error) => {
      console.log(error);
      throw error;
    });
  return { data, status, statusText };
}

export async function addCityCodeConfigurations(cityCodeConfiguration) {
  const token = getToken();
  if (!token) {
    throw INVALID_TOKEN;
  }
  const { data, status, statusText } = await axios
    .put(`${BASE_URL}CityCodeConfiguration`, cityCodeConfiguration, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .catch((error) => {
      throw error;
    });
  return { data, status, statusText };
}

export async function getTicketByIssueDate(
  startDateTime,
  endDateTime,
  pageSize,
  pageIndex,
  carrier,
  iataNumber,
  isEmd,
  isRefund,
  isExported
) {
  const token = getToken();
  if (!token) {
    throw INVALID_TOKEN;
  }
  const payload = {
    PageIndex: pageIndex,
    PageSize: pageSize,
    StartDate: startDateTime,
    EndDate: endDateTime,
    IataNumber: iataNumber || '',
    Carrier: carrier || '',
    IsEmd: isEmd,
    IsRefund: isRefund,
    IsExported: isExported
  };
  const { data } = await axios.post(`${BASE_URL}ticket/issueDate`, payload, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  return data;
}

export async function getTicketByNumber(
  startNumber,
  fromNumber,
  pageSize,
  pageIndex,
  iataNumber,
  carrierValue,
  isEmd,
  isRefund,
  isExported
) {
  const token = getToken();
  if (!token) {
    throw INVALID_TOKEN;
  }
  const payload = {
    PageIndex: pageIndex,
    PageSize: pageSize,
    StartTicketNumber: startNumber,
    EndTicketNumber: fromNumber,
    IataNumber: iataNumber || '',
    Carrier: carrierValue || '',
    IsEmd: isEmd,
    IsRefund: isRefund,
    IsExported: isExported
  };
  const { data } = await axios.post(`${BASE_URL}ticket/ticketNumber`, payload, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  return data;
}

export async function sendUriProVersion2016Export(payload) {
  const token = getToken();
  if (!token) {
    throw INVALID_TOKEN;
  }
  console.log(payload);
  const { data: ticketNumbers } = await axios
    .post(`${BASE_URL}Ticket/all`, payload, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .catch((error) => {
      if (error.response && error.response.status === UNAUTHORIZE_STATUS_CODE) {
        throw new createError.Unauthorized();
      }
      throw API_EXCEPTION;
    });
  if (!token) {
    throw INVALID_TOKEN;
  }
  const { data, status, statusText } = await axios
    .post(`${BASE_URL}uriproversion2016export/list`, ticketNumbers, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .catch((error) => {
      console.log(error);
      throw error;
    });
  return { data, status, statusText };
}

export async function getTicketByRangeAndExport(
  startDate,
  endDate,
  iataNumber,
  carrierValue,
  isEmd,
  isRefund,
  isExported
) {
  const token = getToken();
  if (!token) {
    throw INVALID_TOKEN;
  }
  const payload = {
    StartDateTime: startDate,
    EndDateTime: endDate,
    IataNumber: iataNumber || '',
    Carrier: carrierValue || '',
    IsEmd: isEmd,
    IsRefund: isRefund,
    IsExported: isExported
  };
  const { data, status, statusText } = await axios.post(
    `${BASE_URL}uriproversion2016export/date`,
    payload,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  );
  return { data, status, statusText };
}

export async function getTicketByNumberAndExport(
  startTicketNumber,
  endTicketNumber,
  iataNumber,
  carrierValue,
  isEmd,
  isExported,
  isRefund
) {
  const token = getToken();
  if (!token) {
    throw INVALID_TOKEN;
  }
  const payload = {
    StartTicketNumber: startTicketNumber,
    EndTicketNumber: endTicketNumber,
    IataNumber: iataNumber || '',
    Carrier: carrierValue || '',
    IsEmd: isEmd,
    IsExported: isExported,
    IsRefund: isRefund
  };
  const { data, status, statusText } = await axios.post(
    `${BASE_URL}uriproversion2016export/ticketnumber`,
    payload,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  );
  return { data, status, statusText };
}

export async function getExportByAll(filter) {
  const token = getToken();
  if (!token) {
    throw INVALID_TOKEN;
  }
  // console.log(filter);
  const payload = {
    IataNumber: filter.iataNumber || '',
    Carrier: filter.carrier || '',
    IsEmd: filter.isEmd,
    IsRefund: filter.isRefund,
    IsExported: filter.isExported
  };
  const { data, status, statusText } = await axios
    .post(`${BASE_URL}uriproversion2016export/all`, payload, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .catch((error) => {
      throw error;
    });
  return { data, status, statusText };
}

export async function getCommissionRate() {
  const token = getToken();
  if (!token) {
    throw INVALID_TOKEN;
  }
  console.log('getCommissionRate');
  const { data } = await axios
    .get(`${BASE_URL}CommissionRateConfiguration`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .catch((error) => {
      if (error.response && error.response.status === UNAUTHORIZE_STATUS_CODE) {
        throw new createError.Unauthorized();
      }
      throw API_EXCEPTION;
    });
  return { data };
}

export async function updateCommissionRateConfigurations(commissionRateConfig) {
  const token = getToken();
  if (!token) {
    throw INVALID_TOKEN;
  }
  const { data, status, statusText } = await axios
    .post(`${BASE_URL}CommissionRateConfiguration`, commissionRateConfig, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .catch((error) => {
      throw error;
    });
  return { data, status, statusText };
}

export async function getSchedulerConfig() {
  const token = getToken();
  if (!token) {
    throw INVALID_TOKEN;
  }
  const { data } = await axios
    .get(`${BASE_URL}Scheduler`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .catch((error) => {
      if (error.response && error.response.status === UNAUTHORIZE_STATUS_CODE) {
        throw new createError.Unauthorized();
      }
      throw API_EXCEPTION;
    });
  return { data };
}

export async function updateSchedulerConfigurations(schedulerConfig) {
  const token = getToken();
  if (!token) {
    throw INVALID_TOKEN;
  }
  const { data, status, statusText } = await axios
    .post(`${BASE_URL}Scheduler`, schedulerConfig, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .catch((error) => {
      console.log(error);
      throw error;
    });
  return { data, status, statusText };
}

export async function deleteTicketList(ticketNumbers) {
  const token = getToken();
  if (!token) {
    throw INVALID_TOKEN;
  }
  const { data, status, statusText } = await axios
    .delete(`${BASE_URL}Ticket`, {
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: { ticketNumbers }
    })
    .catch((error) => {
      throw error;
    });
  return { data, status, statusText };
}

export async function deleteTicketListV2(payload) {
  const token = getToken();
  if (!token) {
    throw INVALID_TOKEN;
  }
  // console.log(payload);
  const { data: ticketNumbers } = await axios
    .post(`${BASE_URL}Ticket/all`, payload, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .catch((error) => {
      if (error.response && error.response.status === UNAUTHORIZE_STATUS_CODE) {
        throw new createError.Unauthorized();
      }
      throw API_EXCEPTION;
    });
  const { data, status, statusText } = await axios
    .delete(`${BASE_URL}Ticket`, {
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: { ticketNumbers }
    })
    .catch((error) => {
      throw error;
    });
  return { data, status, statusText };
}

export async function saveTicket(ticket) {
  const token = getToken();
  if (!token) {
    throw INVALID_TOKEN;
  }
  const { data, status, statusText } = await axios
    .put(`${BASE_URL}Ticket`, ticket, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .catch((error) => {
      throw error;
    });
  return { data, status, statusText };
}

export async function getAPIVersionNumber() {
  const token = getToken();
  if (!token) {
    throw INVALID_TOKEN;
  }
  const { data } = await axios
    .get(`${BASE_URL}Version`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .catch((error) => {
      if (error.response && error.response.status === UNAUTHORIZE_STATUS_CODE) {
        throw new createError.Unauthorized();
      }
      throw API_EXCEPTION;
    });
  return { data };
}
