import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // we init with resources
    resources: {
      en: {
        translations: {
          'Sign in': 'Sign in',
          'Sign in on the internal platform.':
            'Sign in on the internal platform.',
          'Login with Facebook': 'Login with Facebook',
          'Login with Google': 'Login with Google',
          'or login with email address': 'or login with email address',
          'Sign in now': 'Sign in now',
          'Don&apost have an account?': "Don't have an account?",
          'Sign up': 'Sign up',
          'Email Address': 'Email Address',
          Password: 'Password',
          Information: 'Information',
          'Files display option of MIR to DISK':
            'Files display option of MIR to DISK',
          'No of files MIR To DISK': 'No of files MIR To DISK',
          'Number of files MIR': 'Number of files MIR',
          'Storage folder to disk': 'Storage folder to disk',
          'No of Expired files MIR': 'No of Expired files MIR',
          'No of expired file': 'No of expired file',
          'Creating Backup Files': 'Creating Backup Files',
          'Display by specifying the issue date':
            'Display by specifying the issue date',
          'Display by specifying the creation date':
            'Display by specifying the creation date',
          'Display specifying the issue date range':
            'Display specifying the issue date range',
          'Display specifying the creation date range':
            'Display specifying the creation date range',
          'Files display option of Import MIR to DISK':
            'Files display option of Import MIR to DISK',
          'Display all files': 'Display all files',
          'MIR To Disk file list': 'MIR To Disk file list',
          'The Selected files are ': 'The Selected files are ',
          Send: 'Send',
          Language: '言語',
          OK: 'OK',
          'IMPORT MIR': 'IMPORT MIR',
          'Are you sure to start import MIR file(s)':
            'Are you sure to start import MIR file(s)',
          Start: 'Start',
          Cancel: 'Cancel',
          'Select All': 'Select All',
          Update: 'Update',
          'MIR SETTINGS': 'MIR SETTINGS',
          'Are you sure to update the  setting(s)?':
            'Are you sure to update the  setting(s)?',
          'MIR to Disk Settings': 'MIR to Disk Settings',
          Settings: 'Settings',
          'Current MIR File Location': 'Current MIR File Location',
          'MIR File Location': 'MIR File Location',
          'File Expiry day': 'File Expiry day',
          Analysis: 'Analysis',
          Refund: 'Refund',
          'VOID/REFUND Type': 'VOID/REFUND Type',
          'VOID/REFUND Designated date': 'VOID/REFUND Designated date',
          None: 'None',
          'Ticket Number': 'Ticket Number',
          'Check Digit': 'Check Digit',
          'Airline Code': 'Airline Code',
          'Issue Date': 'Issue Date',
          'IATA Number': 'IATA Number',
          'Original Ticket Number': 'Original Ticket Number',
          'Passenger Name': 'Passenger Name',
          'Passenger Type': 'Passenger Type',
          'Standard Information': 'Standard Information',
          Payment: 'Payment',
          'S&#058;CASH': 'S:CASH',
          'CC&#058;CREDIT': 'CC:CREDIT',
          'CK&#058;CHECK': 'CK:CHECK',
          'X&#058;Difference adjustment': 'X:Difference adjustment',
          'EK&#058;Exchange issuance': 'EK:Exchange issuance',
          'MS&#058;Dedicated check': 'MS:Dedicated check',
          'CK&#058;Multiple designation': 'CK:Multiple designation',
          'Comm Amt': 'Comm Amt',
          'Comm Rate': 'Comm Rate',
          'Comm ConsumptionTax': 'Comm ConsumptionTax',
          'BSP payment amount': 'BSP payment amount',
          Recalculation: 'Recalculation',
          'Settlement method': 'Settlement method',
          'Fare Type': 'Fare Type',
          'Discount Adjustment Amount': 'Discount Adjustment Amount',
          '2A&#45;NET Amount': '2A-NET Amount',
          'Exclusion Control': 'Exclusion Control',
          Others: 'Others',
          Credit: 'Credit',
          'Additional Informations': 'Additional Informations',
          '(R) Accepting orders': '(R) Accepting orders',
          '(S) Section': '(S) Section',
          '(A) In Charge': '(A) In Charge',
          '(C) Client': '(C) Client',
          '(D) Direction': '(D) Direction',
          '(T) Tour': '(T) Tour',
          '(M) Deposit': '(M) Deposit',
          '(G) Order ID': '(G) Order ID',
          'Taxes Details': 'Taxes Details',
          'Card Code': 'Card Code',
          'Card Number': 'Card Number',
          'Name of the holder': 'Name of the holder',
          'Expiration Date': 'Expiration Date',
          'Approval Number': 'Approval Number',
          'Billing amount': 'Billing amount',
          Division: 'Division',
          'Classification Code(MK 2 digits)':
            'Classification Code(MK 2 digits)',
          'Classification Code(MK 14 digits)':
            'Classification Code(MK 14 digits)',
          'New Tkt - Gross': 'New Tkt - Gross',
          'Difference collection - Gross': 'Difference collection - Gross',
          'New Tkt - Tax': 'New Tkt - Tax',
          'Difference collection - Tax': 'Difference collection - Tax',
          'New Tkt - Tax Details': 'New Tkt - Tax Details',
          'Difference collection - Tax Details':
            'Difference collection - Tax Details',
          'Tkt to exchange for - TicketNumber':
            'Tkt to exchange for - TicketNumber',
          'Tkt to exchange for - Carrier': 'Tkt to exchange for - Carrier',
          'Tkt to exchange for - Place': 'Tkt to exchange for - Place',
          'Tkt to exchange for - IssueDate': 'Tkt to exchange for - IssueDate',
          'Tkt to exchange for - IATA': 'Tkt to exchange for - IATA',
          'Tkt to exchange for - Gross': 'Tkt to exchange for - Gross',
          'Tkt to exchange for - Tax': 'Tkt to exchange for - Tax',
          'Tkt to exchange for - Tax Details':
            'Tkt to exchange for - Tax Details',
          'Original Tkt - TicketNumber': 'Original Tkt - TicketNumber',
          'Original Tkt - Carrier': 'Original Tkt -  Carrier',
          'Original Tkt - Place': 'Original Tkt - Place',
          'Original Tkt - IssueDate': 'Original Tkt - IssueDate',
          'Original Tkt - IATA': 'Original Tkt - IATA',
          'Ticket face display': 'Ticket face display',
          'Authentication number display': 'Authentication number display',
          'IFD Issue Date': 'IFD Issue Date',
          'IFD Issue Time': 'IFD Issue Time',
          'CSV Issue Date': 'CSV Issue Date',
          'CSV Issue Time': 'CSV Issue Time',
          'URI Issue Date': 'URI Issue Date',
          'URI Issue Time': 'URI Issue Time',
          'Conjunction / Ticket Number': 'Conjunction / Ticket Number',
          'EMD Type': 'EMD Type',
          Arrange: 'Arrange',
          Quantity: 'Quantity',
          'Ticket Search': 'Ticket Search',
          Search: 'Search',
          'Search option': 'Search option',
          'Condition specification': 'Condition specification',
          Option: 'Option',
          'All Tickets': 'All Tickets',
          'IATA number specification': 'IATA number specification',
          'Plating Carrier specification': 'Plating Carrier specification',
          Carrier: 'Carrier',
          'Ticket list display': 'Ticket list display',
          'MIR settings': 'MIR settings',
          'Fare type code settings': 'Fare type code settings',
          'Fare Calculation Mode Indicator': 'Fare Calculation Mode Indicator',
          'BackUp Required': 'BackUp Required',
          'Import MIR From Disk': 'Import MIR From Disk',
          'Import MIR From Backup': 'Import MIR From Backup',
          'Ticket Details': 'Ticket Details',
          'Ticket List': 'Ticket List',
          Setting: 'Setting',
          Account: 'Account',
          'Sign Out': 'Sign Out',
          'Airline Number': 'Airline Number',
          'Airline Name': 'Airline Name',
          Decode: 'Decode',
          Delete: 'Delete',
          'Add Data': 'Add Data',
          ADD: 'ADD',
          'Update Airline Code': 'Update Airline Code',
          'Are you sure to update the settings':
            'Are you sure to update the settings',
          'Delete Airline Code': 'Delete Airline Code',
          'Are you sure to delete the settings':
            'Are you sure to delete the settings',
          'All Tickets                          ** If you specify an option, all that correspond to the specified option.':
            'All Tickets                          ** If you specify an option, all that correspond to the specified option.',
          Export: 'Export',
          'Only unexported data': 'Only unexported data',
          'Only Refund data': 'Only Refund data',
          'Only NDC data': 'Only NDC data',
          'not containing refunded EMD data':
            'not containing refunded EMD data',
          'Only EMD data': 'Only EMD data',
          'Search Tickets': 'Search Tickets',
          Save: 'Save',
          'Number of tickets': 'Number of tickets',
          'Fare Types': 'Fare Types',
          Airline: 'Airline',
          City: 'City',
          'City Code': 'City Code',
          Scheduler: 'Scheduler',
          'Update City Code': 'Update City Code',
          'Are you sure to update the City Code':
            'Are you sure to update the City Code',
          'Delete City Code': 'Delete City Code',
          'Are you sure to delete the City Code':
            'Are you sure to delete the City Code',
          'SAVE FARE TYPE': 'SAVE FARE TYPE',
          'Are you sure to save the Fare Type Setting(s)':
            'Are you sure to save the Fare Type Setting(s)',
          'FCMI Settings': 'FCMI Settings',
          'Save FCMI Type': 'Save FCMI Type',
          'Are you sure to save the FCMI Setting(s)':
            'Are you sure to save the FCMI Setting(s)',
          'Delete FCMI Setting': 'Delete FCMI Setting',
          'Are you sure to Delete the FCMI settings':
            'Are you sure to Delete the FCMI settings',
          'Add Code': 'Add Code',
          'Code Settings': 'Code Settings',
          'AirlineCode Settings': 'AirlineCode Settings',
          'Add Airline Code': 'Add Airline Code',
          Commission: 'Commission',
          'Concession Rate Commission': 'Concession Rate Commission',
          'Update Commission Code': 'Update Commission Code',
          'Are you sure to update Commission':
            'Are you sure to update Commission',
          'Update Scheduler Setting': 'Update Scheduler Setting',
          'Are you sure to update the Scheduler':
            'Are you sure to update the Scheduler',
          CLOSE: 'CLOSE',
          'for Import': 'for Import',
          'for Export': 'for Export',
          'Please Select': 'Please Select',
          Daily: 'Daily',
          Hourly: 'Hourly',
          'CheckingInterval (minutes)': 'CheckingInterval (minutes)',
          'ImportTimeOffset (minutes)': 'ImportTimeOffset (minutes)',
          'Select Time': 'Select Time',
          'Enable Schedule': 'Enable Schedule',
          Type: 'Type',
          '*Only files that have passed the set time will be imported':
            '*Only files that have passed the set time will be imported',
          'Error in login check it out!': 'Error in login check it out!',
          'View Details': 'View Details',
          'Response Message': 'Response Message',
          'Connection to server is lost. Please check API server':
            'Connection to server is lost. Please check API server',
          'Number of MIR files processed': 'Number of MIR files processed',
          'Number of PNRs processed': 'Number of PNRs processed',
          'Number of registered tickets': 'Number of registered tickets',
          succesfullImportedNewTickets: 'succesfullImportedNewTickets',
          successfulVoidedTickets: 'successfulVoidedTickets',
          successfulRefundedTickets: 'successfulRefundedTickets',
          successfulCancelRefundedTickets: 'successfulCancelRefundedTickets',
          succesfullImportedExchangeTickets:
            'succesfullImportedExchangeTickets',
          succesfullImportedEMDTickets: 'succesfullImportedEMDTickets',
          breakdown: 'breakdown',
          'Error files': 'Error files',
          duplicateWhenImportNewTickets: 'duplicateWhenImportNewTickets',
          duplicateWhenImportExchangeTickets:
            'duplicateWhenImportExchangeTickets',
          duplicateWhenImportEMDTickets: 'duplicateWhenImportEMDTickets',
          nonExistentToVoidTickets: 'nonExistentToVoidTickets',
          nonExistentToRefundTickets: 'nonExistentToRefundTickets',
          nonExistentToCancelRefundTickets: 'nonExistentToCancelRefundTickets',
          errorneousFiles: 'errorneousFiles',
          'Delete Ticket From The List': 'Delete Ticket From The List',
          'Are you sure to Delete the listed ticket':
            'Are you sure to Delete the listed ticket',
          'Delete Ticket from the Ticket details':
            'Delete Ticket from the Ticket details',
          'Are you sure to Delete the selected ticket':
            'Are you sure to Delete the selected ticket',
          Valid: 'Valid',
          Invalid: 'Invalid',
          NONE: 'NONE',
          'Export Ticket From The List': 'Export Ticket From The List',
          'Are you sure to Export the listed ticket':
            'Are you sure to Export the listed ticket',
          'Update Ticket Data': 'Update Ticket Data',
          'Are you sure to update ticket data':
            'Are you sure to update ticket data',
          'Are you sure want to delete the conjuction tickets':
            'Are you sure want to delete the conjuction tickets',
          UPDATE: 'UPDATE',
          'Delete Data': 'Delete Data',
          Code: 'Code',
          'City Name': 'City Name',
          'Airport Code': 'Airport Code',
          'City Code Settings': 'City Code Settings',
          'Fare Basis - After 2 digits': 'Fare Basis - After 2 digits',
          'Fare Basis - After 3 digits': 'Fare Basis - After 3 digits',
          'Carrier Code': 'Carrier Code',
          'Updated Successfully': 'Updated Successfully',
          'Tickets Deleted Successfully': 'Tickets Deleted Successfully',
          'Travelport GBS | Import MIR': 'Travelport GBS | Import MIR',
          'Travelport GBS | Import MIR from Backup':
            'Travelport GBS | Import MIR from Backup',
          'Travelport GBS | Ticket Details': 'Travelport GBS | Ticket Details',
          'Travelport GBS | Ticket List/Export':
            'Travelport GBS | Ticket List/Export',
          'Travelport GBS | Settings': 'Travelport GBS | Settings',
          'Travelport GBS | Sign in': 'Travelport GBS | Sign in',
          'Email is required': 'Email is required',
          'Password is required': 'Password is required',
          'Must be a valid email': 'Must be a valid email',
          Error: 'Error',
          'Sign in failed —': 'Sign in failed —',
          'Please check Email Address and Password':
            'Please check Email Address and Password',
          'No registered tickets': 'No registered tickets',
          'API Version Number': 'API Version Number',
          'Error unable to retrieve mir file information. Please check the folder exist.':
            'Error unable to retrieve mir file information. Please check the folder exist.',
          'Are you sure to Save the ticket': 'Are you sure to Save the ticket',
          'Are you sure to Delete the ticket':
            'Are you sure to Delete the ticket',
          'Save the Ticket details': 'Save the Ticket details',
          'The specified ticket data is successfully exported':
            'The specified ticket data is successfully exported',
          'Export of the specified ticket data failed':
            'Export of the specified ticket data failed',
          'The specified ticket data is successfully deleted':
            'The specified ticket data is successfully deleted',
          'Failed to export the specified ticket data':
            'Failed to export the specified ticket data',
          'Export Configuration': 'Export Configuration',
          'Export Folder': 'Export Folder',
          URIPro: 'URIPro',
          NewURIPro: 'NewURIPro',
          // NewURIPro: 'NewURIPro',
          // NewURIPRo3Digit: 'NewURIPRo3Digit',
          'Update Export Configuration': 'Update Export Configuration',
          'Are you sure to update Export Configuration':
            'Are you sure to update Export Configuration',
          'Error Message': 'Error Message',
          Conjuction: 'Conjuction',
          'Conjuction Tickets Deleted Successfully':
            'Conjuction Tickets Deleted Successfully',
          'Export folder path is not valid or or not exist':
            'Export folder path is not valid or or not exist',
          'Registration failure file exists':
            'Registration failure file exists',
          '* I you would like to change the output type NewURIPro / NewUriProFormat4DigitFlightNumber / UriProNewTest, please upgrade the API to version 2.0.01':
            '* I you would like to change the output type NewURIPro / NewUriProFormat4DigitFlightNumber / UriProNewTest, please upgrade the API to version 2.0.01',
          '* "URIPRo3Digit" has been changed to "NewURIPro" since UI Version 3.0.3':
            '* "URIPRo3Digit" has been changed to "NewURIPro" since UI Version 3.0.3',
          '* "NewURIPro" is an output format compatible with NDC, applicable for API version 3.0.0 or higher':
            '* "NewURIPro" is an output format compatible with NDC, applicable for API version 3.0.0 or higher'
        }
      },
      ja: {
        translations: {
          'Sign in': 'サインイン',
          'Sign in on the internal platform.':
            'インターナルプラットフォームにサインインします。',
          'Login with Facebook': 'Facebook でログイン',
          'Login with Google': 'Google でログイン',
          'or login with email address': 'または、メールアドレスでログイン',
          'Sign in now': 'サインイン',
          "Don't have an account?": 'アカウントをお持ちではないですか？',
          'Sign up': 'アカウントの作成',
          'Email Address': 'メールアドレス',
          Password: 'パスワード',
          Information: 'インフォメーション',
          'Files display option of MIR to DISK': 'MIR To DISKファイル表示指定',
          'No of files MIR To DISK': 'MIR To DISKファイル件数',
          'Number of files MIR': 'MIRファイル件数',
          'Storage folder to disk': 'MIR To DISK格納ホルダー',
          'No of Expired files MIR': '保有期限切れファイル件数',
          'No of expired file': 'ファイル保有期間',
          'Creating Backup Files': 'バックアップの作成',
          'Display by specifying the issue date': '発券日指定',
          'Display by specifying the creation date': '作成日指定',
          'Display specifying the issue date range': '発券日範囲指定',
          'Display specifying the creation date range': '作成日範囲指定',
          'Files display option of Import MIR to DISK':
            'MIRファイル表示範囲指定',
          'Display all files': '全てのファイル表示',
          'MIR To Disk file list': 'MIRファイル一覧',
          'The Selected files are ': '選択されたファイル数： ',
          Send: '登録',
          Language: 'Language',
          'IMPORT MIR': 'MIRデータ取り込み',
          'Are you sure to start import MIR file(s)':
            'MIRデータの取り込みを開始します。よろしいですか？',
          Start: 'OK',
          Cancel: 'キャンセル',
          'Select All': '全てを選択',
          Update: '更新',
          'MIR SETTINGS': 'MIRファイル指定',
          'Are you sure to update the  setting(s)?':
            '更新を行います。よろしいですか？',
          'MIR to Disk Settings': 'MIRファイル指定',
          'Current MIR File Location': 'MIR to Diskファイル　格納フォルダー',
          'MIR File Location': 'バックアップファイル　格納フォルダー',
          'File Expiry day': 'ファイル保有期限',
          Analysis: '解析処理',
          Refund: '払い戻し',
          'VOID/REFUND Type': 'VOID/REFUND 種別',
          'VOID/REFUND Designated date': 'VOID/REFUND 指定日',
          None: 'なし',
          'Ticket Number': 'チケット番号',
          'Check Digit': '',
          'Airline Code': '航空会社コード',
          'Issue Date': '発券日',
          'IATA Number': 'IATA番号',
          'Original Ticket Number': '親番号',
          'Passenger Name': '搭乗者名',
          'Passenger Type': '搭乗者タイプ',
          'Standard Information': '標準情報',
          Payment: '支払い',
          'S&#058;CASH': 'S:キャッシュ',
          'CC&#058;CREDIT': 'CC:クレジット',
          'CK&#058;CHECK': 'CK:小切手',
          'X&#058;Difference adjustment': 'X:差額調整',
          'EK&#058;Exchange issuance': 'EK:交換発行',
          'MS&#058;Dedicated check': 'MS:専用小切手',
          'CK&#058;Multiple designation': 'CK:（複数指定）',
          'Comm Amt': 'コミッション額',
          'Comm Rate': 'Comm Rate',
          'Comm ConsumptionTax': 'コミッション消費税',
          'BSP payment amount': 'BSP支払い額',
          Recalculation: '再計算',
          'Settlement method': '精算方式',
          'Fare Type': '運賃種別',
          'Discount Adjustment Amount': '幅調整額',
          '2A&#45;NET Amount': '2A-NET精算',
          'Exclusion Control': '排他制御',
          Others: 'その他',
          Credit: 'クレジット',
          'Additional Informations': '追加情報',
          '(R) Accepting orders': '(R) 受注',
          '(S) Section': '(S) セクション',
          '(A) In Charge': '(A) 担当',
          '(C) Client': '(C) 顧客',
          '(D) Direction': '(D) 方面',
          '(T) Tour': '(T) ツアー',
          '(M) Deposit': '(M) 入金',
          '(G) Order ID': '(G) オーダーID',
          'Taxes Details': 'TAX明細',
          'Card Code': 'クレジット会社',
          'Card Number': 'カード番号',
          'Name of the holder': 'カード名義人氏名',
          'Expiration Date': 'カード有効期限',
          'Approval Number': 'カード承認番号',
          'Billing amount': '請求金額',
          Division: '分割回数',
          'Classification Code(MK 2 digits)': '機能分類コード(MK2桁)',
          'Classification Code(MK 14 digits)': '機能分類コード(MK14桁)',
          'New Tkt - Gross': '新券 - Gross',
          'Difference collection - Gross': '差額徴収額 - Gross',
          'New Tkt - Tax': '新券 - Tax',
          'Difference collection - Tax': '差額徴収額 - Tax',
          'New Tkt - Tax Details': '新券 - Tax明細',
          'Difference collection - Tax Details': '差額徴収額 - Tax明細',
          'Tkt to exchange for - TicketNumber': '元券 - チケット番号',
          'Tkt to exchange for - Carrier': '元券 - 航空会社',
          'Tkt to exchange for - Place': '元券 - 発行地',
          'Tkt to exchange for - IssueDate': '元券 - 発行日',
          'Tkt to exchange for - IATA': '元券 - IATA',
          'Tkt to exchange for - Gross': '元券 - Gross',
          'Tkt to exchange for - Tax': '元券 - Tax',
          'Tkt to exchange for - Tax Details': '元券 - Tax明細',
          'Original Tkt - TicketNumber': '原券 - チケット番号',
          'Original Tkt - Carrier': '原券 - 航空会社',
          'Original Tkt - Place': '原券 - 発行地',
          'Original Tkt - IssueDate': '原券 - 発行日',
          'Original Tkt - IATA': '原券 - IATA',
          'Ticket face display': '券面表示',
          'Authentication number display': '認証番号表示',
          'IFD Issue Date': 'IFD出力日',
          'IFD Issue Time': 'IFD出力時間',
          'CSV Issue Date': 'CSV出力日',
          'CSV Issue Time': 'CSV出力時間',
          'URI Issue Date': 'URI出力日',
          'URI Issue Time': 'URI出力時間',
          'Conjunction / Ticket Number': 'コンジャンクション / チケット番号',
          'EMD Type': 'EMD種別',
          Arrange: '手配',
          Quantity: '個数',
          'Ticket Search': 'チケット検索範囲指定',
          Search: '検索',
          'Search option': '検索オプション',
          'Condition specification': '条件設定',
          Option: 'オプション設定',
          'All Tickets        ** If you specify an option, all that correspond to the specified option.':
            '全てのチケット        ** オプション指定を行った場合、指定オプションに該当する全て',
          'IATA number specification': 'IATA番号指定',
          'Plating Carrier specification': '発行航空会社指定',
          Carrier: '航空会社',
          'Ticket list display': '指定チケット確認',
          'MIR Settings': 'MIR登録',
          'Fare type code settings': '運賃種別コード設定',
          'BackUp Required': 'バックアップの作成を行う',
          'Import MIR From Disk': 'MIR登録',
          'Import MIR From Backup': 'MIR登録 from Backup',
          'Ticket Details': 'チケット情報修正',
          'Ticket List': 'チケットリスト/出力',
          Settings: 'GBS設定',
          Account: 'アカウント',
          'Sign Out': 'サインアウト',
          'Airline Number': '番号',
          'Code Settings': 'コード設定',
          'Airline Name': '航空会社名',
          Decode: 'Decode情報',
          Delete: '削除',
          'Add Data': '追加',
          ADD: '追加',
          'Update Airline Code': '航空会社情報 - 更新',
          'Are you sure to update the settings':
            '設定を変更します。よろしいですか？',
          'Delete Airline Code': '航空会社情報 - 削除',
          'Are you sure to delete the settings':
            '情報を削除します。よろしいですか？',
          'All Tickets': '全てのチケット',
          Export: '出力',
          'Only unexported data': '未出力データのみ',
          'Only Refund data': 'リファンドのみ',
          'Only NDC data': 'NDCのみ',
          'not containing refunded EMD data': 'リファンドEMDは含まない',
          'Only EMD data': 'EMDのみ',
          'Search Tickets': 'チケット検索 範囲指定',
          Save: '更新',
          'Number of tickets': '該当件数',
          'Fare Types': '運賃種別識別コード',
          Airline: '航空会社',
          City: 'フライト区間判別',
          'City Code': '都市コード',
          Scheduler: 'タイマー設定',
          'Update City Code': 'コード - 更新',
          'Are you sure to update the City Code':
            'コードを更新します。よろしいですか？',
          'Delete City Code': 'コード - 削除',
          'Are you sure to delete the City Code':
            'コードを削除します。よろしいですか？',
          'SAVE FARE TYPE': '運賃種別コード - 更新',
          'Are you sure to save the Fare Type Setting(s)':
            '運賃種別コードを更新します。よろしいですか？',
          'FCMI Settings': 'FCMI - 設定',
          'Save FCMI Type': 'FCMI - 更新',
          'Are you sure to save the FCMI Setting(s)':
            'FCMIを更新します。よろしいですか？',
          'Delete FCMI Setting': 'FCMI - 削除',
          'Are you sure to Delete the FCMI settings':
            'FCMIを削除します。よろしいですか？',
          'Add Code': 'コード - 追加',
          'AirlineCode Settings': '航空会社設定',
          'Add Airline Code': '航空会社情報 - 追加',
          Commission: '消費税',
          'Concession Rate Commission': '消費税率',
          'Update Commission Code': '消費税率 - 更新',
          'Are you sure to update Commission':
            '消費税率を更新します。よろしいですか？',
          'Update Scheduler Setting': '実行日時の設定',
          'Are you sure to update the Scheduler':
            '実行日時を更新します。よろしいですか？',
          CLOSE: '閉じる',
          'for Import': 'データ取込タイマー',
          'for Export': 'データ出力タイマー',
          'Please Select': '選択してください',
          Daily: '毎日',
          Hourly: '毎時',
          'CheckingInterval (minutes)': 'インターバル (分)',
          'ImportTimeOffset (minutes)': 'ファイル作成経過（分）',
          'Select Time': '時間選択',
          'Enable Schedule': '実行日時設定を有効にする',
          Type: 'タイプ',
          '*Only files that have passed the set time will be imported':
            '*設定した時間を経過したファイルのみインポート対象',
          'View Details': '詳細を確認',
          'Response Message': 'メッセージ',
          'Connection to server is lost. Please check API server':
            '接続に失敗しました。 サーバへの接続状況をご確認ください。',
          'Number of MIR files processed': '処理MIRファイル数',
          'Number of PNRs processed': '処理PNR総数',
          'Number of registered tickets': '登録チケット枚数',
          succesfullImportedNewTickets: 'NEW チケット枚数',
          successfulVoidedTickets: 'VOID チケット枚数',
          successfulRefundedTickets: 'REFUND チケット枚数',
          successfulCancelRefundedTickets: 'REFUND CANCEL チケット枚数',
          succesfullImportedExchangeTickets: 'EXCHANGE チケット枚数',
          succesfullImportedEMDTickets: 'EMD チケット枚数',
          breakdown: '内訳',
          'Error files': 'エラー詳細',
          duplicateWhenImportNewTickets: '登録済 チケット枚数',
          duplicateWhenImportExchangeTickets: '登録済 EXCHANGE チケット枚数',
          duplicateWhenImportEMDTickets: '登録済 EMD チケット枚数',
          nonExistentToVoidTickets: 'VOID チケット該当なし',
          nonExistentToRefundTickets: 'REFUND チケット該当なし',
          nonExistentToCancelRefundTickets: 'REFUND CANCEL チケット該当なし',
          errorneousFiles: 'DECODE失敗',
          'Delete Ticket From The List': 'チケット情報削除',
          'Are you sure to Delete the listed ticket':
            '表示されているチケットを削除します。よろしいですか？',
          'Delete Ticket from the Ticket details': 'チケット情報削除',
          'Are you sure to Delete the selected ticket':
            '表示されているチケットを削除します。よろしいですか？',
          Valid: '有効',
          Invalid: '無効',
          NONE: 'なし',
          'Export Ticket From The List': 'チケット情報出力',
          'Are you sure to Export the listed ticket':
            '表示されているチケットを出力します。よろしいですか？',
          'Update Ticket Data': 'チケット情報更新',
          'Are you sure to update ticket data':
            '表示されているチケットを更新します。よろしいですか？',
          'Are you sure want to delete the conjuction tickets':
            'コンジャンクションチケットです。まとめて削除します。よろしいですか？',
          UPDATE: '更新',
          'Delete Data': '削除',
          Code: 'コード',
          'City Name': '都市名',
          'Airport Code': '空港コード',
          'City Code Settings': 'コード設定',
          'Fare Basis - After 2 digits': 'Fare Basis - 2桁以降',
          'Fare Basis - After 3 digits': 'Fare Basis - 3桁以降',
          'Carrier Code': '2レター',
          'Updated Successfully': '更新完了しました',
          'Tickets Deleted Successfully': 'チケット情報が削除されました',
          'Travelport GBS | Import MIR': 'Travelport GBS | MIR登録',
          'Travelport GBS | Import MIR from Backup':
            'Travelport GBS | MIR登録 from Backup',
          'Travelport GBS | Ticket Details': 'Travelport GBS | チケット情報',
          'Travelport GBS | Ticket List/Export':
            'Travelport GBS | チケットリスト/出力',
          'Travelport GBS | Settings': 'Travelport GBS | GBS設定',
          'Travelport GBS | Sign in': 'Travelport GBS | サインイン',
          'Email is required': 'メールアドレスを入力してください',
          'Password is required': 'パスワードを入力してください',
          'Must be a valid email': 'メールアドレスが有効ではありません',
          Error: 'エラー',
          'Sign in failed —': 'サインインエラー —',
          'Please check Email Address and Password':
            'メールアドレスまたはパスワードに誤りがあります',
          'No registered tickets': '登録されているチケットはありません',
          'API Version Number': 'バージョン情報',
          'Error unable to retrieve mir file information. Please check the folder exist.':
            '【 GBS設定 】からMIRファイル格納フォルダーの設定を行ってください。【 GBS設定 】はこちら　→',
          'Are you sure to Save the ticket':
            'チケット情報を更新します。よろしいですか？',
          'Are you sure to Delete the ticket':
            'チケット情報を削除します。よろしいですか？',
          'Save the Ticket details': 'チケット情報の更新',
          'The specified ticket data is successfully exported':
            '指定したチケット情報は正常に出力されました',
          'Export of the specified ticket data failed':
            '指定したチケットデータの出力に失敗しました',
          'The specified ticket data is successfully deleted':
            '指定したチケット情報は正常に削除されました',
          'Failed to export the specified ticket data':
            '指定したチケットデータの削除に失敗しました',
          'Export Configuration': '出力設定',
          'Export Folder': '出力フォルダー',
          URIPro: 'URIPro形式',
          NewURIPro: '新URIPro形式',
          // NewURIPro: '新URIPro形式',
          // NewURIPRo3Digit: '新URIPRo形式(便名 3桁)',
          'Update Export Configuration': '出力フォルダー更新',
          'Are you sure to update Export Configuration':
            '出力するフォルダーを更新します。よろしいですか？',
          'Error Message': 'エラーメッセージ',
          Conjuction: 'コンジャンクション',
          'Conjuction Tickets Deleted Successfully':
            'コンジャンクションのチケット情報が削除されました',
          'Export folder path is not valid or or not exist':
            '出力するフォルダーが有効でない、または存在しません',
          'Registration failure file exists': '登録失敗ファイルあり',
          '* I you would like to change the output type NewURIPro / NewUriProFormat4DigitFlightNumber / UriProNewTest, please upgrade the API to version 2.0.01':
            '* 出力タイプ 新URIPRo形式/新URIPRo形式(便名 3桁)/UriProTestをご利用の場合は、APIバージョン2.0.01以上をご利用ください。',
          '* "URIPRo3Digit" has been changed to "NewURIPro" since UI Version 3.0.3':
            '* 「URIPRo形式(便名 3桁)」はUI Version 3.0.3より「新URIPro形式」へ変更になりました。',
          '* "NewURIPro" is an output format compatible with NDC, applicable for API version 3.0.0 or higher':
            '* 「新URIPro形式」はNDCに対応した出力フォーマットです。APIバージョン3.0.0以上で適用されます。'
        }
      }
    },
    lng: 'ja',
    fallbackLng: 'ja',
    debug: true,

    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
