import React from 'react';
// import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import {
  Box,
  // Container,
  Button,
  Card,
  Typography,
  CardContent,
} from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/styles';
import {
  useMutation
} from 'react-query';
import { sendUriProVersion2016Export, deleteTicketListV2 } from 'src/api/api';
import { useTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar';
import CircularProgress from '@material-ui/core/CircularProgress';
import Pagination from 'src/components/common/Pagination';

import StatusMessage from '../common/StatusMessage';

const useStyles = makeStyles({
  subTitle: {
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontWeight: 'bold',
  },
  scrollbox: {
    width: 1040,
    height: 380,
  },
  scrollstyle: {
    height: 380,
    marginTop: 5,
  },
  mainTitle2: {
    fontSize: '20px',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontWeight: 'normal',
    color: '#0A121A'
  },
  commonBtn: {
    width: '120px'
  }
});

const listStyle = {
  listStyleType: 'none'

};
const TicketList = (props) => {
  const { row, onTicketSelected, selectedTickets } = props;

  const onChecked = (event, data) => {
    if (event.target.checked === true) {
      onTicketSelected(data.tktnumber, true);
    }
    if (event.target.checked === false) {
      onTicketSelected(data.tktnumber, false);
    }
  };

  const isConjuctionAndChildTicket = row.isConjuctionTicket && row.isChildrenTicket;
  return (
    <>
      <TableRow>

        <input
          type="hidden"
          id={`${row.tktnumber}`}
          name={row.tktnumber}
          checked={selectedTickets.includes(row.tktnumber)}
          onChange={(e) => onChecked(e, row)}
        />
        <TableCell style={{ width: 140, textAlign: 'center', padding: 5 }} scope="row">
          {isConjuctionAndChildTicket ? `-${row.tktnumber}` : row.tktnumber}

        </TableCell>
        <TableCell style={{ width: 120, textAlign: 'center', padding: 10 }}>{isConjuctionAndChildTicket ? '' : row.tktstatus}</TableCell>
        <TableCell style={{ width: 80, textAlign: 'center', padding: 10 }}>{isConjuctionAndChildTicket ? '' : row.tktaircode}</TableCell>
        <TableCell style={{ width: 130, textAlign: 'center', padding: 10 }}>{isConjuctionAndChildTicket ? '' : row.tktrecloc}</TableCell>
        <TableCell style={{ width: 260, textAlign: 'left', padding: 10 }}>{isConjuctionAndChildTicket ? '' : row.tktpassenger}</TableCell>
        <TableCell style={{ width: 170, textAlign: 'center', padding: 10 }}>{isConjuctionAndChildTicket ? '' : row.tktpassengertype}</TableCell>
        <TableCell style={{ width: 120, textAlign: 'center', padding: 10 }}>{isConjuctionAndChildTicket ? '' : row.tktiatanum}</TableCell>
        <TableCell style={{ width: 120, textAlign: 'center', padding: 10 }}>{isConjuctionAndChildTicket ? '' : row.tktissuedate}</TableCell>
      </TableRow>
    </>
  );
};

TicketList.propTypes = {
  row: PropTypes.shape({
    otherticketnumbers: PropTypes.string.isRequired,
    tktnumber: PropTypes.string.isRequired,
    isConjuctionTicket: PropTypes.bool.isRequired,
    isChildrenTicket: PropTypes.bool.isRequired,
    tktrecloc: PropTypes.string.isRequired,
    tktstatus: PropTypes.string.isRequired,
    tktaircode: PropTypes.string.isRequired,
    tktiatanum: PropTypes.string.isRequired,
    tktissuedate: PropTypes.string.isRequired,
    tktpassenger: PropTypes.string.isRequired,
    tktpassengertype: PropTypes.string.isRequired,
  }).isRequired,
  selectedTickets: PropTypes.arrayOf(PropTypes.string).isRequired,
  onTicketSelected: PropTypes.func,
};

const TicketListResult = (props) => {
  const {
    tickets, onTicketSelected, selectedTickets, totalNumberOfTickets, onPageChange, currentPage,
    searchParameter, onDeleteComplete
  } = props;

  const DEFAULT_PAGE_SIZE = 20;

  const changePage = (event, value) => onPageChange(value);
  const [toBeDeleted, setToBeDeleted] = React.useState(false);
  const [toBeExported, setToBeExported] = React.useState(false);
  const [messageResponse, setMessageForResponse] = React.useState('');
  const [messageResponseStatus, setMessageForResponseStatus] = React.useState('');
  // const [openResponseDialog, setOpenResponseDialog] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [openLoadingModal, setOpenLoadingModal] = React.useState(false);
  const classes = useStyles();
  const { t } = useTranslation();

  const useExportUriPro = useMutation(
    () => {
      const payload = {
        IataNumber: searchParameter.filter.iataNumber,
        Carrier: searchParameter.filter.carrier,
        IsEmd: searchParameter.filter.isEmd,
        IsRefund: searchParameter.filter.isRefund,
        IsExported: searchParameter.filter.isExported,
        StartIssueDate: searchParameter.filter.startDateTime,
        EndIssueDate: searchParameter.filter.endDateTime,
        StartTicketNumber: searchParameter.filter.StartTicketNumber,
        EndTicketNumber: searchParameter.filter.EndTicketNumber
      };
      return sendUriProVersion2016Export(payload);
    },
    {
      onSuccess: (r) => {
        setOpen(true);
        // setOpenResponseDialog(true); // open the response
        setOpenLoadingModal(false);
        // console.log(r);
        setMessageForResponse(r.data.ticketExportResultStatus === 'Success' ? t('The specified ticket data is successfully exported') : r.data.ticketExportResultStatus);
        setMessageForResponseStatus(r.data.fileName);
      },
      OnError: (r) => {
        setOpen(true);
        //  setOpenResponseDialog(true); // open the response
        setMessageForResponse(r.data.ticketExportResultStatus !== 'Success' ? t('Export of the specified ticket data failed') : r.data.ticketExportResultStatus);
        setMessageForResponseStatus('');
      },
    }
  );

  const deleteTicketsFromList = useMutation(
    () => {
      const payload = {
        IataNumber: searchParameter.filter.iataNumber,
        Carrier: searchParameter.filter.carrier,
        IsEmd: searchParameter.filter.isEmd,
        IsRefund: searchParameter.filter.isRefund,
        IsExported: searchParameter.filter.isExported,
        StartIssueDate: searchParameter.filter.startDateTime,
        EndIssueDate: searchParameter.filter.endDateTime,
        StartTicketNumber: searchParameter.filter.StartTicketNumber,
        EndTicketNumber: searchParameter.filter.EndTicketNumber
      };
      return deleteTicketListV2(payload);
    },
    {
      onSuccess: (r) => {
        setOpen(true); // open the response
        setOpenLoadingModal(false);
        setMessageForResponse(r.data.deleteTicketResultStatus === 'Ok' ? t('The specified ticket data is successfully deleted') : r.data.deleteTicketResultStatus);
        setMessageForResponseStatus('');
      },
      OnError: (r) => {
        setOpen(true); // open the response
        setOpenLoadingModal(false);
        setMessageForResponse(r.data.deleteTicketResultStatus !== 'Ok' ? t('Failed to export the specified ticket data') : r.data.deleteTicketResultStatus);
        setMessageForResponseStatus('');
      },

      // refetchOnWindowFocus: true
    }
  );

  const handleSubmit = () => {
    setOpenLoadingModal(true);
    useExportUriPro.mutate();
    selectedTickets.includes(null);
    setToBeExported(false);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
    onDeleteComplete();
  };
  const handleDelete = () => {
    setOpenLoadingModal(true); // open loading
    deleteTicketsFromList.mutate();
    // setOpen(true);
    // setOpenDeleteDialog(true); // open the response
    setToBeDeleted(false);
  };
  const handleClosDeleteDialog = () => {
    setToBeDeleted(false);
  };

  const handleClickOpenDialog = () => {
    setToBeDeleted(true);
  };

  const handleClickExportOpenDialog = () => {
    setToBeExported(true);
  };

  const handleCloseExportDialog = () => {
    setToBeExported(false);
  };

  const handleCloseLoadingModel = () => {
  };

  const DialogLoadingStyle = {
    '& .MuiDialog-paper': {
      color: '#0A121A',
      padding: '10px',
      backgroundColor: '#ececec',
      maxWidth: '300px'
    },
  };

  // const makeSetMessage = (messageResponse === 'Success') ? t('Successfully exported') : t('Export failed');

  const setMessage = `${messageResponse} \n ${messageResponseStatus}`;
  const statusMessageDialog = (
    <div>
      <StatusMessage
        open={open}
        title={t('Response Message')}
        // message={setMessage}
        message={setMessage.split('\n').map((m) => (<div>{m}</div>))}
      >
        <Button color="primary" onClick={handleClose}>
          {t('CLOSE')}
        </Button>
      </StatusMessage>
    </div>
  );

  const deleteTicketListSettings = (
    <div>
      <Dialog
        open={toBeDeleted}
        onClose={handleClosDeleteDialog}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle
          style={{ cursor: 'move' }}
          id="draggable-dialog-title"
        >
          {t('Delete Ticket From The List')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('Are you sure to Delete the listed ticket')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDelete} color="primary">
            OK
          </Button>
          <Button autoFocus onClick={handleClosDeleteDialog} color="primary">
            {t('Cancel')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );

  const exportTicketList = (
    <div>
      <Dialog
        open={toBeExported}
        onClose={handleCloseExportDialog}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle
          style={{ cursor: 'move' }}
          id="draggable-dialog-title"
        >
          {t('Export Ticket From The List')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('Are you sure to Export the listed ticket')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit} color="primary">
            OK
          </Button>
          <Button autoFocus onClick={handleCloseExportDialog} color="primary">
            {t('Cancel')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );

  const displayLoading = (
    <Modal
      open={openLoadingModal}
      onClose={handleCloseLoadingModel}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <Dialog
        sx={DialogLoadingStyle}
        open={openLoadingModal}
        // aria-labelledby="draggable-dialog-title"
      >
        <DialogContent>
          <Card style={{ justifyContent: 'center' }}>
            <CircularProgress style={{
              marginTop: 10, marginBottom: 5, marginLeft: 60
            }}
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Now Processing....
              </Typography>
            </CardContent>
          </Card>
        </DialogContent>
      </Dialog>
    </Modal>
  );
  const ticketList = tickets ? tickets.map((l) => (
    <TicketList key={l.tktnumber} row={l} onTicketSelected={onTicketSelected} selectedTickets={selectedTickets} />
  )) : null;

  const buttonEnable = !(ticketList);

  return (
    <div>
      <Card style={{ clear: 'left' }}>
        <CardContent style={{ padding: 0 }}>
          <div style={{
            float: 'left', paddingTop: 15, paddingBottom: 10, paddingLeft: 10
          }}
          >
            <Typography variant="body2" color="text.secondary">
              <div>
                <span className={classes.mainTitle2}>
                  {t('Number of tickets')}
                  {'     :    '}
                  <span style={{ fontWeight: 'bold' }}>{totalNumberOfTickets}</span>
                </span>
              </div>
            </Typography>
          </div>
          <div style={{
            float: 'right', paddingTop: 10, paddingBottom: 10, paddingRight: 22
          }}
          >
            <Box>
              {/* <Container maxWidth={false}> */}
              <Button
                className={classes.commonBtn}
                variant="contained"
                color="primary"
                disabled={buttonEnable}
                onClick={handleClickOpenDialog}
              >
                {t('Delete')}
              </Button>
              <Button
                style={{ marginLeft: 10 }}
                className={classes.commonBtn}
                variant="contained"
                color="primary"
                disabled={buttonEnable}
                onClick={handleClickExportOpenDialog}
              >
                {t('Export')}
              </Button>
              {/* </Container> */}
            </Box>
          </div>
        </CardContent>
        <Box color="primary.main" border={0}>
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  {/* <TableCell width={80} align="left"><span className={classes.subTitle}>Select</span></TableCell> */}
                  <TableCell style={{ width: 130, textAlign: 'center', padding: 5 }}><span className={classes.subTitle}>Ticket Number</span></TableCell>
                  <TableCell style={{ width: 100, textAlign: 'center', padding: 5 }}><span className={classes.subTitle}>Status</span></TableCell>
                  <TableCell style={{ width: 90, textAlign: 'center', padding: 5 }}><span className={classes.subTitle}>Airline</span></TableCell>
                  <TableCell style={{ width: 90, textAlign: 'center', padding: 5 }}><span className={classes.subTitle}>Recloc</span></TableCell>
                  <TableCell style={{ width: 230, textAlign: 'center', padding: 5 }}><span className={classes.subTitle}>Passenger Name</span></TableCell>
                  <TableCell style={{ width: 150, textAlign: 'center', padding: 5 }}><span className={classes.subTitle}>Passenger Type</span></TableCell>
                  <TableCell style={{ width: 110, textAlign: 'center', padding: 5 }}><span className={classes.subTitle}>IATA</span></TableCell>
                  <TableCell style={{
                    textAlign: 'left', paddingTop: 5, paddingBottom: 5, paddingLeft: 10
                  }}
                  >
                    <span className={classes.subTitle}>Issue Date</span>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>

                <TableRow>
                  <TableCell colSpan={8} style={{ padding: 0 }}>
                    <div className={classes.scrollbox}>
                      <ul style={listStyle}>
                        <PerfectScrollbar className={classes.scrollstyle}>
                          <Table style={{ width: 1000 }}>
                            <TableBody>
                              {ticketList}
                            </TableBody>
                          </Table>
                        </PerfectScrollbar>
                      </ul>
                    </div>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <div style={{ padding: 10 }}>
            <Pagination
              itemCount={totalNumberOfTickets}
              itemsPerPage={DEFAULT_PAGE_SIZE}
              onPageChange={changePage}
              currentPage={currentPage}
              pageCount={Math.ceil(totalNumberOfTickets / DEFAULT_PAGE_SIZE)}
            />
          </div>
        </Box>
        {deleteTicketListSettings}
        {statusMessageDialog}
        {displayLoading}
        {exportTicketList}
      </Card>
    </div>
  );
};

TicketListResult.propTypes = {
  tickets: PropTypes.array.isRequired,
  totalNumberOfTickets: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  selectedTickets: PropTypes.arrayOf(PropTypes.string).isRequired,
  onTicketSelected: PropTypes.func,
  onPageChange: PropTypes.func.isRequired,
  onDeleteComplete: PropTypes.func.isRequired,
  searchParameter: PropTypes.object.isRequired,
};

export default TicketListResult;
