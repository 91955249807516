import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar';
// import InfoIcon from '@material-ui/icons/Info';

import {
  Checkbox, FormControlLabel, Box, Card, CardHeader, Divider,
  CardContent, Grid, Button, Dialog, DialogContent, DialogActions, DialogTitle, DialogContentText
} from '@material-ui/core';

export default function MirToDiskListResults(props) {
  const {
    files, selectedFiles, isSelectAll, onSelectedFilesChange, onSelectAllChange,
    useSubmitSelectedFilesMutation, updateStatusMessage, importResultModal, openDialog, onCloseDialog, onhandleSubmit, onClickOpenDialog, displayLoading, IsImportBackup
  } = props;

  const listStyle = {
    listStyleType: 'none'

  };
  const handleChange = (file, event) => {
    onSelectedFilesChange(file, event.target.checked);
  };
  const selectAll = (value) => {
    onSelectAllChange(value.target.checked);
  };
  const useStyles = makeStyles({
    mainTitle: {
      paddingLeft: '10px',
      fontSize: '20px',
      fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
      fontWeight: 'bold',
      color: '#0A121A'
    },
    subTitle: {
      paddingLeft: '0px',
      fontSize: '14px',
      fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
      fontWeight: 'nomal',
      color: '#0A121A'
    },
    scrollbox: {
      float: 'left',
      marginTop: 10,
      marginLeft: 20,
      marginBottom: 15,
      width: 360,
      height: 380,
      border: '1px',
      borderStyle: 'solid',
      borderColor: 'rgb(133,133,133)',
      borderRadius: 5
    },
    scrollstyle: {
      width: 350,
      height: 360,
      // marginLeft: 10,
      marginTop: 10,
      marginBottom: 10,
      paddingLeft: 10,

    },
    labelRoot: {
      fontSize: '14px'
    }
  });
  const classes = useStyles();
  const { t } = useTranslation();
  const menuItems = () => (files && files.length !== 0
    ? files.map((file, index) => {
      const isChecked = selectedFiles ? selectedFiles.findIndex((s) => s.id === file.id) > -1 : false;
      const setLavel = (IsImportBackup === 'true') ? `${file.name}` : `${file.name} ${moment(file.dateTime).format('l h:mm:ss a')}`;
      return (
        <li key={file.id}>
          <FormControlLabel
            value={file.name}
            control={(
              <Checkbox
                id={`${file.id}`}
                checked={isChecked}
                name={file.name}
              />
            )}
            label={<span className={classes.labelRoot}>{setLavel}</span>}
            onChange={(e) => handleChange(file, e, index)}
            labelPlacement="end"
          />
        </li>
      );
    })
    : null);

  return (
    <Card style={{ clear: 'left' }}>
      <CardHeader
        style={{ padding: '10px' }}
        title={t('MIR To Disk file list')}
        titleTypographyProps={{
          fontWeight: 'bold', fontSize: '16px', fontFamily: 'Roboto, Helvetica, Arial, sans-serif', color: '#0A121A'
        }}
      />
      <Divider />
      <Box color="primary.main" border={0}>
        <div className={classes.scrollbox}>
          <ul style={listStyle}>
            <PerfectScrollbar className={classes.scrollstyle}>
              {menuItems()}
            </PerfectScrollbar>
          </ul>
        </div>
        <div style={{
          float: 'left', marginLeft: 10, marginTop: 10, width: 400
        }}
        >
          <CardContent>
            <Grid
              container
              spacing={2}
              sx={{ marginBottom: '0px' }}
            >
              <Grid
                item
                md={12}
                sm={12}
              >
                <FormControlLabel
                  control={(
                    <Checkbox
                      type="checkbox"
                      name="selectAll"
                      id="selectAll"
                      checked={isSelectAll}
                    />
                  )}
                  label={<span className={classes.labelRoot}>{t('Select All')}</span>}
                  onChange={(event) => selectAll(event)}
                  labelPlacement="end"
                />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              sx={{ marginBottom: '0px' }}
            >
              <Grid
                item
                md={12}
                sm={12}
                sx={{ marginTop: '20px' }}
              >
                <span className={classes.subTitle}>
                  {t('The Selected files are ')}
                </span>
                <span className={classes.mainTitle}>{selectedFiles.length}</span>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              sx={{ marginBottom: '0px' }}
            >
              <Grid
                item
                md={12}
                sm={12}
              >
                {!useSubmitSelectedFilesMutation.isLoading
                  ? updateStatusMessage
                  : null}
                {selectedFiles.length > 0
                  ? (
                    <Button
                      style={{ marginTop: 35, marginLeft: 35, width: 120 }}
                      variant="contained"
                      color="primary"
                      onClick={onClickOpenDialog}
                    >
                      {t('Send')}
                    </Button>
                  ) : null}
                {displayLoading}
                {importResultModal}

                <Dialog
                  open={openDialog}
                  onClose={onCloseDialog}
                  aria-labelledby="draggable-dialog-title"
                >
                  <DialogTitle
                    style={{ cursor: 'move', fontWeight: 'bold' }}
                    id="draggable-dialog-title"
                  >
                    {t('IMPORT MIR')}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      {t('Are you sure to start import MIR file(s)')}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={onhandleSubmit} color="primary">
                      {t('Start')}
                    </Button>
                    <Button autoFocus onClick={onCloseDialog} color="primary">
                      {t('Cancel')}
                    </Button>
                  </DialogActions>
                </Dialog>
              </Grid>
            </Grid>
          </CardContent>
        </div>
      </Box>
    </Card>
  );
}
MirToDiskListResults.propTypes = {
  files: PropTypes.arrayOf(PropTypes.object),
  selectedFiles: PropTypes.arrayOf(PropTypes.object),
  onSelectedFilesChange: PropTypes.func,
  onSelectAllChange: PropTypes.func,
  isSelectAll: PropTypes.bool,
  // selectedFiles: PropTypes.arrayOf(PropTypes.object)
  useSubmitSelectedFilesMutation: PropTypes.arrayOf(PropTypes.object),
  updateStatusMessage: PropTypes.arrayOf(PropTypes.object),
  importResultModal: PropTypes.arrayOf(PropTypes.object),
  openDialog: PropTypes.bool,
  onCloseDialog: PropTypes.func,
  onhandleSubmit: PropTypes.func,
  onClickOpenDialog: PropTypes.func,
  displayLoading: PropTypes.object,
  IsImportBackup: PropTypes.string
};
