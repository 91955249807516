import { Helmet } from 'react-helmet';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { useTheme, colors, Container } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import React from 'react';
import PropTypes from 'prop-types';
import SettingsView from 'src/components/settings/SettingsView';
import FareTypeSettings from 'src/components/settings/FareTypeSettings';
import FareCalculationSettings from 'src/components/settings/FareCalculationSettings';
import AirlineCodeTable from 'src/components/settings/AirLineCodeTable';
import CityCodeSettings from 'src/components/settings/CityCodeSettings';
import CommissionSettings from 'src/components/settings/CommissionSettings';
import SchedulerSettings from 'src/components/settings/SchedulerSettings';
import ExportSettings from 'src/components/settings/ExportSettings';
import VersionSettings from 'src/components/settings/VersionSettings';
import {
  getAirlineCodeConfigurations, getFareTypeConfigurations, getFCMIConfigurations, getCityCodeConfigurations,
  getExportConfiguration, getCommissionRate, getSchedulerConfig, healthCheck, getAPIVersionNumber
} from 'src/api/api';
import {
  QueryClient, QueryClientProvider, useQuery
} from 'react-query';
import ProgressBar from 'src/components/common/ProgressBar';
// import { Navigate } from 'react-router';
import { useTranslation } from 'react-i18next';

import NetworkErrorPage from 'src/components/common/NetworkErrorPage';
import Logout from './Logout';

function TabPanel(props) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={0}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
    color: 'aquawhite'
  };
}

export default function Settings() {
  const isLogged = localStorage.getItem('token');
  if (!isLogged) {
    Logout();
  }
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [connectionStatus, setConnectionStatus] = React.useState(<></>);

  const useGetFareResult = () => useQuery('info', async () => getFareTypeConfigurations().then((data) => data), { refetchOnWindowFocus: false });
  const {
    isLoading, isFetching, error, data
  } = useGetFareResult();
  const queryClient = new QueryClient();
  const loading = <span><ProgressBar /></span>;
  const errorMessage = error ? <span>Error</span> : null;
  const fareTypes = data
    ? (

      <FareTypeSettings fareTypes={data} />
    )
    : errorMessage;
  const {
    data: dataFcmi, isLoading: isLoadingFcmi, refetch: refectchFcmi, isFetching: isFetchingFcmi, error: isErrorFcmi
  } = useQuery('fcmi', async () => getFCMIConfigurations(), { refetchOnWindowFocus: false });
  const LoadingFcmi = <span><ProgressBar /></span>;
  const errorMessageFcmi = isErrorFcmi ? <span>Error</span> : null;
  const fcmiTypes = dataFcmi
    ? (

      <FareCalculationSettings fcmiTypes={dataFcmi} refetch={refectchFcmi} />
    )
    : errorMessageFcmi;
  const { t } = useTranslation();

  const {
    data: dataAirlineConfig, isLoading: isLoadingAirlineConfig, refetch, isFetching: isFetchingAirlineConfig, error: isErrorAirlineConfig
  } = useQuery('AirlineConfig', async () => getAirlineCodeConfigurations(), { refetchOnWindowFocus: false, enabled: false });
  const LoadingAirlineConfig = <span><ProgressBar /></span>;
  const errorMessageAirlineConfig = isErrorAirlineConfig ? <span>Error</span> : null;

  const airlineConfigTypes = dataAirlineConfig
    ? (
      <>
        <AirlineCodeTable airlineCodes={dataAirlineConfig} refetch={refetch} />
      </>
    )
    : errorMessageAirlineConfig;

  const {
    data: dataCityCodeConfig, isLoading: isLoadingCityCodeConfig, refetch: refetchCity, isFetching: isFetchingCityCodeConfig, error: isErrorCityCodeConfig
  } = useQuery('CityCodeConfig', async () => getCityCodeConfigurations(), { refetchOnWindowFocus: false, enabled: false });
  const LoadingCityCodeConfig = <span><ProgressBar /></span>;
  const errorMessageCityCodeConfig = isErrorCityCodeConfig ? <span>Error</span> : null;

  const cityCodeConfigTypes = dataCityCodeConfig
    ? (
      <>
        <CityCodeSettings cityCodes={dataCityCodeConfig} refetch={refetchCity} />
      </>
    )
    : errorMessageCityCodeConfig;

  const {
    data: dataCommissionRateConfig, isLoading: isLoadingCommissionRateConfig, refetch: refetchCommission, isFetching: isFetchingCommissionRateConfig, error: isErrorCommissionRateConfig
  } = useQuery('CommissionRateConfig', async () => getCommissionRate(), {
    refetchOnWindowFocus: false
  });
  const LoadingCommissionRateConfig = <span><ProgressBar /></span>;
  const errorMessageCommissionRateConfig = isErrorCommissionRateConfig ? <span>Error</span> : null;

  const commissionRateConfigTypes = dataCommissionRateConfig
    ? (
      <>
        <CommissionSettings
          row={dataCommissionRateConfig}
          refetch={refetchCommission}
        />
      </>
    )
    : errorMessageCommissionRateConfig;

  const {
    data: dataSchedulerConfig, isLoading: isLoadingSchedulerConfig, refetch: refetchScheduler, isFetching: isFetchingSchedulerConfig, error: isErrorSchedulerConfig
  } = useQuery('SchedulerConfig', async () => getSchedulerConfig(), {
    refetchOnWindowFocus: false
  });

  const {
    data: dataExportConfig, isLoading: isLoadingExportConfig, refetch: refetchExport, isFetching: isFetchingExportConfig, error: isErrorExportConfig
  } = useQuery('ExportConfig', async () => getExportConfiguration(), {
    refetchOnWindowFocus: false
  });

  const LoadingSchedulerConfig = <span><ProgressBar /></span>;
  const errorMessageSchedulerConfig = isErrorSchedulerConfig ? <span>Error</span> : null;

  const schedulerConfigTypes = dataSchedulerConfig
    ? (
      <>
        <SchedulerSettings
          row={dataSchedulerConfig}
          refetch={refetchScheduler}
        />
      </>
    )
    : errorMessageSchedulerConfig;

  const LoadingExportConfig = <span><ProgressBar /></span>;
  const errorMessageExportConfig = isErrorExportConfig ? <span>Error</span> : null;

  const exportConfigTypes = dataExportConfig
    ? (
      <>
        <ExportSettings
          row={dataExportConfig}
          refetch={refetchExport}
        />
      </>
    )
    : errorMessageExportConfig;

  const {
    data: dataAPIVersionConfig, isLoading: isLoadingAPIVersionConfig, isFetching: isFetchingAPIVersionConfig, error: isErrorAPIVersionConfig
  } = useQuery('APIVersionConfig', async () => getAPIVersionNumber(), {
    refetchOnWindowFocus: false
  });
  const LoadingAPIVersionConfig = <span><ProgressBar /></span>;
  const errorMessageAPIVersionConfig = isErrorAPIVersionConfig ? <span>Error</span> : null;

  const apiVersionConfig = dataAPIVersionConfig
    ? (
      <>
        <VersionSettings
          row={dataAPIVersionConfig}
        />
      </>
    )
    : errorMessageAPIVersionConfig;

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === 3) refetch();
    if (newValue === 4) refetchCity();
  };

  React.useEffect(() => {
    healthCheck().then((r) => {
      if (!r) {
        setConnectionStatus(NetworkErrorPage(t('Connection to server is lost. Please check API server'),
          () => Logout()));
      }
    });
  });

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <Helmet>
          <title>{t('Travelport GBS | Settings')}</title>
        </Helmet>
        {connectionStatus}
        <Box
          sx={{
            backgroundColor: 'background.default',
            minHeight: '100%',
            maxWidth: '1100px',
            minWidth: '1100px',
            py: 3
          }}
        >
          <Container maxWidth>
            <AppBar position="static" style={{ marginBottom: 10 }}>
              <Tabs
                value={value}
                onChange={handleChange}
                TabIndicatorProps={{ style: { background: colors.common.white } }}
                indicatorColor="secondary"
                textColor={theme.palette.background.default}
                aria-label="simple tabs example"
              >
                <Tab label="MIR" {...a11yProps(0)} color="aquawhite" />
                <Tab label={t('Fare Type')} {...a11yProps(1)} />
                <Tab label="FCMI" {...a11yProps(2)} />
                <Tab label={t('Airline Code')} {...a11yProps(3)} />
                <Tab label={t('City')} {...a11yProps(4)} />
                <Tab label={t('Commission')} {...a11yProps(5)} />
                <Tab label={t('Scheduler')} {...a11yProps(6)} />
                <Tab label={t('Export')} {...a11yProps(7)} />
                <Tab label={t('API Version Number')} {...a11yProps(8)} />
              </Tabs>
            </AppBar>
            <TabPanel value={value} index={0} sx={{ pt: 0 }} color="primary.main" borderBottom={0} marginBottom={0}>
              <SettingsView />
            </TabPanel>
            <TabPanel value={value} index={1} sx={{ pt: 0 }} color="primary.main" borderBottom={0} marginBottom={0}>
              {isLoading || isFetching ? loading
                : fareTypes}
            </TabPanel>
            <TabPanel value={value} index={2} sx={{ pt: 0 }} color="primary.main" borderBottom={0} marginBottom={0}>
              {isLoadingFcmi || isFetchingFcmi ? LoadingFcmi
                : fcmiTypes}

            </TabPanel>
            <TabPanel value={value} index={3} sx={{ pt: 0 }} color="primary.main" borderBottom={0} marginBottom={0}>
              {isLoadingAirlineConfig || isFetchingAirlineConfig ? LoadingAirlineConfig
                : airlineConfigTypes}
            </TabPanel>
            <TabPanel value={value} index={4} sx={{ pt: 0 }} color="primary.main" borderBottom={0} marginBottom={0}>
              {isLoadingCityCodeConfig || isFetchingCityCodeConfig ? LoadingCityCodeConfig
                : cityCodeConfigTypes}
            </TabPanel>
            <TabPanel value={value} index={5} sx={{ pt: 0 }} color="primary.main" borderBottom={0} marginBottom={0}>
              {isLoadingCommissionRateConfig || isFetchingCommissionRateConfig ? LoadingCommissionRateConfig
                : commissionRateConfigTypes}
            </TabPanel>
            <TabPanel value={value} index={6} sx={{ pt: 0 }} color="primary.main" borderBottom={0} marginBottom={0}>
              {isLoadingSchedulerConfig || isFetchingSchedulerConfig ? LoadingSchedulerConfig
                : schedulerConfigTypes}

            </TabPanel>
            <TabPanel value={value} index={7} sx={{ pt: 0 }} color="primary.main" borderBottom={0} marginBottom={0}>
              {isLoadingExportConfig || isFetchingExportConfig ? LoadingExportConfig
                : exportConfigTypes}
            </TabPanel>
            <TabPanel value={value} index={8} sx={{ pt: 0 }} color="primary.main" borderBottom={0} marginBottom={0}>
              {isLoadingAPIVersionConfig || isFetchingAPIVersionConfig ? LoadingAPIVersionConfig
                : apiVersionConfig}
            </TabPanel>
          </Container>
        </Box>
      </QueryClientProvider>
    </>
  );
}
