import { Link as RouterLink } from 'react-router-dom';
import { AppBar, Toolbar, Box, } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Logo from './Logo';
import LangJP from './LangJP';
import LangEN from './LangEN';

const MainNavbar = (props) => {
  const { i18n } = useTranslation();
  const changeLanguage = (lng) => (
    i18n.changeLanguage(lng)
  );

  const { t } = useTranslation();
  return (
    <AppBar
      elevation={0}
      {...props}
    >
      <Toolbar sx={{ height: 64 }}>
        <RouterLink to="/">
          <Logo />
        </RouterLink>
        <Box sx={{ flexGrow: 1 }} />
        <p>
          {t('Language')}
            &nbsp;:&nbsp;
        </p>
        <LangJP
          onClick={() => changeLanguage('ja')}
        />
        <LangEN
          onClick={() => changeLanguage('en')}
        />
      </Toolbar>
    </AppBar>

  );
};

export default MainNavbar;
