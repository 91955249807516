import React from 'react';

import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  MenuItem,
  Button,
  Stack
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { savingFCMIConfig, addFCMIConfig, deleteFCMIConfig } from 'src/api/api';
import {
  useMutation
} from 'react-query';
import ProgressBar from 'src/components/common/ProgressBar';
import { makeStyles } from '@material-ui/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import StatusMessage from 'src/components/common/StatusMessage';
import { useTranslation } from 'react-i18next';

export default function FareCalculationSettings(props) {
  const { fcmiTypes, refetch } = props;
  const [selectedFcmiTypes, setSelectedFcmiTypes] = React.useState(fcmiTypes);
  const [open, setOpen] = React.useState(false);
  const [openSetting, setOpenSetting] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [toBeDeletedId, setToBeDeletedId] = React.useState({ dialogOpen: false, id: null });
  const { t } = useTranslation();
  const handleChangeFcmiType = (event) => {
    const selected = selectedFcmiTypes.find((x) => x.id === event.target.name);
    selected.fcmiType = event.target.value;
    setSelectedFcmiTypes([...selectedFcmiTypes]);
  };

  const [configuration, setConfiguration] = React.useState('');
  const dataToUpdate = { fcmiConfigurations: selectedFcmiTypes };
  // console.log(dataToUpdate);
  const useSubmitSaveFCMIType = useMutation(
    () => savingFCMIConfig(dataToUpdate)
  );

  const dataToAdd = { fcpi: configuration.fcpi, fcmiType: configuration.fcmiType };
  const useSaveFCMI = useMutation(
    () => addFCMIConfig(dataToAdd).then(refetch)
  );
  const handleSubmit = () => {
    useSubmitSaveFCMIType.mutate();
    setOpenDialog(false);
    setOpen(false);
  };

  const handleDeleteDialog = (id) => {
    setToBeDeletedId({ dialogOpen: true, id });
  };
  const handleDelete = (id) => {
    setToBeDeletedId({ dialogOpen: false, id: null });
    deleteFCMIConfig(id).then(refetch);
  };
  const handleSave = () => {
    useSaveFCMI.mutate();
    setOpenSetting(false);
  };
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleCloseSetting = () => {
    setOpenSetting(false);
  };

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };
  const handleClosDeleteDialog = () => {
    setToBeDeletedId({ dialogOpen: false, id: null });
  };
  const ChangeValueFCPI = (e) => {
    setConfiguration({ ...configuration, fcpi: e.target.value });
  };

  const ChangeValueFCPIType = (e) => {
    setConfiguration({ ...configuration, fcmiType: e.target.value });
  };

  const TextStyleReadOndy = {
    '& .MuiInputBase-input': {
      color: '#0A121A',
      padding: '10px',
      backgroundColor: '#ececec',
      textAlign: 'center'
    },
  };
  const TextStyleChangeable = {
    '& .MuiInputBase-input': {
      color: '#0A121A',
      padding: '10px',
      // textTransform: 'uppercase'
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'rgba(0, 0, 0, 0.23)',
      },
      '&:hover fieldset': {
        borderColor: '#FF5E6F',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#FF5E6F',
      },
    },
  };

  const useStyles = makeStyles({
    commonBtn: {
      width: '120px'
    },
    commonBtnLarge: {
      width: '150px',
    },
  });
  const classes = useStyles();
  const errorMessage = useSubmitSaveFCMIType.error ? useSubmitSaveFCMIType.error.toString() : null;
  const message = (useSubmitSaveFCMIType.data != null) ? t('Updated Successfully')
    : errorMessage;
  const loading = <span><ProgressBar /></span>;
  const updateStatusMessage = (
    <div>
      <StatusMessage
        open={open}
        title={t('Response Message')}
        message={message}
      >
        <Stack direction="row" spacing={2}>
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={handleClose}
          >
            OK
          </Button>
        </Stack>
      </StatusMessage>
    </div>

  );

  const fareCalculationSettings = (
    <div>

      <Dialog open={openSetting} onClose={handleCloseSetting} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{t('FCMI Settings')}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="FCPI"
            label="FCPI"
            type="number"
            onChange={ChangeValueFCPI}
          />
          <br />
          <TextField
            select
            variant="outlined"
            label="FCPI Type"
            onChange={ChangeValueFCPIType}
            fullWidth="true"
          >
            <MenuItem value="ZeroFAuto">0:Auto</MenuItem>
            <MenuItem value="OneFManual">1:Manual</MenuItem>
            <MenuItem value="TwoFAuto_BGG_TFC_CHGD">2:Auto(BGG/TFC CHGD)</MenuItem>
            <MenuItem value="FUnKnown">-:UnKnown</MenuItem>
          </TextField>
          <br />
          {/* <FormControlLabel control={<Checkbox onChange={ChangeValueIATA} />} label="IATA" /> */}

        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleSave}>
            {t('ADD')}
          </Button>
          <Button onClick={handleCloseSetting} color="primary">
            {t('Cancel')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>

  );
  const fareCalculationDeleteSetting = (id) => (
    <Dialog
      open={toBeDeletedId.dialogOpen}
      onClose={handleClosDeleteDialog}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle
        style={{ cursor: 'move' }}
        id="draggable-dialog-title"
      >
        {t('Delete FCMI Setting')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t('Are you sure to Delete the FCMI settings')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleDelete(id)} color="primary">
          OK
        </Button>
        <Button autoFocus onClick={handleClosDeleteDialog} color="primary">
          {t('Cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
  const handleClickOpen = () => {
    setOpenSetting(true);
  };
  const fcmiSelect = (f) => (
    <div style={{ paddingBottom: 10 }}>
      <Grid
        container
        spacing={1}
        sx={{ marginBottom: '0px' }}
      >
        <Grid
          item
          md={1}
          sm={12}
        >
          <TextField
            sx={TextStyleReadOndy}
            label="FCPI"
            name="fcpi"
            value={f.fcpi}
            variant="outlined"
          />
        </Grid>
        <Grid
          item
          md={5}
          sm={12}
        >
          <TextField
            sx={TextStyleChangeable}
            select
            variant="outlined"
            key={f.id}
            name={f.id}
            value={f.fcmiType}
            label="FCMI Type"
            fullWidth="true"
            onChange={handleChangeFcmiType}
          >
            <MenuItem value="ZeroFAuto">0:Auto</MenuItem>
            <MenuItem value="OneFManual">1:Manual</MenuItem>
            <MenuItem value="TwoFAuto_BGG_TFC_CHGD">2:Auto(BGG/TFC CHGD)</MenuItem>
            <MenuItem value="FUnKnown">-:UnKnown</MenuItem>
          </TextField>
        </Grid>
        <Grid
          item
          md={3}
          sm={12}
        >
          <Button
            sx={{ marginTop: '3px' }}
            className={classes.commonBtnLarge}
            variant="contained"
            color="primary"
            onClick={() => handleDeleteDialog(f.id)}
          >
            {t('Delete Data')}
          </Button>
          {fareCalculationSettings}
        </Grid>
      </Grid>
    </div>
  );

  const messageDialog = (
    <Dialog
      open={openDialog}
      onClose={handleCloseDialog}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        {t('Save FCMI Type')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t('Are you sure to save the FCMI Setting(s)')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSubmit} color="primary">
          OK
        </Button>
        <Button autoFocus onClick={handleCloseDialog} color="primary">
          {t('Cancel')}
        </Button>

      </DialogActions>
    </Dialog>
  );

  return (
    (useSubmitSaveFCMIType.isLoading
      || useSubmitSaveFCMIType.isFetching)
      ? loading : (
        <>
          <Card style={{ marginBottom: 10 }}>
            <CardHeader
              style={{ padding: '10px' }}
              title={t('Fare Calculation Mode Indicator')}
              titleTypographyProps={{
                fontWeight: 'bold', fontSize: '16px', fontFamily: 'Roboto, Helvetica, Arial, sans-serif', color: '#0A121A',
              }}
            />
            <Divider />
            <CardContent style={{ paddingBottom: '15px' }}>
              {selectedFcmiTypes.map((f) => fcmiSelect(f))}
            </CardContent>
            {messageDialog}
            { !useSubmitSaveFCMIType.isLoading ? updateStatusMessage : null }
            {fareCalculationDeleteSetting(toBeDeletedId.id)}
          </Card>
          <Button
            sx={{ float: 'right', marginLeft: 1 }}
            className={classes.commonBtn}
            variant="contained"
            color="primary"
            label="Update"
            onClick={handleClickOpenDialog}
          >
            {t('UPDATE')}
          </Button>
          <Button
            sx={{ float: 'right' }}
            className={classes.commonBtn}
            variant="contained"
            color="primary"
            onClick={() => handleClickOpen()}
          >
            {t('Add Data')}
          </Button>
        </>
      )
  );
}
FareCalculationSettings.propTypes = {
  fcmiTypes: PropTypes.array,
  refetch: PropTypes.func.isRequired
};
