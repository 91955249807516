import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  paper: {
    top: '50%',
    left: '50%',
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    backgroundColor: theme.palette.background.paper,
    width: '900px',
    height: '500px'

  },
  scrollstyle: {
    width: '880px',
    height: '480px',
    marginTop: 10,
    marginLeft: 10,
  },
  styleh4: {
    fontWeight: 'bold', fontSize: '16px', fontFamily: 'Roboto, Helvetica, Arial, sans-serif', color: '#ffffff', backgroundColor: '#0A121A', padding: '10px',
  }
}));

export default function ImportResult(props) {
  const classes = useStyles();
  const {
    // duplicateTickets, succesfullTickets, voidTickets, successfulRefundedTickets,
    // successfulVoidedTickets, duplicateExchangeTickets, succesfullImportedExchangeTickets
    duplicateTickets, voidTickets, refundTickets, cancelRefundTickets,
    exchangeTickets, emdTickets, errorTickets, errorRecordLocator
  } = props;
  const { t } = useTranslation();

  const duplicateTicketsTable = (rows) => (
    <TableContainer component={Paper}>
      <Table style={{ width: '800px' }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center" style={{ width: '150px' }}>Ticket No.</TableCell>
            <TableCell align="left" style={{ width: '270px' }}>Pax Name</TableCell>
            <TableCell align="center" style={{ width: '60px' }}>Carrier</TableCell>
            <TableCell align="center" style={{ width: '110px' }}>MIR</TableCell>
            <TableCell align="center" style={{ width: '110px' }}>PNR</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.ticketDetail.TicketNumber.value}>
              <TableCell component="th" scope="row" align="center">
                {row.ticketDetail.TicketNumber.value}
              </TableCell>
              <TableCell align="left">
                {row.ticketDetail.Traveler.PersonName.Given}
                {' '}
                /
                {' '}
                {row.ticketDetail.Traveler.PersonName.Surname}
              </TableCell>
              <TableCell align="center">{row.ticketDetail.FlightSegment[0].Carrier}</TableCell>
              <TableCell align="right">{row.ticketDetail.SourceName}</TableCell>
              <TableCell align="center">{row.ticketDetail.RecordLocator}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
  const voidTicketsTable = (rows) => (
    <TableContainer component={Paper}>
      <Table style={{ width: '800px' }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center" style={{ width: '150px' }}>Ticket No.</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row}>
              <TableCell component="th" scope="row">
                {row}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>

  );
  const refundTicketsTable = (rows) => (
    <TableContainer component={Paper}>
      <Table style={{ width: '800px' }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center" style={{ width: '150px' }}>Ticket No.</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row}>
              <TableCell component="th" scope="row">
                {row}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>

  );
  const cancelRefundTicketsTable = (rows) => (
    <TableContainer component={Paper}>
      <Table style={{ width: '800px' }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center" style={{ width: '150px' }}>Ticket No.</TableCell>
            <TableCell align="left" style={{ width: '270px' }}>Pax Name</TableCell>
            <TableCell align="center" style={{ width: '60px' }}>Carrier</TableCell>
            <TableCell align="center" style={{ width: '110px' }}>MIR</TableCell>
            <TableCell align="center" style={{ width: '110px' }}>PNR</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.ticketDetail.TicketNumber.value}>
              <TableCell component="th" scope="row">
                {row.ticketDetail.TicketNumber.value}
              </TableCell>
              <TableCell align="left">
                {row.ticketDetail.Traveler.PersonName.Given}
                {' '}
                /
                {' '}
                {row.ticketDetail.Traveler.PersonName.Surname}
              </TableCell>
              <TableCell align="right">{row.ticketDetail.FlightSegment[0].Carrier}</TableCell>
              <TableCell align="right">{row.ticketDetail.SourceName}</TableCell>
              <TableCell align="right">{row.ticketDetail.RecordLocator}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const exchangeTicketsTable = (rows) => (
    <TableContainer component={Paper}>
      <Table style={{ width: '800px' }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center" style={{ width: '150px' }}>Ticket No.</TableCell>
            <TableCell align="left" style={{ width: '270px' }}>Pax Name</TableCell>
            <TableCell align="center" style={{ width: '60px' }}>Carrier</TableCell>
            <TableCell align="center" style={{ width: '110px' }}>MIR</TableCell>
            <TableCell align="center" style={{ width: '110px' }}>PNR</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.ticketDetail.TicketNumber.value}>
              <TableCell component="th" scope="row">
                {row.ticketDetail.TicketNumber.value}
              </TableCell>
              <TableCell align="left">
                {row.ticketDetail.Traveler.PersonName.Given}
                {' '}
                /
                {' '}
                {row.ticketDetail.Traveler.PersonName.Surname}
              </TableCell>
              <TableCell align="right">{row.ticketDetail.FlightSegment[0].Carrier}</TableCell>
              <TableCell align="right">{row.ticketDetail.SourceName}</TableCell>
              <TableCell align="right">{row.ticketDetail.RecordLocator}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>

  );
  const emdTicketsTable = (rows) => (
    <TableContainer component={Paper}>
      <Table style={{ width: '800px' }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center" style={{ width: '150px' }}>Ticket No.</TableCell>
            <TableCell align="left" style={{ width: '270px' }}>Pax Name</TableCell>
            <TableCell align="center" style={{ width: '60px' }}>Carrier</TableCell>
            <TableCell align="center" style={{ width: '110px' }}>MIR</TableCell>
            <TableCell align="center" style={{ width: '110px' }}>PNR</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.ticketDetail.TicketNumber.value}>
              <TableCell component="th" scope="row">
                {row.ticketDetail.TicketNumber.value}
              </TableCell>
              <TableCell align="left">
                {row.ticketDetail.Traveler.PersonName.Given}
                {' '}
                /
                {' '}
                {row.ticketDetail.Traveler.PersonName.Surname}
              </TableCell>
              <TableCell align="right">{row.ticketDetail.FlightSegment[0].Carrier}</TableCell>
              <TableCell align="right">{row.ticketDetail.SourceName}</TableCell>
              <TableCell align="right">{row.ticketDetail.RecordLocator}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>

  );

  const errorTicketsTable = (rows, values) => (
    <TableContainer component={Paper}>
      <Table style={{ width: '800px' }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="left" style={{ width: '110px' }}>MIR files</TableCell>
            <TableCell align="center" style={{ width: '100px' }}>Record Locator</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow>
              <TableCell align="left">{row}</TableCell>
              <TableCell align="center">{values}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>

  );

  return (
    <div className={classes.paper}>
      <PerfectScrollbar className={classes.scrollstyle}>
        <div style={{ width: '855px' }}>
          {/* {succesfullNewTickets != null && succesfullNewTickets.length > 0 ? <h4 id="simple-modal-title">succesfullNewTickets</h4> : null}
      {succesfullNewTickets != null && succesfullNewTickets.length > 0 ? successfulTicketsTable(succesfullNewTickets) : null} */}
          {duplicateTickets != null && duplicateTickets.length > 0 ? <h4 className={classes.styleh4} id="simple-modal-title">{t('duplicateWhenImportNewTickets')}</h4> : null}
          {duplicateTickets != null && duplicateTickets.length > 0 ? duplicateTicketsTable(duplicateTickets) : null}
          {voidTickets != null && voidTickets.length > 0 ? <h4 className={classes.styleh4} id="simple-modal-title">{t('nonExistentToVoidTickets')}</h4> : null}
          {voidTickets != null && voidTickets.length > 0 ? voidTicketsTable(voidTickets) : null}
          {refundTickets != null && refundTickets.length > 0 ? <h4 className={classes.styleh4} id="simple-modal-title">{t('nonExistentToRefundTickets')}</h4> : null}
          {refundTickets != null && refundTickets.length > 0 ? refundTicketsTable(refundTickets) : null}
          {cancelRefundTickets != null && cancelRefundTickets.length > 0 ? <h4 className={classes.styleh4} id="simple-modal-title">{t('nonExistentToCancelRefundTickets')}</h4> : null}
          {cancelRefundTickets != null && cancelRefundTickets.length > 0 ? cancelRefundTicketsTable(cancelRefundTickets) : null}
          {exchangeTickets != null && exchangeTickets.length > 0 ? <h4 className={classes.styleh4} id="simple-modal-title">{t('duplicateWhenImportExchangeTickets')}</h4> : null}
          {exchangeTickets != null && exchangeTickets.length > 0 ? exchangeTicketsTable(exchangeTickets) : null}
          {emdTickets != null && emdTickets.length > 0 ? <h4 className={classes.styleh4} id="simple-modal-title">{t('duplicateWhenImportEMDTickets')}</h4> : null}
          {emdTickets != null && emdTickets.length > 0 ? emdTicketsTable(emdTickets) : null}
          {errorTickets != null && errorTickets.length > 0 ? <h4 className={classes.styleh4} id="simple-modal-title">{t('errorneousFiles')}</h4> : null}
          {errorTickets != null && errorTickets.length > 0 ? errorTicketsTable(errorTickets, errorRecordLocator) : null}
        </div>
      </PerfectScrollbar>
    </div>
  );
}
ImportResult.propTypes = {
  // succesfullNewTickets: PropTypes.arrayOf(PropTypes.object),
  duplicateTickets: PropTypes.arrayOf(PropTypes.object),
  voidTickets: PropTypes.arrayOf(PropTypes.object),
  refundTickets: PropTypes.arrayOf(PropTypes.object),
  cancelRefundTickets: PropTypes.arrayOf(PropTypes.object),
  exchangeTickets: PropTypes.arrayOf(PropTypes.object),
  emdTickets: PropTypes.arrayOf(PropTypes.object),
  errorTickets: PropTypes.arrayOf(PropTypes.object),
  errorRecordLocator: PropTypes.arrayOf(PropTypes.object),
};
