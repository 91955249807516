import React from 'react';
import { TextField, Grid, CardContent } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ConnectForm from '../common/ConnectForm';

const Exchange = (prop) => {
  const {
    exchcurrentgross,
    exchdifferencegross,
    exchcurrenttax,
    exchdifferencetax,
    exchcurrenttaxdetail,
    exchdifferencetaxdetail,
    originaltktnum,
    originalcrr,
    originalplace,
    originaldate,
    originaliata,
    originalgross,
    originaltax,
    originaltaxdetails,
    currenttktnum,
    currentcrr,
    currentplace,
    currentdate,
    currentiata,
    typeofexchticket,
    originalairlinenumber,
    exchangedairlinenumber
  } = prop;
  const { t } = useTranslation();
  const TextStyleReadOndy = {
    '& .MuiInputBase-input': {
      color: '#0A121A',
      padding: '10px',
      backgroundColor: '#ececec'
    }
  };
  return (
    <ConnectForm>
      {({ register }) => (
        <CardContent>
          <Grid container spacing={3} sx={{ marginBottom: '10px' }}>
            <Grid item md={6} sm={12}>
              <TextField
                sx={TextStyleReadOndy}
                fullWidth
                label={t('New Tkt - Gross')}
                InputLabelProps={{ shrink: true }}
                name="exchcurrentgross"
                {...register('exchcurrentgross')}
                defaultValue={exchcurrentgross}
                variant="outlined"
                InputProps={{
                  readOnly: true
                }}
              />
            </Grid>
            <Grid item md={6} sm={12}>
              <TextField
                sx={TextStyleReadOndy}
                fullWidth
                label={t('Difference collection - Gross')}
                InputLabelProps={{ shrink: true }}
                name="exchdifferencegross"
                {...register('exchdifferencegross')}
                defaultValue={exchdifferencegross}
                variant="outlined"
                InputProps={{
                  readOnly: true
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} sx={{ marginBottom: '10px' }}>
            <Grid item md={6} sm={12}>
              <TextField
                sx={TextStyleReadOndy}
                fullWidth
                label={t('New Tkt - Tax')}
                InputLabelProps={{ shrink: true }}
                name="exchcurrenttax"
                {...register('exchcurrenttax')}
                defaultValue={exchcurrenttax}
                variant="outlined"
                InputProps={{
                  readOnly: true
                }}
              />
            </Grid>
            <Grid item md={6} sm={12}>
              <TextField
                sx={TextStyleReadOndy}
                fullWidth
                label={t('Difference collection - Tax')}
                InputLabelProps={{ shrink: true }}
                name="exchdifferencetax"
                {...register('exchdifferencetax')}
                defaultValue={exchdifferencetax}
                variant="outlined"
                InputProps={{
                  readOnly: true
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} sx={{ marginBottom: '10px' }}>
            <Grid item md={6} sm={12}>
              <TextField
                sx={TextStyleReadOndy}
                fullWidth
                label={t('New Tkt - Tax Details')}
                InputLabelProps={{ shrink: true }}
                name="exchcurrentgrossdetail"
                {...register('exchcurrenttaxdetail')}
                defaultValue={exchcurrenttaxdetail}
                variant="outlined"
                InputProps={{
                  readOnly: true
                }}
              />
            </Grid>
            <Grid item md={6} sm={12}>
              <TextField
                sx={TextStyleReadOndy}
                fullWidth
                label={t('Difference collection - Tax Details')}
                InputLabelProps={{ shrink: true }}
                name="exchdifferencetaxdetail"
                {...register('exchdifferencetaxdetail')}
                defaultValue={exchdifferencetaxdetail}
                variant="outlined"
                InputProps={{
                  readOnly: true
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} sx={{ marginBottom: '10px' }}>
            <Grid item md={4} sm={12}>
              <TextField
                sx={TextStyleReadOndy}
                fullWidth
                label={t('Tkt to exchange for - TicketNumber')}
                InputLabelProps={{ shrink: true }}
                name="originaltktnum"
                {...register('originaltktnum')}
                defaultValue={originaltktnum}
                variant="outlined"
                InputProps={{
                  readOnly: true
                }}
              />
            </Grid>
            <Grid item md={2} sm={12}>
              <TextField
                sx={TextStyleReadOndy}
                fullWidth
                label={t('Tkt to exchange for - Carrier')}
                InputLabelProps={{ shrink: true }}
                name="originalcrr"
                {...register('originalcrr')}
                defaultValue={originalcrr}
                variant="outlined"
                InputProps={{
                  readOnly: true
                }}
              />
            </Grid>
            <Grid item md={2} sm={12}>
              <TextField
                sx={TextStyleReadOndy}
                fullWidth
                label={t('Tkt to exchange for - Place')}
                InputLabelProps={{ shrink: true }}
                name="originalplace"
                {...register('originalplace')}
                defaultValue={originalplace}
                variant="outlined"
                InputProps={{
                  readOnly: true
                }}
              />
            </Grid>
            <Grid item md={2} sm={12}>
              <TextField
                sx={TextStyleReadOndy}
                fullWidth
                label={t('Tkt to exchange for - IssueDate')}
                InputLabelProps={{ shrink: true }}
                name="originaldate"
                {...register('originaldate')}
                defaultValue={originaldate}
                variant="outlined"
                InputProps={{
                  readOnly: true
                }}
              />
            </Grid>
            <Grid item md={2} sm={12}>
              <TextField
                sx={TextStyleReadOndy}
                fullWidth
                label={t('Tkt to exchange for - IATA')}
                InputLabelProps={{ shrink: true }}
                name="originaliata"
                {...register('originaliata')}
                defaultValue={originaliata}
                variant="outlined"
                InputProps={{
                  readOnly: true
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} sx={{ marginBottom: '10px' }}>
            <Grid item md={3} sm={12}>
              <TextField
                sx={TextStyleReadOndy}
                fullWidth
                label={t('Tkt to exchange for - Gross')}
                InputLabelProps={{ shrink: true }}
                name="originalgross"
                {...register('originalgross')}
                defaultValue={originalgross}
                variant="outlined"
                InputProps={{
                  readOnly: true
                }}
              />
            </Grid>
            <Grid item md={3} sm={12}>
              <TextField
                sx={TextStyleReadOndy}
                fullWidth
                label={t('Tkt to exchange for - Tax')}
                InputLabelProps={{ shrink: true }}
                name="originaltax"
                {...register('originaltax')}
                defaultValue={originaltax}
                variant="outlined"
                InputProps={{
                  readOnly: true
                }}
              />
            </Grid>
            <Grid item md={6} sm={12}>
              <TextField
                sx={TextStyleReadOndy}
                fullWidth
                label={t('Tkt to exchange for - Tax Details')}
                InputLabelProps={{ shrink: true }}
                name="originaltaxdetails"
                {...register('originaltaxdetails')}
                defaultValue={originaltaxdetails}
                variant="outlined"
                InputProps={{
                  readOnly: true
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} sx={{ marginBottom: '10px' }}>
            <Grid item md={4} sm={12}>
              <TextField
                sx={TextStyleReadOndy}
                fullWidth
                label={t('Original Tkt - TicketNumber')}
                InputLabelProps={{ shrink: true }}
                name="currenttktnum"
                {...register('currenttktnum')}
                defaultValue={currenttktnum}
                variant="outlined"
                InputProps={{
                  readOnly: true
                }}
              />
            </Grid>
            <Grid item md={2} sm={12}>
              <TextField
                sx={TextStyleReadOndy}
                fullWidth
                label={t('Original Tkt - Carrier')}
                InputLabelProps={{ shrink: true }}
                name="currentcrr"
                {...register('currentcrr')}
                defaultValue={currentcrr}
                variant="outlined"
                InputProps={{
                  readOnly: true
                }}
              />
            </Grid>
            <Grid item md={2} sm={12}>
              <TextField
                sx={TextStyleReadOndy}
                fullWidth
                label={t('Original Tkt - Place')}
                InputLabelProps={{ shrink: true }}
                name="currentplace"
                {...register('currentplace')}
                defaultValue={currentplace}
                variant="outlined"
                InputProps={{
                  readOnly: true
                }}
              />
            </Grid>
            <Grid item md={2} sm={12}>
              <TextField
                sx={TextStyleReadOndy}
                fullWidth
                label={t('Original Tkt - IssueDate')}
                InputLabelProps={{ shrink: true }}
                name="currentdate"
                {...register('currentdate')}
                defaultValue={currentdate}
                variant="outlined"
                InputProps={{
                  readOnly: true
                }}
              />
            </Grid>
            <Grid item md={2} sm={12}>
              <TextField
                sx={TextStyleReadOndy}
                fullWidth
                label={t('Original Tkt - IATA')}
                InputLabelProps={{ shrink: true }}
                name="currentiata"
                {...register('currentiata')}
                defaultValue={currentiata}
                variant="outlined"
                InputProps={{
                  readOnly: true
                }}
              />
            </Grid>
          </Grid>
          <input
            type="hidden"
            value={typeofexchticket}
            name="typeofexchticket"
          />
          <input
            type="hidden"
            value={originalairlinenumber}
            name="originalairlinenumber"
          />
          <input
            type="hidden"
            value={exchangedairlinenumber}
            name="exchangedairlinenumber"
          />
        </CardContent>
      )}
    </ConnectForm>
  );
};

export default Exchange;
