import {
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Grid,
  Checkbox,
  Button,
  Card,
  CardContent,
  Divider,
  CardHeader,
  Box
  // Container,
} from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
// import SendIcon from '@material-ui/icons/Send';
// import SearchIcon from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

export default function TicketFilter(props) {
  const {
    startDateTime,
    endDateTime,
    dateTimeFilterChanged,
    startTicketNumber,
    endTicketNumber,
    fromToTicketChanged,
    iataNumber,
    carrier,
    allOptionSelected
    // dateTimeFilterChangedExport, fromToTicketChangedExport, allFilterChangedExport,
    // isTicketDetails,
  } = props;

  const [selectedStartDateValue, setSelectedStartDateValue] = React.useState(
    startDateTime === undefined ? moment().format('yyyy-MM-DD') : startDateTime
  );
  const [selectedEndDateValue, setSelectedEndDateValue] = React.useState(
    endDateTime === undefined ? moment().format('yyyy-MM-DD') : endDateTime
  );
  const [selectedFromTktValue, setSelectedFromTktValue] = React.useState(startTicketNumber);
  const [selectedToTktValue, setSelectedToTktValue] = React.useState(endTicketNumber);
  const [isOptionDisplayDate, setOptionDispalyDate] = React.useState(true);
  const [isOptionDispalyAll, setOptionDispalyAll] = React.useState(false);
  const [isOptionDisplayTicket, setOptionDispalyTicket] = React.useState(false);
  const [selectedIataNumber, setSelectedIataNumberValue] = React.useState(
    iataNumber || ''
  );
  const [selectedCarrierValue, setSelectedCarrierValue] = React.useState(
    carrier || ''
  );
  const [selectedEmdValue, setSelectedEmdValue] = React.useState(false);
  const [selectedRefundValue, setSelectedRefundValue] = React.useState(false);
  const [selectedExportedValue, setSelectedExportedValue] = React.useState(false);
  const [selectedNdcValue, setSelectedNdcValue] = React.useState(false);
  // const [exportButtonEnable, setexportButtonEnable] = React.useState(true);

  const HandleChangeValue = (e) => {
    if (e.target.value === 'radioDisplayTicketNo') {
      setOptionDispalyTicket(true);
      setOptionDispalyAll(false);
      setOptionDispalyDate(false);
    } else if (e.target.value === 'radioDisplayDate') {
      setOptionDispalyTicket(false);
      setOptionDispalyAll(false);
      setOptionDispalyDate(true);
    } else if (e.target.value === 'radioDisplayAll') {
      setOptionDispalyTicket(false);
      setOptionDispalyAll(true);
      setOptionDispalyDate(false);
    }
  };
  const DEFAULT_PAGE_SIZE = 5000000;
  const [pageIndex, setPageIndex] = React.useState(0);
  const [ticketIndex] = React.useState(0);
  if (Math.floor(ticketIndex / DEFAULT_PAGE_SIZE) !== pageIndex) {
    setPageIndex(Math.floor(ticketIndex / DEFAULT_PAGE_SIZE));
  }
  const ChangeValueEndDate = (e) => {
    setSelectedEndDateValue(e.target.value);
  };
  const ChangeValueStartDate = (e) => {
    setSelectedStartDateValue(e.target.value);
  };
  const ChangeValueFromTktNum = (e) => {
    setSelectedFromTktValue(e.target.value);
  };
  const ChangeValueToTktNum = (e) => {
    setSelectedToTktValue(e.target.value);
  };
  const searchButtonEnable = !(
    isOptionDisplayDate
    && (!selectedStartDateValue || !selectedEndDateValue)
  );

  const ChangeIataValue = (e) => {
    setSelectedIataNumberValue(e.target.value);
  };

  const ChangeEMDValue = (e) => {
    if (e.target.checked === true) {
      setSelectedEmdValue(e.target.checked);
    } else {
      setSelectedEmdValue(e.target.checked);
    }
  };
  const ChangeRefundValue = (e) => {
    if (e.target.checked === true) {
      setSelectedRefundValue(e.target.checked);
    } else {
      setSelectedRefundValue(e.target.checked);
    }
    console.log(e.target.checked);
  };
  const ChangeNDCValue = (e) => {
    if (e.target.checked === true) {
      setSelectedNdcValue(e.target.checked);
    } else {
      setSelectedNdcValue(e.target.checked);
    }
  };
  const ChangeExportedValue = (e) => {
    if (e.target.checked === true) {
      setSelectedExportedValue(e.target.checked);
    } else {
      setSelectedExportedValue(e.target.checked);
    }
  };
  const ChangeCarrierValue = (e) => {
    setSelectedCarrierValue(e.target.value);
  };

  const { t } = useTranslation();

  const handleSearch = () => {
    // setexportButtonEnable(false);
    if (isOptionDispalyAll) {
      allOptionSelected({
        iataNumber: selectedIataNumber,
        carrier: selectedCarrierValue,
        isEmd: selectedEmdValue,
        isRefund: selectedRefundValue,
        isExported: selectedExportedValue,
        isNdc: selectedNdcValue,
        pageIndex,
        DEFAULT_PAGE_SIZE
      });
    }
    if (isOptionDisplayDate) {
      dateTimeFilterChanged({
        startDateTime: selectedStartDateValue,
        endDateTime: selectedEndDateValue,
        iataNumber: selectedIataNumber,
        carrier: selectedCarrierValue,
        isEmd: selectedEmdValue,
        isRefund: selectedRefundValue,
        isExported: selectedExportedValue,
        isNdc: selectedNdcValue,
        pageIndex,
        DEFAULT_PAGE_SIZE
      });
    }
    if (isOptionDisplayTicket) {
      fromToTicketChanged({
        StartTicketNumber: selectedFromTktValue,
        EndTicketNumber: selectedToTktValue,
        iataNumber: selectedIataNumber,
        carrier: selectedCarrierValue,
        isEmd: selectedEmdValue,
        isRefund: selectedRefundValue,
        isExported: selectedExportedValue,
        isNdc: selectedNdcValue,
        pageIndex,
        DEFAULT_PAGE_SIZE
      });
    }
  };

  const TextStyleChangeable = {
    '& .MuiInputBase-input': {
      color: '#0A121A',
      padding: '10px'
      // textTransform: 'uppercase'
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'rgba(0, 0, 0, 0.23)'
      },
      '&:hover fieldset': {
        borderColor: '#FF5E6F'
      },
      '&.Mui-focused fieldset': {
        borderColor: '#FF5E6F'
      }
    }
  };
  const useStyles = makeStyles({
    labelRoot: {
      fontSize: '14px'
    },
    commonBtn: {
      width: '120px'
    }
  });
  const classes = useStyles();

  return (
    <div>
      <Card style={{ clear: 'right' }}>
        <CardHeader
          style={{ padding: '10px' }}
          title={t('Search')}
          titleTypographyProps={{
            fontWeight: 'bold',
            fontSize: '16px',
            fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
            color: '#0A121A'
          }}
        />
        <Divider />
        <CardContent style={{ padding: '5px' }}>
          <div style={{ float: 'left', width: 540 }}>
            <RadioGroup style={{ marginLeft: 15, marginBottom: 0 }}>
              <div style={{ marginTop: 2, marginBottom: 10 }}>
                <FormControlLabel
                  style={{ width: 170 }}
                  value="Ticket Number"
                  control={(
                    <Radio
                      value="radioDisplayTicketNo"
                      onChange={HandleChangeValue}
                      checked={isOptionDisplayTicket}
                    />
                  )}
                  label={(
                    <span className={classes.labelRoot}>
                      {t('Ticket Number')}
                    </span>
                  )}
                />
                <TextField
                  sx={TextStyleChangeable}
                  id="tktnum"
                  label="From"
                  type="text"
                  defaultValue=""
                  value={selectedFromTktValue}
                  onChange={ChangeValueFromTktNum}
                  InputLabelProps={{
                    shrink: true
                  }}
                  style={{ width: 170 }}
                />
                <TextField
                  sx={TextStyleChangeable}
                  id="tktnum"
                  label="To"
                  type="tktnum"
                  defaultValue=""
                  value={selectedToTktValue}
                  onChange={ChangeValueToTktNum}
                  InputLabelProps={{
                    shrink: true
                  }}
                  style={{ marginLeft: 10, width: 170 }}
                />
              </div>
              <div style={{ marginBottom: 10 }}>
                <FormControlLabel
                  style={{ width: 170 }}
                  value="Issue Date"
                  control={(
                    <Radio
                      value="radioDisplayDate"
                      onChange={HandleChangeValue}
                      checked={isOptionDisplayDate}
                    />
                  )}
                  label={
                    <span className={classes.labelRoot}>{t('Issue Date')}</span>
                  }
                />
                <TextField
                  sx={TextStyleChangeable}
                  id="issuedt"
                  label="From"
                  type="date"
                  value={selectedStartDateValue}
                  defaultValue={moment().format('yyyy-MM-DD')}
                  onChange={ChangeValueStartDate}
                  InputLabelProps={{
                    shrink: true
                  }}
                  style={{ width: 170 }}
                />
                <TextField
                  sx={TextStyleChangeable}
                  id="issuedt"
                  label="To"
                  type="date"
                  value={selectedEndDateValue}
                  defaultValue={moment().format('yyyy-MM-DD')}
                  onChange={ChangeValueEndDate}
                  InputLabelProps={{
                    shrink: true
                  }}
                  style={{ marginLeft: 10, width: 170 }}
                />
              </div>
              <div style={{ marginBottom: 10 }}>
                <FormControlLabel
                  style={{ width: 150 }}
                  value="All Tickets"
                  control={(
                    <Radio
                      value="radioDisplayAll"
                      onChange={HandleChangeValue}
                      checked={isOptionDispalyAll}
                    />
                  )}
                  // label={t('All Tickets                          ** If you specify an option, all that correspond to the specified option.')}
                  label={(
                    <span className={classes.labelRoot}>
                      {t('All Tickets')}
                    </span>
                  )}
                />
              </div>
            </RadioGroup>
          </div>
          <div
            style={{
              float: 'left',
              width: 470,
              marginLeft: 10,
              marginBottom: 10
            }}
          >
            <Box sx={{ p: 1, border: '1px dashed #d3d3d3', height: 220 }}>
              <p
                style={{
                  paddingTop: 5,
                  paddingBottom: 8,
                  fontSize: '14px',
                  fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                  color: '#0A121A'
                }}
              >
                {t('Search option')}
              </p>
              <Divider />
              <div style={{ float: 'left', marginLeft: 5 }}>
                <Grid container spacing={1}>
                  <Grid item md={4} sm={12}>
                    <TextField
                      sx={TextStyleChangeable}
                      style={{ width: 120 }}
                      id="iataNumber"
                      label="IATANumber"
                      type="text"
                      defaultValue=""
                      margin="normal"
                      onChange={ChangeIataValue}
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item md={4} sm={12}>
                    <TextField
                      sx={TextStyleChangeable}
                      style={{ width: 120, marginTop: 3 }}
                      id="carrier"
                      label="Carrier"
                      type="text"
                      defaultValue=""
                      margin="normal"
                      onChange={ChangeCarrierValue}
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </Grid>
                </Grid>
              </div>
              <div style={{ float: 'left', marginLeft: 20 }}>
                <Grid container spacing={1}>
                  <Grid
                    item
                    // md={12}
                    sm={12}
                  >
                    <FormControlLabel
                      // style={{ paddingLeft: 20, paddingTop: 15 }}
                      value=""
                      control={(
                        <Checkbox
                          id="isEMD"
                          name="EMD"
                          onChange={ChangeEMDValue}
                        />
                      )}
                      label={(
                        <span className={classes.labelRoot}>
                          {t('Only EMD data')}
                        </span>
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid
                    item
                    // md={8}
                    sm={12}
                  >
                    <FormControlLabel
                      // style={{ paddingLeft: 20, paddingTop: 0 }}
                      value=""
                      control={(
                        <Checkbox
                          id="isExported"
                          name="Exclude Exported"
                          onChange={ChangeExportedValue}
                        />
                      )}
                      label={(
                        <span className={classes.labelRoot}>
                          {t('Only unexported data')}
                        </span>
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid
                    item
                    // md={8}
                    sm={12}
                  >
                    <FormControlLabel
                      value=""
                      control={(
                        <Checkbox
                          id="isRefund"
                          name="Refund"
                          onChange={ChangeRefundValue}
                        />
                      )}
                      label={(
                        <span className={classes.labelRoot}>
                          {t('Only Refund data')}
                        </span>
                      )}
                    />
                    <p
                      style={{
                        fontSize: '14px',
                        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                        color: '#0A121A'
                      }}
                    >
                      (
                      {t('not containing refunded EMD data')}
                      )
                    </p>
                  </Grid>
                </Grid>
              </div>
              <div style={{ float: 'left', marginLeft: -20 }}>
                <Grid container spacing={1}>
                  <Grid
                    item
                    // md={12}
                    sm={12}
                  >
                    <FormControlLabel
                      // style={{ paddingLeft: 20, paddingTop: 15 }}
                      value=""
                      control={(
                        <Checkbox
                          id="isNDC"
                          name="NDC"
                          onChange={ChangeNDCValue}
                        />
                      )}
                      label={(
                        <span className={classes.labelRoot}>
                          {t('Only NDC data')}
                        </span>
                      )}
                    />
                  </Grid>
                </Grid>
              </div>
            </Box>
          </div>
          <div
            style={{
              float: 'left',
              width: '860px',
              marginBottom: 10
            }}
          >
            <Box>
              <Grid container>
                <Grid item md={12} sm={12}>
                  <Button
                    className={classes.commonBtn}
                    style={{ float: 'right' }}
                    variant="contained"
                    color="primary"
                    disabled={!searchButtonEnable}
                    // endIcon={<SendIcon />}
                    onClick={handleSearch}
                  >
                    {t('Search')}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </div>
        </CardContent>
      </Card>
      {/* <Box>
        <Container style={{ paddingRight: 0, paddingTop: 10 }}>
          {setExportBtn}
        </Container>
      </Box> */}
    </div>
  );
}

TicketFilter.propTypes = {
  dateTimeFilterChanged: PropTypes.func,
  startDateTime: PropTypes.string,
  endDateTime: PropTypes.string,
  startTicketNumber: PropTypes.string,
  endTicketNumber: PropTypes.string,
  fromToTicketChanged: PropTypes.func,

  iataNumber: PropTypes.string,
  carrier: PropTypes.string,
  allOptionSelected: PropTypes.func
};
