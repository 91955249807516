import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  Alert,
  AlertTitle,
  Stack,
  // IconButton,
  // Collapse
} from '@material-ui/core';
// import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';
import {
  healthCheck
} from 'src/api/api';
import { useMutation } from 'react-query';
import NetworkErrorPage from 'src/components/common/NetworkErrorPage';
import Logout from './Logout';

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('admin@travelport.com');
  const [password, setPassword] = useState('123');
  // const [isCollapse, setCollapse] = useState(true);
  const { t } = useTranslation();
  const [connectionStatus, setConnectionStatus] = React.useState(<></>);

  const mutation = useMutation(() => fetch('http://localhost:4001/api/account/token', {
    method: 'post',
    headers: {
      'content-type': 'application/json',
      accept: 'application/json'
    },
    body: JSON.stringify({
      username: email,
      password
    })
  }));
    // store token here if status is OK
  if (mutation.data && mutation.data.status === 200) {
    mutation.data.json().then((data) => {
      localStorage.setItem('token', data);
      navigate('/', { replace: true });
    });
  }

  React.useEffect(() => {
    healthCheck().then((r) => {
      if (!r) {
        setConnectionStatus(NetworkErrorPage(t('Connection to server is lost. Please check API server'), () => Logout()));
      }
    });
  });
  return mutation.isLoading ? (
    null
  ) : (
    <>
      {mutation.data && mutation.data.status === 400 ? (
        <Stack>
          <Alert variant="outlined" severity="error">
            <AlertTitle>{t('Error')}</AlertTitle>
            {t('Sign in failed —')}
            {' '}
            <strong>{t('Please check Email Address and Password')}</strong>
          </Alert>
        </Stack>
      ) : null}
      <Helmet>
        <title>{t('Travelport GBS | Sign in')}</title>
      </Helmet>
      {connectionStatus}
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center'
        }}
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              email,
              password
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email(t('Must be a valid email'))
                .max(255)
                .required(t('Email is required')),
              password: Yup.string().max(255).required(t('Password is required'))
            })}
            // eslint-disable-next-line consistent-return
            onSubmit={() => {
              // setCollapse(true);
              mutation.mutate();
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched
            }) => (
              <form onSubmit={handleSubmit}>
                <Box sx={{ mb: 3 }}>
                  <Typography color="textPrimary" variant="h2">
                    <p>{t('Sign in')}</p>
                  </Typography>
                </Box>
                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label={t('Email Address')}
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    handleChange(e);
                  }}
                  type="email"
                  value={email}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label={t('Password')}
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    handleChange(e);
                  }}
                  type="password"
                  value={password}
                  variant="outlined"
                />
                <Box sx={{ py: 2 }}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    <p>{t('Sign in now')}</p>
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </>
  );
};

export default Login;
