import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  AppBar,
  Badge,
  Box,
  Hidden,
  IconButton,
  Toolbar
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import InputIcon from '@material-ui/icons/Input';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import Logo from './Logo';
import LangJP from './LangJP';
import LangEN from './LangEN';
import Version from './Version';

const useStyles = makeStyles({
  langlabel: {
    fontSize: '12px',
    fontFamily: 'DrukText-Bold sans-serif',
    fontWeight: 'nomal',
    color: '#fff'
  }
});

const DashboardNavbar = ({ onMobileNavOpen, ...rest }) => {
  const classes = useStyles();
  const [notifications] = useState([]);
  const { i18n } = useTranslation();
  const changeLanguage = (lng) => (
    i18n.changeLanguage(lng)
  );
  const signOut = () => {
    localStorage.removeItem('token');
    window.location = '/login';
  };
  const { t } = useTranslation();
  return (
    <AppBar
      elevation={0}
      {...rest}
    >
      <Toolbar>
        <RouterLink to="/">
          <Logo />
        </RouterLink>
        <Box sx={{ flexGrow: 1 }} />
        <Version />
        <IconButton
          color="inherit"
          onClick={onMobileNavOpen}
        />
        <IconButton
          color="inherit"
        />
        <p className={classes.langlabel}>
          {t('Language')}
            &nbsp;:&nbsp;
        </p>

        <LangJP
          onClick={() => changeLanguage('ja')}
        />
        <LangEN
          onClick={() => changeLanguage('en')}
        />
        <Hidden lgDown>
          <IconButton color="inherit">
            <Badge
              badgeContent={notifications.length}
              color="primary"
              variant="dot"
            >
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <IconButton color="inherit">
            <InputIcon onClick={() => signOut()} />
          </IconButton>
        </Hidden>
        <Hidden lgUp>
          <IconButton
            color="inherit"
            onClick={onMobileNavOpen}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

DashboardNavbar.propTypes = {
  onMobileNavOpen: PropTypes.func
};

export default DashboardNavbar;
