import React from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {
  TextField,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TablePagination from '@material-ui/core/TablePagination';
import {
  useMutation
} from 'react-query';

import { updateCityCodeConfigurations, deleteCityCodeConfigurations, addCityCodeConfigurations } from 'src/api/api';

const CityCodeRow = (props) => {
  const { row, refetch } = props;
  const [codeState, setCodeState] = React.useState(row);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const { t } = useTranslation();

  const handleChange = (e) => {
    row.cityCode = e.target.value;
    setCodeState((prevConfiguration) => ({ ...prevConfiguration, ...row }));
  };

  const handleChangeCityName = (e) => {
    row.cityName = e.target.value;
    setCodeState((prevConfiguration) => ({ ...prevConfiguration, ...row }));
  };

  const handleChangeCode = (e) => {
    row.code = e.target.value;
    setCodeState((prevConfiguration) => ({ ...prevConfiguration, ...row }));
  };

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleClosDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };
  const handleClickDeleteOpenDialog = () => {
    setOpenDeleteDialog(true);
  };
  const dataToUpdate = { cityCodeConfiguration: [row] };
  const useUpdateMutation = useMutation(
    () => updateCityCodeConfigurations(dataToUpdate).then(refetch)
  );

  const dataToDelete = { cityCodeConfiguration: [row] };
  const useDeleteMutation = useMutation(
    () => deleteCityCodeConfigurations(dataToDelete).then(refetch)
  );
  const handleSubmit = () => {
    useUpdateMutation.mutate();
    setOpenDialog(false);
  };

  const handleDelete = () => {
    useDeleteMutation.mutate();
    setOpenDeleteDialog(false);
  };

  const useStyles = makeStyles({
    commonBtnSmall: {
      width: '90px'
    }
  });
  const classes = useStyles();
  const TextStyleChangeable = {
    '& .MuiInputBase-input': {
      color: '#0A121A',
      padding: '10px',
      // textTransform: 'uppercase',
      textAlign: 'center'
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'rgba(0, 0, 0, 0.23)',
      },
      '&:hover fieldset': {
        borderColor: '#FF5E6F',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#FF5E6F',
      },
    },
  };
  return (
    <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
      <TableCell align="center" width="150" sx={{ padding: 1 }}><TextField sx={TextStyleChangeable} value={codeState.code} onChange={(e) => handleChangeCode(e)} fullWidth="true" /></TableCell>
      <TableCell align="center" width="200" sx={{ padding: 1 }}><TextField sx={TextStyleChangeable} value={codeState.cityName} onChange={(e) => handleChangeCityName(e)} fullWidth="true" /></TableCell>
      <TableCell align="center" width="150" sx={{ padding: 1 }}><TextField sx={TextStyleChangeable} value={codeState.cityCode} onChange={(e) => handleChange(e)} fullWidth="true" /></TableCell>
      <TableCell colspan={2} sx={{ padding: 1 }}>
        <Button
          className={classes.commonBtnSmall}
          variant="contained"
          color="primary"
          onClick={handleClickOpenDialog}
        >
          {t('Update')}
        </Button>
        <Button
          className={classes.commonBtnSmall}
          sx={{ marginLeft: 1 }}
          variant="contained"
          color="primary"
          onClick={handleClickDeleteOpenDialog}
        >
          {t('Delete')}
        </Button>
      </TableCell>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle
          style={{ cursor: 'move' }}
          id="draggable-dialog-title"
        >
          {t('Update City Code')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('Are you sure to update the City Code')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit} color="primary">
            {t('OK')}
          </Button>
          <Button autoFocus onClick={handleCloseDialog} color="primary">
            {t('Cancel')}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openDeleteDialog}
        onClose={handleClosDeleteDialog}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle
          style={{ cursor: 'move' }}
          id="draggable-dialog-title"
        >
          {t('Delete City Code')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('Are you sure to delete the City Code')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDelete} color="primary">
            {t('OK')}
          </Button>
          <Button autoFocus onClick={handleClosDeleteDialog} color="primary">
            {t('Cancel')}
          </Button>
        </DialogActions>
      </Dialog>

    </TableRow>

  );
};
CityCodeRow.propTypes = {
  row: PropTypes.object.isRequired,
  refetch: PropTypes.func.isRequired
};

const CityCodeSettings = (props) => {
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [open, setOpen] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 5));
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const { cityCodes, refetch } = props;
  const [configuration, setConfiguration] = React.useState('');

  const handleClose = () => {
    setOpen(false);
  };
  const addToUpdate = { cityCodeConfiguration: [configuration] };
  const addUpdateMutation = useMutation(
    () => addCityCodeConfigurations(addToUpdate).then(refetch)
  );

  const handleAddSubmit = () => {
    addUpdateMutation.mutate();
    setOpen(false);
  };

  const ChangeValueCode = (e) => {
    setConfiguration({ ...configuration, Code: e.target.value });
  };
  const ChangeValueCityName = (e) => {
    setConfiguration({ ...configuration, CityName: e.target.value });
  };
  const ChangeValueCityCode = (e) => {
    setConfiguration({ ...configuration, CityCode: e.target.value });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const { t } = useTranslation();

  const useStyles = makeStyles({
    commonBtn: {
      width: '120px'
    }
  });
  const classes = useStyles();
  const TextStyleChangeable = {
    '& .MuiInputBase-input': {
      color: '#0A121A',
      padding: '10px',
      // textTransform: 'uppercase'
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'rgba(0, 0, 0, 0.23)',
      },
      '&:hover fieldset': {
        borderColor: '#FF5E6F',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#FF5E6F',
      },
    },
  };
  const cityCodeSettings = (
    <div>

      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{t('Add Code')}</DialogTitle>
        <DialogContent>
          <TextField
            sx={TextStyleChangeable}
            autoFocus
            margin="dense"
            id="Code"
            label={t('Airport Code')}
            onChange={ChangeValueCode}
          />
          <br />
          <TextField
            sx={TextStyleChangeable}
            margin="dense"
            id="CityName"
            label={t('City Name')}
            onChange={ChangeValueCityName}
          />
          <br />
          <TextField
            sx={TextStyleChangeable}
            margin="dense"
            id="CityCode"
            label={t('City Code')}
            onChange={ChangeValueCityCode}
          />
          <br />

        </DialogContent>
        <DialogActions>
          <Button onClick={handleAddSubmit} color="primary">
            {t('Add Data')}
          </Button>
          <Button onClick={handleClose} color="primary">
            {t('Cancel')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>

  );
  return (
    <>
      <Card style={{ marginBottom: 10 }}>
        <CardHeader
          style={{ padding: '10px' }}
          title={t('City Code Settings')}
          titleTypographyProps={{
            fontWeight: 'bold', fontSize: '16px', fontFamily: 'Roboto, Helvetica, Arial, sans-serif', color: '#0A121A',
          }}
        />
        <Divider />
        <CardContent style={{ paddingBottom: '15px' }}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell align="center" width="150" sx={{ padding: 1 }}>{t('Airport Code')}</TableCell>
                  <TableCell align="center" width="200" sx={{ padding: 1 }}>{t('City Name')}</TableCell>
                  <TableCell align="center" width="150" sx={{ padding: 1 }}>{t('City Code')}</TableCell>
                  <TableCell colspan={2}> </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {cityCodes
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((s) => (
                    <CityCodeRow key={s.id} row={s} refetch={refetch} />
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5]}
            component="div"
            count={cityCodes.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </CardContent>
      </Card>
      <div>
        <Button
          className={classes.commonBtn}
          sx={{ float: 'right' }}
          variant="contained"
          color="primary"
          onClick={() => handleClickOpen()}
        >
          {t('Add Data')}
        </Button>
        {cityCodeSettings}
      </div>
    </>

  );
};

CityCodeSettings.propTypes = {
  cityCodes: PropTypes.array.isRequired,
  refetch: PropTypes.func.isRequired
};
export default CityCodeSettings;
