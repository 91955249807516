import React from 'react';
import PropTypes from 'prop-types';

import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  Grid
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const VersionSettings = (props) => {
  const { row } = props;
  const [versionNumber, setversionNumber] = React.useState(row.data);
  const { t } = useTranslation();

  console.log(setversionNumber);
  const TextStyleReadOndy = {
    '& .MuiInputBase-input': {
      color: '#0A121A',
      padding: '10px',
      backgroundColor: '#ececec'
    },
  };

  return (
    <>
      <Card style={{ marginBottom: 10 }}>
        <CardHeader
          style={{ padding: '10px' }}
          title={t('API Version Number')}
          titleTypographyProps={{
            fontWeight: 'bold', fontSize: '16px', fontFamily: 'Roboto, Helvetica, Arial, sans-serif', color: '#0A121A',
          }}
        />
        <Divider />
        <CardContent style={{ paddingBottom: '15px' }}>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={3}
              sm={12}
            >
              <TextField
                sx={TextStyleReadOndy}
                fullWidth
                label={t('API Version Number')}
                name="versionNumber"
                variant="outlined"
                value={versionNumber}
              />
            </Grid>

          </Grid>
        </CardContent>
      </Card>

    </>

  );
};

VersionSettings.propTypes = {
  row: PropTypes.array.isRequired

};

export default VersionSettings;
