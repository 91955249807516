/* eslint-disable no-lone-blocks */
import React from 'react';
import PropTypes from 'prop-types';

import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  Grid,
  Button,
  Stack
} from '@material-ui/core';
import {
  useMutation
} from 'react-query';

import { updateCommissionRateConfigurations } from 'src/api/api';
import { makeStyles } from '@material-ui/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTranslation } from 'react-i18next';
import StatusMessage from 'src/components/common/StatusMessage';

const CommissionSettings = (props) => {
  const { row, refetch } = props;
  const [configuration, setConfiguration] = React.useState(row.data[0].commissionRate);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const handleChange = (e) => {
    setConfiguration(e.target.value);
  };

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const dataToUpdate = { CommissionRate: configuration };
  const useUpdateMutation = useMutation(
    () => updateCommissionRateConfigurations(dataToUpdate).then(refetch)
  );
  const handleSubmit = () => {
    useUpdateMutation.mutate();
    setOpen(true);
    setOpenDialog(false);
  };
  const { t } = useTranslation();

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  const showMessage = (
    <div>
      <StatusMessage
        open={open}
        title={t('Response Message')}
        message={t('Updated Successfully')}
      >
        <Stack direction="row" spacing={2}>
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={handleClose}
          >
            OK
          </Button>
        </Stack>
      </StatusMessage>
    </div>
  );

  const commissionSetting = (
    <div>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle
          style={{ cursor: 'move' }}
          id="draggable-dialog-title"
        >
          {t('Update Commission Code')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('Are you sure to update Commission')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit} color="primary">
            OK
          </Button>
          <Button autoFocus onClick={handleCloseDialog} color="primary">
            {t('Cancel')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );

  const useStyles = makeStyles({
    commonBtn: {
      width: '120px'
    }
  });
  const classes = useStyles();

  const TextStyleChangeable = {
    '& .MuiInputBase-input': {
      color: '#0A121A',
      padding: '10px',
      // textTransform: 'uppercase'
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'rgba(0, 0, 0, 0.23)',
      },
      '&:hover fieldset': {
        borderColor: '#FF5E6F',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#FF5E6F',
      },
    },
  };
  return (
    <>
      <Card style={{ marginBottom: 10 }}>
        <CardHeader
          style={{ padding: '10px' }}
          title={t('Concession Rate Commission')}
          titleTypographyProps={{
            fontWeight: 'bold', fontSize: '16px', fontFamily: 'Roboto, Helvetica, Arial, sans-serif', color: '#0A121A',
          }}
        />
        <Divider />
        <CardContent style={{ paddingBottom: '15px' }}>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={3}
              sm={12}
            >
              <TextField
                sx={TextStyleChangeable}
                fullWidth
                label={t('Concession Rate Commission')}
                name="commissionRate"
                variant="outlined"
                value={configuration}
                onChange={handleChange}
              />
            </Grid>

          </Grid>
        </CardContent>
      </Card>
      <div>
        <Button
          sx={{ float: 'right' }}
          className={classes.commonBtn}
          variant="contained"
          color="primary"
          onClick={handleClickOpenDialog}
        >
          {t('Update')}

        </Button>
        {showMessage}
        {commissionSetting}
      </div>
    </>

  );
};

CommissionSettings.propTypes = {
  row: PropTypes.array.isRequired,
  refetch: PropTypes.func.isRequired

};
export default CommissionSettings;
