import { makeStyles } from '@material-ui/styles';

const LangJP = (props) => {
  const useStyles = makeStyles(() => ({
    imageButton: {
      width: '34px',
      height: '22px',
      cursor: 'pointer'
    },
  }));

  const classes = useStyles();
  return (
    <img
      alt="Japanese"
      src="/static/images/lang_JP.png"
      width="34px"
      height="22px"
      className={classes.imageButton}
      {...props}
    />
  );
};

export default LangJP;
